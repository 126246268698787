export const getFlightAttributes = ({
  arrivalAirport,
  departureAirport,
  outboundDate,
}) => ({
  'data-skyscanner-widget': 'SearchWidget',
  'data-origin-iata-code': arrivalAirport || '',
  'data-destination-iata-code': departureAirport || '',
  'data-flight-outbound-date': outboundDate || '',
  'data-flight-type': 'one way',
});

export const getHotelAttributes = ({
  destination,
  checkinDate,
  checkoutDate,
}) => ({
  'data-skyscanner-widget': 'HotelSearchWidget',
  'data-destination-name': destination || '',
  'data-button-label': 'Search hotels',
  'data-hotel-check-in-date': checkinDate || '',
  'data-hotel-check-out-date': checkoutDate || '',
});
