import { useParams } from 'react-router-dom';
import { useState } from 'react';

import QuickstartModal from '../../molecules/Modal/AutoPilotModal';

function AutoPilot() {
  const { slug: tripId } = useParams();
  const [isQuickstartEnable, setIsQuickstartEnable] = useState(false);

  return (
    <QuickstartModal
      open={isQuickstartEnable}
      onClose={() => setIsQuickstartEnable(false)}
      handleClose={() => {
        setIsQuickstartEnable(false);
      }}
      tripId={tripId}
      handleOpen={() => setIsQuickstartEnable(true)}
      isImported
    />
  );
}

export default AutoPilot;
