// TODO: replace with styled
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { withStyles } from '@mui/styles';

// custom Buttons
export { default as RefetchButton } from './RefetchButton';
export { default as InviteButton } from './InviteButton';

// styled Buttons
export const ButtonDefault = styled(Button)(({ theme: { palette } }) => ({
  backgroundColor: palette.primary.main,
  color: '#FFF',
}));

export const FullPageButton = styled(Button)(() => ({
  width: '100%',
}));

export const EditProfileCoverImageButton = styled(Button)(() => ({
  backgroundColor: 'rgba(34, 34, 34, 0.5)',
  color: '#FFF',
  borderRadius: 4,
  fontSize: '0.8rem',
  padding: '4px 8px',
  '& .MuiButton-startIcon': {
    margin: '0px 4px 0px 0px',
  },
  '&:hover': {
    backgroundColor: 'rgba(34, 34, 34, 0.5)',
  },
}));

export const LightGreyButton = styled(Button)(({ theme: { palette } }) => ({
  backgroundColor: palette.system.lightgrey,
  color: '#A7A7A7',
  borderRadius: 6,
  fontSize: '14px',
  '&:hover': {
    backgroundColor: palette.system.lightgrey,
    color: palette.text.light,
  },
}));

export const ClearButton = styled(Button)(({ theme: { palette } }) => ({
  backgroundColor: palette.system.white,
  color: palette.primary.main,
  width: '100%',
  '&:hover': {
    color: palette.system.white,
    backgroundColor: palette.primary.light,
  },
}));

export const TravelRestrictionsButton = styled(Button)(
  ({ theme: { palette } }) => ({
    backgroundColor: palette.primary.extraLight,
    color: palette.primary.main,
    borderRadius: '50px',
    display: 'flex',
    marginBottom: '8px',
    padding: '6px 16px',
    paddingLeft: '12px',
    fontSize: '12px',
    lineHeight: '14.32px',
    '& .MuiButton-startIcon': {
      margin: '0px 4px 0px 0px',
      '& svg': {
        fill: palette.primary.main,
      },
    },
    '&:hover': {
      opacity: 1,
      backgroundColor: palette.primary.extraLight,
      color: palette.primary.light,
      '& .MuiButton-startIcon': {
        '& svg': {
          fill: palette.primary.light,
        },
      },
    },
  })
);

export const ItineraryActionButton = withStyles(() => ({
  root: {
    backgroundColor: 'transparent',
    color: '#8A8A8A',
    padding: 0,
    fontSize: 12,
    fontWeight: 400,
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#8A8A8A',
      textDecoration: 'underline',
      textUnderlineOffset: '2px',
    },
  },
}))(Button);

export const OutlinedButton = styled(Button)(
  ({ theme: { palette, typography } }) => ({
    ...typography.ctaSmall,
    fontWeight: 400,
    backgroundColor: '#FFF',
    color: palette.primary.main,
    borderRadius: 6,
    border: `1px solid ${palette.primary.main}`,
    '&:hover': {
      color: palette.primary.light,
      backgroundColor: '#FFF',
      borderColor: palette.primary.light,
    },
  })
);

export const BasicButton = styled(Button)(({ theme: { palette } }) => ({
  backgroundColor: palette.system.white,
  border: `1px solid ${palette.system.grey}`,
  color: palette.text.main,
  borderRadius: 6,
  '&:hover': {
    backgroundColor: palette.system.white,
    borderColor: palette.primary.light,
    color: palette.text.main,
  },
  '.MuiTouchRipple-child': {
    backgroundColor: palette.primary.light,
  },
}));

export const TertiaryButton = styled(Button)(
  ({ theme: { palette, typography } }) => ({
    backgroundColor: 'transparent',
    color: palette.text.secondary,
    padding: 0,
    ...typography.ctaSmall,
    '&:hover': {
      textDecoration: 'underline',
      color: palette.text.secondary,
      backgroundColor: 'transparent',
    },
  })
);

export const SecondaryOutlinedButton = styled(Button)(
  ({ theme: { palette, typography } }) => ({
    ...typography.ctaSmall,
    fontWeight: 400,
    backgroundColor: '#FFF',
    color: palette.text.secondary,
    borderRadius: 4,
    border: `1px solid ${palette.system.grey}`,
    '& .MuiButton-startIcon': {
      '& svg': {
        transition: '0.25s',
      },
    },
    '&:hover': {
      color: palette.primary.light,
      backgroundColor: '#FFF',
      borderColor: palette.primary.light,
      '& .MuiButton-startIcon': {
        '& svg': {
          color: palette.primary.light,
        },
      },
    },
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'clip',
    flexShrink: 1,
  })
);

export const OrangeOutlinedButton = styled(Button)(
  ({ theme: { palette, typography } }) => ({
    ...typography.ctaSmall,
    fontWeight: 400,
    backgroundColor: '#FFF1E0',
    color: '#ED702E',
    borderRadius: 4,
    border: `1px solid #ED702E`,
    '& .MuiButton-startIcon': {
      '& svg': {
        transition: '0.25s',
      },
    },
    '& .MuiButton-endIcon': {
      marginLeft: 4,
    },
    '&:hover': {
      color: palette.primary.light,
      backgroundColor: '#FFF1E0',
      borderColor: palette.primary.light,
      '& .MuiButton-startIcon': {
        '& svg': {
          color: palette.primary.light,
        },
      },
    },
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'clip',
    flexShrink: 1,
  })
);

export const TransparentBackButton = styled(Button)(
  ({ theme: { palette } }) => ({
    backgroundColor: 'rgba(255, 255, 255, 0.50)',
    color: '#4E4E4E',
    borderRadius: 4,
    padding: '4px 12px',
    opacity: 1,
    fontSize: 16,
    '& .MuiButton-startIcon': {
      color: '#4E4E4E',
      marginRight: 2,
    },
    '&:hover': {
      color: '#4E4E4E',
      backgroundColor: palette.primary.extraLight,
    },
  })
);

export const ExploreBackButton = styled(Button)(({ theme: { palette } }) => ({
  backgroundColor: '#FFFFFF',
  color: '#4E4E4E',
  borderRadius: 20,
  padding: '4px 12px',
  opacity: 1,
  fontSize: 16,
  '& .MuiButton-startIcon': {
    color: '#4E4E4E',
    marginRight: 2,
  },
  '&:hover': {
    color: '#4E4E4E',
    backgroundColor: palette.primary.extraLight,
  },
}));
