import React, { useState } from 'react';
import { Typography, Stack, Box } from '@mui/material';
import NorthEastRoundedIcon from '@mui/icons-material/NorthEastRounded';
import { EVENTS, phTrackEvent } from '../../../analytics';

function OfferCard(props) {
  const [hovered, setHovered] = useState(false);

  return (
    <Box>
      <Box
        sx={{
          maxWidth: '450px',
          margin: '0 auto',
          width: '100%',
          boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
          borderRadius: '4px',
        }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}>
        <img
          src={props?.cardImage?.url}
          alt=""
          style={{
            height: '280px',
            width: '100%',
            objectFit: 'cover',
            objectPosition: 'center',
            borderRadius: '4px 4px 0 0',
          }}
        />

        <Box sx={{ padding: '20px 20px 30px 20px' }}>
          <Stack
            direction="row"
            sx={{
              height: '30px',
              gap: '10px',
              alignItems: 'center',
              mb: '20px',
            }}>
            <img src={props?.logo?.url} alt="" style={{ height: '20px' }} />

            <Typography sx={{ fontSize: '20px', fontWeight: 700 }}>
              {props?.name}
            </Typography>
          </Stack>

          <Typography
            sx={{ fontSize: '14px', fontWeight: 400, color: '#4E4E4E' }}
            dangerouslySetInnerHTML={{ __html: props?.content }}
          />

          {props?.coupons &&
            props?.coupons.map((coupon, index) => (
              <Box
                key={index}
                sx={{
                  backgroundColor: '#FFF1E0',
                  mt: index > 0 ? '10px' : '15px',
                  padding: '12px 15px',
                  borderRadius: '4px',
                }}>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 400,
                    color: '#222',
                  }}
                  dangerouslySetInnerHTML={{ __html: coupon?.coupon[0]?.text }}
                />
              </Box>
            ))}
        </Box>

        <Box
          sx={{
            opacity: hovered ? 1 : 0,
            height: hovered ? '50px' : 0,
            overflow: 'hidden',
            transition:
              'opacity 0.3s ease-in-out, height 0.3s ease-in-out, margin 0.3s ease-in-out',
            visibility: hovered ? 'visible' : 'hidden',
            borderTop: '1px solid #D9D9D9',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}>
          <Stack direction="row" alignItems="center" mr="10px" gap="2px">
            <a
              href={props?.customClickHandler ? '' : props?.link}
              onClick={(e) => {
                phTrackEvent({
                  event: EVENTS.OFFERS.DEALS_CLICK,
                  meta: {
                    deal_name: props?.name,
                  },
                });
                if (props?.customClickHandler) {
                  e.preventDefault();
                  props?.customClickHandler();
                }
              }}
              target="_blank"
              rel="noreferrer"
              style={{ color: '#4E4E4E', fontSize: '14px' }}>
              {props?.linkText ?? 'View deals'}
            </a>

            <NorthEastRoundedIcon fontSize="1px" />
          </Stack>
        </Box>
      </Box>
    </Box>
  );
}

export default OfferCard;
