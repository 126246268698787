import { Link } from '@mui/material';
import { Link as LinkRRD } from 'react-router-dom';
import { withStyles } from '@mui/styles';

/*
    https://material-ui.com/api/link/
*/
function ReactRouterLink({ classes, ...rest }) {
  return <LinkRRD {...rest} className={classes.root} />;
}

export const LinkRoute = withStyles(() => ({
  root: {
    fontSize: '14px',
    lineHeight: '19px',
    color: '#ED702E',
    '&:hover': {
      opacity: 0.8,
      cursor: 'pointer',
    },
  },
}))(ReactRouterLink);

export const LinkHyper = withStyles(() => ({
  root: {
    fontSize: '14px',
    lineHeight: '19px',
    color: '#0872C7',
    '&:hover': {
      opacity: 0.8,
      cursor: 'pointer',
    },
  },
}))(Link);

export const LinkDefault = withStyles(() => ({
  root: {
    fontSize: '14px',
    lineHeight: '19px',
    '&:hover': {
      opacity: 0.8,
      cursor: 'pointer',
    },
  },
}))(Link);
