/**
 * A React component that displays an image with a fallback mechanism.
 *
 * @prop {string} src The URL of the primary image.
 * @prop {string} alt The alternative text for the image, used for accessibility.
 * @prop {string} className A CSS class name to apply to the image element.
 * @prop {object} style An inline style object to apply to the image element.
 * @prop {string} id A unique identifier for the image element.
 * @prop {string} fallbackImg (optional) The URL of the fallback image to display if the primary image fails to load. Defaults to '/images/placeholderImage.png'.
 *
 * @returns {JSX.Element} An img element with the provided props and fallback functionality.
 */

import { useTheme } from '@mui/styles';
import { useState } from 'react';

function ImgWithFallback({
  src,
  alt,
  className,
  style,
  id,
  fallbackImg,
  ...rest
}) {
  const theme = useTheme();
  const [bgColor, setBgColor] = useState(
    theme?.palette?.loading?.bg || '#DCDCDC'
  );
  return (
    <img
      style={{
        ...style,
        ...{
          backgroundColor: bgColor,
        },
      }}
      onLoad={() => {
        setBgColor(style?.backgroundColor || 'transparent');
      }}
      onError={(e) => {
        if (
          !e.currentTarget?.src?.endsWith(
            fallbackImg || '/images/placeholderImage.png'
          )
        ) {
          e.currentTarget.src = fallbackImg || '/images/placeholderImage.png';
        }
      }}
      src={src}
      alt={alt}
      className={className}
      id={id}
      {...rest}
    />
  );
}

export default ImgWithFallback;
