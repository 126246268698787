import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Typography, IconButton, Button } from '@mui/material';
import { useParams } from 'react-router-dom';
import {
  LocationOnRounded,
  PhoneRounded,
  AccessTimeRounded,
  PublicRounded,
  ChevronLeftRounded,
  FacebookRounded,
  Instagram,
  RestaurantRounded,
  StarRounded,
  CheckRounded,
  AddRounded,
} from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { ExploreBackButton, TertiaryButton } from '../../atoms/Button/index';
import { Bookmark } from '../../atoms/Icon';
import AddToTripButton from '../../molecules/AddToTripButton';
import actions from '../../../redux/actions';
import {
  addSavedItem,
  deleteSavedItem,
} from '../../../redux/slices/Recommendations';
import recommendationCategories from '../../../assets/newRecommendationCategories.json';
import { formatRatingCount } from '../../../utils';
import classList from '../../classList';
import CustomCarousel from '../../molecules/Carousel';
import { useSounds, SOUNDS } from '../../../sounds';
import { EVENTS, phTrackEvent } from '../../../analytics';

const useStyles = makeStyles(({ palette }) => ({
  imageContainer: {
    width: '100%',
    minHeight: '30%',
    position: 'relative',
    maxHeight: 300,
    backgroundColor: palette.system.lightGrey,
    '& > img': {
      objectFit: 'cover',
    },
    zIndex: 4,
  },
  page: {
    height: '100%',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  detailsContainer: {
    maxWidth: 510,
    alignSelf: 'center',
    height: '100%',
    position: 'relative',
    width: '100%',
  },
  headerContainer: {
    marginTop: 16,
    backgroundColor: '#FFF',
    width: '100%',
    borderRadius: 4,
    paddingBottom: 16,
    borderBottom: '1px solid #D9D9D9',
  },
  description: {
    marginTop: 18,
  },
  photosContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 40,
  },
  titleContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  detailIcon: {
    height: 20,
    width: 20,
    marginRight: 8,
  },
  imageList: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: 4,
    '& > img': {
      height: 192,
      objectFit: 'cover',
      marginTop: 8,
    },
  },
  backButton: {
    position: 'absolute',
    backgroundColor: '#FFF',
    color: palette.primary.main,
    height: 32,
    width: 32,
    top: 24,
    left: 24,
    '&:hover': {
      backgroundColor: '#FFF',
    },
  },
  unsavedIconButton: {
    borderColor: palette.system.grey,
    '& > svg': {
      fill: palette.system.lightgrey,
      color: palette.system.grey,
    },
    '&:hover': {
      borderColor: palette.primary.main,
      '& > svg': {
        fill: palette.primary.extraLight,
        color: palette.primary.main,
      },
    },
  },
  savedIconButton: {
    borderColor: palette.primary.main,
    '& > svg': {
      fill: palette.primary.main,
      color: palette.primary.main,
    },
  },
  backButtonContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 3,
    padding: '14px calc(calc(100% - 510px)/2)',
  },
}));

export function PoiDetails({
  poi,
  showRating = true,
  showBackButton = true,
  showSaveButton = true,
  showAddToTripButton = true,
  handleClose = () => {},
  handleSaveClick = () => {},
  isSaved = false,
  showSaveCta = false,
}) {
  const classes = useStyles();
  const { slug: tripId } = useParams();
  const [openImageList, setOpenImageList] = useState(false);
  const [cardHovered, setCardHovered] = useState(false);

  const [addToWishlistBtnHovered, setAddToWishlistBtnHovered] = useState(false);

  const parseOpeningHours = (openingHours = {}) => {
    if (!openingHours || Object.keys(openingHours || {})?.length === 0)
      return null;
    if (openingHours.generalResult) return openingHours.generalResult;

    // merging overlapping intervals
    const mergedOpeningHours = [];

    Object.keys(openingHours || {}).forEach((day) => {
      const openingTime = openingHours[day];
      const currSize = mergedOpeningHours.length;
      if (
        currSize === 0 ||
        !(mergedOpeningHours[currSize - 1].time === openingTime)
      ) {
        mergedOpeningHours.push({
          days: [day],
          time: openingTime,
        });
      } else {
        mergedOpeningHours[currSize - 1].days.push(day);
      }
    });
    return (
      <div>
        {mergedOpeningHours.map((openInterval) => {
          let formattedDays = openInterval.days[0].toString();
          const intervalSize = openInterval.days.length;
          if (intervalSize !== 1)
            formattedDays = formattedDays.concat(
              ` - ${openInterval.days[intervalSize - 1].toString()}`
            );
          return (
            <div style={{ width: '100%' }}>
              <span style={{ fontWeight: 'bold' }}>{formattedDays}</span>{' '}
              <span>{openInterval.time}</span>
            </div>
          );
        })}
      </div>
    );
  };

  const details = [
    { text: poi?.address, Icon: LocationOnRounded, title: 'Address' },
    {
      text: parseOpeningHours(poi?.openingHours),
      Icon: (props) => (
        <AccessTimeRounded sx={{ alignSelf: 'flex-start' }} {...props} />
      ),
      title: 'Hours',
    },
    { text: poi?.contact, Icon: PhoneRounded, title: 'Phone' },
    {
      text: poi?.website && (
        <a
          href={`${poi?.website?.startsWith('http') ? '' : `https://`}${
            poi?.website
          }`}
          onClick={() =>
            phTrackEvent({
              event: EVENTS.EXPLORE_ACTIVITY_DETAIL.ACTIVITY_WEBSITE_CLICK,
            })
          }
          target="_blank"
          rel="noreferrer">
          {poi?.website}
        </a>
      ),
      Icon: PublicRounded,
      title: 'Website',
    },
    {
      text: poi?.menuLink && (
        <a
          href={`${poi?.menuLink?.startsWith('http') ? '' : `https://`}${
            poi?.menuLink
          }`}
          target="_blank"
          rel="noreferrer">
          {poi?.menuLink}
        </a>
      ),
      Icon: RestaurantRounded,
      title: 'Menu',
    },
    {
      text: poi?.socials?.facebook && (
        <a
          href={`https://facebook.com/${poi?.socials?.facebook}`}
          target="_blank"
          onClick={() =>
            phTrackEvent({
              event: EVENTS.EXPLORE_ACTIVITY_DETAIL.ACTIVITY_SOCIAL_MEDIA_CLICK,
            })
          }
          rel="noreferrer">
          {poi?.socials?.facebook}
        </a>
      ),
      Icon: FacebookRounded,
      title: 'Facebook',
    },
    {
      text: poi?.socials?.instagram && (
        <a
          href={`https://instagram.com/${poi?.socials?.instagram}`}
          target="_blank"
          onClick={() =>
            phTrackEvent({
              event: EVENTS.EXPLORE_ACTIVITY_DETAIL.ACTIVITY_SOCIAL_MEDIA_CLICK,
            })
          }
          rel="noreferrer">
          {poi?.socials?.instagram}
        </a>
      ),
      Icon: Instagram,
      title: 'Instagram',
    },
  ];

  function FormattedTagsList() {
    const topTags = poi?.rankedCategories?.slice(0, 2);
    return (
      <div style={{ display: 'flex', marginTop: 3 }}>
        {topTags?.map((tag) => (
          <div
            style={{
              backgroundColor: '#F4F4F4',
              padding: '4px 8px',
              borderRadius: 12,
              marginRight: 6,
            }}>
            <Typography variant="h5" fontSize={14} color="text.light" noWrap>
              {recommendationCategories.ref[tag]?.name || ''}
            </Typography>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className={classes.page}>
      <div
        className={classes.imageContainer}
        onMouseEnter={() => setCardHovered(true)}
        onMouseLeave={() => setCardHovered(false)}>
        {showBackButton ? (
          <div className={classes.backButtonContainer}>
            <ExploreBackButton
              onClick={handleClose}
              startIcon={<ChevronLeftRounded sx={{ color: '#ED702E' }} />}>
              Back to Explore
            </ExploreBackButton>
          </div>
        ) : null}

        <CustomCarousel
          showArrows={cardHovered}
          images={poi.images}
          isRecomendationCard
          isCityPage
        />
      </div>
      <div className={classes.detailsContainer}>
        <div className={classes.headerContainer}>
          <div style={{ display: 'flex' }}>
            <Typography variant="h2" fontSize={24}>
              {poi?.title}
            </Typography>
            <div style={{ marginLeft: 'auto' }}>
              {showSaveButton ? (
                <IconButton
                  sx={{
                    height: 32,
                    width: 32,
                    border: '1px solid',
                    marginRight: '8px',
                  }}
                  className={`${
                    isSaved
                      ? classes.savedIconButton
                      : classes.unsavedIconButton
                  } recommendations-page-saved-button`}
                  disableRipple
                  onClick={handleSaveClick}
                  id={`recommendations-page-saved-button-${poi?.referenceId}`}>
                  <Bookmark />
                </IconButton>
              ) : null}
            </div>
          </div>
          {showRating && poi?.rating && (
            <div
              style={{ display: 'flex', alignItems: 'center', marginTop: 8 }}>
              <StarRounded
                sx={{ height: 20, width: 20, color: '#F6C443', mr: '4px' }}
              />
              <Typography
                variant="h5"
                fontSize={14}
                color="#4E4E4E"
                justifyItems="center"
                alignContent="center">
                {poi.rating ? (poi.rating / 2).toFixed(1) : ''}{' '}
                {poi.ratingCount
                  ? `(${formatRatingCount(poi.ratingCount)})`
                  : ''}
              </Typography>
            </div>
          )}
          {poi?.description && (
            <Typography
              variant="h5"
              fontSize={14}
              color="#4E4E4E"
              sx={{ mt: '8px' }}>
              {poi?.description}
            </Typography>
          )}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: 16,
            }}>
            <FormattedTagsList />

            {showAddToTripButton ? (
              <div style={{ marginLeft: 'auto' }}>
                <AddToTripButton
                  placeDetails={{
                    title: poi?.title,
                    placeId: poi?.referenceId,
                    website: poi?.website,
                    photo: poi?.thumbnail,
                    lat: poi?.coordinates?.latitude,
                    long: poi?.coordinates?.longitude,
                    rating: poi?.rating,
                    ratingCount: poi?.ratingCount,
                    description: poi?.description,
                  }}
                  buttonProps={{
                    sx: {
                      padding: '6px 36px',
                    },
                  }}
                  tripId={tripId}
                  id={`recommendations-page-add-to-trip-button-${poi?.referenceId}`}
                  className="recommendations-page-add-to-trip-button"
                  source="recommendations-details"
                />
              </div>
            ) : showSaveCta ? (
              <Button
                onMouseEnter={() => setAddToWishlistBtnHovered(true)}
                onMouseLeave={() => setAddToWishlistBtnHovered(false)}
                onClick={(e) => {
                  setAddToWishlistBtnHovered(false);
                  handleSaveClick(e);
                }}
                className={
                  isSaved
                    ? classList.discoverRemoveFromWishlistBtn
                    : classList.discoverAddToWislistBtn
                }
                data-poi-slug={`${poi?.referenceId}-${poi?.title}`}>
                {isSaved ? (
                  addToWishlistBtnHovered ? (
                    'Remove from wishlist'
                  ) : (
                    <>
                      <CheckRounded
                        sx={{ marginRight: '8px', height: 16, width: 16 }}
                      />
                      Saved
                    </>
                  )
                ) : (
                  <>
                    <AddRounded
                      sx={{ marginRight: '8px', height: 16, width: 16 }}
                    />
                    Add to Wishlist
                  </>
                )}
              </Button>
            ) : null}
          </div>
        </div>
        <div className={classes.description}>
          <Typography variant="h4Sub">About this place</Typography>
          <div style={{ marginTop: 14 }} />
          {details.map(
            ({ text, Icon, title }) =>
              text &&
              text !== '' && (
                <div
                  style={{
                    display: 'flex',
                    marginBottom: '12px',
                  }}>
                  <Icon className={classes.detailIcon} />
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography color="text.secondary" variant="h4">
                      {title}
                    </Typography>
                    <Typography
                      color="text.secondary"
                      variant="h5"
                      fontSize={14}>
                      {text}
                    </Typography>
                  </div>
                </div>
              )
          )}
          {poi?.images && poi?.images?.length > 1 ? (
            <div className={classes.photosContainer}>
              <div className={classes.titleContainer}>
                <Typography variant="h4Sub">Photos</Typography>
                <TertiaryButton
                  sx={{ color: '#8A8A8A' }}
                  onClick={() => setOpenImageList(!openImageList)}
                  disableRipple>
                  View {openImageList ? 'less' : 'more'}
                </TertiaryButton>
              </div>
              <div className={classes.imageList}>
                {poi?.images
                  ?.slice(1, openImageList ? poi?.images?.length : 2)
                  .map((image) => (
                    <img src={image} alt="tesss" />
                  ))}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

function CityPage() {
  const { slug: tripId } = useParams();
  const poi = useSelector((state) => state.View.poiDetails.payload);
  const savedItem = useSelector(
    (state) =>
      poi?.referenceId &&
      Object.values(state.Recommendations.saved[tripId] || {}).filter(
        (obj) => obj?.referenceId === poi?.referenceId
      )
  );

  const isSaved = Boolean(savedItem?.length);

  // local state for quicker interactions
  const [saved, setSaved] = useState(isSaved);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSaved(isSaved);
  }, [isSaved]);

  const dispatch = useDispatch();
  const { playSound } = useSounds();

  const handleSaveClick = async (e) => {
    e.stopPropagation();
    if (loading) return;

    setLoading(true);
    if (isSaved) {
      playSound(SOUNDS.removeBaritone);
      setSaved(false);
      await dispatch(
        deleteSavedItem({
          variables: {
            id: savedItem?.length > 0 && savedItem[0].id,
          },
          tripId,
        })
      ).catch(() => setSaved(true));
    } else {
      playSound(SOUNDS.heartChime);
      phTrackEvent({
        event: EVENTS.EXPLORE_ACTIVITY_DETAIL.SAVED_LIST_ADD,
      });
      setSaved(true);
      await dispatch(
        addSavedItem({
          variables: {
            tripId,
            referenceId: poi?.referenceId,
            savedData: JSON.stringify(poi || {}),
          },
        })
      ).catch(() => setSaved(false));
    }

    setLoading(false);
  };

  const handleClose = () => {
    dispatch(actions.View.setPoiView({ isInView: false }));
    dispatch(actions.Recommendations.setActivePoisPlaceMarker(null));
  };

  return (
    <PoiDetails
      poi={poi}
      handleClose={handleClose}
      handleSaveClick={handleSaveClick}
      isSaved={saved}
    />
  );
}

export default CityPage;
