import { createSlice } from '@reduxjs/toolkit';
import getAsyncThunk from '../helpers';
import QUERY from '../../graphql/queries';

const initialState = {
  saved: {},
  recommendations: {},
  filterLabels: [],
  activePoisPlaceMarker: null,
  hoveredCardIndex: null,
};

export const addSavedItem = getAsyncThunk(
  'Recommendations/addSavedItem',
  QUERY.ADD_SAVED_ITEM
);

export const deleteSavedItem = getAsyncThunk(
  'Recommendations/deleteSavedItem',
  QUERY.DELETE_SAVED_ITEM
);

const RecommendationsSlice = createSlice({
  name: 'Recommendations',
  initialState,
  reducers: {
    initializeSaves: (state, action) => {
      state.saved[action.payload.tripId] = {
        ...state.saved[action.payload.tripId],
        ...Object.fromEntries(
          action.payload.saved
            ?.filter((savedItem) => savedItem?.type !== 'HOTEL')
            ?.map((savedItem) => [savedItem.id, savedItem]) || []
        ),
      };
    },
    setCity: (state, action) => {
      state.recommendations[action.payload.tripId] = {
        ...state.recommendations[action.payload.tripId],
        city: action.payload.city,
        sortOption: 'RATING',
      };
    },
    setPois: (state, action) => {
      state.recommendations[action.payload.tripId] = {
        ...state.recommendations[action.payload.tripId],
        pois: action.payload.pois,
      };
    },
    resetCity: (state, action) => {
      state.recommendations[action.payload.tripId] = {
        ...state.recommendations[action.payload.tripId],
        pois: null,
        city: null,
        sortOption: 'RATING',
      };
    },
    setSortOption: (state, action) => {
      state.recommendations[action.payload.tripId] = {
        ...state.recommendations[action.payload.tripId],
        sortOption: action.payload.sortOption,
      };
    },
    setActivePoisPlaceMarker: (state, action) => {
      state.activePoisPlaceMarker = action.payload;
    },
    setHoveredCardIndex: (state, action) => {
      state.hoveredCardIndex = action.payload.cardIndex;
    },
  },
  extraReducers: {
    [addSavedItem.fulfilled]: (
      state,
      {
        payload,
        meta: {
          arg: { variables },
        },
      }
    ) => {
      state.saved[variables.tripId] = {
        ...state.saved[variables.tripId],
        [payload.addSavedItem.id]: {
          ...variables,
          ...(payload.addSavedItem || {}),
        },
      };
    },
    [deleteSavedItem.fulfilled]: (
      state,
      {
        payload,
        meta: {
          arg: { tripId },
        },
      }
    ) => {
      if (payload.deleteSavedItem) {
        delete state.saved[tripId][payload.deleteSavedItem];
      }
    },
  },
});

export const RecommendationsActions = RecommendationsSlice.actions;
export const RecommendationsReducer = RecommendationsSlice.reducer;
