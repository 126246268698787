// eslint-disable-next-line
import { Document, Page, pdfjs } from 'react-pdf';
import { useState } from 'react';
import { CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
// eslint-disable-next-line
import 'react-pdf/dist/esm/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const useStyles = makeStyles(() => ({
  pdfPage: {
    '& > canvas': {
      margin: '0 auto',
    },
  },
}));

function PDFViewer({ url, scale = 1.2 }) {
  const [pages, setPages] = useState([]);
  const classes = useStyles();

  function onDocumentLoadSuccess(pdf) {
    const pdfPages = [];
    for (let p = 1; p < pdf.numPages + 1; p += 1) {
      pdfPages.push(p);
    }
    setPages(pdfPages || []);
  }

  return (
    <Document
      file={url}
      loading={
        <div className="flex justify-center items-center w-full h-full">
          <CircularProgress />
        </div>
      }
      onLoadSuccess={onDocumentLoadSuccess}>
      <div
        style={{
          height: '100%',
          width: '100%',
          overflow: 'scroll',
          backgroundColor: '#FFF',
        }}>
        {pages?.map((pg) => (
          <Page
            key={`page-${pg}`}
            pageNumber={pg}
            enderTextLayer={false}
            renderAnnotationLayer={false}
            customTextRenderer={false}
            scale={scale}
            className={classes.pdfPage}
          />
        ))}
      </div>
    </Document>
  );
}

export default PDFViewer;
