import { useMediaQuery, useTheme, Stack, Typography } from '@mui/material';
import AvatarStack from '../../../molecules/AvatarStack';
import { ManMapin } from './assets';

function CustomLimitComponent({ count }) {
  return (
    <span
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#FFD9D9',
        height: '36px',
        width: '36px',
        padding: '12px',
        borderRadius: '24px',
        position: 'relative',
        right: '8px',
      }}>
      <ManMapin />
      <span
        style={{
          fontSize: '12px',
          color: '#fff',
        }}>
        +{count}
      </span>
    </span>
  );
}

function Tripmates({ sharedUsers }) {
  const { palette, breakpoints } = useTheme();

  const isMobile = useMediaQuery(breakpoints.down('sm'));
  return (
    <Stack
      mt={isMobile ? '0px' : '39px'}
      py={isMobile ? '28px' : '40px'}
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      gap="12px">
      <Typography
        fontSize="26px"
        fontWeight={500}
        color={palette?.text?.light}
        fontFamily="Clearface">
        Tripmates:
      </Typography>
      <div style={{ marginRight: '1.25rem' }}>
        <AvatarStack
          iconSize={isMobile ? '36px' : '46px'}
          fontSize={isMobile ? '12px' : '16px'}
          borderColor="#fff"
          people={sharedUsers}
          renderSmall={isMobile}
          limit={4}
          CustomLimitComponent={CustomLimitComponent}
        />
      </div>
    </Stack>
  );
}

export default Tripmates;
