import config from '../../../config';
import getCfConnector from '../../../cfConnector';

export const getHotel = async ({
  hotelKey,
  checkInDate,
  checkOutDate,
  rooms,
}) => {
  try {
    return (
      await (
        await getCfConnector()
      ).get(
        `${config.recommendationsAPI}/hotel?hotelKey=${hotelKey}&checkInDate=${checkInDate}&checkOutDate=${checkOutDate}&rooms=${rooms}`
      )
    )?.data?.data;
  } catch {
    return null;
  }
};

export const getPrices = async () => {};
