import { Modal, Typography, IconButton } from '@mui/material';
import { CloseRounded, Add } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';

const useStyles = makeStyles(({ breakpoints, palette }) => ({
  modal: {
    display: 'flex',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 4147483003,
  },
  container: {
    backgroundColor: '#f4f4f4',
    width: 800,
    maxWidth: '95%',
    minHeight: 411,
    borderRadius: 4,
    display: 'flex',
    padding: 35,
    flexDirection: 'column',
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 50,
    paddingBottom: 50,
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      paddingTop: 20,
      paddingBottom: 20,
    },
  },
  card: {
    backgroundColor: '#fff',
    display: 'flex',
    margin: 10,
    minWidth: '322px',
    minHeight: '237px',
    border: '1px solid #D9D9D9',
    borderRadius: 8,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
      borderColor: palette.primary.main,
    },
    [breakpoints.down('sm')]: {
      minHeight: '175px',
      width: '100%',
    },
  },
  badge: {
    position: 'absolute',
    top: -50,
    right: -20,
    [breakpoints.down('sm')]: {
      top: -25,
      right: -20,
    },
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  centerIcon: {
    backgroundColor: '#D9D9D9',
    borderRadius: '50%',
    width: 50,
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 10,
  },
}));

function NewTripModal({ open, handleClose, handleCreateTrip }) {
  const classes = useStyles();
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title">
      <div className={classes.modal}>
        <div className={classes.container}>
          <div style={{ display: 'flex' }}>
            <div style={{ flex: 1, flexDirection: 'row' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                <Typography variant="h2">Create Trip</Typography>
              </div>
            </div>
            <div>
              <IconButton
                sx={{ padding: '4px', color: '#1D1D1D' }}
                size="small"
                onClick={handleClose}>
                <CloseRounded />
              </IconButton>
            </div>
          </div>
          <div className={classes.rowContainer}>
            <div
              id="blank-trip-button"
              className={classes.card}
              onClick={() => handleCreateTrip(false)}>
              <div className={classes.center}>
                <div className={classes.centerIcon}>
                  <Add size="50" />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <CreateOutlinedIcon />
                  <Typography
                    style={{
                      fontSize: 16,
                      marginLeft: '10px',
                    }}
                    variant="h3">
                    {' '}
                    {'   '}Blank Trip
                  </Typography>
                </div>
                <Typography
                  style={{
                    color: '#8A8A8A',
                  }}>
                  Blank Itinerary for Endless Ideas
                </Typography>
              </div>
            </div>
            <div
              id="quickStart-button"
              className={classes.card}
              onClick={() => handleCreateTrip(true)}>
              <div className={classes.center}>
                <Typography
                  fontSize={12}
                  noWrap
                  marginLeft={1}
                  border="1px solid"
                  borderColor="primary.main"
                  borderRadius={8}
                  paddingX="8px"
                  color="primary.main"
                  className={classes.badge}>
                  Labs
                </Typography>
                <div className={classes.centerIcon}>
                  <Add size="50" />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <AutoFixHighOutlinedIcon />
                  <Typography
                    variant="h3"
                    style={{
                      fontSize: 16,
                      marginLeft: '10px',
                    }}>
                    {' '}
                    {'   '}Quick Start
                  </Typography>
                </div>
                <Typography
                  style={{
                    color: '#8A8A8A',
                  }}>
                  Al Generated Itinerary to Remix
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default NewTripModal;
