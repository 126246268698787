import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Helmet } from 'react-helmet';

import WishlistContainer from '../organisms/WishlistContainer';
import AppLayout from '../templates/AppLayout';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    [theme.breakpoints.only('xs')]: {
      maxWidth: 328,
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: 680,
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 1032,
    },
  },
}));

function Wishlist() {
  const classes = useStyles();

  return (
    <AppLayout>
      <Helmet>
        <title>Wishlist | Pilot Trip Planner</title>
      </Helmet>
      <Box
        style={{
          display: 'flex',
          width: '100%',
          height: 'calc(100%)',
          justifyContent: 'center',
        }}>
        <Box container direction="column" className={classes.root}>
          <WishlistContainer main />
        </Box>
      </Box>
    </AppLayout>
  );
}

export default Wishlist;
