import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import PwywModal from '../molecules/Modal/PwywModal';
import FLAGS from '../../featureFlags';

const useStyles = makeStyles(() => ({
  form: {
    overflowY: 'hidden',
  },
  container: {
    height: '100%',
  },
}));

function AppLayout({ children }) {
  const classes = useStyles();

  /* Feature flag start */
  const showPwywModal = useFeatureFlagEnabled(FLAGS.PWYW);
  /* Feature flag end */

  return (
    <Grid container spacing={0} className={classes.form}>
      <Grid item xs={12} className={classes.container}>
        {showPwywModal && <PwywModal />}
        {children}
      </Grid>
    </Grid>
  );
}

export default AppLayout;
