import { useState } from 'react';

import { Icon, ListItemText, Box, List, ListItem, Fade } from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  AddCircle,
  AddCircleOutline,
  Language,
  FlightTakeoff,
  DirectionsBus,
  DirectionsCar,
} from '@mui/icons-material';
import classList from '../../classList';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 4,
    border: `2px solid ${theme.palette.system.lightgrey}`,
    // height: '36px',
    width: '100%',
    padding: '4px',
    backgroundColor: 'white',
  },
  lineGrid: {
    width: '100%',
    flex: 1,
  },
  iconGrid: {
    display: 'flex',
    padding: '4px',
  },
  popover: {
    marginTop: 24,
  },
  icon: {
    color: theme.palette.text.light,
    marginRight: '5px',
    [theme.breakpoints.up('sm')]: {
      marginRight: '10px',
    },
  },
  iconHovered: {
    color: theme.palette.primary.main,
    marginRight: '5px',
    [theme.breakpoints.up('sm')]: {
      marginRight: '10px',
    },
  },
  listIcon: {
    display: 'flex',
    alignItems: 'center',
    width: '16px',
    height: '16px',
    marginRight: '8px',
    color: '#222222',
  },
  listItem: {
    height: '28px',
    padding: '4px 12px 4px 8px',
    borderRadius: '8px',
    margin: '0 4px',
    '&:hover': {
      cursor: 'pointer',
      '& $listIcon': {
        color: theme.palette.primary.main,
      },
      '& .MuiListItemText-primary': {
        color: theme.palette.primary.main,
      },
    },
  },
  spaced: {
    height: '36px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: (props) => (props.type === 'transportation' ? '15px' : '32px'),
    flexGrow: 1,
  },
  menuContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    marginTop: '100px',
    zIndex: 100,
    width: '100%',
  },
  addModuleButton: {
    position: 'relative',
    display: 'flex',
    marginLeft: 'calc(3.6rem - 21px)',
    [theme.breakpoints.up('sm')]: {
      marginLeft: 'calc(3.6rem - 15px)',
    },
    justifyContent: 'flex-start',
  },
}));

function AddModuleMenu({ handleClose, callbacks }) {
  const classes = useStyles();

  const callOptionCallback = (index, params = null) => {
    handleClose();
    callbacks[index](params);
  };

  function ListItemMaker({ icon, text, callback }) {
    return (
      <ListItem disablePadding className={classes.listItem} onClick={callback}>
        <Icon className={classes.listIcon}>{icon}</Icon>
        <ListItemText
          primary={text}
          primaryTypographyProps={{
            fontSize: '14px',
            lineHeight: '19.6px',
          }}
        />
      </ListItem>
    );
  }

  return (
    <List
      disablePadding
      className={`${classes.root} ${classList.addModuleMenuBar}`}>
      <ListItemMaker
        icon={<Language style={{ width: '16px', height: '16px' }} />}
        text="Destination"
        callback={() => callOptionCallback(0)}
      />
      <ListItemMaker
        icon={<FlightTakeoff style={{ width: '16px', height: '16px' }} />}
        text="Flight"
        callback={() => callOptionCallback(1, 'flight')}
      />
      <ListItemMaker
        icon={<DirectionsBus style={{ width: '16px', height: '16px' }} />}
        text="Bus/Train"
        callback={() => callOptionCallback(1, 'bus')}
      />
      <ListItemMaker
        icon={<DirectionsCar style={{ width: '16px', height: '16px' }} />}
        text="Other"
        callback={() => callOptionCallback(1, 'other')}
      />
    </List>
  );
}

function DashedLine({ anchorEl }) {
  return anchorEl === null ? (
    <svg width="100%" height="3px">
      <line
        x1="0"
        x2="100%"
        y1="1.5"
        y2="1.5"
        stroke="#DEDDDD"
        strokeWidth="3"
        strokeLinecap="round"
        strokeDasharray="10, 10"
      />
    </svg>
  ) : null;
}

function AddModuleButton({
  handleAddLocation,
  handleAddTransportation,
  needsInteraction = true,
  type,
}) {
  const classes = useStyles({ type });
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <div
      id="add-module-button"
      onMouseLeave={() => {
        if (needsInteraction) setAnchorEl(null);
      }}
      className={classes.addModuleButton}>
      <Box className={classes.spaced}>
        <Box
          item
          container
          className={classes.iconGrid}
          aria-owns={anchorEl ? 'addModulePopover' : undefined}
          aria-haspopup="true"
          onMouseEnter={(e) => {
            if (needsInteraction) setAnchorEl(e.currentTarget);
          }}>
          {!anchorEl && needsInteraction ? (
            <AddCircleOutline
              className={`${classes.icon} ${classList.addModuleButton}`}
            />
          ) : (
            <AddCircle
              className={`${classes.iconHovered} ${classList.addModuleButton}`}
            />
          )}
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}>
          {anchorEl || !needsInteraction ? (
            needsInteraction ? (
              <Fade
                in={Boolean(anchorEl)}
                className={classes.menuContainer}
                timeout={{ enter: 10 }}>
                <div>
                  <AddModuleMenu
                    handleClose={() => setAnchorEl(null)}
                    callbacks={[handleAddLocation, handleAddTransportation]}
                  />
                </div>
              </Fade>
            ) : (
              <div className={classes.menuContainer}>
                <AddModuleMenu
                  handleClose={() => {}}
                  callbacks={[handleAddLocation, handleAddTransportation]}
                />
              </div>
            )
          ) : (
            <DashedLine anchorEl={anchorEl} />
          )}
        </Box>
      </Box>
    </div>
  );
}

export default AddModuleButton;
