import Header from '../molecules/Header';
import AppLayout from '../templates/AppLayout';

function Activity() {
  return (
    <AppLayout>
      <Header title="Activity" />
    </AppLayout>
  );
}

export default Activity;
