export function Highlight(props) {
  return (
    <svg
      width="437"
      height="95"
      viewBox="0 0 437 95"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M9.5 31.5L0 20.5L437 0L423.5 4.5L437 14L429.5 20.5L437 31.5L423.5 43.5L437 57.5L429.5 62L437 74.5L2.5 95L14.5 81L2.5 71L9.5 62L0 43.5L9.5 31.5Z"
        fill="#FFF1E0"
      />
    </svg>
  );
}

export function Tape() {
  return (
    <svg
      style={{
        position: 'absolute',
        top: -20,
        right: 50,
      }}
      width="244"
      height="57"
      viewBox="0 0 244 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_9409_4215)">
        <path
          d="M8.86521 11.1802L4.29764 4.90707L237.712 13.344L230.33 15.1407L237.092 20.7927L232.814 23.9191L236.318 30.1037L228.604 35.8907L235.167 43.937L230.977 45.9993L234.414 52.9819L2.32999 44.6556L9.33433 37.7381L3.39236 31.8864L7.51512 27.4078L3.27953 17.1443L8.86521 11.1802Z"
          fill="#FFF1E0"
          fillOpacity="0.4"
          shapeRendering="crispEdges"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_9409_4215"
          x="0.330078"
          y="0.907227"
          width="243.382"
          height="56.0742"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_9409_4215"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_9409_4215"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
export function ManMapin(props) {
  return (
    <svg
      {...props}
      width="54"
      height="62"
      viewBox="0 0 54 62"
      fill="none"
      style={{
        position: 'absolute',
        bottom: 15,
        left: '45%',
      }}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M45.8408 32.5642C42.455 40.5056 26.2216 50.2926 19.2597 54.2182C17.5941 55.1509 15.6053 54.303 15.1251 52.4554C13.0589 44.6807 8.95951 26.2249 12.3453 18.2835C16.4532 8.64842 27.2813 4.0347 36.531 7.97827C45.7771 11.9204 49.9487 22.9292 45.8408 32.5642Z"
        fill="#8AD6B0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.9096 20.7368C33.8974 21.1579 35.0501 20.6943 35.4712 19.7066C35.8923 18.7188 35.4287 17.5661 34.441 17.145C33.4532 16.7239 32.3006 17.1875 31.8794 18.1752C31.4583 19.163 31.9219 20.3157 32.9096 20.7368ZM25.4678 30.2981L26.9367 28.2715L28.0567 30.8713L26.1425 35.361C25.932 35.8548 26.1638 36.4312 26.6576 36.6417C27.1515 36.8523 27.7278 36.6205 27.9384 36.1266L30.0976 31.0623C30.3081 30.5684 30.3097 30.0173 30.096 29.5229L29.3068 27.6903L30.9941 25.2261C31.4801 26.7492 32.5312 28.1418 33.9864 29.0593C34.4907 29.3698 35.1594 29.1456 35.3929 28.5978C35.5805 28.1578 35.4142 27.6519 35.0048 27.3924C33.7357 26.586 32.9488 25.2955 32.799 23.9795L32.5136 22.1599C32.3841 21.468 31.9985 20.8792 31.3699 20.6112C31.1005 20.4963 30.8827 20.5096 30.6133 20.3947L26.3963 20.3796C25.6094 20.3836 24.9027 20.8464 24.5926 21.5737L23.6853 23.7018C23.4748 24.1957 23.7065 24.772 24.2004 24.9826C24.6943 25.1931 25.2706 24.9613 25.4812 24.4675L26.4 22.3124L28.2842 22.373L23.7466 29.0337L20.533 26.8147C20.0902 26.5092 19.4803 26.6205 19.1748 27.0633L19.1595 27.0992C18.854 27.542 18.9654 28.1519 19.4081 28.4574L22.7847 30.7671C23.6561 31.3615 24.8465 31.158 25.4678 30.2981Z"
        fill="white"
      />
    </svg>
  );
}

export function MapPinCamera(props) {
  return (
    <svg
      width="51"
      height="70"
      viewBox="0 0 51 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        position: 'absolute',
        top: -10,
        left: -10,
      }}
      {...props}>
      <path
        d="M50.3472 21.3462C52.9462 33.1147 42.4491 57.4064 37.7268 67.5155C36.5918 69.9268 33.6446 70.5777 31.5997 68.8686C22.9429 61.7141 3.30829 44.0767 0.709348 32.3081C-2.44388 18.0297 6.11164 4.0012 19.8189 0.974105C33.5211 -2.05185 47.194 7.06776 50.3472 21.3462Z"
        fill="#ED702E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.5603 15.862L30.3421 16.7936L28.2954 15.3616C27.6825 14.9247 26.8934 14.764 26.1483 14.9286L20.5062 16.1746C19.761 16.3392 19.113 16.8172 18.7278 17.4744L17.4878 19.6323L13.2696 20.5639C11.8058 20.8871 10.8727 22.3492 11.196 23.8129L14.7223 39.781C15.0456 41.2447 16.5077 42.1779 17.9714 41.8546L39.2622 37.1528C40.7259 36.8295 41.659 35.3675 41.3358 33.9037L37.8094 17.9357C37.4862 16.4719 36.0241 15.5388 34.5603 15.862ZM27.7359 35.5116C24.0632 36.3227 20.4242 34.0003 19.6132 30.3276C18.8021 26.655 21.1246 23.016 24.7972 22.2049C28.4699 21.3939 32.1088 23.7163 32.9199 27.389C33.731 31.0616 31.4085 34.7006 27.7359 35.5116ZM25.0912 23.5357L24.236 27.5624L20.944 30.0338L24.9707 30.889L27.4421 34.1811L28.2973 30.1544L31.5894 27.6829L27.5627 26.8277L25.0912 23.5357Z"
        fill="white"
      />
    </svg>
  );
}

export function RecommendedBadge() {
  return (
    <svg
      width="108"
      height="108"
      viewBox="0 0 108 108"
      fill="none"
      style={{
        position: 'absolute',
        bottom: -21,
        right: -35,
      }}
      xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_9409_4225)">
        <path
          d="M64.1913 96.9715C88.078 91.1889 102.754 67.1372 96.9717 43.2505C91.1891 19.3638 67.1374 4.68751 43.2507 10.4701C19.364 16.2527 4.68772 40.3044 10.4703 64.1911C16.2529 88.0778 40.3046 102.754 64.1913 96.9715Z"
          fill="#FFFBF6"
        />
        <path
          d="M69.5588 43.2116C69.5351 43.0913 69.5103 42.9712 69.4812 42.8511C68.3145 38.0317 63.2402 34.8379 58.2278 36.0513C54.5634 36.9384 51.9797 40.0619 51.2749 43.4154C49.1143 40.7555 45.3871 39.1598 41.7226 40.0469C36.7102 41.2604 33.6586 46.4214 34.8253 51.2408C34.8543 51.3609 34.8831 51.48 34.9211 51.5968C34.9211 51.5968 35.7944 57.4456 43.677 62.1542C47.2751 64.3549 51.1815 66.3293 57.5604 69.3708C61.8414 63.7465 64.4124 60.2041 66.606 56.6034C71.4572 48.8122 69.5588 43.2116 69.5588 43.2116Z"
          fill="#ED702E"
        />
        <path
          d="M25.6404 63.4663C25.9717 64.3625 26.0082 65.1545 25.7555 65.8489C25.5028 66.5433 24.9945 67.0259 24.2335 67.3082C23.4493 67.5961 22.7446 67.5542 22.1242 67.1856C21.5029 66.813 21.0271 66.1806 20.7005 65.2872L20.5789 65.19L19.8643 63.2508L16.518 64.4817L16.0253 63.1383L24.3877 60.0639L25.6422 63.474L25.6404 63.4663ZM21.727 65.6965L18.5352 69.9624L17.9622 68.4056L20.7653 64.7282L21.727 65.6965ZM21.7135 64.7969C21.9155 65.3445 22.1766 65.7143 22.4989 65.8978C22.8211 66.0813 23.215 66.0881 23.6804 65.9182C24.1382 65.7502 24.431 65.4913 24.5588 65.1417C24.6905 64.7912 24.6515 64.343 24.4524 63.7907L23.7322 61.8283L20.9933 62.8345L21.7144 64.8007L21.7135 64.7969Z"
          fill="#2C2B2B"
        />
        <path
          d="M20.2081 72.839L27.4794 67.6877L31.2097 72.9539L30.195 73.6735L27.3015 69.5856L25.2092 71.0686L27.4737 74.2628L26.4832 74.9642L24.2187 71.7701L22.0596 73.2978L25.0636 77.5386L24.0489 78.2582L20.2119 72.8381L20.2081 72.839Z"
          fill="#2C2B2B"
        />
        <path
          d="M33.5434 83.5383C33.0606 83.8391 32.5613 84.0212 32.0485 84.08C31.5318 84.1397 31.0285 84.0859 30.5318 83.9242C30.0351 83.7626 29.5652 83.4923 29.1115 83.12C28.4529 82.5767 27.9972 81.9598 27.7461 81.277C27.494 80.5904 27.456 79.8765 27.6272 79.1323C27.7984 78.3881 28.1873 77.6485 28.7967 76.9086C29.4031 76.1734 30.0571 75.6515 30.7577 75.3389C31.4592 75.0302 32.1669 74.9324 32.8893 75.0476C33.6117 75.1627 34.296 75.4915 34.9488 76.028C35.3987 76.4012 35.7568 76.8006 36.0185 77.2235C36.2812 77.6501 36.4377 78.0943 36.4882 78.556C36.5387 79.0177 36.4783 79.494 36.3071 79.9849L34.8664 79.586C35.0658 79.0597 35.1126 78.5949 35.0059 78.1877C34.8991 77.7804 34.6352 77.399 34.2121 77.052C33.788 76.7012 33.334 76.4965 32.8491 76.4341C32.3642 76.3718 31.8743 76.4577 31.3842 76.6948C30.8941 76.9319 30.4203 77.3204 29.9654 77.8717C29.5164 78.4135 29.2206 78.9468 29.0694 79.4696C28.9181 79.9924 28.9145 80.4835 29.0622 80.9421C29.2062 81.4016 29.5017 81.8121 29.9362 82.1727C30.3603 82.5235 30.8153 82.7157 31.3062 82.7521C31.7971 82.7885 32.2846 82.6419 32.7811 82.3134L33.553 83.5442L33.5434 83.5383Z"
          fill="#2C2B2B"
        />
        <path
          d="M42.0422 80.2673C42.8391 80.6055 43.453 81.0657 43.8897 81.6545C44.3254 82.2394 44.5669 82.9164 44.6162 83.6766C44.6625 84.4417 44.5013 85.2611 44.1297 86.1395C43.7581 87.018 43.2792 87.7059 42.6966 88.2024C42.1141 88.6988 41.4618 88.9916 40.7369 89.0854C40.0119 89.1791 39.251 89.0569 38.458 88.7178C37.6708 88.3854 37.0607 87.9243 36.6249 87.3394C36.1892 86.7544 35.9477 86.0775 35.8984 85.3172C35.8483 84.5531 36.0133 83.7328 36.3849 82.8544C36.7565 81.9759 37.2354 81.288 37.818 80.7915C38.4005 80.295 39.0528 80.0023 39.7777 79.9085C40.5027 79.8147 41.2588 79.934 42.0431 80.2712L42.0422 80.2673ZM41.5501 81.4242C41.0355 81.2056 40.5458 81.1403 40.0713 81.2224C39.5968 81.3046 39.1704 81.5345 38.7776 81.9033C38.3857 82.276 38.0516 82.7859 37.7732 83.4416C37.5006 84.0877 37.3632 84.6848 37.3678 85.2271C37.3725 85.7693 37.5076 86.2432 37.7753 86.6401C38.043 87.037 38.4317 87.3432 38.9463 87.5618C39.4695 87.7825 39.9641 87.8507 40.4347 87.7694C40.9043 87.6843 41.3394 87.4564 41.7304 87.0799C42.1252 86.7024 42.4593 86.1925 42.7319 85.5464C43.0103 84.8907 43.1438 84.2946 43.1401 83.7562C43.1355 83.2139 42.9983 82.7487 42.7307 82.3518C42.463 81.9549 42.0695 81.6457 41.5462 81.4251L41.5501 81.4242Z"
          fill="#2C2B2B"
        />
        <path
          d="M56.0633 83.0376L55.4124 91.926L54.0773 91.8284L54.4537 86.6834L54.7407 84.2402L54.7291 84.243L51.4649 91.6355L50.2665 91.5457L48.1182 83.7516L48.1066 83.7544L48.0344 86.2069L47.657 91.348L46.3257 91.2495L46.9776 82.3649L49.1194 82.5206L50.5314 87.7964L50.9968 89.9553L51.0218 89.9574L51.8087 87.8916L53.9659 82.8794L56.0662 83.0328L56.0633 83.0376Z"
          fill="#2C2B2B"
        />
        <path
          d="M66.4071 80.3498L68.8685 88.9138L67.5848 89.2817L66.1598 84.3236L65.5815 81.9347L65.5699 81.9375L65.0676 90.0055L63.9142 90.3378L59.2027 83.769L59.1911 83.7718L59.9721 86.1035L61.3971 91.0616L60.1134 91.4295L57.6482 82.8665L59.7108 82.2732L62.8615 86.7318L64.0447 88.5985L64.0717 88.5919L64.0959 86.3799L64.3822 80.9299L66.4062 80.3459L66.4071 80.3498Z"
          fill="#2C2B2B"
        />
        <path
          d="M72.7804 86.8478L67.8673 79.4126L73.2526 75.8537L73.9363 76.8893L69.7551 79.6501L71.1684 81.792L74.4369 79.6321L75.1074 80.6464L71.8388 82.8064L73.2969 85.015L77.6329 82.1514L78.3167 83.187L72.7727 86.8497L72.7804 86.8478Z"
          fill="#2C2B2B"
        />
        <path
          d="M78.976 69.4711L85.7497 75.2589L84.6353 76.5623L78.1305 75.5304L76.3698 75.16L76.3601 75.1705L77.4927 76.0689L81.8662 79.8066L81.0064 80.8114L74.2327 75.0236L75.3374 73.7307L81.8258 74.7625L83.6163 75.138L83.626 75.1275L82.4829 74.2194L78.1094 70.4817L78.9789 69.4663L78.976 69.4711Z"
          fill="#2C2B2B"
        />
        <path
          d="M81.1463 65.0849C81.7029 63.8062 82.4874 62.9626 83.5045 62.557C84.5217 62.1515 85.6855 62.2334 86.9959 62.8027C88.3063 63.372 89.1613 64.1701 89.5553 65.1901C89.9493 66.21 89.8685 67.3613 89.3119 68.64L88.0576 71.5257L79.8881 67.9716L81.1424 65.0859L81.1463 65.0849ZM88.1887 68.152C88.567 67.2842 88.5959 66.5091 88.2802 65.8297C87.9636 65.1464 87.3369 64.5995 86.3922 64.1908C85.4476 63.7822 84.6248 63.6913 83.9094 63.9258C83.194 64.1602 82.6487 64.709 82.2704 65.5768L81.5904 67.1387L87.5087 69.7139L88.1849 68.1529L88.1887 68.152Z"
          fill="#2C2B2B"
        />
        <path
          d="M91.9587 60.9886L83.1314 59.7631L84.0189 53.3709L85.2495 53.5428L84.5594 58.5063L87.1004 58.8595L87.6407 54.9822L88.8453 55.1482L88.3049 59.0255L90.9257 59.3879L91.6402 54.2387L92.8708 54.4107L91.9549 60.9895L91.9587 60.9886Z"
          fill="#2C2B2B"
        />
        <path
          d="M83.5955 48.9714C83.4036 47.588 83.6335 46.4619 84.2911 45.5836C84.9449 44.7063 85.9832 44.1689 87.3985 43.9734C88.8177 43.7769 89.9611 44.0108 90.8287 44.6751C91.6963 45.3394 92.2299 46.3623 92.4218 47.7458L92.8532 50.8608L84.0268 52.0864L83.5955 48.9714ZM91.2042 47.9139C91.0754 46.9768 90.6952 46.3008 90.0713 45.8839C89.4475 45.4671 88.625 45.327 87.6098 45.4707C86.5937 45.6104 85.8427 45.9679 85.352 46.5402C84.8614 47.1125 84.6843 47.8662 84.8131 48.8033L85.0491 50.4867L91.4401 49.5973L91.208 47.913L91.2042 47.9139Z"
          fill="#2C2B2B"
        />
        <path
          d="M25.8533 26.6163L30.9973 32.7735L29.9998 33.6074L24.8558 27.4501L25.8533 26.6163ZM31.5819 26.267L32.2971 27.1241L28.2571 30.4972L27.5419 29.6402L31.5819 26.267ZM29.9055 23.2328L35.0494 29.3901L34.0423 30.2299L28.8983 24.0727L29.9055 23.2328Z"
          fill="#2C2B2B"
        />
        <path
          d="M33.9454 20.8167L37.9733 27.7592L36.8471 28.4108L32.8191 21.4683L33.9454 20.8167Z"
          fill="#2C2B2B"
        />
        <path
          d="M42.8242 25.6573C42.1188 25.9201 41.4487 25.994 40.8173 25.8783C40.1851 25.7591 39.6183 25.4695 39.1153 25.0026C38.6123 24.5357 38.2077 23.8978 37.9049 23.0881C37.6056 22.2923 37.4993 21.5491 37.5825 20.8593C37.6657 20.1696 37.9243 19.5661 38.3575 19.0455C38.7907 18.5249 39.3609 18.1293 40.0766 17.8641C40.8594 17.5715 41.5435 17.4795 42.136 17.5863C42.7249 17.6939 43.2768 17.9981 43.7952 18.4981L42.8679 19.4547C42.567 19.0934 42.214 18.8661 41.8035 18.7815C41.3965 18.6961 40.9547 18.7405 40.486 18.9165C40.0137 19.0934 39.6431 19.356 39.3751 19.7079C39.1071 20.0598 38.9487 20.4844 38.9103 20.9794C38.8719 21.4743 38.9632 22.0188 39.1835 22.6093C39.4097 23.2095 39.6871 23.6868 40.0201 24.044C40.3531 24.4012 40.7374 24.6209 41.1712 24.7109C41.6051 24.8009 42.0816 24.7481 42.5974 24.5533C42.8774 24.4487 43.1248 24.3153 43.3428 24.1521C43.5574 23.9898 43.7331 23.8037 43.863 23.5957C43.9929 23.3876 44.0692 23.1558 44.0885 22.9009C44.1077 22.6461 44.0664 22.3691 43.955 22.076L43.8733 21.8603L41.6877 22.6801L41.3152 21.6886L44.5837 20.4632L46.0327 24.3361L45.122 24.6779L44.4861 23.191L44.7539 23.2181C44.7499 23.7636 44.5875 24.2481 44.2669 24.6716C43.9463 25.095 43.4634 25.4253 42.8293 25.6634L42.8242 25.6573Z"
          fill="#2C2B2B"
        />
        <path
          d="M47.951 15.8471L48.9123 23.8152L47.6215 23.9695L46.6601 16.0014L47.951 15.8471ZM52.9472 18.6736L53.0803 19.7856L47.8536 20.4144L47.7205 19.3024L52.9472 18.6736ZM53.1916 15.2149L54.1521 23.1796L52.8474 23.3372L51.886 15.3691L53.1908 15.2114L53.1916 15.2149Z"
          fill="#2C2B2B"
        />
        <path
          d="M57.5114 22.268L61.9149 22.7067L61.8017 23.8195L56.1043 23.2488L56.9011 15.2634L58.1941 15.3919L57.5063 22.2619L57.5114 22.268Z"
          fill="#2C2B2B"
        />
        <path
          d="M69.3999 17.6083L65.367 21.6641L64.5859 24.6383L63.3282 24.3063L64.1128 21.3312L62.6093 15.8196L64.0081 16.1874L64.736 19.0575L65.0428 20.3703L65.9582 19.3797L67.9933 17.2388L69.4042 17.611L69.3999 17.6083Z"
          fill="#2C2B2B"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_9409_4225"
          x="5.21094"
          y="9.21094"
          width="97.0195"
          height="97.0205"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_9409_4225"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_9409_4225"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
