import React, { useEffect, useState } from 'react';
import { Typography, Chip, TextField, Switch } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useFeatureFlagPayload } from 'posthog-js/react';
import ABTESTS from '../../../../abTests';
import COPIES from './copies.json';

const useStyles = makeStyles((theme) => ({
  priceContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    width: '100%',
    marginTop: 16,
  },
  selectedChip: {
    backgroundColor: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    color: '#FFF',
    '&:hover': {
      color: theme.palette.text.secondary,
      backgroundColor: theme.palette.primary.main,
    },
  },
  unselectedChip: {
    backgroundColor: '#FFF',
    border: `1px solid ${theme.palette.system.grey}`,
    color: theme.palette.text.secondary,
  },
  chip: {
    height: 45,
    width: 90,
    borderRadius: 44,
    fontSize: 16,
    cursor: 'pointer',
  },
  toggleContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
}));

function SubscriptionsPriceSelector({
  price,
  setPrice,
  isSubscription,
  setIsSubscription,
  disableToggle,
}) {
  const [inputChanged, setInputChanged] = useState(false);

  const defaultTestValues = {
    presetValues: [8.0, 18.0, 28.0],
    presetAnnualValues: [28.0, 58.0, 78.0],
  };
  const pricingTableFromPh = useFeatureFlagPayload(ABTESTS.PWYW_PRICING) || {};
  const {
    presetValues = defaultTestValues.presetValues,
    presetAnnualValues = defaultTestValues.presetAnnualValues,
  } = pricingTableFromPh || defaultTestValues;

  useEffect(() => {
    if (isSubscription && pricingTableFromPh?.presetValues?.length === 3) {
      setPrice(presetValues[1]);
    }
  }, [pricingTableFromPh]);

  const classes = useStyles();

  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
      <div className={classes.priceContainer}>
        {(isSubscription ? presetValues : presetAnnualValues)?.map(
          (value, idx) => (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              {idx === 1 && (
                <Typography
                  variant="caption"
                  component="p"
                  color="primary.main"
                  fontStyle="italic"
                  marginBottom="4px">
                  Most popular
                </Typography>
              )}
              <Chip
                label={`$${value}`}
                onClick={() => {
                  setPrice(value);
                  setInputChanged(false);
                }}
                className={`${classes.chip} ${
                  price === value
                    ? classes.selectedChip
                    : classes.unselectedChip
                }`}
              />
            </div>
          )
        )}
      </div>
      <TextField
        fullWidth
        variant="outlined"
        type="number"
        value={inputChanged ? price : ''}
        onChange={(e) => {
          if (!inputChanged) setInputChanged(true);
          const sanitizedValue = e.target.value.replace(/[^0-9.]/g, '');
          setPrice(parseFloat(sanitizedValue));
        }}
        placeholder={COPIES.SELL_SCREEN.customPlaceholder}
        sx={{
          marginTop: '8px',
          '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
          },
        }}
        inputProps={{
          sx: {
            padding: '12px',
          },
        }}
      />
      <Typography
        component="p"
        color="text.light"
        fontSize="12px"
        marginLeft="auto"
        fontStyle="italic"
        marginTop="2px">
        {COPIES.SELL_SCREEN.contributionDescription}
      </Typography>
      {!disableToggle && (
        <div className={classes.toggleContainer}>
          <Switch
            checked={isSubscription}
            onChange={() => {
              const priceValues = isSubscription
                ? presetValues
                : presetAnnualValues;
              if (priceValues.includes(price)) {
                setPrice(
                  isSubscription ? presetAnnualValues[1] : presetValues[1]
                );
                setInputChanged(false);
              }
              setIsSubscription(!isSubscription);
            }}
            color="primary"
            sx={{ marginLeft: '-12px' }}
          />
          <Typography variant="caption" component="p" color="text.secondary">
            {COPIES.SELL_SCREEN.contribution}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default SubscriptionsPriceSelector;
