const classList = {
  // tabs
  wishlistTab: 'wishlistTab',
  tripsTab: 'tripsTab',
  discoverTab: 'discoverTab',
  offersTab: 'offersTab',
  profileTab: 'profileTab',

  // global nav
  globalNavMoreClick: 'globalNavMoreClick',

  // profile
  profileSaveButton: 'profileSaveButton',
  profileWishlistTab: 'profileWishlistTab',
  profileTripsTab: 'profileTripsTab',

  // settings
  settingsProductRequests: 'settingsProductRequests',
  settingsLeaveReview: 'settingsLeaveReview',
  settingsAccountDeleteBtn: 'settingsAccountDeleteBtn',

  // wishlist
  wishlistItemEditBtn: 'wishlistItemEditBtn',
  wishlistItemAddBtn: 'wishlistItemAddBtn',

  // auth
  signupForm: 'signupForm',
  signinForm: 'signinForm',
  forgotPasswordForm: 'forgotPasswordForm',
  forgotPasswordBtn: 'forgotPasswordBtn',
  verifyEmailBackBtn: 'verifyEmailBackBtn',

  // trip nav
  tripNavLeaveTrip: 'tripNavLeaveTrip',
  tripNavDeleteTrip: 'tripNavDeleteTrip',

  // explore
  exploreThingsToDoTab: 'exploreThingsToDoTab',
  explorePlacesToStayTab: 'explorePlacesToStayTab',

  // hotel details
  stayDetailViewAllAmenitiesBtn: 'stayDetailViewAllAmenitiesBtn',
  stayDetailViewLessAmenitiesBtn: 'stayDetailViewLessAmenitiesBtn',
  stayDetailViewAllDescriptionBtn: 'stayDetailViewAllDescriptionBtn',
  stayDetailViewLessDescriptionBtn: 'stayDetailViewLessDescriptionBtn',

  createTripButton: 'createTripButton',
  createTripCard: 'createTripCard',
  tripCard: 'tripCard',
  upcomingTripsContainer: 'upcomingTripsContainer',
  pastTripsContainer: 'pastTripsContainer',
  tripTitle: 'tripTitle',
  tripCoverImage: 'tripCoverImage',
  emptyTripCoverImage: 'emptyTripCoverImage',
  tripDatePickerButton: 'tripDatePickerButton',
  addModuleButton: 'addModuleButton',
  addModuleMenuBar: 'addModuleMenuBar',
  blockHeader: 'blockHeader',
  itineraryHeader: 'itineraryHeader',
  addAccommodationButton: 'addAccommodationButton',
  addTodo: 'addTodo',
  addTodoSection: 'addTodoSection',
  mapContainer: 'mapContainer',
  mapSearchBar: 'mapSearchBar',
  inviteButton: 'inviteButton',
  refetchButton: 'refetchButton',
  accommodationBlock: 'accommodationBlock',
  iconDatePickerButton: 'iconDatePickerButton',
  transportationBlock: 'transportationBlock',
  avtarStack: 'avtarStack',
  shareTripModal: 'shareTripModal',
  automatedFlightBlade: 'automatedFlightBlade',
  todoList: 'todoList',
  todoItem: 'todoItem',
  item: 'item',
  plannerNavButtons: 'plannerNavButtons',
  tripPlanNavButton: 'tripPlanNavButton',
  notesNavButton: 'notesNavButton',
  filesNavButton: 'filesNavButton',
  plannerNavBar: 'plannerNavBar',
  plannerNavBarTablet: 'plannerNavBarTablet',
  exportPdfButton: 'exportPdfButton',
  exportTripModal: 'exportTripModal',
  tripChatButton: 'tripChatButton',
  tripSupportButton: 'tripSupportButton',
  tripOptionsMenuButton: 'tripOptionsMenuButton',
  providerSignInButtons: 'providerSignInButtons',
  flightBookingsButton: 'flightBookingsButton',
  hotelBookingsButton: 'hotelBookingsButton',
  itemActionContainer: 'item-action-container',

  // discover
  discoverSearchBar: 'discoverSearchBar',
  discoverPoiCard: 'discoverPoiCard',
  discoverCityCard: 'discoverCityCard',
  discoverBlogCard: 'discoverBlogCard',
  discoverCreateTripModalBtn: 'discoverCreateTripModalBtn',
  discoverCreateTripBtn: 'discoverCreateTripBtn',
  discoverCreateTripTitleInput: 'discoverCreateTripTitleInput',
  discoverCreateTripDateInput: 'discoverCreateTripDateInput',
  discoverCreateTripModalCancelBtn: 'discoverCreateTripModalCancelBtn',
  discoverAddToWislistBtn: 'discoverAddToWislistBtn',
  discoverRemoveFromWishlistBtn: 'discoverRemoveFromWishlistBtn',
  discoverWishlistSnackbarButton: 'discoverWishlistSnackbarButton',
  discoverBackToDiscoverBtn: 'discoverBackToDiscoverBtn',

  // stays
  staysProviderBtn: 'staysProviderBtn',
  staysQuickbookBtn: 'staysQuickbookBtn',
  staysAddToSavedListBtn: 'staysAddToSavedListBtn',
  staysRemoveFromSavedListBtn: 'staysRemoveFromSavedListBtn',

  // explore
  exploreToggleMenu: 'explore-toggle-menu',
  recommendationsSearchbarContainer: 'recommendations-searchbar-container',
  staysSearchbarContainer: 'stays-searchbar-container',

  // invite modal
  acceptTripButton: 'acceptTripButton',
  declineTripButton: 'declineTripButton',
  maybeLaterButton: 'maybeLaterButton',
};

export default classList;
