import React from 'react';
import { Typography, useTheme, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getRatingProps } from './utils';

const useStyles = makeStyles(({ breakpoints }) => ({
  controlledWidth: {
    width: 1032,
    margin: '0 auto',
    padding: '40px 0px',
    [breakpoints.down('lg')]: {
      width: '100%',
      padding: '32px 48px',
      margin: 0,
    },
    [breakpoints.down('sm')]: {
      padding: '32px 36px',
    },
  },
  quoteBox: {
    border: '1px solid #D9D9D9',
    padding: '24px',
    backgroundColor: '#FFF',
    textAlign: 'center',
    borderRadius: 8,
    [breakpoints.down('lg')]: {
      padding: '12px 24px',
    },
  },
  quoteContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 32,
    gap: 24,
  },
}));

function ReviewSection() {
  const classes = useStyles();

  const { slug: hotelKey } = useParams();
  const hotelDetails = useSelector(
    (state) => state.Bookings?.hotelDetailsPage?.data[hotelKey]
  );

  const theme = useTheme();
  const isTab = useMediaQuery(theme.breakpoints.down('lg'));

  const getTitleCase = (str) =>
    str.toLowerCase().replace(/(?:^|\s)\S/g, (a) => a.toUpperCase());

  const noOfReviews = hotelDetails?.reviews?.numberOfReviews || 0;

  // if noOfReviews is < 25 then we'll not show the ratingSentiment
  const overallRatingCopy =
    noOfReviews > 25
      ? getRatingProps(hotelDetails?.reviews?.guestRatings?.OVERALL)?.[0]
      : null;

  return (
    <div
      style={{
        width: '100%',
        backgroundColor: '#FFFBF6',
      }}>
      <div className={classes.controlledWidth}>
        <Typography variant="h2">What People Are Saying</Typography>

        <div
          style={{
            display: 'flex',
            marginTop: isTab ? 12 : 32,
            alignItems: 'center',
          }}>
          <Typography variant="h2" fontSize="54px" color="primary.main">
            {hotelDetails?.reviews?.guestRatings?.OVERALL}
          </Typography>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginLeft: 8,
            }}>
            <Typography variant="h3">{overallRatingCopy}</Typography>
            <Typography variant="h5" color="text.secondary">
              {hotelDetails?.reviews?.numberOfReviews} reviews
            </Typography>
          </div>
        </div>

        <div style={{ marginTop: 20 }}>
          <Typography variant="h3">Categories</Typography>
          <div
            style={{
              display: 'flex',
              marginTop: 12,
              justifyContent: isTab ? 'flex-start' : 'space-around',
              ...(isTab ? { width: '100%', flexWrap: 'wrap' } : {}),
            }}>
            {Object.keys(hotelDetails?.reviews?.guestRatings || {})?.map(
              (ratingType) =>
                ratingType !== 'OVERALL' && (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      minWidth: 120,
                      marginRight: 24,
                    }}>
                    <Typography variant="h1">
                      {hotelDetails?.reviews?.guestRatings[ratingType]?.toFixed(
                        1
                      )}
                    </Typography>
                    <Typography variant="h4" ml={2} textTransform="capitalize">
                      {getTitleCase(ratingType)}
                    </Typography>
                  </div>
                )
            )}
          </div>
        </div>

        <div className={classes.quoteContainer}>
          {hotelDetails?.reviews?.quotes
            ?.filter((quote) => quote?.polarity === 1)
            ?.map((quote) => (
              <Typography variant="h5" className={classes.quoteBox}>
                <i>&quot;{quote?.text}&quot;</i>
              </Typography>
            ))}
        </div>
      </div>
    </div>
  );
}

export default ReviewSection;
