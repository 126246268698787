import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, IconButton } from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import UpdateContribution from './UpdateContribution';
import AppreciationScreen from './AppreciationScreen';
import ConfirmCancellation from './ConfirmCancellation';
import CancellationSuccess from './CancellationSuccess';
import actions from '../../../../redux/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.system.white,
    position: 'relative',
    borderRadius: 4,
    padding: '32px 28px',
    maxWidth: 450,
  },
  iconBtn: {
    top: 4,
    right: 4,
    position: 'absolute',
  },
}));

function UpdateSubcriptionsModal() {
  const classes = useStyles();

  const dispatch = useDispatch();
  const actionStep = useSelector(
    (state) => state.View.updateSubscription?.action
  );

  const handleClose = () => {
    dispatch(actions.View.setUpdateSubscription({ action: -1 }));
  };

  const SelectedScreen = useMemo(() => {
    switch (actionStep) {
      case 0:
        return <UpdateContribution />;
      case 1:
        return <AppreciationScreen />;
      case 2:
        return <ConfirmCancellation />;
      case 3:
        return <CancellationSuccess />;
      default:
        return <div />;
    }
  }, [actionStep]);

  return (
    <Modal
      open={actionStep !== -1}
      onClose={handleClose}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <div className={classes.root}>
        <IconButton className={classes.iconBtn} onClick={handleClose}>
          <CloseRounded sx={{ height: 16, width: 16 }} />
        </IconButton>
        {SelectedScreen}
      </div>
    </Modal>
  );
}

export default UpdateSubcriptionsModal;
