import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers';

import Popover from '@mui/material/Popover';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  TextField,
  Box,
  Tabs,
  Tab,
  Checkbox,
  Typography,
  Button,
} from '@mui/material';

import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { makeStyles } from '@mui/styles';
import { format } from 'date-fns';

import { useDateUtils } from './DateUtils';
import { useItineraryDnd } from '../../organisms/ItineraryDndProvider';
import { TertiaryButton } from '../../atoms/Button/index';
import { parseISODate } from '../../../utils';

const useStyles = makeStyles(({ palette }) => ({
  tabs: {
    color: '#222',
    display: 'flex',
    alignItems: 'center',
    paddingX: '2px',
    paddingLeft: '4px',
    marginVertical: '4px',
    width: '100%',
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    '& .MuiTab-textColorInherit': {
      opacity: 1,
    },
    '& .Mui-selected': {
      backgroundColor: palette.primary.extraLight,
      borderColor: palette.primary.main,
      color: palette.primary.main,
    },
  },
  tab: {
    width: '50px !important',
    textTransform: 'none',
    transition: 'all 0.2s',
    padding: '2px 4px',
    minHeight: '15px',
    border: `1px solid ${palette.system.grey}`,
    ' & .MuiButtonBase-root': {
      minWidth: '50px !important',
      maxWidth: '50px !important',
    },
  },
  numberInput: {
    '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button':
      {
        WebkitAppearance: 'none',
        margin: 0,
      },
    '& input[type=number]': {
      MozAppearance: 'textfield',
    },
  },
}));

function CustomTimePicker({ value, onComplete, onAccept, onClose }) {
  const classes = useStyles();
  const timeInputRefs = React.useRef([]);
  const [timeValue, setTimeValue] = useState(['0', '0', '0', '0']);

  const [activeTab, setActiveTab] = useState(0);

  const userObj = useSelector((state) => state.Auth.userData);

  const [is24HourFormat, setIs24HourFormat] = useState(
    userObj.user.is24HourTime
  );

  useEffect(() => {
    if (value) {
      // get 24 hr time from date

      let hours = value.getHours();
      const minutes = value.getMinutes();

      if (hours > 12) {
        setActiveTab(1);
      }

      if (!is24HourFormat && hours > 12) {
        hours -= 12;
      }

      const newTimeValue = [
        Math.floor(hours / 10)?.toString() || '0',
        (hours % 10).toString() || '0',
        Math.floor(minutes / 10)?.toString() || '0',
        minutes % 10 || '0',
      ];
      setTimeValue(newTimeValue);
    }
  }, [value]);

  useEffect(() => {
    // if am pm selected substract 12 if hours is more than that
    if (!is24HourFormat) {
      let hours = Number(timeValue.slice(0, 2).join(''));

      if (hours > 12) {
        hours -= 12;
        const newTimeValue = [
          Math.floor(hours / 10)?.toString() || '0',
          (hours % 10).toString() || '0',
          timeValue[2],
          timeValue[3],
        ];
        setTimeValue(newTimeValue);
      }
    }
  }, [activeTab]);

  const handleTimeChange = (e, index) => {
    const newTimeValue = [...timeValue];
    const newvalue = e.target.value.split('')[e.target.value.length - 1];
    if (index < 3 && e.target.value.length) {
      timeInputRefs.current[index + 1].focus();
    }

    if (
      (index === 0 && !is24HourFormat && newvalue > 1) ||
      (index === 0 && is24HourFormat && newvalue > 2)
    ) {
      newTimeValue[index] = '0';
      newTimeValue[index + 1] = newvalue;
      timeInputRefs.current[index + 2].focus();
    } else newTimeValue[index] = newvalue;

    if (index === 3 && e.target.value.length) {
      timeInputRefs.current[index].blur();
      // get date from value
    }
    setTimeValue(newTimeValue);
  };

  const handleTimeKeyDown = (e, index) => {
    // Using RegX to check the input character
    const inputChar = e.key;
    const isNumericKey = /^[0-9]+$/.test(inputChar);

    if (!isNumericKey || inputChar === ' ') {
      e.preventDefault();
    }

    if (e.key === 'Backspace' && index > 0 && e.target.value === '') {
      timeInputRefs.current[index - 1].focus();
    }
  };

  const resetTime = () => {
    onComplete(null);
    setTimeValue(['0', '0', '0', '0']);
    setActiveTab(0);
    setIs24HourFormat(false);
  };

  const submitDate = () => {
    if (!value) {
      onAccept(null);
      onClose();
      return;
    }
    const date = format(value, 'yyyy-MM-dd');

    // add first 2 digits to hours and last 2 digits to minutes
    let hours = Number(timeValue.slice(0, 2).join(''));
    const minutes = timeValue.slice(2, 4).join('');

    if (!is24HourFormat && activeTab === 1 && hours < 12) {
      // add 12 hours from making it PM
      hours += 12;
    }
    const selectedDateTime = new Date(
      `${date}T${hours >= 10 ? hours : `0${hours}`}:${minutes}`
    );

    // is valid date
    if (selectedDateTime.toString() === 'Invalid Date') onAccept(value);
    else {
      onAccept(selectedDateTime);
      onComplete(selectedDateTime);
    }
    onClose();
  };

  const handleFocus = (index) => {
    const newTimeValue = [...timeValue];
    newTimeValue[index] = '';
    setTimeValue(newTimeValue);
  };

  const handle24hourToggle = (enabled) => {
    let hours = Number(timeValue.slice(0, 2).join(''));

    if (enabled && activeTab === 1 && hours < 12) {
      // add 12 hours from making it PM
      hours += 12;
      const newTimeValue = [
        Math.floor(hours / 10)?.toString() || '0',
        (hours % 10).toString() || '0',
        timeValue[2],
        timeValue[3],
      ];
      setTimeValue(newTimeValue);
    }
    if (!enabled && hours > 12) {
      hours -= 12;
      const newTimeValue = [
        Math.floor(hours / 10)?.toString() || '0',
        (hours % 10).toString() || '0',
        timeValue[2],
        timeValue[3],
      ];
      setTimeValue(newTimeValue);
      setActiveTab(1);
    }
    setIs24HourFormat(enabled);
  };

  return (
    <>
      <Box display="flex" alignItems="center" width={342} paddingX={1}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box display="flex" alignItems="center" height="50px">
            <Box display="flex" marginLeft={2}>
              {Array.from({ length: 4 }).map((_, index) => {
                if (index === 1)
                  return (
                    <>
                      <TextField
                        key={index}
                        inputRef={(ref) => {
                          timeInputRefs.current[index] = ref;
                        }}
                        onFocus={() => handleFocus(index)}
                        type="number"
                        inputMode="numeric"
                        value={timeValue[index] || ''}
                        onChange={(e) => handleTimeChange(e, index)}
                        onKeyDown={(e) => handleTimeKeyDown(e, index)}
                        variant="outlined"
                        size="small"
                        className={classes.numberInput}
                        inputProps={{
                          style: {
                            textAlign: 'center',
                            textDecoration: 'none',
                            width: '25px',
                            height: '35px',
                            padding: '0px',
                          },
                        }}
                        style={{
                          margin: '0.15rem',
                        }}
                      />
                      <Typography bold textAlign="center" marginTop={1}>
                        :
                      </Typography>
                    </>
                  );
                return (
                  <TextField
                    key={index}
                    inputRef={(ref) => {
                      timeInputRefs.current[index] = ref;
                    }}
                    onFocus={() => handleFocus(index)}
                    type="number"
                    inputMode="numeric"
                    value={timeValue[index] || ''}
                    onChange={(e) => handleTimeChange(e, index)}
                    onKeyDown={(e) => handleTimeKeyDown(e, index)}
                    variant="outlined"
                    size="small"
                    className={classes.numberInput}
                    inputProps={{
                      style: {
                        textAlign: 'center',
                        width: '25px',
                        height: '35px',
                        padding: '0px',
                      },
                    }}
                    style={{
                      margin: '0.15rem',
                    }}
                  />
                );
              })}
            </Box>

            {!is24HourFormat && (
              <Tabs
                value={activeTab}
                onChange={(_, newValue) => setActiveTab(newValue)}
                indicatorColor="primary"
                className={classes.tabs}>
                <Tab
                  className={classes.tab}
                  sx={{
                    borderRadius: '6px 0px 0px 6px',
                    borderRightWidth:
                      activeTab === 0 ? 'auto' : '0px !important',
                    maxWidth: '50px',
                  }}
                  label="AM"
                />
                <Tab
                  className={classes.tab}
                  sx={{
                    borderRadius: '0px 6px 6px 0px',
                    borderLeftWidth:
                      activeTab === 1 ? 'auto' : '0px !important',
                    maxWidth: '50px',
                  }}
                  label="PM"
                />
              </Tabs>
            )}
          </Box>
          <Box
            paddingX={1}
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            alignContent="center"
            alignItems="center">
            <Checkbox
              checked={is24HourFormat}
              value={is24HourFormat}
              onChange={() => handle24hourToggle(!is24HourFormat)}
            />
            <Typography>24 hr</Typography>
          </Box>
        </Box>
      </Box>
      <Box
        paddingX={1}
        paddingY={1}
        style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <TertiaryButton
          type="submit"
          variant="text"
          onClick={resetTime}
          paddingX={1}>
          Reset
        </TertiaryButton>
        <Button type="submit" onClick={submitDate}>
          Done
        </Button>
      </Box>
    </>
  );
}

// using custom layout from https://mui.com/x/react-date-pickers/custom-layout/

function CustomDateTimePicker(allprops) {
  const { onClose, open, PopperProps, anchorEl, value, onAccept, ...rest } =
    allprops;
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    if (value) setSelectedDate(new Date(value));
  }, [value]);

  const handleClose = () => {
    onClose();
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    if (rest.disableTime) {
      onAccept(date);
      handleClose();
    }
  };

  const id = open ? 'simple-popover' : undefined;
  // Put the action bar before the content
  return (
    <Popover
      id={id}
      open={open}
      onClose={handleClose}
      anchorEl={anchorEl}
      {...PopperProps}>
      <StaticDatePicker
        {...rest}
        value={selectedDate}
        onChange={handleDateChange}
        renderInput={({ inputRef, inputProps }) => (
          <div ref={inputRef} {...inputProps} />
        )}
        slots={{
          toolbar: () => null, // hide the toolbar,
          actionBar: () => null,
        }}
      />
      {!rest.disableTime && (
        <CustomTimePicker
          value={selectedDate}
          onComplete={handleDateChange}
          onAccept={onAccept}
          onClose={handleClose}
        />
      )}
    </Popover>
  );
}

/**
 *
 * @param {bool} open state to handle programatic open/close of the picker
 * @param {function} handleClose function to run on close, ideally setting open=false
 * @param {Date} selectedDate property indication the current date value in the picker
 * @param {function} handleDateChange function to handle date change, ideally set the date state.
 * @param {function} handleSubmit callback to handle when the date is accepted
 *
 * @returns DateTimePicker
 */
function DateTimePicker({
  open = false,
  handleClose = () => {},
  selectedDate,
  handleDateChange = () => {},
  handleSubmit = () => {},
  maxDate = null,
  minDate = null,
  disableTime = false,
  anchorEl = null,
  onOpen = () => {},
}) {
  const dateUtils = useDateUtils();
  const { tripStartDate } = useItineraryDnd();
  const finalMinDate = minDate
    ? parseISODate(minDate)
    : dateUtils?.arrivalDate // checking first parent like sections
    ? parseISODate(dateUtils?.arrivalDate)
    : tripStartDate // checking if trip has start date
    ? parseISODate(tripStartDate)
    : null;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <CustomDateTimePicker
        views={[
          'year',
          'month',
          'day',
          ...(!disableTime ? ['hours', 'minutes'] : []),
        ]}
        open={open}
        onClose={handleClose}
        renderInput={({ inputRef, inputProps }) => (
          <div ref={inputRef} {...inputProps} />
        )}
        value={selectedDate || null}
        onChange={handleDateChange}
        onAccept={handleSubmit}
        maxDateTime={maxDate || new Date('2100-01-01')}
        referenceDate={finalMinDate}
        minDateTime={minDate || null}
        maxDate={maxDate || null}
        minDate={minDate || null}
        showToolbar={false}
        hideTabs={false}
        ampm
        disableTime={disableTime}
        PopperProps={{
          placement: 'bottom-start',
        }}
        anchorEl={anchorEl}
        onOpen={onOpen}
      />
    </LocalizationProvider>
  );
}

export default DateTimePicker;
