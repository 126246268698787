import React, { useEffect } from 'react';
import { Button, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { ChevronLeftRounded } from '@mui/icons-material';
import { format } from 'date-fns';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { convertSlugToTitle } from '../../../utils';
import { OutlinedArrowUp } from '../../atoms/Icon';
import { TransparentBackButton } from '../../atoms/Button/index';
import actions from '../../../redux/actions';

const useStyles = makeStyles(({ typography }) => ({
  header: {
    position: 'relative',
    width: '100%',
    height: 369,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    objectFit: 'cover',
    padding: '0px 20%',
  },
  titleContainer: {
    // offset button space
    paddingTop: 60,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    color: '#ffffff',
  },
  backButtonContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    padding: '24px 20%',
  },
  body: {
    padding: '30px 20%',
    backgroundColor: '#FFFBF6',
  },
  article: {
    fontSize: '20px',
    marginTop: 40,
    '& p': {
      marginBottom: '1.4rem',
      color: '#4E4E4E',
      '& a': {
        color: '#4E4E4E',
        textDecoration: 'underline',
      },
    },
    '& figure': {
      width: '100%',
      marginRight: 'auto',
      marginLeft: 'auto',
    },
    '& figcaption': {
      color: '#8A8A8A',
      fontSize: '0.94rem',
      textAlign: 'center',
    },
    '& li': {
      color: '#4E4E4E',
      marginBottom: '0.8rem',
    },
    '& h2': {
      color: '#2C2B2B',
      marginTop: '2rem',
      ...typography.h2Sub,
      lineHeight: '1.16',
    },
    '& h3': {
      color: '#D35E07',
      ...typography.h3Sub,
      fontWeight: 600,
      '& a': {
        color: '#D35E07',
        textDecoration: 'underline',
      },
    },
    '& img': {
      width: '100%',
    },
  },
}));

function BackToTopButton({ handleClick = () => {} }) {
  return (
    <Button
      onClick={handleClick}
      sx={{
        backgroundColor: '#FFA766',
        color: '#FFF1E0',
        borderRadius: 50,
        fontSize: 16,
        fontWeight: 600,
        fontFamily: 'Work Sans',
        padding: '12px',
        '&:hover': {
          backgroundColor: '#ED702E',
          color: '#FFF1E0',
        },
      }}>
      <OutlinedArrowUp style={{ marginRight: 8 }} />
      BACK TO TOP
    </Button>
  );
}

function DestinationArticlePage({ destination, article }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleNavigateBack = () => {
    navigate(`/discover/${destination}`);
  };

  useEffect(() => {
    dispatch(
      actions.Discover.setPrevPath(`/${destination}/article/${article?.slug}`)
    );
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div
        className={classes.header}
        style={{
          backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%), url(${article?.coverImage})`,
        }}>
        <div className={classes.backButtonContainer}>
          <TransparentBackButton
            onClick={handleNavigateBack}
            startIcon={<ChevronLeftRounded />}>
            Back to {convertSlugToTitle(destination)}
          </TransparentBackButton>
        </div>
        <div className={classes.titleContainer}>
          <Typography
            variant="h2Sub"
            component="h1"
            fontSize="40px"
            color="#FFF"
            fontWeight={700}
            lineHeight={1.16}>
            {article?.title}
          </Typography>
          <div style={{ marginTop: 20 }} />
          <Typography color="#FFF" fontSize="19px">
            {article?.createdOn &&
              format(new Date(article?.createdOn), 'MMMM d, yyyy')}
            <span style={{ margin: '0px 10px', color: '#D9D9D9' }}>•</span>
            {article?.readingTime} minute read
          </Typography>
        </div>
      </div>
      <div className={classes.body}>
        <div style={{ marginTop: 20 }} />
        <Typography variant="h3Sub">{article?.summary}</Typography>
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: article?.content,
          }}
          className={classes.article}
        />
        <div
          style={{
            padding: '40px 0px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <BackToTopButton
            handleClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          />
        </div>
      </div>
    </div>
  );
}

export default DestinationArticlePage;
