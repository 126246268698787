import { Typography } from '@mui/material';
import { SellRounded, EastRounded } from '@mui/icons-material';
import { OrangeOutlinedButton } from '../../atoms/Button/index';
import CLASSES from '../../classList';

function BookingsHeader({ openBookingsModal }) {
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        alignItems: 'center',
      }}>
      <Typography
        style={{
          color: '#222',
          fontSize: 12,
          fontWeight: 500,
          margin: '8px 2px',
          display: 'flex',
          alignItems: 'center',
          flex: 1,
        }}>
        <SellRounded
          sx={{
            height: 14,
            width: 14,
            fill: '#4E4E4E',
            marginRight: 1,
          }}
        />
        Haven&apos;t booked yet?
      </Typography>
      <OrangeOutlinedButton
        className={CLASSES.flightBookingsButton}
        onClick={openBookingsModal}
        endIcon={<EastRounded sx={{ height: 12, width: 12 }} />}
        sx={{
          padding: '2px 8px',
          fontSize: 12,
        }}>
        Find flight deals
      </OrangeOutlinedButton>
    </div>
  );
}

export default BookingsHeader;
