import { Navigate, Route } from 'react-router-dom';
import SentryRoutes from './SentryRoutes';
import Signin from '../components/pages/auth/Signin';
import Signup from '../components/pages/auth/Signup';
import PasswordRecovery from '../components/pages/auth/PasswordRecovery';
import PasswordReset from '../components/pages/auth/PasswordReset';
import MailSniper from '../components/pages/auth/MailSniper';
import ActionLinkHandler from '../components/pages/auth/ActionLinkHandler';

function AuthRoutes({ defaultAuthRoute }) {
  return (
    <SentryRoutes>
      <Route exact path="/continue" element={<Signin />} />
      <Route exact path="/signin" element={<Navigate to="/continue" />} />
      <Route exact path="/signup" element={<Signup />} />
      <Route exact path="/passwordrecovery" element={<PasswordRecovery />} />
      <Route exact path="/password-reset" element={<PasswordReset />} />
      <Route exact path="/email-verification" element={<MailSniper />} />
      <Route exact path="/auth" element={<ActionLinkHandler />} />
      <Route
        path="*"
        element={
          <Navigate
            to={defaultAuthRoute}
            replace
            state={{
              deleteConfirmationPopup: defaultAuthRoute === '/signup',
            }}
          />
        }
      />
    </SentryRoutes>
  );
}

export default AuthRoutes;
