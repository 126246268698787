import { combineReducers, configureStore } from '@reduxjs/toolkit';
import reducers from './reducers';

const appReducer = combineReducers(reducers);

const rootReducer = (state, action) => {
  let newState = state;
  if (action.type === 'AUTH/reset') {
    newState = undefined;
  }
  return appReducer(newState, action);
};

const store = configureStore({
  reducer: rootReducer,
});

export default store;
