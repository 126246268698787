import { useDispatch } from 'react-redux';
import { ItineraryActionButton } from '../../../atoms/Button/index';
import { useItineraryDnd } from '../../ItineraryDndProvider';
import actions from '../../../../redux/actions';
import classList from '../../../classList';

function AddTodoSection({ locationId, index = -1, style = {} }) {
  const { setNewSection } = useItineraryDnd();
  const dispatch = useDispatch();

  const handleClick = async () => {
    // TODO: replace with uuid
    const newColumnId = `local-section-${Date.now()}`;
    const newSection = {
      id: newColumnId,
      name: '',
      todos: [],
    };
    dispatch(
      actions.Section.addLocalSection({
        localId: newColumnId,
        section: newSection,
      })
    );
    dispatch(
      actions.Location.addLocalSectionToLocation({
        locationId,
        sectionId: newColumnId,
        index,
      })
    );

    setNewSection(newColumnId);
  };

  return (
    <ItineraryActionButton
      disableRipple
      onClick={handleClick}
      style={{ ...style, marginTop: 4 }}
      className={classList.addTodoSection}>
      + Heading
    </ItineraryActionButton>
  );
}

export default AddTodoSection;
