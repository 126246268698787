import { useEffect } from 'react';
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import LottiePlayer from '../molecules/CustomLottiePlayer';
import { isIOS } from '../../utils';

const useStyles = makeStyles(() => ({
  loadingContainer: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    backgroundColor: '#fff',
    color: '#fff',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 9999, // Ensure it is on top of other elements
  },
  horizontalAlign: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '20px',
  },
  transitionText: {
    marginTop: '20px',
    color: '#000',
  },
  redirectText: {
    marginTop: '10px',
    color: '#000',
    fontWeight: 500,
  },
}));

const providerLogos = {
  'Booking.com': '/images//providers/booking.com.svg',
  Expedia: '/images//providers/expedia.png',
  'Hotels.com': '/images//providers/hotels.com.png',
  'Agoda.com': '/images//providers/agoda.png',
};

function TransitionLayout({ providerDetails, targetUri, setShowTransition }) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const useGif = isIOS();
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (isSafari) {
        // Open in new tab for Safari
        window.location.href = targetUri;
      } else {
        // Use window.open for other browsers
        window.open(targetUri, '_blank');
      }
      setShowTransition(false);
    }, 1500);
    return () => clearTimeout(timeout);
  }, [targetUri]);

  const logoSrc = providerLogos[providerDetails?.name] || providerDetails?.logo;

  const logoWidth = isMobile
    ? providerDetails?.name === 'Agoda.com'
      ? 100
      : 80
    : providerDetails?.name === 'Agoda.com'
    ? 190
    : 130;
  const logoHeight = isMobile ? 80 : 130;
  const logoPadding = isMobile ? 1 : 2;

  return (
    <Grid container className={classes.loadingContainer}>
      <Grid item className={classes.horizontalAlign}>
        <Grid item>
          <img
            src="/logo512.png"
            alt="logo1"
            style={{
              width: isMobile ? 80 : 130,
              height: isMobile ? 80 : 130,
            }}
          />
        </Grid>
        <Grid item>
          {useGif ? (
            <img
              src="/images/arrow-bookings-transition.gif"
              alt="arrow-transition-animation"
              style={{
                width: isMobile ? 80 : 120,
                height: isMobile ? 60 : 100,
              }}
            />
          ) : (
            <LottiePlayer
              filePath="/lottiefiles/arrow_transition_animation.lottie"
              style={{
                width: isMobile ? 100 : 150,
                height: isMobile ? 100 : 150,
              }}
            />
          )}
        </Grid>
        <Grid item>
          <Box
            component="img"
            alt="hotel-provider-logo"
            src={logoSrc}
            sx={{
              width: logoWidth,
              height: logoHeight,
              padding: logoPadding,
            }}
          />
        </Grid>
      </Grid>
      <Typography variant="h1" className={classes.transitionText}>
        {`We've found your deal!`}
      </Typography>
      <Typography variant="body1" className={classes.redirectText}>
        {`Now redirecting you to ${providerDetails.name} to book`}
      </Typography>
    </Grid>
  );
}

export default TransitionLayout;
