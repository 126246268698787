import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import getAsyncThunk from '../helpers';
import graphqlClient from '../../graphql/index';
import QUERY from '../../graphql/queries';
import { createSection } from './sharedThunks';
import { trackEvents, Events } from '../../intercom';

const initialState = {
  todos: {},
  placeholderLoading: null,
  isNewActivity: null,
};

export const createActivity = getAsyncThunk(
  'ACTIVITY/createActivity',
  QUERY.CREATE_TODO
);

export const updateActivity = getAsyncThunk(
  'ACTIVITY/updateActivity',
  QUERY.UPDATE_TODO
);

export const deleteActivity = getAsyncThunk(
  'ACTIVITY/deleteActivity',
  QUERY.DELETE_TODO
);

export const createNewActivityInLocation = createAsyncThunk(
  'ACTIVITY/createNewActivityInLocation',
  async (
    { locationId, activityTitle, ...activityVars },
    { rejectWithValue }
  ) => {
    const { data: activity, error: activityError } = await graphqlClient.mutate(
      {
        mutation: QUERY.CREATE_TODO,
        variables: {
          title: activityTitle,
          ...activityVars,
        },
      }
    );
    const { data: section, error: sectionError } = await graphqlClient.mutate({
      mutation: QUERY.CREATE_THINGS_TODO,
      variables: {
        locationId,
        name: '',
        todos: [activity.createTodo.id],
      },
    });
    if (activityError || sectionError)
      rejectWithValue(activityError || sectionError);
    return {
      activity: {
        ...activity.createTodo,
        mapPin: activity.createTodo?.mapPin?.id || null,
        id: activity.createTodo.id,
        title: activityTitle,
      },
      section: {
        ...section.createThingsToDo,
        locationId,
        todos: [activity.createTodo.id],
        name: '',
      },
    };
  }
);

const ActivitySlice = createSlice({
  name: 'ACTIVITY',
  initialState,
  reducers: {
    initializeActivities: (state, action) => {
      state.todos = {
        ...state.todos,
        ...action.payload,
      };
    },
    createLocalActivity: (state, { payload: { localId, todo } }) => {
      state.todos = {
        ...state.todos,
        [localId]: {
          ...todo,
        },
      };
    },
    setNewActivity: (state, { payload: { activityId } }) => {
      state.isNewActivity = activityId;
    },
    deleteActivityFile: (state, { payload: { activityId, fileId } }) => {
      state.todos[activityId] = {
        ...state.todos[activityId],
        files: state.todos[activityId].files.filter((file) => file !== fileId),
      };
    },
    handleActivityFiles: (
      state,
      { payload: { fileId, itemsToAttach, itemsToDetach } }
    ) => {
      if (itemsToAttach?.length > 0) {
        itemsToAttach?.forEach((item) => {
          state.todos[item.id].files = [...state.todos[item.id].files, fileId];
        });
      }
      if (itemsToDetach?.length > 0) {
        itemsToDetach?.forEach((item) => {
          state.todos[item.id].files = state.todos[item.id].files.filter(
            (file) => file !== fileId
          );
        });
      }
    },
  },
  extraReducers: {
    [createActivity.fulfilled]: (
      state,
      {
        payload: { createTodo: todo },
        meta: {
          arg: { variables, shouldAppendActivity, localActivity },
        },
      }
    ) => {
      state.todos = {
        ...state.todos,
        [todo?.id]: {
          ...variables,
          ...todo,
          mapPin: todo?.mapPin?.id,
        },
        ...(shouldAppendActivity
          ? {
              [localActivity?.id]: {
                ...localActivity,
              },
            }
          : {}),
      };
      trackEvents(Events.ActivityAdded);
    },
    [updateActivity.fulfilled]: (state, { payload }) => {
      const updated = payload.updateTodo;
      state.todos[updated.id] = {
        ...updated,
        mapPin: updated?.mapPin?.id,
        files: updated?.files?.map((file) => file?.id) || [],
      };
    },
    [deleteActivity.pending]: (
      state,
      {
        meta: {
          arg: { variables: todo },
        },
      }
    ) => {
      const newTodos = { ...state.todos };
      delete newTodos[todo.id];
      state.todos = { ...newTodos };
    },
    [createNewActivityInLocation.pending]: (
      state,
      {
        meta: {
          arg: { locationId },
        },
      }
    ) => {
      state.placeholderLoading = locationId;
    },
    [createNewActivityInLocation.fulfilled]: (
      state,
      {
        payload: { activity },
        meta: {
          arg: { localActivity },
        },
      }
    ) => {
      state.todos = {
        ...state.todos,
        [activity.id]: {
          ...activity,
        },
        ...(localActivity
          ? {
              [localActivity.id]: {
                ...localActivity,
              },
            }
          : {}),
      };
      state.placeholderLoading = null;
    },
    [createSection.fulfilled]: (
      state,
      {
        meta: {
          arg: { shouldAppendActivity, localActivity },
        },
      }
    ) => {
      if (shouldAppendActivity) {
        state.todos = {
          ...state.todos,
          [localActivity.id]: {
            ...localActivity,
          },
        };
      }
    },
  },
});

export const ActivityActions = ActivitySlice.actions;
export const ActivityReducer = ActivitySlice.reducer;
