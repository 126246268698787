import { useState } from 'react';
import { Typography, Box, IconButton, Menu, MenuItem } from '@mui/material';
import { MoreVert, ExitToAppRounded } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { Share, Delete } from '../../../atoms/Icon';

const useStyles = makeStyles(() => ({
  container: {
    position: 'absolute',
    backgroundColor: 'white',
    borderRadius: '8px',
    right: (smallVersion) => (smallVersion ? '8px' : '12px'),
    bottom: (smallVersion) => (smallVersion ? '74px' : '90px'),
    display: 'flex',
  },
  button: {
    display: 'flex',
    width: (smallVersion) => (smallVersion ? '32px' : '40px'),
    height: (smallVersion) => (smallVersion ? '32px' : '40px'),
    padding: '8px',
  },
  icon: {
    color: '#ED702E',
    height: 16,
    width: 16,
  },
}));

function TripContextMenu({
  shareTripCallback,
  deleteTripCallback,
  smallVersion = true,
  closeMenu,
  isOwner = false,
}) {
  const classes = useStyles(smallVersion);
  // const buttonDimension = smallVersion ? "32px" : "40px";
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <Box
      className={classes.container}
      onClick={(event) => event.stopPropagation()}>
      <IconButton
        className={classes.button}
        disableRipple
        onClick={(event) => {
          event.stopPropagation();
          shareTripCallback();
          closeMenu();
        }}
        size="large">
        <Share />
      </IconButton>
      <IconButton
        className={classes.button}
        disableRipple
        onClick={(event) => {
          event.stopPropagation();
          setAnchorEl(event.currentTarget);
        }}
        size="large">
        <MoreVert
          style={{ color: '#222222', width: smallVersion ? '16px' : '24px' }}
        />
        <Menu
          MenuListProps={{
            style: {
              padding: '4px 0',
            },
          }}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={(event) => {
            setAnchorEl(null);
            event.stopPropagation();
            closeMenu();
          }}>
          <MenuItem
            style={{
              height: '26px',
              padding: '4px 12px',
            }}
            onClick={(event) => {
              event.stopPropagation();
              setAnchorEl(null);
              deleteTripCallback();
              closeMenu();
            }}>
            {isOwner ? (
              <Delete />
            ) : (
              <ExitToAppRounded className={classes.icon} />
            )}
            <Typography style={{ fontSize: '14px', margin: '0 16px 0 10px' }}>
              {isOwner ? 'Delete' : 'Leave'}
            </Typography>
          </MenuItem>
        </Menu>
      </IconButton>
    </Box>
  );
}

export default TripContextMenu;
