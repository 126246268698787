import { Typography } from '@mui/material';
import Error from '../../atoms/Error';

function TripNotFoundError() {
  return (
    <Error
      containerMaxWidth="720px"
      showSupportButton={false}
      error={
        <>
          <img
            style={{
              borderRadius: '20px',
            }}
            src="/images/plane-flying-404.gif"
            width="347px"
            height="208px"
            alt="Plane flying"
          />
          <Typography pt={4} fontWeight={600} fontSize={56}>
            Uh-Oh!
          </Typography>
          <Typography fontWeight={500} pt={2}>
            Looks like we can't find the trip. It may have been deleted or it
            did not exist in the first place. Please ask the trip owner for an
            updated invite link
          </Typography>
          <Typography fontWeight={500} pt={2} fontStyle="italic">
            We're redirecting you to your trips dashboard
          </Typography>
        </>
      }
    />
  );
}

export default TripNotFoundError;
