import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import getCfConnector from '../../components/cfConnector';

const initialState = {
  cities: {
    data: {},
    status: 'IDLE',
  },
  prevPath: '',
};

export const fetchCities = createAsyncThunk(
  'Discover/fetchCities',
  async (_, { rejectWithValue, getState }) => {
    const shouldBeFetched =
      getState().Discover.cities.status === 'LOADING' ||
      getState().Discover.cities.status === 'IDLE';
    if (shouldBeFetched) {
      let data = [];

      try {
        data = (
          await (await getCfConnector()).get(process.env.REACT_APP_DISCOVER_API)
        )?.data;
      } catch (err) {
        rejectWithValue('FETCH_FAILED');
      }

      return data;
    }
    return null;
  }
);

export const fetchCityInfo = createAsyncThunk(
  '/Discover/fetchCityInfo',
  async ({ cityId }, { rejectWithValue, getState }) => {
    const currState = getState().Discover.cities;
    const shouldBeFetched =
      !currState.data[cityId] ||
      currState.data[cityId].status === 'LOADING' ||
      currState.data[cityId].status === 'IDLE';
    if (shouldBeFetched) {
      let data = [];

      try {
        const response = (
          await (
            await getCfConnector()
          ).get(`${process.env.REACT_APP_DISCOVER_API}/cityInfo?slug=${cityId}`)
        )?.data;
        data = response.data;
        if (!response.success && response.error === 'INVALID_CITY') {
          rejectWithValue('INVALID_CITY');
        }
      } catch (err) {
        rejectWithValue('FETCH_FAILED');
      }
      return data;
    }
    return null;
  }
);

const DiscoverSlice = createSlice({
  name: 'Discover',
  initialState,
  reducers: {
    setPrevPath: (state, { payload: prevPath }) => {
      state.prevPath = prevPath;
    },
  },
  extraReducers: {
    [fetchCities.pending]: (state) => {
      state.cities.status = 'LOADING';
    },
    [fetchCities.fulfilled]: (state, { payload: citiesList }) => {
      if (citiesList) {
        state.cities.data = citiesList?.reduce((cities, city) => {
          cities[city.slug] = {
            name: city.city_name,
            image: city.img_url,
            description: city.description,
            slug: city.slug,
            country: city.country,
            // half fetched
            status: 'IDLE',
          };
          return cities;
        }, {});
      }
      state.cities.status = 'SUCCESS';
    },
    [fetchCities.rejected]: (state) => {
      state.cities.status = 'ERROR';
    },
    [fetchCityInfo.pending]: (state, { meta: { arg } }) => {
      state.cities.data[arg.cityId] = {
        ...(state.cities.data[arg.cityId] || {}),
        status: 'LOADING',
      };
    },
    [fetchCityInfo.fulfilled]: (
      state,
      { meta: { arg }, payload: cityInfo }
    ) => {
      state.cities.data[arg.cityId] = {
        ...(state.cities.data[arg.cityId] || {}),
        placeId: cityInfo?.goog_place_id,
        blogs: cityInfo?.blogs,
        pois: cityInfo?.pois,
        status: 'SUCCESS',
      };
    },
    [fetchCityInfo.rejected]: (state, { meta: { arg } }) => {
      state.cities.data[arg.cityId] = {
        ...(state.cities.data[arg.cityId] || {}),
        status: 'ERROR',
      };
    },
  },
});

export const DiscoverActions = DiscoverSlice.actions;
export const DiscoverReducer = DiscoverSlice.reducer;
