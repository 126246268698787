import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Card,
  CardMedia,
  Typography,
  Popper,
  List,
  ListItem,
  Grid,
  Tooltip,
  Skeleton,
  Snackbar,
  Alert,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { Close, CheckCircleOutline, CancelOutlined } from '@mui/icons-material';
import { useSounds, SOUNDS } from '../../../../sounds';
import {
  ArrowIcon,
  CallIcon,
  ClockIcon,
  GoogleMapsIcon,
  LocationPinIconFilled,
} from '../../../atoms/Icon';
import {
  createActivity,
  createNewActivityInLocation,
} from '../../../../redux/slices/Activity';
import { LocationActions } from '../../../../redux/slices/Location';
import { trackEvents, Events } from '../../../../intercom';
import { EVENTS, phTrackEvent } from '../../../../analytics';
import { MapLinkButton } from '../../../atoms/Button';
import StarRatings from '../../StarRatings';
import PinToMapIcon from '../../PinToMapIcon';
import AddToTripButton from '../../AddToTripButton';
import { useMapUtils } from '../../../organisms/MapUtils';
import {
  getPlaceTypeFromTypes,
  updateSessionStorageForLastEditedSection,
} from '../../../../utils';
import FLAGS from '../../../../featureFlags';
import AddToTripButtonNew from '../../AddToTripButton/AddToTripButtonNew';
import actions from '../../../../redux/actions';

const useCustomCardStyles = makeStyles(() => ({
  mainContainer: {
    zIndex: 1120,
    position: 'absolute',
    display: 'block',
    width: '77.5%',
    bottom: '5%',
    left: '50%',
    transform: 'translate(-50%, 0)',
  },
  container: {
    position: 'relative',
    width: '100%',
  },
  root: {
    minHeight: 162,
    maxHeight: 200,
    display: 'flex',
    padding: 16,
    borderRadius: 8,
    '&:hover': {
      cursor: 'auto',
    },
    '&:hover $hoverContent': {
      visibility: 'visible',
      opacity: 1,
    },
  },
  hoverContent: {
    visibility: 'hidden',
    opacity: 0,
    transition: 'visibility 0.2s ease-in-out, opacity 0.2s ease-in-out',
  },
  snackBar: {
    position: 'absolute',
    top: -60,
    left: '50%',
    transform: 'translateX(-50%)',
  },
  image: {
    borderRadius: 4,
    backgroundColor: '#DEDDDD',
    height: '100%',
  },
  description: {
    fontSize: 12,
    lineHeight: '140%',
    maxHeight: 'calc(22.4px * 3)',
    fontWeight: 400,
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    '-webkit-box-pack': 'end',
  },
  addressComponent: {
    fontSize: 12,
    lineHeight: '140%',
    maxHeight: 'calc(22.4px * 3)',
    fontWeight: 400,
    overflow: 'hidden',
    display: 'block',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  flexLeft: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: 24,
    width: '100%',
  },
  headerContainer: {
    display: 'flex',
    marginBottom: 4,
  },
  title: {
    marginBottom: 0,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'inline-flex',
    marginRight: '4%',
  },
  close: {
    position: 'absolute',
    right: 16,
    padding: 4,
    color: '#000',
    borderRadius: '50%',
    '&:hover': {
      color: '#000',
      backgroundColor: '#F4F4F4',
    },
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 0px 0px 16px',
    height: '100%',
  },
  ratingContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
    flexWrap: 'wrap',
  },
  reviews: {
    color: '#1877F2',
    fontSize: '0.8rem',
    marginLeft: '5%',
  },
  nullReviews: {
    color: '#8A8A8A',
    fontStyle: 'italic',
    marginLeft: '5%',
  },
  defaultStartIcon: {
    '& .MuiButton-startIcon': {
      margin: '0px 4px 0px 0px',
    },
  },
  detailsContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  detailsBlock: {
    display: 'flex',
  },
  detailsBlockIcon: {
    flexShrink: 0,
    marginRight: 6,
  },
  endContainer: {
    flexShrink: 1,
    display: 'flex',
    marginTop: 8,
    justifyContent: 'space-between',
  },
  toggleContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  toggleExpand: {
    color: '#686868',
    textDecoration: 'underline',
    fontSize: '0.8rem',
    '&:hover': {
      textDecoration: 'none',
      cursor: 'pointer',
    },
  },
  datePopper: {
    zIndex: 1121,
  },
  dateList: {
    backgroundColor: '#FFF',
    borderRadius: 4,
    boxShadow: '0 0 0 1px #DEDDDD',
  },
  dateListItem: {
    fontSize: 12,
    padding: '2px 12px',
    '&:hover': {
      backgroundColor: '#FFFFFF',
      color: '#222222',
    },
    '& .active': {
      backgroundColor: '#EAEAEA',
      fontWeight: 'bold',
      color: '#222222',
    },
    '& > span': {
      '&:nth-child(1)': {
        minWidth: 80,
      },
    },
  },
  dateListItemActive: {
    fontSize: 12,
    padding: '2px 12px',
    backgroundColor: '#EAEAEA',
    fontWeight: 'bold',
    color: '#222222',
    '&:hover': {
      backgroundColor: '#EAEAEA',
      color: '#222222',
    },
    '& > span': {
      '&:nth-child(1)': {
        minWidth: 80,
      },
    },
  },
  placeType: {
    fontSize: 12,
    maxWidth: '25%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  tooltip: {
    background: 'rgba(0,0,0,.8)',
    fontSize: 12,
  },
  tooltipArrow: {
    color: 'rgba(0,0,0,.8)',
  },
  tooltipDisabled: {
    display: 'none',
  },
}));

function MapPopupCard({
  placeId,
  photo,
  title,
  rating,
  description = '',
  website,
  maps,
  ratingCount,
  addressComponents,
  formattedAddress,
  formattedPhoneNumber,
  handleClose,
  tripId,
  lat,
  long,
  pinned,
  pinId,
  pins,
  type,
  types,
  categoryId,
  pinColor,
  openingHoursObj,
  // isMapGenerated = false,
  handleImageError,
  source = 'map',
  initAction = () => {},
  postAction = () => {},
  setLoader = () => {},
}) {
  const classes = useCustomCardStyles();
  const [datePopperOpen, setDatePopperOpen] = useState(false);
  const [dateAnchor, setDateAnchor] = useState(null);
  const [currPlaceId, setCurrPlaceId] = useState(false);
  const [activeCardDetails, setActiveCardDetails] = useState({});
  const [cardLoading, setCardLoading] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [lastAddedActivity, setLastAddedActivity] = useState({});
  const [destinationDelete, setDestinationDelete] = useState(false);
  const [activityDelete, setActivityDelete] = useState(false);
  const isQuickAddActivityEnabled = useFeatureFlagEnabled(
    FLAGS.QUICK_ADD_ACTIVITIES
  );

  const destinationDeletetimeoutRef = useRef(null);

  const datePopperId = datePopperOpen ? 'date-popper' : undefined;

  const { playSound } = useSounds();
  const dispatch = useDispatch();

  const tripMapPins = useSelector(
    (state) => state.Trips.trips[tripId]?.mapPins || []
  );
  const mapPins = useSelector((state) => state.Map.mapPins[tripId] || []);
  const activities = useSelector((state) => state.Activity.todos || {});
  const thingsToDo = useSelector((state) => state.Section.sections);
  const locations = useSelector((state) => state.Location.locations);

  const computeActiveCardProps = (cardPlaceId) => {
    const activeCardItems = {};
    const foundPin = Object.values(mapPins || {}).find(
      (pin) => pin.placeId === cardPlaceId
    );
    activeCardItems.mapPin = foundPin?.id ?? null;
    if (activeCardItems?.mapPin) {
      const foundTodo = Object.values(activities || {}).find(
        (activity) => activity.mapPin === activeCardItems?.mapPin
      );
      activeCardItems.toDoId = foundTodo?.id ?? null;
      activeCardItems.toDoName = foundTodo?.title ?? null;

      if (activeCardItems?.toDoId) {
        const foundSection = Object.values(thingsToDo || {}).find((section) =>
          section.todos.includes(activeCardItems?.toDoId)
        );
        activeCardItems.sectionId = foundSection?.id ?? null;
        activeCardItems.sectionName = foundSection?.name ?? null;
      }
      // search mapPin under locations
      const foundLocation = Object.values(locations || {}).find(
        (location) => location.mapPin === activeCardItems?.mapPin
      );
      if (foundLocation) {
        activeCardItems.locationId = foundLocation?.id ?? null;
        activeCardItems.locationName = foundLocation?.name ?? null;
      } else {
        // get the parent location name and Id using the mapPinOfActiveCard
        const parentLocation = Object.values(locations || {}).find(
          (location) => {
            if (
              location &&
              location.thingsToDo &&
              location.thingsToDo.length > 0
            ) {
              return location.thingsToDo.includes(activeCardItems?.sectionId);
            }
            return false;
          }
        );
        activeCardItems.parentLocationId = parentLocation?.id ?? null;
        activeCardItems.parentLocationName = parentLocation?.name ?? null;
      }
    }
    setActiveCardDetails({ ...activeCardItems });
  };

  useEffect(() => {
    computeActiveCardProps(placeId);
  }, [placeId, mapPins, activities, thingsToDo, locations]);

  const handleDateHover = (e) => {
    if (datePopperOpen) return;
    setDatePopperOpen(true);
    setDateAnchor(e.currentTarget);
  };

  // TODO: Add placeType
  const placeType = getPlaceTypeFromTypes(types);

  function TextTooltipElement(props) {
    const thisRef = useRef(null);
    const [showTooltip, setShowTooltip] = useState(false);
    const onHover = () => {
      if (thisRef.current.offsetWidth < thisRef.current.scrollWidth) {
        setShowTooltip(true);
      } else {
        setShowTooltip(false);
      }
    };
    return (
      <Tooltip
        title={props.title}
        placement="top"
        classes={{
          tooltip: showTooltip ? classes.tooltip : classes.tooltipDisabled,
        }}
        enterDelay={600}
        leaveDelay={0}
        className="dynamic-text"
        ref={thisRef}
        onMouseOver={onHover}>
        {props.children}
      </Tooltip>
    );
  }

  const renderOpeningDays = () => {
    const day = new Date().getDay();
    const getClassName = (index) => {
      if (day === index + 1 && day !== 0) {
        return classes.dateListItemActive;
      }
      if (day === 0 && index === 6) {
        return classes.dateListItemActive;
      }
      return classes.dateListItem;
    };
    if (openingHoursObj) {
      return openingHoursObj.weekday_text.map((str, index) => (
        <ListItem className={getClassName(index)} key={index}>
          {str.split(': ', 2).map((ele, i) => (
            <span key={i}>{ele}</span>
          ))}
        </ListItem>
      ));
    }
    return null;
  };

  const getOpeningDaysBlockText = () => {
    const weekDay = new Date().getDay();
    const weekDayText = openingHoursObj?.weekday_text[
      weekDay === 0 ? 6 : weekDay - 1
    ].split(': ', 2);
    if (
      openingHoursObj?.open_now ||
      (!openingHoursObj?.open_now && weekDayText[1] !== 'Closed')
    ) {
      return `${weekDayText[1]} (${weekDayText[0].slice(0, 3)})`;
    }
    return weekDayText[0];
  };

  // in case the image from places api returns false .
  const fallBackUrl = '/images/DefaultMapPopupBanner.png';

  const [imgUrl, setImgUrl] = useState(fallBackUrl);

  const img = new Image();
  img.src = photo;
  img.onload = () => {
    if (img.height === 100 && img.width === 100) {
      setImgUrl(fallBackUrl);
      if (placeId) {
        handleImageError();
      }
    } else {
      setImgUrl(photo);
    }
  };

  const {
    getPlaceDetails,
    place,
    setPlace,
    getCachedPlacesItem,
    extractAddressComponents,
    createMapPinForPlace,
  } = useMapUtils();

  const refreshPlaceDetails = async () => {
    setCardLoading(true);
    window?.heap?.track('Map-card-refreshed');

    try {
      // get details from sessionStorage, if it doesn't exist,
      let details = getCachedPlacesItem(placeId) || null;

      if (details === null) {
        details = await getPlaceDetails(placeId, type, true);
        window?.heap?.track('Map-card-new-request');
      } else {
        window?.heap?.track('Map-card-cache-used');
      }

      setPlace({
        ...place,
        maps: details?.maps,
        website: details?.website,
        photo: details?.photo,
        rating: details?.rating,
        lat: details?.lat,
        long: details?.long,
        ratingCount: details?.ratingCount,
        openingHoursObj: details?.openingHoursObj,
        addressComponents: details?.addressComponents,
        formattedAddress: details?.formattedAddress,
        types: details?.types,
        formattedPhoneNumber: details?.formattedPhoneNumber,
      });
    } catch (err) {
      // Error - probably because it's not a valid placeId
    }
    setCardLoading(false);
  };

  useEffect(() => {
    if (placeId && placeId !== currPlaceId) {
      setCurrPlaceId(placeId);
      refreshPlaceDetails();
    }
  }, [placeId]);

  const handleSnackBarClose = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowSnackBar(false);
  };

  const handleAddActivity = async (
    activityTitle,
    locationId,
    sectionId,
    sectionName,
    locationName,
    autoAdd = false
  ) => {
    setLoader(true);
    dispatch(
      actions.View.setHighlightedSection({
        section: null,
      })
    );
    dispatch(
      actions.View.setHighlightedHeading({
        section: null,
      })
    );
    setDestinationDelete(false);
    setActivityDelete(false);
    const result = await initAction({
      newLocationId: locationId,
      newSectionId: sectionId,
    });
    playSound(SOUNDS.inOutClick);

    // action already done, no need to repeat activity
    if (result === 'NO-ACTION') {
      setLoader(false);
      return;
    }
    if (
      source === 'map' ||
      source === 'recommendations' ||
      source === 'recommendations-details'
    ) {
      phTrackEvent({
        event:
          source === 'map' && autoAdd
            ? EVENTS.PLAN_MAP_CARD.AUTO_ADD_ACTIVITY
            : source === 'map'
            ? EVENTS.PLAN_MAP_CARD.MANUAL_ACTIVITY_ADD
            : source === 'recommendations'
            ? EVENTS.EXPLORE_ACTIVITIES.ACTIVITY_PLAN_ADD
            : EVENTS.EXPLORE_ACTIVITY_DETAIL.ACTIVITY_PLAN_ADD,
      });
    }
    // addMapPin
    const mapPin = await createMapPinForPlace(
      {
        title,
        description,
        photo,
        rating,
        website,
        maps,
        ratingCount,
        long,
        lat,
        types,
        placeId,
        categoryId,
      },
      'ACTIVITY',
      {
        openingHoursObj,
        addressComponents,
        formattedAddress,
        formattedPhoneNumber,
        maps,
        website,
      }
    );
    const address = extractAddressComponents(addressComponents);
    if (window?.heap)
      window?.heap.track('Activity Created', {
        source: 'Map',
      });
    const lastUpdatedSectionDetails = JSON.parse(
      window?.sessionStorage?.getItem('lastUpdatedSectionDetails') || '{}'
    );
    const lastUpdatedSectionId =
      (autoAdd &&
        lastUpdatedSectionDetails &&
        lastUpdatedSectionDetails[locationId]) ||
      sectionId;

    if (sectionId === null) {
      await dispatch(
        createNewActivityInLocation({
          locationId,
          activityTitle,
          mapPin: mapPin?.id,
          ...address,
        })
      );
      setToastMessage(`Added to ${locationName}`);
      setShowSnackBar(true);
    } else {
      await dispatch(
        createActivity({
          variables: {
            title: activityTitle,
            mapPin: mapPin?.id,
            thingsToDoId: lastUpdatedSectionId,
            index: thingsToDo[lastUpdatedSectionId]?.todos?.length,
            ...address,
          },
          sectionId: lastUpdatedSectionId,
          index: thingsToDo[lastUpdatedSectionId]?.todos?.length,
        })
      );
      // updated the last updated section under destination
      updateSessionStorageForLastEditedSection(
        locationId,
        lastUpdatedSectionId
      );
      if (sectionName) {
        setToastMessage(`Added to ${locationName} > ${sectionName}`);
      } else {
        setToastMessage(`Added to ${locationName}`);
      }
      setShowSnackBar(true);
    }
    trackEvents(
      source === 'map'
        ? Events.LocationAddedFromMap
        : Events.RecommendationsItemAdded
    );
    await postAction({
      locationId,
      sectionId,
    });
    setLoader(false);
  };

  const handleUndo = async () => {
    setShowSnackBar(false);
    phTrackEvent({
      event: EVENTS.PLAN_MAP_CARD.ACTIVITY_DELETE_UNDO,
    });
    if (activeCardDetails?.locationId) {
      dispatch(
        LocationActions.addLocationSectionTemp({
          locationId: activeCardDetails?.locationId,
        })
      );
      if (destinationDeletetimeoutRef.current) {
        clearTimeout(destinationDeletetimeoutRef.current);
        destinationDeletetimeoutRef.current = null;
      }
    } else {
      await handleAddActivity(
        lastAddedActivity?.activityTitle,
        lastAddedActivity?.locationId,
        lastAddedActivity?.sectionId,
        lastAddedActivity?.sectionName,
        lastAddedActivity?.locationName,
        false
      );
    }
  };

  // Check if a pin that's not a trip map pin, is added to the trip
  const checkIfAddedToPlan = () =>
    pins?.filter(
      (pin) => !tripMapPins?.includes(pin?.id) && pin?.placeId === placeId
    )?.length > 0 || false;

  return (
    <div className={classes.mainContainer}>
      <div className={classes.container}>
        <Snackbar
          className={classes.snackBar}
          open={showSnackBar}
          autoHideDuration={5000}
          onClose={handleSnackBarClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert
            severity={
              (destinationDelete || activityDelete) && checkIfAddedToPlan()
                ? 'error'
                : checkIfAddedToPlan()
                ? 'success'
                : 'error'
            }
            variant="filled"
            iconMapping={{
              success: <CheckCircleOutline fontSize="inherit" />,
              error: <CancelOutlined fontSize="inherit" />,
            }}
            sx={{
              width: 'auto',
              paddingRight: '8px',
              paddingLeft: '8px',
              paddingTop: '1px',
              paddingBottom: '1px',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            action={
              !checkIfAddedToPlan() || destinationDelete || activityDelete ? (
                <Typography
                  onClick={handleUndo}
                  sx={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    color: 'white',
                    marginLeft: 4,
                    marginRight: 2,
                    paddingBottom: 0.5,
                  }}>
                  Undo
                </Typography>
              ) : null
            }>
            {toastMessage}
          </Alert>
        </Snackbar>
        <Card className={classes.root}>
          <Grid container>
            <Grid
              item
              xs={0}
              sm={0}
              md={4}
              style={{ maxHeight: '100%', maxWidth: 165 }}
              display={{ sm: 'none', md: 'flex' }}>
              <CardMedia
                component="img"
                alt={title}
                image={imgUrl || fallBackUrl}
                className={classes.image}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={8}
              style={{ maxWidth: '100%', flex: 1, minWidth: 0 }}>
              <div className={classes.cardContent}>
                <div className={classes.headerContainer}>
                  <div className={classes.flexLeft}>
                    <div className={classes.title}>
                      <TextTooltipElement title={title}>
                        <Typography noWrap variant="h4">
                          {title}
                        </Typography>
                      </TextTooltipElement>
                    </div>
                    <div
                      style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
                      <PinToMapIcon
                        key={title}
                        mapPinDetails={{
                          id: pinId,
                          lat,
                          long,
                          pinData: {
                            title,
                            photo,
                            rating,
                            description,
                            website,
                            maps,
                            ratingCount,
                            types,
                          },
                          categoryId,
                          pinColor,
                          type,
                          placeId,
                        }}
                        pinned={pinned}
                        pins={pins}
                        tripId={tripId}
                        isAdded={checkIfAddedToPlan()}
                        isQuickAddActivityEnabled={isQuickAddActivityEnabled}
                      />
                    </div>
                  </div>
                  <Close
                    className={classes.close}
                    disableFocusRipple
                    disableRipple
                    fontSize="medium"
                    onClick={handleClose}
                    style={{ cursor: 'pointer' }}
                  />
                </div>
                {cardLoading ? (
                  <div>
                    <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
                    <Skeleton variant="rounded" width="100%" height="80" />
                  </div>
                ) : (
                  <>
                    <div className={classes.ratingContainer}>
                      {placeType && (
                        <>
                          <TextTooltipElement title={placeType}>
                            <span className={classes.placeType}>
                              {placeType}
                            </span>
                          </TextTooltipElement>
                          &nbsp;&nbsp;·&nbsp;{' '}
                        </>
                      )}
                      <StarRatings rating={(rating || 0) % 5} maxRating={5} />
                      {ratingCount && ratingCount !== 0 ? (
                        <Typography className={classes.reviews}>
                          {`${ratingCount} Reviews`}
                        </Typography>
                      ) : (
                        <Typography className={classes.nullReviews}>
                          No Reviews
                        </Typography>
                      )}
                    </div>

                    <div className={classes.detailsContainer}>
                      {formattedAddress && (
                        <div className={classes.detailsBlock}>
                          <LocationPinIconFilled
                            fill="#ED702E"
                            className={classes.detailsBlockIcon}
                          />
                          <TextTooltipElement title={formattedAddress}>
                            <Typography
                              className={classes.addressComponent}
                              style={{ WebkitLineClamp: 1, lineClamp: 1 }}>
                              {formattedAddress}
                            </Typography>
                          </TextTooltipElement>
                        </div>
                      )}
                      {openingHoursObj && (
                        <div
                          className={classes.detailsBlock}
                          style={{ display: 'flex', alignItems: 'center' }}
                          onMouseOver={handleDateHover}
                          onMouseLeave={() => setDatePopperOpen(false)}>
                          <ClockIcon
                            fill="#ED702E"
                            className={classes.detailsBlockIcon}
                          />
                          <Typography className={classes.description}>
                            {openingHoursObj?.open_now ? (
                              <span style={{ color: '#149F22' }}>OPEN NOW</span>
                            ) : (
                              <span style={{ color: '#E5493D' }}>CLOSED</span>
                            )}{' '}
                            · {getOpeningDaysBlockText()}
                          </Typography>
                          <ArrowIcon />
                          <Popper
                            id={datePopperId}
                            placement="top-start"
                            open={datePopperOpen}
                            anchorEl={dateAnchor}
                            className={classes.datePopper}>
                            <List className={classes.dateList}>
                              {renderOpeningDays()}
                            </List>
                          </Popper>
                        </div>
                      )}
                      {formattedPhoneNumber && (
                        <div className={classes.detailsBlock}>
                          <CallIcon
                            fill="#ED702E"
                            className={classes.detailsBlockIcon}
                          />
                          <Typography className={classes.description}>
                            {formattedPhoneNumber}
                          </Typography>
                        </div>
                      )}
                    </div>
                    <div className={classes.endContainer}>
                      {maps ? (
                        <MapLinkButton
                          elevation={0}
                          disableFocusRipple
                          disableRipple
                          onClick={() => window.open(maps, '_blank')}
                          startIcon={<GoogleMapsIcon />}>
                          Maps
                        </MapLinkButton>
                      ) : null}
                      {/* &nbsp;
              {website ? (
                <MapLinkButton
                  elevation={0}
                  disableFocusRipple
                  disableRipple
                  className={classes.defaultStartIcon}
                  onClick={() => window.open(website, '_blank')}
                  startIcon={<LaunchRounded />}>
                  Website
                </MapLinkButton>
              ) : null} */}
                      &nbsp;
                      {isQuickAddActivityEnabled ? (
                        <AddToTripButtonNew
                          tripId={tripId}
                          isAdded={checkIfAddedToPlan()}
                          placeDetails={{
                            title,
                            description,
                            photo,
                            rating,
                            website,
                            maps,
                            ratingCount,
                            long,
                            lat,
                            types,
                            placeId,
                            categoryId,
                          }}
                          additionalPlaceDetails={{
                            openingHoursObj,
                            addressComponents,
                            formattedAddress,
                            formattedPhoneNumber,
                            maps,
                            website,
                          }}
                          addressComponents={addressComponents}
                          hoverContentClass={classes.hoverContent}
                          activeCardDetails={activeCardDetails}
                          handleAddActivity={handleAddActivity}
                          setToastMessage={setToastMessage}
                          setShowSnackBar={setShowSnackBar}
                          setLastAddedActivity={setLastAddedActivity}
                          setDestinationDelete={setDestinationDelete}
                          setActivityDelete={setActivityDelete}
                          destinationDeletetimeoutRef={
                            destinationDeletetimeoutRef
                          }
                        />
                      ) : (
                        <AddToTripButton
                          tripId={tripId}
                          isAdded={checkIfAddedToPlan()}
                          placeDetails={{
                            title,
                            description,
                            photo,
                            rating,
                            website,
                            maps,
                            ratingCount,
                            long,
                            lat,
                            types,
                            placeId,
                            categoryId,
                          }}
                          additionalPlaceDetails={{
                            openingHoursObj,
                            addressComponents,
                            formattedAddress,
                            formattedPhoneNumber,
                            maps,
                            website,
                          }}
                          addressComponents={addressComponents}
                        />
                      )}
                    </div>
                  </>
                )}
              </div>
            </Grid>
          </Grid>
        </Card>
      </div>
    </div>
  );
}

export default MapPopupCard;
