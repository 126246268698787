import { createSlice } from '@reduxjs/toolkit';
import QUERY from '../../graphql/queries';
import getAsyncThunk from '../helpers';
import { trackEvents, Events } from '../../intercom';
import { EVENTS, phTrackEvent } from '../../analytics';

const initialState = {
  transports: {},
};

export const createFlight = getAsyncThunk(
  'TRANSPORT/createFlight',
  QUERY.CREATE_FLIGHT
);

export const updateFlight = getAsyncThunk(
  'TRANSPORT/updateFlight',
  QUERY.UPDATE_FLIGHT
);

export const deleteFlight = getAsyncThunk(
  'TRANSPORT/deleteFlight',
  QUERY.DELETE_FLIGHT
);

export const createTransportation = getAsyncThunk(
  'TRANSPORTATION/createTransportation',
  QUERY.CREATE_TRANSPORTATION
);

export const updateTransportation = getAsyncThunk(
  'TRANSPORTATION/updateTransportation',
  QUERY.UPDATE_TRANSPORTATION
);

export const deleteTransportation = getAsyncThunk(
  'TRANSPORTATION/deleteTransportation',
  QUERY.DELETE_TRANSPORTATION
);

const TransportationSlice = createSlice({
  name: 'TRANSPORTATION',
  initialState,
  reducers: {
    initializeTransportations: (state, action) => {
      state.transports = {
        ...state.transports,
        ...action.payload,
      };
    },
    deleteTransportationFile: (
      state,
      { payload: { transportationId, flightId, fileId } }
    ) => {
      const index = state.transports[transportationId].details?.findIndex(
        (obj) => obj.id === flightId
      );
      if (index !== -1) {
        state.transports[transportationId].details[index] = {
          ...state.transports[transportationId].details[index],
          files: state.transports[transportationId].details[index].files.filter(
            (file) => file !== fileId
          ),
        };
      }
    },
    handleTransportationFiles: (
      state,
      { payload: { fileId, itemsToAttach, itemsToDetach } }
    ) => {
      if (itemsToAttach?.length > 0) {
        itemsToAttach?.forEach((item) => {
          const transportId = Object.values(state.transports)?.find(
            (transport) =>
              transport?.details?.map((detail) => detail.id).includes(item.id)
          )?.id;
          const index = state.transports[transportId].details?.findIndex(
            (obj) => obj.id === item.id
          );
          state.transports[transportId].details[index].files = [
            ...(state.transports[transportId].details[index].files || []),
            fileId,
          ];
        });
      }
      if (itemsToDetach?.length > 0) {
        itemsToDetach?.forEach((item) => {
          const transportId = Object.values(state.transports)?.find(
            (transport) =>
              transport?.details?.map((detail) => detail.id).includes(item.id)
          )?.id;
          const index = state.transports[transportId].details?.findIndex(
            (obj) => obj.id === item.id
          );
          state.transports[transportId].details[index].files = state.transports[
            transportId
          ].details[index].files.filter((file) => file !== fileId);
        });
      }
    },
  },
  extraReducers: {
    [updateFlight.fulfilled]: (state, { payload, meta }) => {
      if (meta?.variables?.cost) trackEvents(Events.CostAdded);
      const updated = payload.updateFlight;
      const { transportId } = meta.arg.extra;
      const flightIdx = state.transports[transportId].details.findIndex(
        (flight) => flight.id === updated.id
      );
      if (flightIdx !== -1) {
        state.transports[transportId].details[flightIdx] = {
          ...updated,
          files: updated?.files?.map((file) => file.id),
        };
      }
    },
    [createFlight.fulfilled]: (
      state,
      {
        payload,
        meta: {
          arg: {
            variables: { transportId = null },
            extra: { type },
          },
        },
      }
    ) => {
      if (type && payload.createFlight.id) {
        const transportEvent =
          type === 'flight'
            ? EVENTS.PLAN_FLIGHT.ADD_CREATE
            : type === 'bus'
            ? EVENTS.PLAN_BUS_TRAIN.ADD_CREATE
            : EVENTS.PLAN_OTHER_TRANSPORT.ADD_CREATE;
        phTrackEvent({
          event: transportEvent,
        });
      }
      if (transportId && payload.createFlight.id) {
        state.transports[transportId].details = [
          ...(state.transports[transportId]?.details || []),
          payload.createFlight,
        ];
      }
    },
    [deleteFlight.fulfilled]: (
      state,
      {
        meta: {
          arg: {
            variables: { transportId = null, id = null },
          },
        },
      }
    ) => {
      if (transportId && id) {
        state.transports[transportId].details = (
          state.transports[transportId]?.details || []
        ).filter((flight) => flight?.id !== id);
      }
    },
    [createTransportation.fulfilled]: (state, { payload, meta }) => {
      const {
        variables,
        extra: { attachedFlights },
      } = meta.arg;
      // meta = 'Flight'), 'Bus/Train', 'Other';
      const transportId = payload.createTransportation.id;
      state.transports[transportId] = {
        id: transportId,
        ...variables,
        details: attachedFlights,
      };
    },
    [updateTransportation.fulfilled]: (state, { payload }) => {
      const updated = payload.updateTransportation;
      state.transports[updated.id] = {
        ...(state.transports[updated.id] || {}),
        ...(updated || {}),
      };
    },
    [deleteTransportation.fulfilled]: (state, { meta }) => {
      const { id: transportId } = meta.arg.variables;
      delete state.transports[transportId];
    },
  },
});

export const TransportationActions = TransportationSlice.actions;
export const TransportationReducer = TransportationSlice.reducer;
