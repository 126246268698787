import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';

import { Box, FormHelperText, Typography, InputAdornment } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { makeStyles } from '@mui/styles';

import { firebaseAuth } from '../../../provider/AuthProvider';

import { FullPageButton } from '../../atoms/Button/index';
import { InputAuthForm } from '../../atoms/Input/index';
import AuthLayout from '../../templates/AuthLayout';
import { LinkRoute } from '../../atoms/Link';
import classList from '../../classList';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  form__success: {
    fontSize: '14px',
    marginBottom: '2vh',
    color: theme.palette.success.main,
  },
}));

function PasswordRecovery() {
  const classes = useStyles();

  const location = useLocation();
  const navigate = useNavigate();

  const { email } = location.state || { email: '' };

  const { handlePasswordRecovery, fetchSignInMethodsForEmail } =
    useContext(firebaseAuth);
  const errors = useSelector((state) => state.Auth.error);

  const [emailValue] = useState(email);
  const [isEmailExists, setIsEmailExists] = useState(false);

  const handleSubmitWithFormik = async (values) => {
    await handlePasswordRecovery(values);

    navigate('/email-verification', {
      state: { email: values.email || '', isPasswordReset: true },
    });
  };

  const checkEmailExists = async (value) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const isValidEmail = emailRegex.test(value);

    if (!isValidEmail) {
      return;
    }

    try {
      // Use Firebase's authentication service to check if email exists
      const providers = await fetchSignInMethodsForEmail(value);

      // If there are sign-in methods associated with the email, it exists
      setIsEmailExists((providers && providers.length > 0) || false);
    } catch (error) {
      // Error occurred while checking email existence
      // Set to false if there's an error (e.g., Firebase API error)
      setIsEmailExists(false);
    }
  };

  useEffect(() => {
    checkEmailExists(emailValue);
  }, [emailValue]);

  return (
    <Formik
      initialValues={{ email: emailValue }}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(false);
        await handleSubmitWithFormik(values);
      }}
      // Define Validations
      validationSchema={Yup.object().shape({
        email: Yup.string().email().required('Email is Required'),
      })}>
      {(props) => {
        const {
          values,
          isSubmitting,
          handleBlur,
          handleChange,
          handleSubmit,
          isValid,
          touched,
        } = props;

        const handleEmailChange = (event) => {
          const { value } = event.target;
          handleChange(event); // Let Formik handle the change event
          setIsEmailExists(false); // Reset email existence state
          if (value && value.trim().length > 0) {
            // Only check email existence if the value is not empty
            checkEmailExists(value);
          }
        };

        return (
          <AuthLayout>
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Typography
                  mb="0.5rem"
                  variant="h2"
                  sx={{
                    fontFamily: 'Work Sans',
                    fontSize: '2rem',
                    fontWeight: '600',
                    lineHeight: '20px',
                  }}>
                  Forgot password?
                </Typography>

                <Typography
                  pt={3}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    color: '#8A8A8A',
                    fontSize: '0.875rem',
                    textAlign: 'center',
                  }}>
                  Please enter the email address associated with your account
                  and we will send a link to reset your password.
                </Typography>
              </Box>
              <FormHelperText error className={classes.error}>
                {errors}
              </FormHelperText>

              <form
                autoComplete="on"
                className={classList.forgotPasswordForm}
                onSubmit={handleSubmit}
                noValidate>
                <Box mt="12%" mb="5%">
                  <InputAuthForm
                    label="E-mail"
                    placeholder="Email Address..."
                    name="email"
                    autoComplete="email"
                    // required
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                      style: { color: '#8A8A8A' },
                      required: false,
                    }}
                    InputProps={{
                      sx: {
                        '&::placeholder': {
                          color: '#8A8A8A',
                        },
                        '&::label': {
                          color: 'red',
                        },
                      },
                      endAdornment: isEmailExists ? (
                        <InputAdornment position="end">
                          <CheckIcon sx={{ color: '#3AA031' }} />
                        </InputAdornment>
                      ) : null,
                    }}
                    onBlur={handleBlur}
                    onChange={handleEmailChange}
                    value={values.email}
                  />
                </Box>

                <FullPageButton
                  type="submit"
                  disabled={
                    !isEmailExists &&
                    (!isValid || isSubmitting || touched.email)
                  }>
                  Send Reset Link
                </FullPageButton>

                {/* <Box mt="2%" mb="2%">
                  {emailHasBeenSent && (
                    <Typography className={`${classes.form__success}`}>
                      We have sent you an email with a link to reset your
                      password!
                      <br />
                      <br />
                      Open the link and get back to trip planning.
                    </Typography>
                  )}
                  {errors && (
                    <FormHelperText error margin="normal">
                      {errors}
                    </FormHelperText>
                  )}
                </Box> */}
              </form>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center">
                <Typography
                  component="span"
                  variant="h5"
                  // onClick={handleResetCLick}
                  sx={{
                    padding: '15px 0',
                    color: 'text.light', // Apply link-like color
                    textDecoration: 'underline', // Apply link-like underline
                    cursor: 'pointer', // Show pointer cursor on hover
                  }}>
                  <LinkRoute to="/continue">Not you? Go back. </LinkRoute>
                </Typography>
              </Box>
            </Box>
          </AuthLayout>
        );
      }}
    </Formik>
  );
}

export default PasswordRecovery;
