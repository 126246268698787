import { Box, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import HotelDetailsCard from './HotelDetailsCard';
import { BookingThumbnailSaveIcon } from '../../atoms/Icon';
import config from '../../config';
import HotelDetailsCardSkeleton from './HotelDetailsCardSkeleton';
import SortOptionsMenu from '../Recommendations/SortOptionsMenu';
import recommendationCategories from '../../../assets/newRecommendationCategories.json';
import getCfConnector from '../../cfConnector';

const useStyles = makeStyles(() => ({
  mainContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
}));

function HotelsSaveListContainer({ locationsList, accomodationKeyId }) {
  const { slug: tripId } = useParams();
  const classes = useStyles();

  const [sortOption, setSortOption] = useState(0);
  const handleItemClick = (idx) => {
    if (!Number.isNaN(+idx)) setSortOption(+idx);
  };

  const [loading, setLoading] = useState(false);
  const [localStateData, setLocalStateData] = useState(null);

  const savedItems = useSelector((state) =>
    Object.values(state.Bookings.saved[tripId] || {})
  );

  const getLowestRateFromSavedItem = (rates) => {
    try {
      return rates?.sort((x, y) => x.totalRate - y.totalRate)[0]?.totalRate;
    } catch (err) {
      return 0;
    }
  };

  const SortPropertyMap = {
    0: 'numberOfReviews',
    1: 'guestRating',
    2: 'totalRate',
    3: 'starRating',
    4: 'distance',
  };

  const sortSavedList = (a, b) => {
    const aSavedData = JSON.parse(a?.savedData || '{}');
    const bSavedData = JSON.parse(b?.savedData || '{}');

    const propToCompare = SortPropertyMap[sortOption];
    try {
      if (sortOption === 2) {
        const aRates = (localStateData || {})[aSavedData?.hotelKey]?.results;
        const bRates = (localStateData || {})[bSavedData?.hotelKey]?.results;

        return (
          getLowestRateFromSavedItem(aRates) -
          getLowestRateFromSavedItem(bRates)
        );
      }
      if (sortOption === 0 || sortOption === 1 || sortOption === 3) {
        return bSavedData[propToCompare] - aSavedData[propToCompare];
      }
      return aSavedData[propToCompare] - bSavedData[propToCompare];
    } catch (e) {
      return 0;
    }
  };

  const { sortFieldName } = recommendationCategories.BookingOptions;

  const getHotelsRefreshData = async (queryString) => {
    setLoading(true);
    // setIsError(false);

    try {
      const url = `${config.recommendationsAPI}/search-single-hotels?${queryString}`;

      const response = (await (await getCfConnector()).get(url)).data;
      setLoading(false);
      const { data: responseData, error: responseError, success } = response;
      if (success && !responseData.error) {
        // setData(responseData);
        return responseData;
      }
      if (responseError) {
        // setIsError(true);
      }
    } catch (err) {
      // console.log(err);
      // setIsError(true);
    }
    setLoading(false);
    // return null;
  };

  const loadData = async () => {
    if (savedItems?.length > 0) {
      const hotelKeyArray = savedItems?.map((savedItem) => {
        const savedItemDetails = JSON.parse(savedItem?.savedData || '{}');
        return savedItemDetails?.hotelKey;
      });

      const queryParams = new URLSearchParams();

      hotelKeyArray.forEach((value, idx) => {
        queryParams.append(`param${idx + 1}`, value);
      });

      const queryString = queryParams.toString();

      const resData = await getHotelsRefreshData(queryString);
      setLocalStateData(resData);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div
      className={classes.mainContainer}
      style={{ marginTop: 20, marginLeft: 0 }}>
      <div style={{ display: 'flex', width: '100%', flexWrap: 'wrap' }}>
        <div style={{ display: 'flex' }}>
          {savedItems?.length > 0 && (
            <SortOptionsMenu
              selectedSortOption={sortOption}
              sortFieldName={sortFieldName?.filter(
                (option) => option !== 'Distance'
              )}
              handleMenuItemClick={handleItemClick}
            />
          )}
        </div>
      </div>

      {savedItems?.length > 0 ? (
        <Grid
          container
          width="100%"
          columnSpacing="12px"
          rowSpacing="2px"
          mt="16px">
          {loading
            ? savedItems?.map((_, idx) => (
                <Grid item key={idx} xs={12} md={6}>
                  <HotelDetailsCardSkeleton />
                </Grid>
              ))
            : savedItems?.sort(sortSavedList)?.map((savedItem, idx) => {
                const savedItemDetails = JSON.parse(
                  savedItem?.savedData || '{}'
                );
                const savedItemKey = savedItemDetails?.hotelKey;

                const images = savedItemDetails?.images || [];
                return (
                  <Grid item xs={12} md={6}>
                    <HotelDetailsCard
                      key={idx}
                      hotelKey={savedItemDetails?.hotelKey}
                      hotelName={savedItemDetails?.hotelName}
                      starRating={savedItemDetails?.starRating}
                      guestRating={savedItemDetails?.guestRating}
                      numberOfReviews={savedItemDetails?.numberOfReviews}
                      guestRatingSentiment={
                        savedItemDetails?.guestRatingSentiment
                      }
                      imageLink={
                        images?.length > 0 && images[images.length - 1]?.large
                      }
                      rates={(localStateData || {})[savedItemKey]?.results}
                      referenceId={savedItem?.referenceId}
                      images={images}
                      providers={
                        (localStateData || {})[savedItemKey]?.providers
                      }
                      currencyCode={savedItemDetails?.currencyCode}
                      isSavedItem
                      clickable
                      propertyType={savedItemDetails?.propertyType || 'Hotel'}
                      // TODO: Set this to a selected nights value depending on saved entries. Current default based on backend fetch
                      numberOfNights={2}
                      locationsList={locationsList}
                      accomodationKeyId={accomodationKeyId}
                    />
                  </Grid>
                );
              })}
        </Grid>
      ) : (
        <Box
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 12,
          }}>
          <BookingThumbnailSaveIcon />
          <Typography
            sx={{
              color: '#8A8A8A',
              fontWeight: '400',
              fontSize: '16px',
              marginTop: '16px',
            }}>
            Click save to add items here
          </Typography>
        </Box>
      )}
    </div>
  );
}

export default HotelsSaveListContainer;
