import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Divider,
  Button,
  CircularProgress,
  IconButton,
} from '@mui/material';
import {
  AddRounded,
  ArrowCircleRightOutlined,
  CheckRounded,
  MoreVertRounded,
} from '@mui/icons-material';
import OptionsMenu from '../../molecules/Menu/OptionsMenu';
import DeleteModal from '../../molecules/Modal/DeleteModal';
import categoriesMap from '../../../assets/newRecommendationCategories.json';
import classList from '../../classList';

const useStyles = makeStyles(() => ({
  card: {
    width: '98%',
    borderRadius: 4,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
    position: 'relative',
    height: 320,
    '&:hover': {
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.5)',
      '& .MuiButton-root': {
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.5)',
      },
      cursor: 'pointer',
    },
    '& .MuiCardContent-root:last-child': {
      paddingBottom: 0,
    },
  },
  cardContent: {
    padding: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    height: 164,
  },
  saveButton: {
    display: 'flex',
    color: '#FFF',
    alignItems: 'center',
    padding: '8px 16px',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    '&:hover': {
      backgroundColor: 'primary.light',
    },
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  },
}));

function PoiMenu({
  id,
  handleDeleteButtonClick,
  anchorEl,
  setAnchorEl,
  wrapUp,
}) {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  return (
    <>
      <IconButton
        disableRipple
        sx={{
          backgroundColor: '#FFF',
          borderRadius: 1,
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        }}
        onClick={(e) => {
          e.stopPropagation();
          setAnchorEl(e.currentTarget);
        }}>
        <MoreVertRounded sx={{ color: '#222', width: 16, height: 16 }} />
      </IconButton>
      <OptionsMenu
        id={`wishlist-discover-menu-${id}`}
        state={anchorEl}
        setState={setAnchorEl}
        callbacks={[() => setDeleteModalOpen(true)]}
        options={['Delete']}
        isDividerVisisble={false}
        menuProps={{
          disableScrollLock: true,
          onClose: (e) => {
            e.stopPropagation();
            wrapUp();
            setAnchorEl(null);
          },
          sx: {
            marginTop: '2px',
            '& .MuiList-root': {
              padding: '4px 0px',
            },
          },
        }}
        menuItemProps={{
          sx: {
            padding: '4px 16px',
            fontSize: 14,
          },
        }}
      />
      <DeleteModal
        open={deleteModalOpen}
        onClose={(e) => {
          e?.stopPropagation();
          setDeleteModalOpen(false);
        }}
        executeFunc={(e) => {
          e?.stopPropagation();
          handleDeleteButtonClick();
          setDeleteModalOpen(false);
          wrapUp();
        }}
        alertText="Are you sure?"
        descriptionText="This will remove your saved wishlist item!"
        confirmText="Delete"
        cancelText="Cancel"
        emoji="⚠️"
      />
    </>
  );
}

function PoiCard({
  id,
  image,
  title,
  tag,
  handleClick = () => {},
  showSaveButton = true,
  handleSaveButtonClick = () => {},
  isSaved,
  showDeleteMenu = false,
  handleDeleteButtonClick = () => {},
  cardProps = {},
}) {
  const classes = useStyles();

  const [hovered, setHovered] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const formatSubText = () => {
    if (tag) {
      return categoriesMap?.ref[tag]?.name || '';
    }
    return '';
  };

  const [isLoading, setIsLoading] = useState(false);
  const [addToWishlistBtnHovered, setAddToWishlistBtnHovered] = useState(false);

  const handleSaveToWishlist = async (e) => {
    setAddToWishlistBtnHovered(false);
    e.stopPropagation();
    setIsLoading(true);

    await handleSaveButtonClick();
    setIsLoading(false);
  };

  return (
    <Card
      className={`${classes.card} ${classList.discoverPoiCard}`}
      onClick={handleClick}
      onMouseOver={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      {...cardProps}>
      <CardMedia
        component="div"
        sx={{
          height: 156,
          background: `url(${image})`,
          backgroundColor: 'system.lightgrey',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          position: 'relative',
        }}>
        <div style={{ position: 'absolute', bottom: 16, right: 16 }}>
          {showDeleteMenu && (hovered || Boolean(anchorEl)) && (
            <PoiMenu
              id={title}
              handleDeleteButtonClick={handleDeleteButtonClick}
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              wrapUp={() => setHovered(false)}
            />
          )}
        </div>
      </CardMedia>
      <CardContent className={classes.cardContent}>
        <div
          style={{
            padding: '16px',
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
          }}>
          <Typography
            variant="h4Sub"
            overflow="ellipsis"
            sx={{
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 2,
            }}>
            {title}
          </Typography>
          <Typography
            variant="h5"
            color="#4E4E4E"
            noWrap
            marginTop="4px"
            fontSize="12px">
            {formatSubText()}
          </Typography>
        </div>
        <Divider style={{ marginTop: 'auto' }} />
        {!showSaveButton ? (
          <div
            style={{
              display: 'flex',
              color: '#4E4E4E',
              alignItems: 'center',
              padding: '16px',
            }}>
            <Typography fontSize={14}>View detail</Typography>
            <ArrowCircleRightOutlined sx={{ marginLeft: 1 }} />
          </div>
        ) : (
          <Button
            onMouseOver={() => setAddToWishlistBtnHovered(true)}
            onMouseLeave={() => setAddToWishlistBtnHovered(false)}
            onClick={handleSaveToWishlist}
            data-poi-slug={`${id}-${title}`}
            className={`${classes.saveButton} ${
              isSaved
                ? classList.discoverRemoveFromWishlistBtn
                : classList.discoverAddToWislistBtn
            }`}
            disabled={isLoading}>
            {isLoading ? (
              <CircularProgress size={16} />
            ) : isSaved ? (
              addToWishlistBtnHovered ? (
                'Remove from wishlist'
              ) : (
                <>
                  <CheckRounded
                    sx={{ marginRight: '8px', height: 16, width: 16 }}
                  />
                  Saved
                </>
              )
            ) : (
              <>
                <AddRounded
                  sx={{ marginRight: '8px', height: 16, width: 16 }}
                />
                Add to Wishlist
              </>
            )}
          </Button>
        )}
      </CardContent>
    </Card>
  );
}

export default PoiCard;
