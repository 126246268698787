/* eslint-disable-file no-use-before-define  no-unused-vars */
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Modal,
  Checkbox,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Button,
  IconButton,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  AttachFileRounded,
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank,
  CloseRounded,
} from '@mui/icons-material';
import actions from '../../../../redux/actions';
import { attachFiletoItems } from '../../../../redux/slices/Files';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'red',
  },
  attachmentOption: {
    padding: '2px 0',
    borderBottom: '1px solid #DEDDDD',
    cursor: 'pointer',
    '&:hover': {
      background: '#F4F4F4',
    },
  },
  listContainer: {
    height: '100%',
    overflowY: 'scroll',
  },
  listItem: {
    padding: '8px 4px',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: '#FFF',
      color: '#222',
    },
  },
  listItemText: {
    '& .MuiTypography-root': {
      fontSize: 14,
    },
  },
}));

function AttachFileModal({
  tripId,
  fileId,
  handleClose = () => {},
  isImported = false,
  ...props
}) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const tripItems = useSelector((state) => state.Trips.trips[tripId].items);
  const activities = useSelector((state) => state.Activity.todos);
  const accommodation = useSelector((state) => state.Accommodation.hotels);
  const locations = useSelector((state) => state.Location.locations);
  const sections = useSelector((state) => state.Section.sections);
  const transportation = useSelector(
    (state) => state.Transportation.transports
  );
  const fileRelations = useSelector(
    (state) => state.Files.fileRelations[tripId]
  );

  const getName = (type, elemId) => {
    if (type === 'Activity') {
      return activities[elemId].title;
    }
    if (type === 'Accommodation') {
      return accommodation[elemId].name;
    }
    if (type === 'Transportation') {
      const transportObj = Object.values(transportation).find((transport) =>
        transport?.details?.map((detail) => detail.id)?.includes(elemId)
      );
      return transportObj?.details?.find((detail) => detail.id === elemId)
        ?.flightNumber;
    }
    return '';
  };

  const attachedItems = fileRelations
    ?.filter(
      (relation) =>
        relation.fileId === fileId && relation.attachedToType !== 'Trip'
    )
    .map((relation) => {
      return {
        id: relation.attachedToId,
        title: getName(relation.attachedToType, relation.attachedToId),
        type: relation.attachedToType,
      };
    });

  const [checkedItems, setCheckedItems] = useState([
    ...(attachedItems.map((option) => `${option.id}__${option.type}`) || []),
  ]);
  const itemsArr = checkedItems?.map((item) => item.split('__')[0]);
  const attachedItemsArr = attachedItems?.map((item) => item.id);

  const tripItemsList = useMemo(() => {
    const activitesList = [];
    const accommodationList = [];
    const transportationList = [];

    tripItems?.forEach((item) => {
      if (item?.transportation) {
        transportation[item?.transportation]?.details?.forEach((detail) => {
          transportationList.push({
            id: detail.id,
            title: detail.flightNumber,
            type: 'Transportation',
          });
        });
      }
      if (item?.location) {
        locations[item.location]?.hotels?.forEach((hotel) => {
          accommodationList.push({
            id: hotel,
            title: accommodation[hotel].name,
            type: 'Accommodation',
          });
        });
        locations[item.location]?.thingsToDo?.forEach((section) => {
          sections[section]?.todos.forEach((todo) => {
            if (!todo?.includes('local-todo')) {
              activitesList.push({
                id: todo,
                title: activities[todo].title,
                type: 'Activity',
              });
            }
          });
        });
      }
    });

    return [...transportationList, ...accommodationList, ...activitesList];
  }, [
    tripItems,
    locations,
    sections,
    accommodation,
    activities,
    transportation,
  ]);

  const arrCompare = (arr1, arr2) => {
    let equal = false;
    if (arr1.length === arr2.length) {
      equal = true;
      arr1.forEach((element) => {
        if (!arr2.includes(element)) equal = false;
      });
    }
    return equal;
  };

  // eslint-disable-next-line
  const handleAttach = async () => {
    if (!arrCompare(itemsArr, attachedItemsArr)) {
      const attachmentItems = checkedItems
        ?.filter((itemId) => !attachedItemsArr.includes(itemId))
        .map((itemId) => {
          const [id, type] = itemId.split('__');
          return {
            id,
            type,
          };
        });
      const detachedItems = attachedItems
        ?.filter((item) => !itemsArr.includes(item))
        .map((itemId) => {
          const [id, type] = itemId.split('__');
          return {
            id,
            type,
          };
        });
      const toBeAdded = !fileRelations?.some(
        (relation) =>
          relation.fileId === fileId &&
          !detachedItems?.map((item) => item.id).includes(relation.attachedToId)
      );

      await new Promise(() => {
        dispatch(
          attachFiletoItems({
            variables: {
              fileId,
              tripId,
              items: attachmentItems,
              itemsToRemove: detachedItems,
              addToTrip: toBeAdded,
              isImported,
            },
          })
        ).then(() => {
          if (toBeAdded) {
            dispatch(actions.Trips.addTripFile({ tripId, fileId }));
          }
          const getBladeItems = (items, type) => {
            return items?.filter((item) => item.type === type);
          };
          dispatch(
            actions.Activity.handleActivityFiles({
              fileId,
              itemsToAttach: getBladeItems(attachmentItems, 'Activity'),
              itemsToDetach: getBladeItems(detachedItems, 'Activity'),
            })
          );
          dispatch(
            actions.Accommodation.handleAccommodationFiles({
              fileId,
              itemsToAttach: getBladeItems(attachmentItems, 'Accommodation'),
              itemsToDetach: getBladeItems(detachedItems, 'Accommodation'),
            })
          );
          dispatch(
            actions.Transportation.handleTransportationFiles({
              fileId,
              itemsToAttach: getBladeItems(attachmentItems, 'Transportation'),
              itemsToDetach: getBladeItems(detachedItems, 'Transportation'),
            })
          );
          if (isImported)
            dispatch(actions.Trips.removeImportedFile({ tripId, fileId }));
          handleClose();
        });
      });
    }
  };

  const handleCheckboxClick = (e) => {
    const newValue = e.target.value;
    const currentIndex = checkedItems.indexOf(newValue);
    const newChecked = [...checkedItems];

    if (currentIndex === -1) {
      newChecked.push(newValue);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedItems(newChecked);
  };

  const getArrayGroupedByType = (array) => {
    return array.reduce(
      (obj, item) => ({
        ...obj,
        [item.type]: [...(obj[item.type] || []), item],
      }),
      {}
    );
  };

  const groupedItemsList = getArrayGroupedByType(tripItemsList);

  return (
    <Modal {...props}>
      <div
        style={{
          display: 'flex',
          height: '100%',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <div
          style={{
            backgroundColor: '#FFF',
            height: 450,
            width: 466,
            borderRadius: 4,
            display: 'flex',
            padding: 24,
            flexDirection: 'column',
          }}>
          <div style={{ display: 'flex' }}>
            <div style={{ flex: 1 }}>
              <Typography variant="h2">Attach Files</Typography>
            </div>
            <div>
              <IconButton
                sx={{ padding: '4px', color: '#1D1D1D' }}
                size="small"
                onClick={props.onClose}>
                <CloseRounded />
              </IconButton>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              height: '100%',
              overflow: 'hidden',
            }}>
            <List className={classes.listContainer}>
              {Object.keys(groupedItemsList || {})?.map((heading) => {
                return (
                  <>
                    <Typography
                      fontSize={12}
                      textTransform="uppercase"
                      color="#4E4E4E"
                      fontWeight={600}
                      marginTop="16px">
                      {heading}
                    </Typography>
                    <Divider />
                    {groupedItemsList[heading]?.map((option) => (
                      <>
                        <ListItem
                          className={classes.listItem}
                          key={option.id}
                          disablePadding
                          disableRipple>
                          <ListItemIcon style={{ minWidth: 0 }}>
                            <Checkbox
                              style={{ marginRight: 4 }}
                              edge="start"
                              icon={<CheckBoxOutlineBlank fontSize="small" />}
                              checkedIcon={<CheckBoxIcon fontSize="small" />}
                              onChange={handleCheckboxClick}
                              value={`${option.id}__${option.type}`}
                              disableRipple
                              checked={
                                checkedItems.indexOf(
                                  `${option.id}__${option.type}`
                                ) !== -1
                              }
                            />
                          </ListItemIcon>
                          <ListItemText
                            className={classes.listItemText}
                            id={option.id}
                            primary={option.title}
                          />
                        </ListItem>
                        <Divider />
                      </>
                    ))}
                  </>
                );
              })}
            </List>
          </div>
          <Button
            onClick={handleAttach}
            width="100%"
            disabled={arrCompare(itemsArr, attachedItemsArr)}
            startIcon={<AttachFileRounded />}>
            Attach File
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default AttachFileModal;
