import { Box, Select, MenuItem } from '@mui/material';
import { useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { DayPicker, Day, useDayRender } from 'react-day-picker';
import { format, isSameDay } from 'date-fns';
import 'react-day-picker/dist/style.css';

const useStyles = makeStyles((theme) => ({
  overlayFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  resetBtn: {
    marginRight: 4,
    backgroundColor: '#fff',
    color: theme.palette.primary.main,
  },
  selected: {
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
  limit: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    borderRadius: '100%',
    color: '#FFF !important',
  },
  rangeMiddle: {
    borderRadius: '100%',
    backgroundColor: `${theme.palette.primary.extraLight} !important`,
  },
  dayContainerMiddle: {
    backgroundColor: `${theme.palette.primary.extraLight} !important`,
  },
  dayContainerStart: {
    background: `linear-gradient(to right, #FFF 50%, ${theme.palette.primary.extraLight} 50%)`,
  },
  dayContainerEnd: {
    background: `linear-gradient(to left, #FFF 50%, ${theme.palette.primary.extraLight} 50%)`,
  },
  calendarOverlay: {
    zIndex: 1121,
    backgroundColor: 'white',
  },
  calendarPaper: {
    padding: '16px 12px 8px 16px',
    '& .rdp': {
      margin: 0,
    },
  },
  cell: {
    color: '#000',
    fontSize: '0.75rem',
    height: 40,
    width: 40,
    padding: 0,
  },
}));

function CustomDropDown({ children, ...props }) {
  return (
    <div>
      <Select
        {...props}
        labelId={props['aria-label']}
        variant="standard"
        disableUnderline
        style={{
          padding: 4,
          position: 'relative',
        }}
        MenuProps={{
          anchorEl: this,
          disablePortal: true,
          style: {
            zIndex: 1221,
            height: 300,
            width: 200,
            position: 'absolute',
            marginTop: 16,
          },
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}>
        {children?.map((option) => (
          <MenuItem key={option?.key} value={option?.props?.value}>
            {option?.props?.children}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}

function CreateATripDateRangePicker({ range, setRange }) {
  const classes = useStyles();
  // setRange(defaultRange);
  const sameDay = range?.from && range?.to && isSameDay(range?.to, range?.from);
  const [firstClick, setFirstClick] = useState(true);
  const handleDayClick = (date) => {
    if (firstClick) {
      setRange({
        from: date,
        to: date,
      });

      setFirstClick(false);
    } else if (date < range.from) {
      setRange({
        from: date,
        to: null,
      });

      setFirstClick(false);
    } else if (date > range.from) {
      setRange({
        ...range,
        to: date,
      });

      setFirstClick(true);
    } else {
      setRange({ from: date, to: date });

      setFirstClick(false);
    }
  };
  return (
    <Box
      sx={{
        border: '1px solid #D9D9D9',
        padding: '10px',
        backgroundColor: 'white',
      }}>
      <DayPicker
        captionLayout="dropdown"
        selected={range}
        mode="range"
        defaultMonth={new Date()}
        toYear={2050}
        // onSelect={(newRange) => {
        //   if (!newRange) return;
        //   setRange(newRange);
        // }}
        onDayClick={handleDayClick}
        components={{
          Dropdown: CustomDropDown,
          Day: (props) => {
            const dayRender = useDayRender(
              props.date,
              props.displayMonth,
              useRef()
            );
            return (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                className={
                  dayRender?.activeModifiers?.range_middle
                    ? classes.dayContainerMiddle
                    : dayRender?.activeModifiers?.range_start
                    ? (range?.to && !sameDay && classes.dayContainerStart) || ''
                    : dayRender?.activeModifiers?.range_end
                    ? (!sameDay && classes.dayContainerEnd) || ''
                    : ''
                }>
                <Day {...props} />
              </div>
            );
          },
        }}
        modifiersClassNames={{
          selected: classes.selected,
          range_start: classes.limit,
          range_end: classes.limit,
          range_middle: classes.rangeMiddle,
        }}
        formatters={{
          formatWeekdayName: (weekday, options) => {
            return format(weekday, 'ccccc', options);
          },
        }}
        classNames={{
          cell: classes.cell,
        }}
        styles={{
          caption_label: {
            fontSize: 16,
            fontWeight: 400,
            border: 0,
            padding: '0px 8px',
          },
          dropdown_year: {
            cursor: 'pointer',
          },
          dropdown_month: {
            cursor: 'pointer',
          },
          caption: {
            marginBottom: 8,
          },
          table: {
            minHeight: 276,
            display: 'flex',
            flexDirection: 'column',
            margin: '0px 4px',
          },
          head_row: {
            display: 'flex',
          },
          day: {
            height: 36,
            width: 36,
          },
          head_cell: {
            width: 36,
            height: 36,
            margin: '0px 2px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#4E4E4E',
            opacity: 0.6,
            fontWeight: 100,
          },
          nav_button_previous: {
            height: 24,
            width: 24,
            padding: 4,
            marginRight: 24,
          },
          nav_button_next: {
            height: 24,
            width: 24,
            padding: 4,
          },
        }}
      />
    </Box>
  );
}

export default CreateATripDateRangePicker;
