import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import {
  Button,
  Modal,
  TextField,
  Fade,
  Typography,
  CircularProgress,
} from '@mui/material';
import { ChevronLeftRounded, AddRounded } from '@mui/icons-material';
import { format } from 'date-fns';
import PopularSection from './PopularSection';
import DateRangePicker from '../../molecules/DatePickers/DateRangePicker';
import { fetchCityInfo } from '../../../redux/slices/Discover';
import { TransparentBackButton } from '../../atoms/Button/index';
import { createTrip } from '../../../redux/slices/Trips';
import { createLocation } from '../../../redux/slices/Location';
import { useMapUtils } from '../MapUtils';
import classList from '../../classList';
import { getWishlists } from '../../../redux/slices/Wishlist';
import actions from '../../../redux/actions';

const useStyles = makeStyles(({ palette }) => ({
  header: {
    position: 'relative',
    width: '100%',
    height: 250,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    objectFit: 'cover',
  },
  titleContainer: {
    position: 'absolute',
    // center this
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  backButtonContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    padding: '24px 8%',
  },
  createTripContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: '24px 8%',
  },
  addTripIcon: {
    height: 16,
    width: 16,
  },

  backToMainContainer: {
    fontSize: 14,
    color: '#4E4E4E',
    marginBottom: 1,
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },

  body: {
    padding: '0% 8%',
  },

  // modal
  modalHeader: {
    padding: '16px 16px 12px 16px',
    borderBottom: '2px solid #F4F4F4',
  },
  modalBody: {
    display: 'flex',
    flexDirection: 'column',
    padding: '12px 16px 20px 16px',
  },
  textField: {
    '& > .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#4E4E4E',
      },
      '&:hover fieldset': {
        borderColor: palette.primary.light,
      },
      '&.Mui-focused fieldset': {
        borderColor: palette.primary.main,
      },
    },
  },
  imageContainer: {
    position: 'relative',
    width: '100%',
    height: 180,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    objectFit: 'cover',
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

function ModalDatePicker({ handleChange = () => {} }) {
  const [dates, setDates] = useState({
    from: null,
    to: null,
  });

  const getformattedDateString = (dateRange) => {
    let newDateString = '';
    if (
      dateRange?.from &&
      String(dateRange?.to).valueOf() === String(dateRange?.from).valueOf()
    ) {
      newDateString = format(dateRange?.from, 'MMM do');
    } else if (dateRange?.from && dateRange?.to) {
      if (format(dateRange.from, 'MMM') === format(dateRange.to, 'MMM')) {
        newDateString = `${format(dateRange.from, 'MMM do')} - ${format(
          dateRange.to,
          'do'
        )}`;
      } else {
        newDateString = `${format(dateRange.from, 'MMM do')} - ${format(
          dateRange.to,
          'MMM do'
        )}`;
      }
    } else if (dateRange?.from) {
      newDateString = format(dateRange?.from, 'MMM do');
    }
    return newDateString;
  };

  const [calendarAnchor, setCalendarAnchor] = useState(null);
  const classes = useStyles();
  const isOpen = Boolean(calendarAnchor);
  return (
    <>
      <DateRangePicker
        open={isOpen}
        anchorEl={calendarAnchor}
        handleClose={(range) => {
          setCalendarAnchor(null);
          setDates(range);
          handleChange({
            startDate: range?.from || null,
            endDate: range?.to || null,
          });
        }}
        placement="top-start"
        shouldDisableRangeContext
        popperProps={{
          disablePortal: true,
        }}
      />
      <TextField
        required
        ref={calendarAnchor}
        variant="outlined"
        size="small"
        id="title"
        value={getformattedDateString(dates)}
        placeholder="Select Date"
        fullWidth
        className={`${classes.textField} ${classList.discoverCreateTripDateInput}`}
        inputProps={{ readOnly: true }}
        onClick={(e) => {
          setCalendarAnchor(e.currentTarget);
        }}
      />
    </>
  );
}

function CreateTripModal({
  open = false,
  onClose = () => {},
  coverImage = '',
  city = '',
  country = '',
  placeId = null,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  // modal to toggle between filled/completed trip states
  const [preTripView, setPreTripView] = useState(true);

  const userId = useSelector((state) => state.Auth.firebaseUser.uid);

  const [tripProps, setTripProps] = useState({
    title: '',
    startDate: null,
    endDate: null,
  });

  const { createMapPinForPlaceId } = useMapUtils();

  const [isLoading, setLoading] = useState(false);

  const handleAddLocation = async (tripId) => {
    let mapPinId = null;
    if (placeId) {
      mapPinId = await createMapPinForPlaceId(placeId, 'LOCATION', tripId);
    }
    await dispatch(
      createLocation({
        variables: {
          name: city,
          tripID: tripId,
          mapPin: mapPinId,
          index: 0,
        },
        shouldUpdateTrip: false,
      })
    );
  };

  const handleCreateTrip = async () => {
    setLoading(true);
    const trip = await dispatch(
      createTrip({
        trip: {
          title: tripProps.title || `Trip to ${city}`,
          coverImage,
          owner: userId,
          startDate: tripProps.startDate,
          endDate: tripProps.endDate,
        },
        shouldUpdateStore: true,
      })
    );
    const tripId = trip?.payload?.trip?.id;
    setTripProps({ ...tripProps, tripId });
    if (!tripId) return;

    await handleAddLocation(tripId);

    setLoading(false);
    setPreTripView(false);
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        setPreTripView(true);
        onClose();
      }}
      sx={{
        overflowY: 'scroll',
      }}>
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%,-50%)',
          backgroundColor: '#ffffff',
          borderRadius: 4,
          outline: 'none',
          display: 'flex',
          flexDirection: 'column',
          width: preTripView ? 345 : 480,
          transition: 'width 0.5s ease',
        }}>
        {preTripView ? (
          <>
            <div className={classes.modalHeader}>
              <Typography variant="h3">Create a trip</Typography>
            </div>
            <div className={classes.modalBody}>
              <Typography variant="h4" sx={{ marginBottom: 1 }}>
                Trip title
              </Typography>
              <TextField
                required
                variant="outlined"
                size="small"
                id="title"
                placeholder={`Trip to ${city}`}
                fullWidth
                className={`${classes.textField} ${classList.discoverCreateTripTitleInput}`}
                defaultValue={tripProps.title}
                onChange={(e) => {
                  setTripProps({ ...tripProps, title: e.target.value });
                }}
              />
              <Typography variant="h4" sx={{ marginTop: 2, marginBottom: 1 }}>
                Date (optional)
              </Typography>
              <ModalDatePicker
                handleChange={(props) =>
                  setTripProps({ ...tripProps, ...props })
                }
              />
              <Button
                sx={{
                  marginTop: 3,
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                className={classList.discoverCreateTripModalBtn}
                onClick={handleCreateTrip}
                disabled={isLoading}>
                {isLoading ? (
                  <Fade
                    in={isLoading}
                    style={{
                      transitionDelay: isLoading ? '100ms' : '0ms',
                    }}
                    unmountOnExit>
                    <CircularProgress size={20} />
                  </Fade>
                ) : (
                  'Create a trip'
                )}
              </Button>
              <div style={{ width: '100%', textAlign: 'center', marginTop: 8 }}>
                <Typography
                  className={classList.discoverCreateTripModalCancelBtn}
                  onClick={isLoading ? () => {} : onClose}
                  sx={{
                    fontSize: 14,
                    textDecoration: 'underline',
                    color: '#4E4E4E',
                    cursor: 'pointer',
                  }}>
                  Cancel
                </Typography>
              </div>
            </div>
          </>
        ) : (
          <div style={{ padding: 11 }}>
            <div
              className={classes.imageContainer}
              style={{
                backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${
                  coverImage || ''
                })`,
              }}>
              <Typography
                variant="h1Growth"
                sx={{ color: '#fff', fontSize: 40 }}>
                {city}
              </Typography>
              <Typography variant="h4" sx={{ color: '#fff' }}>
                {country}
              </Typography>
            </div>

            <div style={{ padding: 10 }}>
              <Typography variant="h2"> New trip plan created </Typography>
              <Typography variant="h5" sx={{ color: '#8A8A8A' }}>
                {' '}
                You can start building your itinerary and invite trip mates to
                the trip plan!
              </Typography>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ flex: 1 }} />
                <Typography
                  onClick={onClose}
                  sx={{
                    fontSize: 14,
                    textDecoration: 'underline',
                    color: '#4E4E4E',
                    cursor: 'pointer',
                  }}>
                  Continue browsing
                </Typography>
                <Button
                  sx={{ marginLeft: 2 }}
                  onClick={() => {
                    window.open(
                      `${window.location.origin}/trips/${tripProps?.tripId}/planner`,
                      '_blank'
                    );
                  }}>
                  Go to trip
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
}

function DestinationPage({ cityId }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const destination = useSelector(
    (state) => state.Discover.cities.data[cityId]
  );
  const userId = useSelector((state) => state.Auth.firebaseUser.uid);
  const [placesView, setPlacesView] = useState(false);
  const [createTripModalOpen, setCreateTripModalOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (destination?.status === 'IDLE' || !destination?.status) {
      dispatch(fetchCityInfo({ cityId }));
      dispatch(getWishlists({ userId }));
    }
  }, [destination?.status]);

  useEffect(() => {
    dispatch(actions.Discover.setPrevPath(`/${destination?.slug}`));
  }, []);

  const handleNavigateBack = () => {
    dispatch(actions.Discover.setPrevPath(``));
    navigate('/discover');
  };

  return (
    <div>
      <div
        className={classes.header}
        style={{
          backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${
            destination?.image || ''
          })`,
        }}>
        <div className={classes.backButtonContainer}>
          <TransparentBackButton
            className={classList.discoverBackToDiscoverBtn}
            onClick={handleNavigateBack}
            startIcon={<ChevronLeftRounded />}>
            Back to Discover
          </TransparentBackButton>
        </div>
        <div className={classes.createTripContainer}>
          <Button
            className={classList.discoverCreateTripBtn}
            onClick={() => setCreateTripModalOpen(true)}
            startIcon={<AddRounded className={classes.addTripIcon} />}>
            Create a trip
          </Button>
        </div>

        <div className={classes.titleContainer}>
          <Typography variant="h1Growth" fontSize="5rem">
            {destination?.name || cityId}
          </Typography>
          <Typography variant="h45ub" sx={{ fontWeight: 600 }}>
            {destination?.country || ''}
          </Typography>
        </div>
      </div>
      <div className={classes.body}>
        <CreateTripModal
          onClose={() => setCreateTripModalOpen(false)}
          open={createTripModalOpen}
          coverImage={destination?.image}
          city={destination?.name}
          country={destination?.country}
          placeId={destination?.placeId}
        />
        <div style={{ marginTop: 40 }} />
        {!destination?.status ||
        destination?.status === 'LOADING' ||
        destination?.status === 'IDLE' ? (
          <div
            style={{
              width: '100%',
              padding: '4% 0%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <CircularProgress />
          </div>
        ) : destination?.status === 'ERROR' ? (
          <Typography variant="h2Sub">
            We do not have information on this location
          </Typography>
        ) : (
          <>
            {destination?.pois && destination?.pois?.length > 0 ? (
              <PopularSection
                heading="Popular attractions"
                description={`Most popular attractions in ${destination?.name}`}
                type="PLACES"
                city={destination?.slug}
                pois={destination?.pois}
                showViewAll
                toggle={placesView}
                singleRow={!placesView}
                onViewTogglelick={(toggle) => setPlacesView(toggle)}
              />
            ) : null}
            {destination?.blogs && destination?.blogs?.length > 0 ? (
              <PopularSection
                heading="Popular articles"
                description={`Most popular articles in ${destination?.name}`}
                type="BLOGS"
                city={destination?.slug}
                cityTitle={destination?.name}
                blogs={destination?.blogs}
              />
            ) : null}
          </>
        )}
      </div>
    </div>
  );
}

export default DestinationPage;
