import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { WarningTriangleIcon, CheckCircleIcon } from '@iconicicons/react';

const useStyles = (type) =>
  makeStyles(() => ({
    container: {
      backgroundColor: type === 'ERROR' ? '#F45E62' : '#38CF90',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      padding: 8,
      borderRadius: 8,
      marginBottom: 8,
    },
    text: {
      color: '#FFF',
      marginLeft: 8,
      fontSize: 12,
    },
  }))();

function FeedbackPopup({ feedbackText, containerProps, type = 'ERROR' }) {
  const classes = useStyles(type);
  const FeedbackIcon = type === 'ERROR' ? WarningTriangleIcon : CheckCircleIcon;
  return (
    <div className={classes.container} {...containerProps}>
      <FeedbackIcon
        style={{
          color: '#FFF',
          height: 16,
          width: 16,
        }}
      />
      <Typography className={classes.text}>{feedbackText}</Typography>
    </div>
  );
}

export default FeedbackPopup;
