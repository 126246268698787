import { Typography } from '@mui/material';

function AppreciationScreen() {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        textAlign: 'center',
      }}>
      <img src="/logo192.png" alt="Pilot" style={{ width: 40, height: 40 }} />
      <Typography variant="h2" mt="4px">
        Thank you for your continued support
      </Typography>
      <Typography
        variant="caption"
        component="p"
        color="text.secondary"
        sx={{ mt: 2, mb: 1 }}>
        Our progress is only possible thanks to supporters like you. Your
        support means we can continue to help travelers around the world make
        meaningful experiences together.
      </Typography>
      <Typography variant="caption" color="text.light">
        Thank you! - Pilot Crew
      </Typography>
    </div>
  );
}

export default AppreciationScreen;
