/* eslint-disable no-unused-vars */
import { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, InputBase, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AddToWishlist } from '../atoms/Button';
import { AddImage, Icon } from '../atoms/Icon';
import { ImageSelectorModal } from './Modal';
import LoadingLayout from '../templates/LoadingLayout';
import { trackEvents, Events } from '../../intercom';
import { updateWishlist } from '../../redux/slices/Wishlist';
import { handleOldPhotoDelete } from '../../utils';
import { EVENTS, phTrackEvent } from '../../analytics';
import classList from '../classList';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '98%',
    height: '252px',
  },
  imageContainer: {
    width: '100%',
    height: '133px',
    marginBottom: '8px',
    position: 'relative',
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '252px',
  },
  blankImage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    border: '1px solid',
    borderColor: theme.palette.system.grey,
    borderRadius: '8px',
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '8px',
  },
  noteBox: {
    backgroundColor: '#F4F4F4',
    padding: '8px',
    height: '67px',
    marginBottom: '8px',
    borderRadius: '8px',
  },
  resetButton: {
    position: 'absolute',
    right: '4px',
    top: '4px',
    padding: '2px',
    backgroundColor: 'rgba(34, 34, 34, 0.5)',
    color: '#FFF',
    '&:hover': {
      color: '#FFF',
      backgroundColor: 'rgba(34, 34, 34, 0.8)',
    },
  },
  overlayBox: {
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    borderRadius: '8px',
    '&:hover': {
      '& $overlay': {
        opacity: 0.5,
      },
      cursor: 'pointer',
    },
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderRadius: '8px',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: '70%',
    transition: 'opacity 0.3s ease-in-out',
  },
}));

function ImageHolder({ image, imageSetter, removeImage, setPickerOpen }) {
  const classes = useStyles();

  return (
    <Box className={classes.imageContainer}>
      {image ? (
        <Box
          className={classes.overlayBox}
          onClick={() => {
            setPickerOpen(true);
          }}>
          <img src={image} alt="Wish Item" className={classes.image} />
          <Box className={classes.overlay}>
            <IconButton>
              <Icon />
            </IconButton>
          </Box>
        </Box>
      ) : (
        <Box className={classes.blankImage} onClick={() => imageSetter()}>
          <AddImage />
        </Box>
      )}
    </Box>
  );
}

function AddWishItem({
  wishlistDetails = null,
  editMode = false,
  setEditMode,
  handleSubmit,
  uploading,
}) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const [itemTitle, setItemTitle] = useState(wishlistDetails?.title || '');
  const [imageURL, setImageURL] = useState(wishlistDetails?.coverImage || '');
  const [itemTitlePrevious] = useState(wishlistDetails?.title || '');
  const [imageURLPrevious] = useState(wishlistDetails?.coverImage || '');
  const [pickerOpen, setPickerOpen] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const imageRef = useRef({
    image: null,
    source: null,
  });

  const resetImage = () => {
    imageRef.current = {
      image: null,
      source: null,
    };
    setImageURL(null);
  };

  const addItemHandler = () => {
    trackEvents(Events.WishListAdded);
    handleSubmit(itemTitle, imageRef.current);
    setItemTitle('');
    resetImage();
  };

  const handleWishlistUpdate = async () => {
    if (itemTitle !== itemTitlePrevious)
      phTrackEvent({
        event: EVENTS.WISHLIST.ITEM_NOTE_UPDATE,
      });
    if (imageRef.current.image) {
      phTrackEvent({
        event: EVENTS.WISHLIST.ITEM_PHOTO_UPDATE,
      });
    }
    dispatch(
      updateWishlist({
        id: wishlistDetails.id,
        coverImage: imageRef.current.image ? imageRef.current.image : imageURL,
        title: itemTitle,
      })
    );
    setEditMode(false);

    if (!imageRef.current.image) {
      return; // No new image, no need to proceed
    }
    // Delete old picture if new image uploaded
    const originalUrl = wishlistDetails.coverImage;
    if (originalUrl && !originalUrl.includes('unsplash')) {
      handleOldPhotoDelete(originalUrl);
    }
  };

  return (
    <Box className={classes.root}>
      {uploading ? (
        <Box className={classes.loadingContainer}>
          <LoadingLayout fullPage={false} />
        </Box>
      ) : (
        <>
          <ImageSelectorModal
            changeCoverModalOpen={pickerOpen}
            setChangeCoverModalOpen={setPickerOpen}
            tempImageSelect={(image) => {
              const isUploaded = typeof image === 'object';
              imageRef.current = {
                image,
                source: isUploaded ? 'upload' : 'remote',
              };
              setImageURL(isUploaded ? URL.createObjectURL(image) : image);
            }}
          />
          <ImageHolder
            image={imageURL}
            imageSetter={() => setPickerOpen(true)}
            removeImage={resetImage}
            setPickerOpen={setPickerOpen}
          />
          <Box className={classes.noteBox}>
            <InputBase
              value={itemTitle}
              placeholder="Add a new idea! Ex. Eat a croissant in Paris and visit the Eiffel Tower"
              style={{ width: '100%', backgroundColor: '#F4F4F4' }}
              onChange={(e) => setItemTitle(e.target.value)}
              multiline
              rows={2}
            />
          </Box>
          <AddToWishlist
            disabled={
              editMode
                ? itemTitle === itemTitlePrevious &&
                  imageURL === imageURLPrevious
                : itemTitle === '' && imageURL === ''
            }
            className={
              editMode
                ? classList.wishlistItemEditBtn
                : classList.wishlistItemAddBtn
            }
            onClick={editMode ? handleWishlistUpdate : addItemHandler}>
            {editMode ? <>Edit</> : <>Add to</>} wishlist
          </AddToWishlist>
        </>
      )}
    </Box>
  );
}

export default AddWishItem;
