import { Typography, Box } from '@mui/material';
import { useTheme } from '@mui/styles';

import '../pages/Settings.css';

function PrivacySetting(props) {
  const theme = useTheme();

  const { title } = props;
  const { description } = props;
  const { currentPrivacy } = props;

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          /* position: relative; */
          lineHeight: '140%',
          padding: ' 4% 0 0 0',
          justifyContent: 'space-between',
          alignItems: 'center',
          [theme.breakpoints.down('md')]: {
            alignItems: 'start',
            flexDirection: 'column',
          },
        }}>
        <div className="privacy-type">
          <Typography variant="h6" className="privacy-title" fontSize="18px">
            {title}
          </Typography>
        </div>
        <div className="privacy-change">
          <Typography>{currentPrivacy}</Typography>
        </div>
      </Box>
      <Typography
        className="privacy-desc"
        sx={{ [theme.breakpoints.down('md')]: { display: 'none' } }}>
        {description}
      </Typography>
    </Box>
  );
}

export default PrivacySetting;
