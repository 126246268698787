import { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Popover,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ChevronLeft, KeyboardArrowDown } from '@mui/icons-material';
import { SmileEmojiIcon } from '../../atoms/Icon';
import RecommendationsView from './Recommendations';
import actions from '../../../redux/actions';
import SavedIconButton from '../Stays/SavedIconButton';
import CheckInCheckOutButton from '../Stays/CheckInCheckOutButton';
import PlacesToStayView from '../Stays/PlacesToStayView';
import classList from '../../classList';

const useStyles = makeStyles((theme) => ({
  exploreContainer: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  menuButton: {
    display: 'flex',
    zIndex: 1,
    justifyContent: 'center',
    // flexWrap: 'wrap',
    maxHeight: '48px',
    backgroundColor: '#FFF',
    borderColor: '#D9D9D9',
    borderRadius: '40px',
    color: '#222222',
    cursor: 'pointer',
    ...theme.typography.h4Sub,
    fontSize: 20,
    fontWeight: 500,
    '&:hover': {
      backgroundColor: '#f4f4f4',
      borderColor: '#D9D9D9',
      color: '#222222',
      boxShadow: '2px',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '1rem', // Reduce the font size for smaller screens
    },
  },
  PoweredByKayakIconContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  dynamicContainer: {
    marginTop: '20px',
  },
  inputField: {
    marginTop: '10px',
    width: '474px',
    height: '39px',
  },
  suggestionsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
    marginTop: '10px',
  },
  suggestionChip: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    padding: '5px',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    marginTop: '20px',
  },
  filterDrawer: {
    width: '300px',
    padding: '20px',
  },
  filterButton: {
    marginTop: '10px',
  },
  iconButton: {
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  backIconButton: {
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },
}));

function ExploreV1() {
  const classes = useStyles();
  const location = useLocation();
  const { slug: tripId } = useParams();

  const toggleButtonRef = useRef();

  const selectedItem = useSelector((state) => state.View.activeExploreTab || 0);

  const isSaveListVisible = useSelector(
    (state) => state.Bookings[tripId].isSaveListVisible
  );

  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorPEl, setAnchorPEl] = useState(null);

  // check for default explore tab, set by query param focus=stays
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const focus = queryParams.get('focus');
    if (focus === 'stays' && selectedItem !== 0) {
      dispatch(actions.View.setActiveExploreTab(0));
    } else if (focus === 'pois' && selectedItem !== 1) {
      dispatch(actions.View.setActiveExploreTab(1));
    }
  }, [location.search]);

  const handleMenuOpen = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemSelect = (itemId) => {
    dispatch(actions.View.setActiveExploreTab(itemId));
    handleMenuClose();

    // TODO: Create a query params manager for explore
    // Remove query params from other tabs
    const queryParams = new URLSearchParams();
    queryParams.set('focus', itemId === 0 ? 'stays' : 'pois');
    window.history.replaceState(
      {},
      '',
      `${location.pathname}?${queryParams.toString()}`
    );
  };

  const handlePopoverOpen = (event) => {
    setAnchorPEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorPEl(null);
  };

  const open = Boolean(anchorPEl);
  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const menuItems = [
    { id: 0, name: 'Places to stay', className: classList.placesToStayTab },
    {
      id: 1,
      name: 'Things to do',
      className: classList.exploreThingsToDoTab,
    },
  ];

  return (
    <Grid container sx={{ marginTop: '20px' }}>
      <Grid item xs={12}>
        <div className={classes.exploreContainer}>
          <Grid container>
            {isSaveListVisible && (
              <Grid
                item
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <IconButton
                  onClick={() => {
                    dispatch(
                      actions.Bookings.setIsSaveListVisible({
                        tripId,
                        isSaveListVisible: false,
                      })
                    );
                  }}
                  disableRipple
                  className={`${classes.iconButton} ${classes.backIconButton}`}>
                  <ChevronLeft />
                </IconButton>
              </Grid>
            )}
            <Grid
              item
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}>
              <Typography
                variant="h4Sub"
                sx={{
                  fontSize: 20,
                }}>
                {isSaveListVisible ? 'Saved list' : 'Explore'}
              </Typography>
            </Grid>
            <Grid
              item
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: 1,
              }}>
              {isSaveListVisible ? null : (
                <Box
                  sx={{ position: 'relative' }}
                  className={classList.exploreToggleMenu}>
                  <Button
                    ref={toggleButtonRef}
                    variant="outlined"
                    className={classes.menuButton}
                    endIcon={<KeyboardArrowDown />}
                    onClick={handleMenuOpen}>
                    {menuItems[selectedItem]?.name}
                  </Button>
                  <Menu
                    className={classes.menu}
                    anchorOrigin={{
                      vertical: 'center',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    sx={{
                      zIndex: 0,
                    }}
                    PaperProps={{
                      sx: {
                        borderRadius: '0px 4px 4px 0px',
                        boxShadow: 'none',
                        border: '1px solid #D9D9D9',
                        '& > .MuiList-root': {
                          paddingTop: `${
                            (toggleButtonRef?.current?.offsetHeight || 1) *
                              0.5 +
                            4
                          }px`,
                          '& > .Mui-selected': {
                            backgroundColor: '#F4F4F4',
                          },
                        },
                      },
                    }}
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}>
                    {menuItems.map((item) => {
                      const isSelected = item?.id === selectedItem;
                      return (
                        <MenuItem
                          sx={{
                            width: toggleButtonRef?.current?.offsetWidth,
                            backgroundColor: '',
                            typography: 'h5Sub',
                            fontWeight: 400,
                          }}
                          key={item?.id}
                          onClick={() => handleMenuItemSelect(item?.id)}
                          selected={isSelected}>
                          {item?.name}
                        </MenuItem>
                      );
                    })}
                  </Menu>
                </Box>
              )}
            </Grid>
            {!isSaveListVisible ? (
              <Grid
                item
                sx={{ display: 'flex', alignItems: 'center', marginLeft: 1 }}>
                <SavedIconButton selectedItem={selectedItem} />
              </Grid>
            ) : null}
            <Grid
              item
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: isSaveListVisible ? 'flex-start' : 'flex-end',
                marginLeft: isSaveListVisible ? null : 'auto',
              }}>
              {selectedItem === 0 && !isMediumScreen && !isSaveListVisible && (
                <Box
                  sx={{ display: 'flex', gap: 1 }}
                  aria-owns={open ? 'mouse-over-popover' : undefined}
                  aria-haspopup="true"
                  onMouseEnter={handlePopoverOpen}
                  onMouseLeave={handlePopoverClose}>
                  <SmileEmojiIcon />
                  <Typography sx={{ textDecoration: 'underline' }}>
                    Why trust us?
                  </Typography>

                  <Popover
                    id="mouse-over-popover"
                    sx={{
                      pointerEvents: 'none',
                    }}
                    open={Boolean(anchorPEl)}
                    anchorEl={anchorPEl}
                    onClose={handlePopoverClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    disableRestoreFocus>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 2,
                        width: '515px',
                        height: '108px',
                        backgroundImage: 'url(/images/blurbBackground.svg)',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                      }}>
                      <Typography variant="h4">
                        Your stay, and your trust, matter to us. Pilot partners
                        with major companies like Kayak, Booking and hostelworld
                        to bring you the best deals for you - not what makes
                        vendors the most money.
                      </Typography>
                    </Box>
                  </Popover>
                </Box>
              )}
              {isSaveListVisible && <CheckInCheckOutButton />}
            </Grid>
          </Grid>
        </div>
      </Grid>

      <Grid item xs={12}>
        <div className={classes.dynamicContainer}>
          {selectedItem === 0 ? <PlacesToStayView /> : <RecommendationsView />}
        </div>
      </Grid>
    </Grid>
  );
}

export default ExploreV1;
