import { useState, useMemo, useEffect, useRef } from 'react';
import {
  ExpandMoreRounded,
  ExpandLessRounded,
  CloseRounded,
} from '@mui/icons-material';
import {
  IconButton,
  Typography,
  Popper,
  Collapse,
  Divider,
  Button,
  useTheme,
  useMediaQuery,
  Modal,
  Stack,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { BasicButton, TertiaryButton } from '../../atoms/Button/index';
import recommendationCategories from '../../../assets/newRecommendationCategories.json';
import {
  AirConditionedIcon,
  ApartmentIcon,
  BnbIcon,
  BreakfastIcon,
  CancellationIcon,
  FrontDeskIcon,
  HostelIcon,
  HotTubIcon,
  HotelIcon,
  HouseIcon,
  ParkingIcon,
  PetFriendlyIcon,
  PoolIcon,
  WiFiIcon,
} from '../../atoms/Icon';
import actions from '../../../redux/actions';
import PriceRangeSlider from './PriceRangeSlider';
import { phTrackEvent, EVENTS } from '../../../analytics';

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  // Categories
  popper: {
    height: 'calc(100% - 90px)',
    zIndex: 1301,
    bottom: 0,
    marginTop: '8px',
    [breakpoints.down('sm')]: {
      paddingTop: 0,
      paddingBottom: 0,
      height: '100%',
    },
  },
  popperModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '32px 16px',
  },
  categoryContainer: {
    height: '100%',
    maxWidth: 530,
    [breakpoints.down('lg')]: {
      width: '100%',
      minWidth: '100%',
    },
    backgroundColor: '#FFF',
    boxShadow: '0px 0px 4px rgba(0,0,0,0.25)',
    borderRadius: '0px 8px 8px 0px',
    display: 'flex',
    flexDirection: 'column',
  },
  categoryList: {
    display: 'flex',
    flex: 1,
    paddingLeft: '12px',
    flexDirection: 'column',
  },
  bottomContainer: {
    display: 'flex',
    flex: 0,
    padding: '16px 24px',
    justifyContent: 'space-between',
    borderTop: '1px solid #DEDDDD',
  },
  topContainer: {
    flex: 1,
    display: 'flex',
    padding: 12,
    overflow: 'auto',
  },
  categoryItemContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  categoryHeader: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: 12,
    paddingTop: 16,
    paddingLeft: 24,
    alignItems: 'center',
    borderBottom: '1px solid #DEDDDD',
  },

  // searchbar
  searchBar: {
    width: '100%',
    backgroundColor: '#F4F4F4',
    borderRadius: 6,
    padding: 8,
    border: '1px solid transparent',
    '&:hover': {
      boxShadow: `0px 0px 0px 2px ${palette.primary.extraLight}`,
    },
  },
  searchbarFocused: {
    backgroundColor: '#FFF',
    boxShadow: `0px 0px 0px 2px ${palette.primary.extraLight}`,
    border: `1px solid ${palette.primary.light}`,
  },
  '@media (max-width: 600px)': {
    popper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: 0,
      paddingBottom: 0,
      marginTop: 10,
      marginLeft: 10,
      height: '90%',
      width: '95%',
    },
    categoryContainer: {
      width: '100%',
    },
    // Add more responsive styles as needed
  },
}));

function SubCategoryButton({
  active,
  buttonIcon,
  subCategoryId,
  subCategoryIdName,
  subCategoryIdType,
  handleClick,
  marginTop,
  marginRight,
}) {
  return (
    <BasicButton
      disableRipple
      startIcon={buttonIcon}
      sx={{
        marginTop: marginTop || '8px',
        marginRight: marginRight || '8px',
        backgroundColor: active ? 'primary.extraLight' : 'auto',
        color: active ? 'primary.main' : 'auto',
        borderColor: active ? 'primary.main' : 'auto',
        '&:hover': {
          backgroundColor: active ? 'primary.extraLight' : 'auto',
          color: active ? 'primary.main' : 'auto',
          borderColor: active ? 'primary.main' : 'auto',
        },
      }}
      id={`recommendations-filter-pill-${subCategoryId}`}
      className="recommendations-filter-pill"
      onClick={() =>
        handleClick({
          filterId: subCategoryId,
          action: active ? 'DELETE' : 'ADD',
        })
      }>
      {subCategoryIdName}{' '}
      {subCategoryIdType === 'guestRatings' ||
      (subCategoryIdType === 'starRating' && subCategoryIdName !== '5')
        ? '+'
        : ''}
    </BasicButton>
  );
}

function SubCategoryFilter({
  subCategoryId,
  handleClick = () => {},
  active = false,
  activeFilterIds = [],
  isHovered,
}) {
  const CATEGORY_ICON = {
    Wifi: active ? <WiFiIcon style={{ color: '#ED702E' }} /> : <WiFiIcon />,
    Breakfast: active ? (
      <BreakfastIcon style={{ color: '#ED702E' }} />
    ) : (
      <BreakfastIcon />
    ),
    Cancellation: active ? (
      <CancellationIcon style={{ color: '#ED702E' }} />
    ) : (
      <CancellationIcon />
    ),
    Parking: active ? (
      <ParkingIcon style={{ color: '#ED702E' }} />
    ) : (
      <ParkingIcon />
    ),
    Pool: active ? <PoolIcon style={{ color: '#ED702E' }} /> : <PoolIcon />,
    'Pet Friendly': active ? (
      <PetFriendlyIcon style={{ color: '#ED702E' }} />
    ) : (
      <PetFriendlyIcon />
    ),
    'Hot tub': active ? (
      <HotTubIcon style={{ color: '#ED702E' }} />
    ) : (
      <HotTubIcon />
    ),
    'Air-conditioned': active ? (
      <AirConditionedIcon style={{ color: '#ED702E' }} />
    ) : (
      <AirConditionedIcon />
    ),
    '24hrs front desk': active ? (
      <FrontDeskIcon style={{ color: '#ED702E' }} />
    ) : (
      <FrontDeskIcon />
    ),
    Hotel: active ? <HotelIcon style={{ color: '#ED702E' }} /> : <HotelIcon />,
    House: active ? <HouseIcon style={{ color: '#ED702E' }} /> : <HouseIcon />,
    Apartment: active ? (
      <ApartmentIcon style={{ color: '#ED702E' }} />
    ) : (
      <ApartmentIcon />
    ),
    Bnb: active ? <BnbIcon style={{ color: '#ED702E' }} /> : <BnbIcon />,
    Hostel: active ? (
      <HostelIcon style={{ color: '#ED702E' }} />
    ) : (
      <HostelIcon />
    ),
  };
  const [subCategoryIdName, subCategoryIdType] = subCategoryId.split('#');

  const buttonIcon =
    subCategoryIdName in CATEGORY_ICON
      ? CATEGORY_ICON[subCategoryIdName]
      : null;

  const priceFilterList =
    activeFilterIds?.filter((fid) => fid?.includes('Price')) || [];
  const priceRange =
    (priceFilterList?.length > 0 &&
      priceFilterList[0]?.split('#')[0]?.split(',')) ||
    [];

  if (subCategoryIdName === 'Price') {
    return (
      <PriceRangeSlider
        handleAction={handleClick}
        defaultValue={
          priceRange?.length > 0
            ? priceRange?.map((p) => parseInt(p, 10))
            : null
        }
      />
    );
  }
  return subCategoryIdType === 'guestRatings' ? (
    <Stack alignItems="center" marginTop="6px">
      {subCategoryIdType === 'guestRatings' && isHovered && (
        <Typography fontSize="9px" color="GrayText">
          {recommendationCategories.guestRatingDescription[subCategoryIdName]}
        </Typography>
      )}
      <SubCategoryButton
        marginTop="2px"
        marginRight="0px"
        handleClick={handleClick}
        active={active}
        buttonIcon={buttonIcon}
        subCategoryId={subCategoryId}
        subCategoryIdName={subCategoryIdName}
        subCategoryIdType={subCategoryIdType}
      />
    </Stack>
  ) : (
    <SubCategoryButton
      handleClick={handleClick}
      active={active}
      buttonIcon={buttonIcon}
      subCategoryId={subCategoryId}
      subCategoryIdName={subCategoryIdName}
    />
  );
}

function CategoryItem({
  categoryId,
  subCategories,
  handleFilterChange,
  activeFilterIds = [],
}) {
  const classes = useStyles();
  const [collapsed, setCollapsed] = useState(true);
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={classes.categoryItemContainer}>
      <div style={{ display: 'flex', width: '100%' }}>
        {collapsed ? (
          <ExpandLessRounded
            style={{ cursor: 'pointer' }}
            onClick={() => setCollapsed(false)}
          />
        ) : (
          <ExpandMoreRounded
            style={{ cursor: 'pointer' }}
            onClick={() => setCollapsed(true)}
          />
        )}

        <div>
          <Typography variant="h5Sub" marginLeft="8px" fontWeight={600}>
            {categoryId}
          </Typography>
        </div>
      </div>
      <Collapse in={collapsed}>
        <div className={classes.subCategoriesContainer}>
          {categoryId === 'Guest Rating' ? (
            <Stack direction="row" gap="6px">
              {subCategories?.map((subCategoryId) => (
                <SubCategoryFilter
                  isHovered={isHovered}
                  handleClick={handleFilterChange}
                  subCategoryId={subCategoryId}
                  active={activeFilterIds.includes(subCategoryId)}
                  activeFilterIds={activeFilterIds}
                />
              ))}
            </Stack>
          ) : (
            subCategories?.map((subCategoryId) => (
              <SubCategoryFilter
                isHovered={isHovered}
                handleClick={handleFilterChange}
                subCategoryId={subCategoryId}
                active={activeFilterIds.includes(subCategoryId)}
                activeFilterIds={activeFilterIds}
              />
            ))
          )}
        </div>
      </Collapse>
    </div>
  );
}

function BookingsFilter({
  // filterLabels,
  // setFilterLabels,
  filterOpen,
  setFilterOpen,
}) {
  const classes = useStyles();

  const dispatch = useDispatch();
  const { slug: tripId } = useParams();

  const { filterLabels: fLabels } = useSelector(
    (state) => state.Bookings[tripId]
  );

  const [currentFilterLabels, setCurrentFilterLabels] = useState([...fLabels]);
  const [categorySearchValue] = useState('');
  const filterRef = useRef(null);

  // TODO: Improve performance using react virtualized?
  const categoriesOrder = useMemo(
    () =>
      Object.fromEntries(
        Object.keys(recommendationCategories.bookingsFilter)
          ?.map((mainCat) => {
            const x = [
              mainCat,
              recommendationCategories.bookingsFilter[mainCat],
            ];
            return x;
          })
          ?.filter((l) => l[1]?.length > 0)
      ) || {},
    [categorySearchValue]
  );

  const handleFilterChange = ({ filterId, action }) => {
    let newFilterLabels = [...currentFilterLabels];
    if (action === 'RESET') newFilterLabels = [];
    else if (action === 'ADD') newFilterLabels.push(filterId);
    else if (action === 'UPDATE') {
      for (let i = 0; i < newFilterLabels.length; i += 1) {
        if (newFilterLabels[i].includes('Price')) {
          newFilterLabels.splice(i, 1);
          break;
        }
      }

      newFilterLabels.push(filterId);
    } else
      newFilterLabels = newFilterLabels.filter((label) => label !== filterId);
    setCurrentFilterLabels(newFilterLabels);
    phTrackEvent({
      event: EVENTS.EXPLORE_STAYS.FILTERS_APPLY_CLICK,
    });
  };

  useEffect(() => {
    setCurrentFilterLabels(fLabels);
  }, [fLabels]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setFilterOpen(false);
      }
    }

    // Add event listener when the filter is open
    if (filterOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    // Cleanup the event listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [filterOpen]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const SelectorWrapper = isMobile ? Modal : Popper;

  return (
    <SelectorWrapper
      ref={filterRef}
      open={Boolean(filterOpen)}
      id="categories-popper"
      anchorEl={filterOpen}
      className={isMobile ? classes.popperModal : classes.popper}
      disablePortal>
      <div
        className={classes.categoryContainer}
        style={
          !isMobile && !isDesktop
            ? {
                maxWidth:
                  document.getElementById('map-container')?.clientWidth || 530,
              }
            : {}
        }>
        <div className={classes.categoryHeader}>
          <IconButton
            sx={{ padding: '4px', marginLeft: 1 }}
            size="small"
            onClick={() => {
              setFilterOpen(null);
            }}>
            <CloseRounded />
          </IconButton>
        </div>
        <div className={classes.topContainer}>
          <div className={classes.categoryList}>
            {Object.keys(categoriesOrder || {})?.map((categoryId, idx) => (
              <div style={{ marginTop: idx === 0 ? 0 : 24 }}>
                <CategoryItem
                  handleFilterChange={handleFilterChange}
                  categoryId={categoryId}
                  activeFilterIds={currentFilterLabels}
                  subCategories={categoriesOrder[categoryId]}
                />
                <Divider sx={{ marginTop: 2 }} />
              </div>
            ))}
          </div>
        </div>
        <div className={classes.bottomContainer}>
          <TertiaryButton
            id="recommendations-clear-filter-button"
            onClick={() => handleFilterChange({ action: 'RESET' })}
            disableRipple>
            Clear filters
          </TertiaryButton>
          <Button
            disabled={
              [...fLabels].sort().join(',') ===
              [...currentFilterLabels].sort().join(',')
            }
            id="recommendations-show-results-button"
            onClick={() => {
              dispatch(
                actions.Bookings.setFilter({
                  filterItems: currentFilterLabels,
                  tripId,
                })
              );
              if (isMobile) setFilterOpen(null);
            }}>
            {`Apply filters ${
              currentFilterLabels?.length > 0
                ? `(${currentFilterLabels.length})`
                : ''
            }`}
          </Button>
        </div>
      </div>
    </SelectorWrapper>
  );
}

export default BookingsFilter;
