import { Typography, IconButton, Stack } from '@mui/material';
import { CloseRounded, PictureAsPdf } from '@mui/icons-material';
import React from 'react';

function Header({ onClose }) {
  return (
    <div style={{ display: 'flex' }}>
      <Stack width="100%" direction="row" alignItems="center" gap={1}>
        <PictureAsPdf />
        <Typography variant="h2">Export Trip Settings</Typography>
      </Stack>
      <div>
        <IconButton
          sx={{ padding: '4px', color: '#1D1D1D' }}
          size="small"
          onClick={onClose}>
          <CloseRounded />
        </IconButton>
      </div>
    </div>
  );
}

export default Header;
