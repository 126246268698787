import React, { useContext, useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Button,
  FormControlLabel,
  Snackbar,
  SnackbarContent,
  Switch,
  useMediaQuery,
} from '@mui/material';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { makeStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { firebaseAuth } from '../../provider/AuthProvider';
import {
  deleteUser,
  getUser,
  getUserSubscriptions,
  updateUser,
} from '../../redux/slices/Auth';
import { InputBasic } from '../atoms/Input';
import Header from '../molecules/Header';
import Feedback from '../molecules/Feedback';
import { DeleteModal } from '../molecules/Modal';
import PrivacySetting from '../organisms/PrivacySetting';
import AppLayout from '../templates/AppLayout';
import FLAGS from '../../featureFlags';
import './Settings.css';
import { EVENTS, phTrackEvent } from '../../analytics';
import classList from '../classList';
import { sendCustomMessage } from '../../intercom';
import UpdateSubcriptionsModal from '../molecules/Modal/UpdateSubscriptionsModal.js';
import actions from '../../redux/actions';
import CONFIG from '../config';
import getCfConnector from '../cfConnector';

const useStyles = makeStyles(({ breakpoints, palette }) => ({
  container: {
    height: '100%',
    [breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column-reverse',
    },
  },
  leftBorder: {
    borderLeft: '1px solid',
    borderColor: '#c4c4c4',
  },
  clearButton: {
    borderColor: '#ED702E',
    color: '#ED702E',
    textTransform: 'none',
    border: '1px solid #ED702E',
  },
  underlineButton: {
    background: 'none !important',
    border: 'none',
    color: '#AAAAAA',
    padding: '0 !important',
    textDecoration: 'underline',
    cursor: 'pointer',
    '&:hover': {
      color: '#AAAAAA',
    },
  },
  filledButton: {
    marginRight: '2%',
    textTransform: 'none',
    border: '1px solid transparent',
  },
  textField: {
    [`& fieldset`]: {
      borderRadius: '30px',
    },
  },
  emailDiv: {
    display: 'flex',
    padding: '0 5% 3% 5%',
    gap: '10px',
    fontSize: '18px',
  },
  timeFormatDiv: {
    padding: '0 5% 3% 5%',
  },
  formHeadings: {
    color: '#212121',
    fontSize: '23px',
    fontWeight: 600,
    [breakpoints.down('md')]: {
      margin: '20px 0',
    },
  },
  snackbar: {
    backgroundColor: '#43A047',
    textAlign: 'center',
    fontSize: '18px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  subscriptionsContainer: {
    padding: '0 5% 3% 5%',
  },
  subscriptionBox: {
    padding: '28px 28px 12px 28px',
    border: `1px solid ${palette.system.grey}`,
    borderRadius: 4,
  },
}));

function Settings() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user, handleSignout } = useContext(firebaseAuth);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const userObj = useSelector((state) => state.Auth.userData);
  const userData = userObj.user;
  const subscription = userObj?.subscription;

  const defaultSoundSetting = useFeatureFlagEnabled(FLAGS?.APP_SOUNDS);

  const [userFields, setUserFields] = useState({
    firstName: userData?.firstName || '',
    lastName: userData?.lastName || '',
    is24HourTime: userData?.is24HourTime,
    disableSounds:
      userData?.disableSounds === null
        ? defaultSoundSetting
        : userData?.disableSounds || false,
  });

  const [deleteOpen, setDeleteOpen] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);

  // for auto save toast
  const [toastTimeoutWait, setToastTimeoutWait] = useState(false);
  const [inputTimer, setInputTimer] = useState(null);
  const [initialRender, setInitialRender] = useState(true);

  useEffect(() => {
    if (userObj.status === 'IDLE') {
      dispatch(getUser({ id: user.uid }));
    }
  }, []);

  useEffect(() => {
    setUserFields({
      firstName: userData?.firstName || '',
      lastName: userData?.lastName || '',
      is24HourTime: userData?.is24HourTime,
      disableSounds:
        userData?.disableSounds === null
          ? defaultSoundSetting
          : userData?.disableSounds || false,
    });
  }, [userObj.status]);

  function handleSubmitUser() {
    dispatch(
      updateUser({
        variables: {
          id: user.uid,
          ...userFields,
        },
      })
    );
    setToastOpen(true);
  }

  function handleOnChange(e) {
    if (e.target.type === 'checkbox') {
      setUserFields({
        ...userFields,
        [e.target.id]: e.target.checked,
      });
    } else {
      setUserFields({
        ...userFields,
        [e.target.id]: e.target.value,
      });
    }
  }

  const handleDeleteUser = async () => {
    dispatch(
      deleteUser({
        variables: {
          id: user.uid,
        },
      })
    );
    phTrackEvent({
      event: EVENTS.SETTINGS.ACCOUNT_DELETE,
    });
    handleSignout(true);
  };

  const handleToastClose = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setToastOpen(false);
  };

  const openIntercomMessenger = () => {
    sendCustomMessage('');
  };

  const openUpdateSubscriptionModal = (action) =>
    dispatch(
      actions.View.setUpdateSubscription({
        action,
        subscriptionId: subscription?.subscriptionId,
      })
    );

  const handleManageSubscriptionClick = async () => {
    const { sessionUrl = null } = (
      await (
        await getCfConnector()
      ).post(`${CONFIG.paymentsAPI}/new-billing-session`, {
        userEmail: user.email,
      })
    ).data;
    if (sessionUrl) window.open(sessionUrl, '_blank');
  };

  useEffect(() => {
    if (!subscription)
      dispatch(getUserSubscriptions({ userEmail: user.email }));
  }, []);

  useEffect(() => {
    if (!initialRender) {
      if (toastTimeoutWait) {
        clearTimeout(inputTimer);
      }
      setToastTimeoutWait(true);

      setInputTimer(
        setTimeout(() => {
          setToastTimeoutWait(false);
          handleSubmitUser();
        }, 1000)
      );
    } else {
      setInitialRender(false);
    }
  }, [
    userFields.firstName,
    userFields.lastName,
    userFields.is24HourTime,
    userFields.disableSounds,
  ]);

  return (
    <AppLayout>
      <Helmet>
        <title>Settings | Pilot Trip Planner</title>
      </Helmet>
      <Header title="Settings" />
      <UpdateSubcriptionsModal />
      <Grid container justifyContent="center" className={classes.container}>
        <Grid item xs={12} sm={12} md={9} lg={9} xl={10}>
          {/* <Grid item sx={{ flex: 1 }}> */}
          <div className="settings-form">
            <Typography className={classes.formHeadings}>
              Contact information:
            </Typography>
            <form className="settings-fields" noValidate autoComplete="off">
              <InputBasic
                className="text-field"
                required
                id="firstName"
                label="First name"
                value={userFields.firstName}
                style={{
                  margin: '4% 5% 4% 0',
                }}
                variant="filled"
                onChange={handleOnChange}
              />
              <InputBasic
                className="text-field"
                required
                id="lastName"
                label="Last name"
                value={userFields.lastName}
                style={{ margin: '4% 0 4% 0', minWidth: '47.5%' }}
                variant="filled"
                onChange={handleOnChange}
              />
            </form>
          </div>
          <div className={classes.emailDiv}>
            <div style={{ fontWeight: '500' }}>Email:</div>
            <div style={{ color: '#8A8A8A' }}>{userData?.email || ''}</div>
          </div>
          <div className={classes.timeFormatDiv}>
            <Typography
              className={classes.formHeadings}
              style={{ marginBottom: '15px' }}>
              Preferences:
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  id="is24HourTime"
                  checked={userFields.is24HourTime}
                  onChange={handleOnChange}
                />
              }
              label={
                <Typography style={{ fontSize: '18px' }}>
                  24 Hour time
                </Typography>
              }
            />
            <FormControlLabel
              style={{
                width: '100%',
                marginTop: 12,
              }}
              control={
                <Switch
                  id="audioToggle"
                  checked={!userFields?.disableSounds}
                  onChange={(e) =>
                    setUserFields({
                      ...userFields,
                      disableSounds: !e.target.checked,
                    })
                  }
                />
              }
              label={
                <Typography style={{ fontSize: '18px' }}>
                  Enable audio
                </Typography>
              }
            />
          </div>

          <div className="settings-privacy">
            <Typography className={classes.formHeadings} sx={{}}>
              Privacy:
            </Typography>
            <PrivacySetting
              title="Who can see your trips by default"
              description="Here's who can see your trips"
              currentPrivacy="Invited Friends Only"
            />
          </div>
          {subscription && (
            <div
              id="settings-subscriptions"
              className={classes.subscriptionsContainer}>
              <Typography
                className={classes.formHeadings}
                sx={{ marginTop: 4 }}>
                Subscriptions:
              </Typography>
              <div className={classes.subscriptionBox} style={{ marginTop: 8 }}>
                <Typography variant="h4">
                  You're currently contributing:
                </Typography>
                <Typography
                  variant="h2"
                  color="primary.main"
                  marginTop="12px"
                  marginBottom="8px">
                  ${subscription?.amount}/month
                </Typography>
                <Typography
                  variant="caption"
                  fontSize="9px"
                  padding="4px 8px"
                  color="success.main"
                  sx={{
                    borderRadius: '4px',
                    borderColor: 'success.main',
                    border: '1px solid',
                  }}>
                  Automatic renewal
                </Typography>
                <div
                  style={{
                    width: '100%',
                    justifyContent: 'flex-end',
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  <Typography
                    variant="caption"
                    color="text.light"
                    fontWeight={500}
                    onClick={() => openUpdateSubscriptionModal(0)}
                    sx={{
                      fontSize: 14,
                      cursor: 'pointer',
                      textDecoration: 'underline',
                      marginRight: 2,
                    }}>
                    {isMobile ? 'Edit contribution' : 'Edit my contribution'}
                  </Typography>
                  <Button
                    onClick={handleManageSubscriptionClick}
                    sx={{
                      backgroundColor: 'system.white',
                      padding: '4px 8px',
                      fontWeight: 400,
                      borderColor: 'error.main',
                      color: 'error.main',
                      border: '1px solid',
                      '&:hover': {
                        backgroundColor: 'system.white',
                        borderColor: 'error.main',
                        color: 'error.main',
                        border: '1px solid',
                      },
                    }}>
                    Manage subscription
                  </Button>
                </div>
              </div>
              <Typography
                color="warning.main"
                variant="caption"
                component="p"
                sx={{ mt: 2 }}>
                Need help?{' '}
                <span
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={openIntercomMessenger}>
                  {' '}
                  Contact our support{' '}
                </span>
              </Typography>
            </div>
          )}
          <div className="settings-changes">
            <Button
              className={`${classes.underlineButton} ${classList.settingsAccountDeleteBtn}`}
              onClick={() => setDeleteOpen(true)}
              sx={{ mb: '70px' }}>
              Delete Account
            </Button>
            <DeleteModal
              open={deleteOpen}
              onClose={() => setDeleteOpen(false)}
              executeFunc={handleDeleteUser}
              emoji="😮"
              alertText="Oh no!"
              descriptionText="You’re about to delete your account and all of your trips forever. Are you sure?"
              confirmText="Yes, delete it!"
              cancelText="No, keep it."
            />
          </div>
          <Snackbar
            open={toastOpen}
            autoHideDuration={2500}
            onClose={handleToastClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            sx={{
              height: '80px',
            }}>
            <SnackbarContent
              message="Settings updated"
              className={classes.snackbar}
            />
          </Snackbar>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3} xl={2}>
          <Feedback />
        </Grid>
      </Grid>
    </AppLayout>
  );
}

export default Settings;
