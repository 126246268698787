import React, { useState } from 'react';
import { useFeatureFlagPayload } from 'posthog-js/react';
import {
  Typography,
  Button,
  Box,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
// import { AddRounded } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { computeInclusions } from './utils';
import { EVENTS, phTrackEvent } from '../../../../analytics';
import TransitionLayout from '../../../templates/TransitionLayout';

const useStyles = makeStyles(({ breakpoints }) => ({
  inclusionList: {
    display: 'flex',
    flexWrap: 'wrap',
    rowGap: 4,
    columnGap: 32,
    marginTop: 4,
  },
  inclusionsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  dealInfoContainer: {
    display: 'flex',
    marginLeft: 'auto',
    alignItems: 'center',
    [breakpoints.down('lg')]: {
      marginTop: 16,
      width: '100%',
    },
    [breakpoints.down('sm')]: {
      justifyContent: 'space-between',
    },
  },
}));

/*
  Component that renders one deal, along with inclusions and prices under a room type.
*/
function DealsListItem({
  providerIndex,
  totalRate,
  bookUri,
  inclusions,
  hasFreeCancellation,
  canPayLater,
  numberOfNights,
}) {
  const staysDetailsPage = useFeatureFlagPayload('stays-details-page-ab');
  const classes = useStyles();
  const { slug: hotelKey } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTab = useMediaQuery(theme.breakpoints.down('lg'));
  const hotelDetails = useSelector(
    (state) => state.Bookings?.hotelDetailsPage?.data[hotelKey]
  );

  const currencyFormatter = new Intl.NumberFormat(undefined, {
    currency: hotelDetails?.currencyCode || 'USD',
    style: 'currency',
  });

  const inclusionList = computeInclusions({
    inclusions,
    canPayLater,
    hasFreeCancellation,
    features: hotelDetails?.features,
  });

  const provider =
    hotelDetails?.providers && hotelDetails?.providers[providerIndex];

  const [showTransition, setShowTransition] = useState(false);

  const handleButtonClick = () => {
    setShowTransition(true);
    // Track the event
    phTrackEvent({
      event: EVENTS.EXPLORE_STAY_DETAIL.ROOM_DEAL_BOOK_START,
    });
  };

  if (showTransition) {
    return (
      <TransitionLayout
        providerDetails={provider}
        targetUri={bookUri}
        setShowTransition={setShowTransition}
      />
    );
  }

  return (
    <div
      style={{
        padding: 24,
        display: 'flex',
        flexDirection: isTab ? 'column' : 'row',
      }}>
      <div className={classes.inclusionsContainer}>
        <Typography
          variant="h5"
          color="text.light"
          fontSize="12px"
          width="100%">
          What&apos;s included:
        </Typography>
        <div className={classes.inclusionList}>
          {inclusionList?.map((inclusionCopy, idx) => (
            <Typography key={idx} variant="h5" color="success.main">
              {inclusionCopy}
            </Typography>
          ))}
        </div>
      </div>
      <div className={classes.dealInfoContainer}>
        <Box
          component="img"
          alt="hotel-provider-logo"
          src={provider?.logo}
          sx={{ width: '112px' }}
        />
        <div style={{ marginLeft: isMobile ? 0 : '32px' }}>
          <Typography noWrap variant="h5" color="text.primary">
            <b>
              {currencyFormatter
                .format(totalRate / numberOfNights || 0)
                ?.replace(/\.\d+/, '')}
            </b>
            &nbsp;/night
          </Typography>
          <Typography
            noWrap
            variant="h5"
            color="text.secondary"
            fontSize="12px">
            {`Total: ${currencyFormatter
              .format(totalRate)
              ?.replace(/\.\d+/, '')}`}
          </Typography>
        </div>
        <Button
          variant="a"
          onClick={handleButtonClick}
          sx={{
            padding: '4px 12px',
            ml: isMobile ? 0 : isTab ? 'auto' : '20px',
            whiteSpace: 'nowrap',
          }}>
          {/* <AddRounded sx={{ color: '#FFF', height: 16, width: 16 }} /> */}
          {staysDetailsPage}
        </Button>
      </div>
    </div>
  );
}

export default DealsListItem;
