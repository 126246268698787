import { createSlice } from '@reduxjs/toolkit';
import QUERY from '../../graphql/queries';
import getAsyncThunk, { reorderList } from '../helpers';
import {
  createActivity,
  deleteActivity,
  createNewActivityInLocation,
} from './Activity';
import {
  clearMapPinsFromSection,
  createSection,
  handleDndActivities,
} from './sharedThunks';
import { trackEvents, Events } from '../../intercom';

const initialState = {
  sections: {},
};

export const updateSection = getAsyncThunk(
  'SECTION/updateSection',
  QUERY.UPDATE_THINGS_TODO
);

export const deleteSection = getAsyncThunk(
  'SECTION/deleteSection',
  QUERY.DELETE_THINGS_TODO,
  clearMapPinsFromSection,
  ({ variables, tripId }) => ({ sectionId: variables.id, tripId })
);

const SectionSlice = createSlice({
  name: 'SECTION',
  initialState,
  reducers: {
    initializeSections: (state, action) => {
      state.sections = {
        ...state.sections,
        ...action.payload,
      };
    },
    addLocalSection: (state, { payload: { localId, section } }) => {
      state.sections = {
        ...state.sections,
        [localId]: {
          ...section,
        },
      };
    },
    addLocalActivityToSection: (
      state,
      { payload: { sectionId, activityId, index } }
    ) => {
      const newActivities = [
        ...(state.sections[sectionId]?.todos?.slice(0, index + 1) || []),
        activityId,
        ...(state.sections[sectionId]?.todos?.slice(index + 1) || []),
      ];
      state.sections = {
        ...state.sections,
        [sectionId]: {
          ...state.sections[sectionId],
          todos: newActivities,
        },
      };
    },
  },
  extraReducers: {
    [createSection.fulfilled]: (
      state,
      {
        payload: { createThingsToDo: section },
        meta: {
          arg: { variables, shouldAppendActivity, localActivity },
        },
      }
    ) => {
      state.sections = {
        ...state.sections,
        [section?.id]: {
          ...variables,
          ...section,
          ...(shouldAppendActivity ? { todos: [localActivity.id] } : {}),
        },
      };
      trackEvents(Events.HeadingAdded);
    },
    [updateSection.pending]: (
      state,
      {
        meta: {
          arg: { variables: section },
        },
      }
    ) => {
      state.sections = {
        ...state.sections,
        [section?.id]: {
          ...state.sections[section?.id],
          ...section,
        },
      };
    },
    [updateSection.fulfilled]: (
      state,
      {
        payload: {
          updateThingsToDo: { version },
        },
        meta: {
          arg: { variables: section },
        },
      }
    ) => {
      if (version) {
        state.sections = {
          ...state.sections,
          [section?.id]: {
            ...state.sections[section?.id],
            version,
          },
        };
      }
    },
    [deleteSection.fulfilled]: (
      state,
      {
        meta: {
          arg: { variables: section },
        },
      }
    ) => {
      const newSections = { ...state.sections };
      delete newSections[section.id];
      state.sections = { ...newSections };
    },
    [createActivity.fulfilled]: (
      state,
      {
        payload: { createTodo: activity },
        meta: {
          arg: { sectionId, index, shouldAppendActivity, localActivity },
        },
      }
    ) => {
      const newActivities = [...(state.sections[sectionId]?.todos || [])];
      newActivities.splice(index, 1, activity?.id);
      if (shouldAppendActivity) {
        newActivities.splice(index + 1, 0, localActivity?.id);
      }
      state.sections = {
        ...state.sections,
        [sectionId]: {
          ...(state.sections[sectionId] || {}),
          todos: newActivities,
        },
      };
    },
    [createNewActivityInLocation.fulfilled]: (
      state,
      {
        payload: { section },
        meta: {
          arg: { shouldAppendActivity, localActivity },
        },
      }
    ) => {
      state.sections = {
        ...state.sections,
        [section?.id]: {
          ...section,
          ...(shouldAppendActivity
            ? {
                todos: [
                  ...(state.sections[section?.id]?.todos || []),
                  localActivity?.id,
                ],
              }
            : {}),
        },
      };
    },
    [deleteActivity.pending]: (
      state,
      {
        meta: {
          arg: { variables: activity, sectionId },
        },
      }
    ) => {
      const updatedTodos =
        state.sections[sectionId]?.todos?.filter((id) => id !== activity?.id) ||
        [];
      state.sections = {
        ...state.sections,
        [sectionId]: {
          ...(state.sections[sectionId] || {}),
          todos: updatedTodos,
        },
      };
    },
    [handleDndActivities.pending]: (
      state,
      {
        meta: {
          arg: { source, destination },
        },
      }
    ) => {
      const sourceActivities = state.sections[source.droppableId]?.todos;
      const destinationActivities =
        state.sections[destination.droppableId]?.todos;
      const { sourceList, destinationList, isSameList } = reorderList(
        sourceActivities,
        destinationActivities,
        source,
        destination
      );
      state.sections[source.droppableId].todos = sourceList;
      if (!isSameList)
        state.sections[destination.droppableId].todos = destinationList;
    },
  },
});

export const SectionActions = SectionSlice.actions;
export const SectionReducer = SectionSlice.reducer;
