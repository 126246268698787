import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import SignupForm from '../../organisms/SignupForm';
import AuthLayout from '../../templates/AuthLayout';
import { DeleteModal } from '../../molecules/Modal';

function Signup() {
  const location = useLocation();
  const [deleteConfirmation, setDeleteConfirmation] = useState(
    location?.state?.deleteConfirmationPopup || false
  );

  return (
    <AuthLayout>
      <SignupForm />
      <DeleteModal
        open={deleteConfirmation}
        onClose={() => setDeleteConfirmation(false)}
        executeFunc={() => setDeleteConfirmation(false)}
        emoji="😢"
        alertText="It’s done."
        descriptionText="Your account has been deleted. We miss you dearly, but the good news is you can always sign up with us again!"
        confirmText="I’ll be back!"
      />
    </AuthLayout>
  );
}

export default Signup;
