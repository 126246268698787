import MapboxMap, { Marker } from 'react-map-gl';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
// eslint-disable-next-line
import mapboxgl from '!mapbox-gl';
import config from '../../../config';
import { DefaultMapMarker } from '../../../atoms/Icon';

function LocationSection() {
  const { slug: hotelKey } = useParams();
  const hotelDetails = useSelector(
    (state) => state.Bookings?.hotelDetailsPage?.data[hotelKey]
  );

  return (
    <div style={{ display: 'flex', width: '100%' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: 60,
          marginRight: 40,
        }}>
        <Typography variant="h2">Check out the Location:</Typography>
        <Typography variant="h4" sx={{ mt: 2 }}>
          {hotelDetails?.address} <br /> {hotelDetails?.place?.fullName}
        </Typography>
      </div>
      <div
        style={{
          display: 'flex',
          width: 680,
          height: 360,
          borderRadius: 8,
          overflow: 'hidden',
        }}>
        <MapboxMap
          reuseMaps
          initialViewState={{
            latitude: hotelDetails?.latitude,
            longitude: hotelDetails?.longitude,
            zoom: 13,
          }}
          width="680px"
          height="100%"
          mapStyle="mapbox://styles/mapbox/streets-v12"
          mapboxAccessToken={config.mapboxAccessToken}
          mapLib={mapboxgl}>
          <Marker
            longitude={hotelDetails?.longitude}
            latitude={hotelDetails?.latitude}
            anchor="bottom"
            offset={[0, 6]}>
            <DefaultMapMarker className="customMarker" fill="#ED702E" />
          </Marker>
        </MapboxMap>
      </div>
    </div>
  );
}

export default LocationSection;
