import { Typography, Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import actions from '../../../../redux/actions';

function ConfirmCancellation() {
  const dispatch = useDispatch();

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h2" fontSize="20px">
        Turn off automatic renewal
      </Typography>
      <Typography variant="caption" mt="8px">
        Hey there! Before you hit that switch to turn off automatic renewal,
        consider editing your contribution instead!
      </Typography>
      <div
        style={{
          width: '100%',
          justifyContent: 'space-between',
          display: 'flex',
          alignItems: 'center',
          marginTop: 16,
        }}>
        <Typography
          variant="caption"
          color="text.light"
          fontWeight={500}
          onClick={() =>
            dispatch(actions.View.setUpdateSubscription({ action: 0 }))
          }
          sx={{
            fontSize: 14,
            cursor: 'pointer',
            textDecoration: 'underline',
            marginRight: 2,
          }}>
          Edit contribution
        </Typography>
        <Button
          sx={{
            backgroundColor: 'error.main',
            padding: '4px 8px',
            fontWeight: 400,
          }}>
          Turn off automatic renewal
        </Button>
      </div>
    </div>
  );
}

export default ConfirmCancellation;
