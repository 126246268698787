import { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '38px',
    borderRadius: '4px',
    marginBottom: '8px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  font: {
    fontSize: '16px',
    color: '#222222',
    marginLeft: '8px',
  },
}));

function NoteIndex({ title, activated, activateNote }) {
  const classes = useStyles();
  const [noteTitle, setNoteTitle] = useState(title);
  const [state, setState] = useState(activated ? 'active' : 'default');

  useEffect(() => {
    if (activated) setState('active');
    else setState('default');
  }, [activated]);

  useEffect(() => {
    setNoteTitle(title);
  }, [title]);

  return (
    <Box
      className={classes.container}
      style={{
        backgroundColor: state === 'default' ? 'transparent' : '#F4F4F4',
      }}
      onMouseEnter={() => {
        if (state === 'default') setState('hover');
      }}
      onMouseLeave={() => {
        if (state === 'hover') setState('default');
      }}
      onClick={() => {
        setState('active');
        activateNote();
      }}>
      <Typography className={classes.font} noWrap>
        {noteTitle || 'New Note'}
      </Typography>
    </Box>
  );
}

export default NoteIndex;
