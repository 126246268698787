/**
 * Slice to control the view state of the app for modals/new headings etc
 */

import { createSlice } from '@reduxjs/toolkit';
import { getUser } from './Auth';
import graphqlClient from '../../graphql';
import QUERY from '../../graphql/queries';
import { EVENTS, phTrackEvent } from '../../analytics';

const initialState = {
  userId: null,
  poiDetails: {
    isInView: false,
    payload: {},
  },
  bookings: {
    isInView: false,
    payload: {},
  },
  isExploreOpen: false,
  activeExploreTab: 0,
  contribute: {
    action: -1,
    contributeStatus: false,
  },
  updateSubscription: {
    action: -1,
    subscriptionId: null,
  },
  highlightedSection: null,
  highlightedHeading: null,
  activeMapPin: null,
  exploreCitySearch: '',
};

const ViewSlice = createSlice({
  name: 'VIEW',
  initialState,
  reducers: {
    setPoiView: (
      state,
      { payload: { isInView = false, poi = {}, cardIndex } }
    ) => {
      state.poiDetails = {
        isInView,
        payload: isInView ? poi : {},
        cardIndex: isInView ? cardIndex : null,
      };
    },
    setBookingsView: (
      state,
      { payload: { isInView = false, bookingsProps = {} } }
    ) => {
      state.bookings.isInView = isInView;
      state.bookings.payload = bookingsProps;
    },
    setExploreView: (state, { payload: isInView }) => {
      state.isExploreOpen = isInView;
    },
    setActiveExploreTab: (state, { payload: activeExploreTab }) => {
      state.activeExploreTab = activeExploreTab;
    },
    setHighlightedSection: (state, { payload: { section = null } }) => {
      state.highlightedSection = section;
    },
    setHighlightedHeading: (state, { payload: { section = null } }) => {
      state.highlightedHeading = section;
    },
    setActiveMapPin: (state, { payload: { mapPin = null } }) => {
      state.activeMapPin = mapPin;
    },
    setContribute: (state, { payload: { cancelled = false, ...payload } }) => {
      /* TRACKING START */
      if (payload?.action === 0 && cancelled) {
        phTrackEvent({
          event: EVENTS.PWYW.PAYMENT_SCREEN_BACK_BTN_CLICK,
          meta: {
            source: payload.source,
          },
          trackOnIntercom: true,
        });
      } else if (payload?.source && payload?.action === 0) {
        phTrackEvent({
          event: EVENTS.PWYW.CONTRIBUTE_OPEN,
          meta: {
            source: payload.source,
          },
          trackOnIntercom: true,
        });
      } else if (
        payload?.action === 1 &&
        payload?.price &&
        payload?.paymentMode
      ) {
        phTrackEvent({
          event: EVENTS.PWYW.PAYMENT_START,
          meta: {
            price: payload.price,
            paymentMode: payload.paymentMode,
            source: payload.source,
          },
          trackOnIntercom: true,
        });
      } else if (payload?.action === 2) {
        phTrackEvent({
          event: EVENTS.PWYW.VIEW_THANK_YOU_MODAL,
          trackOnIntercom: true,
        });
      }
      /* TRACKING END */

      state.contribute = {
        source: state.contribute?.source,
        ...payload,
      };

      if (payload?.action === -1) {
        graphqlClient.mutate({
          mutation: QUERY.UPDATE_USER,
          variables: {
            id: state.userId,
            disableTravelStats: true,
          },
        });
      } else if (payload?.action === 2) {
        state.contribute.contributeStatus = 'SUCCESS';
      }
    },
    setUpdateSubscription: (state, { payload }) => {
      state.updateSubscription = payload;
    },
    setExploreCitySearch: (state, { payload }) => {
      state.exploreCitySearch = payload;
    },
  },
  extraReducers: {
    [getUser.fulfilled]: (state, { payload }) => {
      state.userId = payload?.id;
    },
  },
});

export const ViewActions = ViewSlice.actions;
export const ViewReducer = ViewSlice.reducer;
