import { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

function TripSelectorStays({
  setIsTripSelectorOpen,
  handleLocationUpdate,
  defaultLocationId,
  locationsList,
}) {
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [selectedLocation, setSelectedLocation] = useState(null);

  const handleTripCloseButtonClick = (e) => {
    e.stopPropagation();
    setIsTripSelectorOpen(false);
  };

  const handleCheckboxChange = (location) => {
    if (selectedLocation && selectedLocation.locationId === location.id) {
      // Deselect if the same location is clicked again
      setSelectedLocation(null);
    } else {
      // Select the clicked location
      setSelectedLocation({
        locationId: location.id,
        title: location.title,
        hotels: location?.hotels || [],
      });
    }
  };

  const handleAddToTripClick = () => {
    if (selectedLocation) {
      handleLocationUpdate(selectedLocation);
      setIsTripSelectorOpen(false);
    }
  };

  useEffect(() => {
    const defaultLocationList = locationsList?.filter(
      (loc) => loc?.id === defaultLocationId
    );
    if (defaultLocationList?.length > 0) {
      const location = defaultLocationList[0];
      setSelectedLocation({
        locationId: location.id,
        title: location.title,
        hotels: location?.hotels || [],
      });
    }
  }, [defaultLocationId]);

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: 2,
        margin: isMediumScreen ? '12px 0px 12px 0px' : null,
        minHeight: 260,
      }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 400,
          }}>
          SELECT LOCATION
        </Typography>
        <IconButton onClick={handleTripCloseButtonClick}>
          <CloseIcon fontSize="small" sx={{ color: '#000000' }} />
        </IconButton>
      </Box>
      <Divider />
      <Box pt={2} pb={2}>
        <FormGroup
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'flex-start',
            height: '180px',
            overflowY: 'auto',
          }}>
          {locationsList?.map(({ id, title, hotels = [] }) => (
            <Box sx={{ width: '90%' }} key={id}>
              <FormControlLabel
                sx={{ fontSize: 14, fontWeight: 400 }}
                control={
                  <Checkbox
                    checked={selectedLocation?.locationId === id}
                    onChange={() => handleCheckboxChange({ id, title, hotels })}
                    sx={{ color: '#D9D9D9' }}
                  />
                }
                label={title}
              />
              <Divider />
            </Box>
          ))}
        </FormGroup>
      </Box>
      <Button
        disabled={!selectedLocation && !defaultLocationId}
        startIcon={<AddIcon />}
        fullWidth
        pt={2}
        onClick={handleAddToTripClick}>
        Add
      </Button>
    </Card>
  );
}

export default TripSelectorStays;
