import { createSlice } from '@reduxjs/toolkit';
import { getCompleteTrip, updateTrip } from './Trips';
import QUERY from '../../graphql/queries';
import getAsyncThunk from '../helpers';
import { updateAccommodation, deleteAccommodation } from './Accommodation';
import { updateActivity, deleteActivity } from './Activity';
import { updateFlight, deleteTransportation } from './Transportation';
import { trackEvents, Events } from '../../intercom';

const initialState = {
  files: {},
  fileRelations: {},
  openFile: null,
  multipleFiles: [],
};

export const createFile = getAsyncThunk('FILES/createFile', QUERY.CREATE_FILE);

export const updateFile = getAsyncThunk('FILES/updateFile', QUERY.UPDATE_FILE);

export const deleteFile = getAsyncThunk('FILES/deleteFile', QUERY.DELETE_FILE);

export const attachFiletoItems = getAsyncThunk(
  'FILES/attachFiletoItems',
  QUERY.ATTACH_FILE_TO_ITEMS
);

const FilesSlice = createSlice({
  name: 'FILES',
  initialState,
  reducers: {
    setFileRelations: (state, { payload }) => {
      state.fileRelations = { ...state.fileRelations, ...payload };
    },
    setFilePreview: (state, { payload: file }) => {
      state.openFile = file;
    },
    setMultipleFilesPreview: (state, { payload: attachedFiles }) => {
      state.multipleFiles = [...attachedFiles];
      if (attachedFiles?.length > 0) {
        state.openFile = {
          extension: state.files[attachedFiles[0]].url
            .split('?')[0]
            ?.split('.')
            ?.pop(),
          ...state.files[attachedFiles[0]],
        };
      }
    },
  },
  extraReducers: {
    [getCompleteTrip.fulfilled]: (
      state,
      {
        payload: {
          tripFiles,
          trip: { id: tripId, importedFiles },
          allFilesRelations,
        },
      }
    ) => {
      tripFiles?.forEach((file) => {
        state.files[file?.id] = { ...file, tripId };
      });
      importedFiles?.forEach((file) => {
        state.files[file?.id] = { ...file, tripId };
      });
      state.fileRelations = {
        ...state.fileRelations,
        [tripId]: allFilesRelations,
      };
    },
    [createFile.fulfilled]: (
      state,
      {
        meta: {
          arg: { tripId, attachedToText, attachedToID, attachedToType },
        },
        payload: { createFile: file },
      }
    ) => {
      state.files[file.id] = { ...file, tripId, attachedToText };
      state.fileRelations[tripId] = [
        ...state.fileRelations[tripId],
        {
          attachedToType,
          attachedToId: attachedToID,
          fileId: file.id,
        },
      ];
    },
    [updateFile.fulfilled]: (
      state,
      {
        meta: {
          arg: { variables },
        },
      }
    ) => {
      state.files[variables.id] = {
        ...state.files[variables.id],
        ...variables,
      };
    },

    // creating and deleting mapPins in real time, this setup to reduce repetitive code.
    ...([
      deleteActivity.fulfilled,
      deleteAccommodation.fulfilled,
      deleteTransportation.fulfilled,
    ].reduce(
      (deleteFileReducers, action) => ({
        ...deleteFileReducers,
        [action]: (
          state,
          {
            meta: {
              arg: {
                variables: { id, tripId, filesToBeAdded },
              },
            },
          }
        ) => {
          state.fileRelations[tripId] = state.fileRelations[tripId]?.filter(
            (relation) => relation.attachedToId !== id
          );
          if (filesToBeAdded?.length > 0) {
            state.fileRelations[tripId] = [
              ...state.fileRelations[tripId],
              ...filesToBeAdded.map((file) => ({
                attachedToType: 'Trip',
                attachedToId: tripId,
                fileId: file,
              })),
            ];
          }
        },
      }),
      {}
    ) || {}),

    ...([
      updateActivity.fulfilled,
      updateAccommodation.fulfilled,
      updateFlight.fulfilled,
    ].reduce(
      (updateFileReducers, action) => ({
        ...updateFileReducers,
        [action]: (
          state,
          {
            meta: {
              arg: {
                variables: { title, flightNumber, name, id, files },
                attachedToType,
                tripId,
              },
            },
          }
        ) => {
          // check for updated titles to update file tab text
          const updatedTitle = title || flightNumber || name;
          if (updatedTitle) {
            const newFiles = Object.values(state.files).filter(
              (file) => file.attachedToID === id
            );
            newFiles?.forEach((file) => {
              state.files[file?.id] = {
                ...state.files[file?.id],
                attachedToText: updatedTitle,
              };
            });
          }

          if (files && tripId && attachedToType) {
            state.fileRelations[tripId] = [
              ...state.fileRelations[tripId].filter(
                (relation) => relation.attachedToId !== id
              ),
              ...files.map((file) => ({
                attachedToType,
                attachedToId: id,
                fileId: file,
              })),
            ];
          }
        },
      }),
      {}
    ) || {}),

    [updateTrip.fulfilled]: (
      state,
      {
        meta: {
          arg: {
            variables: { id },
            addFiles,
          },
        },
      }
    ) => {
      if (addFiles) {
        state.fileRelations[id] = [
          ...state.fileRelations[id],
          ...addFiles.map((file) => ({
            attachedToType: 'Trip',
            attachedToId: id,
            fileId: file,
          })),
        ];
      }
    },

    [deleteFile.fulfilled]: (
      state,
      {
        meta: {
          arg: {
            variables: { id, fileRelation },
            tripId,
          },
        },
      }
    ) => {
      delete state.files[id];
      state.fileRelations[tripId] = state.fileRelations[tripId]?.filter(
        (relation) => {
          const tempArr = fileRelation?.filter((rel) => {
            return (
              rel?.fileId === relation?.fileId &&
              rel?.attachedToId === relation?.attachedToId &&
              rel?.attachedToType === relation?.attachedToType
            );
          });
          return tempArr?.length === 0;
        }
      );
    },

    [attachFiletoItems.fulfilled]: (
      state,
      {
        meta: {
          arg: {
            variables: { fileId, tripId, items, itemsToRemove, addToTrip },
          },
        },
      }
    ) => {
      trackEvents(Events.FileAddedAttached);
      if (items?.length > 0) {
        state.fileRelations[tripId] = [
          ...state.fileRelations[tripId],
          ...items.map((item) => ({
            attachedToType: item.type,
            attachedToId: item.id,
            fileId,
          })),
        ];
      }
      if (itemsToRemove?.length > 0) {
        state.fileRelations[tripId] = state.fileRelations[tripId]?.filter(
          (relation) =>
            !(
              relation.fileId === fileId &&
              itemsToRemove
                ?.map((item) => item.id)
                .includes(relation.attachedToId)
            )
        );
      }
      if (addToTrip) {
        state.fileRelations[tripId] = [
          ...state.fileRelations[tripId],
          {
            attachedToType: 'Trip',
            attachedToId: tripId,
            fileId,
          },
        ];
      }
    },
  },
});

export const FilesActions = FilesSlice.actions;
export const FilesReducer = FilesSlice.reducer;
