import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    width: '100%',
    height: 256,
    borderRadius: 4,
    overflow: 'hidden',
    cursor: 'pointer',
    '&:hover > $imageBackground': {
      transform: 'scale(1.2)',
    },
  },
  content: {
    position: 'absolute',
    bottom: 0,
    color: '#ffffff',
    padding: 18,
  },
  imageBackground: {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    transition: 'all 0.2s ease-in',
  },
}));

function CityCard({
  image,
  title,
  description,
  metaDescription,
  handleClick = () => {},
  styleProps = {},
  className = '',
  cardProps = {},
}) {
  const classes = useStyles();

  // lazy load images
  const [imgSourceLoaded, setImageSourceLoaded] = useState(false);
  useEffect(() => {
    const img = new Image();
    // TODO: Move this into the api response parser inside redux thunk
    img.src = `${image}?fm=jpg&w=3000&fit=max`;
    img.onload = () => setImageSourceLoaded(`${image}?fm=jpg&w=3000&fit=max`);
  }, [image]);

  return (
    <div
      className={`${classes.root} ${className}`}
      onClick={handleClick}
      style={{
        ...styleProps,
      }}
      {...cardProps}>
      <div
        className={classes.imageBackground}
        style={{
          backgroundImage: `linear-gradient(0deg, rgba(44, 43, 43, 0.5), rgba(44, 43, 43, 0.5)),url('${
            imgSourceLoaded || ''
          }')`,
        }}
      />
      <div className={classes.content}>
        {metaDescription && (
          <Typography variant="h5">{metaDescription}</Typography>
        )}
        <Typography variant="h3Sub">{title}</Typography>
        {description && <Typography variant="h5">{description}</Typography>}
      </div>
    </div>
  );
}

export default CityCard;
