import { useRef, useState, useEffect } from 'react';
import {
  Modal,
  Box,
  Typography,
  IconButton,
  Fade,
  CircularProgress,
} from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../../../redux/actions';
import { getFlightAttributes, getHotelAttributes } from './attributes';

function BookingsModal() {
  const widgetContainerRef = useRef();
  const [isLoaded, setLoaded] = useState(false);
  const dispatch = useDispatch();
  const { isInView: bookingOpen = false, payload: bookingProps = {} } =
    useSelector((state) => state.View.bookings);

  const handleClose = (e) => {
    e.stopPropagation();
    dispatch(
      actions.View.setBookingsView({
        isInView: false,
        bookingProps: null,
      })
    );
  };

  const {
    // flight
    arrivalAirport = '',
    departureAirport = '',
    outboundDate = '',

    // hotel
    destination = '',
    checkinDate = '',
    checkoutDate = '',

    // 'flight' || 'hotel'
    type = 'flight',
  } = bookingProps;

  const loadScript = () => {
    setLoaded(false);
    const scriptId = 'skyscanner-loader';
    const widgetId = 'skyscanner-widget';
    const SCRIPT_SRC =
      'https://widgets.skyscanner.net/widget-server/js/loader.js';

    const existingScriptTag = document.getElementById(scriptId);
    const existingWidget = document.getElementById(widgetId);

    // remove existing widget and script when attributes are updated.
    if (existingScriptTag) {
      document.body.removeChild(existingScriptTag);
    }

    if (existingWidget) {
      widgetContainerRef?.current?.removeChild(existingWidget);
    }

    // create the new widget element with the new attributes, and load the widget
    const widget = document.createElement('div');
    const widgetAttributes = {
      id: 'skyscanner-widget',
      ...(type === 'flight'
        ? getFlightAttributes({
            arrivalAirport,
            departureAirport,
            outboundDate,
          })
        : getHotelAttributes({
            destination,
            checkinDate,
            checkoutDate,
          })),
      'data-button-colour': '#ED702E',
      'data-locale': 'en-US',
      'data-market': 'US',
      'data-currency': 'USD',
      'data-hide-powered-by': 'true',
      'data-associate-id': '3595904',
      'data-media-partner-id': '3595904',
    };
    Object.keys(widgetAttributes)
      ?.filter((attr) => widgetAttributes[attr])
      ?.forEach((attr) => widget.setAttribute(attr, widgetAttributes[attr]));

    const script = document.createElement('script');
    script.src = SCRIPT_SRC;
    script.async = true;
    script.id = scriptId;
    script.onload = () => {
      setLoaded(true);
    };

    // append the widget and the script
    widgetContainerRef?.current?.appendChild(widget);
    document.body.appendChild(script);
  };

  useEffect(() => {
    const widgetContainer = widgetContainerRef.current;
    if (widgetContainer) {
      loadScript();
    }
  }, [widgetContainerRef.current, bookingProps]);

  return (
    <Modal
      open={bookingOpen}
      onClose={handleClose}
      keepMounted
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'scroll',
        position: 'absolute',
      }}>
      <Box
        id="bookings-modal"
        ref={widgetContainerRef}
        sx={{
          width: '70%',
          maxWidth: 1012,
          bgcolor: '#FFF',
          boxShadow: 24,
          borderRadius: '16px',
          outline: 'none',
          minHeight: 250,
          p: 4,
        }}>
        <div
          style={{
            display: 'flex',
            marginBottom: 8,
          }}>
          <Typography sx={{ fontSize: 24, fontWeight: 'bold' }}>
            {type === 'flight' ? 'Find flights' : 'Search Deals'}
          </Typography>
          <div style={{ marginLeft: 'auto' }}>
            <IconButton
              sx={{ height: 24, width: 24, color: '#4E4E4E' }}
              onClick={handleClose}>
              <CloseRounded />
            </IconButton>
          </div>
        </div>

        {isLoaded ? null : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: 150,
            }}>
            <Fade in={!isLoaded}>
              <CircularProgress size={24} />
            </Fade>
          </div>
        )}
      </Box>
    </Modal>
  );
}

export default BookingsModal;
