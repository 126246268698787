import React from 'react';
import { useTheme } from '@mui/styles';
import { Box, Modal, IconButton } from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import { PoiDetails } from '../Recommendations/CityPage';

function PoiDetailsModal({
  handleClose,
  poi,
  handleSave = () => {},
  isSaved = false,
  showSaveCta = false,
}) {
  const theme = useTheme();
  return (
    <Modal
      open={Boolean(poi)}
      onClose={handleClose}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '40px 0px',
      }}>
      <Box
        sx={{
          backgroundColor: '#FFF',
          width: '50%',
          maxWidth: 680,
          height: '100%',
          outline: 'none',
          overflow: 'hidden',
          borderRadius: 2,
          position: 'relative',
          [theme.breakpoints.down('md')]: {
            width: '70%',
          },
          [theme.breakpoints.down('sm')]: {
            width: '100%',
          },
        }}>
        <PoiDetails
          poi={{
            thumbnail: poi?.images && poi?.images[0],
            rankedCategories: poi?.categories,
            ...poi,
          }}
          showBackButton={false}
          showSaveButton={false}
          showAddToTripButton={false}
          showSaveCta={showSaveCta}
          handleSaveClick={handleSave}
          isSaved={isSaved}
        />
        <div
          style={{
            position: 'absolute',
            top: 24,
            right: 16,
          }}>
          <IconButton
            onClick={handleClose}
            sx={{
              color: '#222',
              height: 32,
              width: 32,
              padding: '2px',
              backgroundColor: '#FFF',
              '&:hover': {
                backgroundColor: '#FFF',
                color: 'primary.main',
              },
            }}>
            <CloseRounded height={16} width={16} />
          </IconButton>
        </div>
      </Box>
    </Modal>
  );
}

export default PoiDetailsModal;
