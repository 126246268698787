import { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { firebaseAuth } from '../../../provider/AuthProvider';

function ActionLinkHandler() {
  const location = useLocation();
  const navigate = useNavigate();

  const { isSignInWithEmailLink } = useContext(firebaseAuth);

  // Extract the 'mode' parameter from the URL
  const searchParams = new URLSearchParams(location.search);
  const mode = searchParams.get('mode');
  const oobCode = searchParams.get('oobCode');

  useEffect(async () => {
    // Conditional redirect based on 'mode' parameter
    if (
      mode === 'resetPassword' &&
      window.localStorage.getItem('emailForPasswordReset')?.length
    ) {
      window.localStorage.setItem('userOobCode', oobCode);
      navigate('/password-reset');
    } else if (mode === 'signIn') {
      const isValidSignUp = await isSignInWithEmailLink();
      const email = window.localStorage.getItem('emailForSignIn');
      navigate('/signup', {
        state: {
          emailValue: email,
          isValidSignUp,
          signInSession: window.location.href,
        },
      });
    } else {
      navigate('/continue');
    }
  }, [navigate]);

  return (
    // You can render a loading indicator or other content here if needed
    <div>Loading...</div>
  );
}

export default ActionLinkHandler;
