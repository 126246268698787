import { Box, Stack, useMediaQuery } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import React, { useEffect, useState } from 'react';

import Polaroid from './Polaroid';
import Header from './Header';
import getCfConnector from '../../../cfConnector';
import {
  getCeiledDuration,
  getFormattedDateRangeText,
  parseISODate,
} from '../../../../utils';
import FooterActions from './FooterActions';
import Tripmates from './Tripmates';
import { EVENTS, phTrackEvent } from '../../../../analytics';

const useStyles = makeStyles(({ breakpoints }) => ({
  cardContainer: {
    backgroundImage: ({ coverImage }) =>
      `linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), url(${coverImage})`,
    backgroundPosition: 'lightgray 50%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#FFFBF6',
    height: '100vh',
    width: '100vw',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContainer: {
    borderRadius: '12px',
    background: 'url("/images/trip-invite-card-bg.png")',
    // background: 'white',
    height: '545px',
    width: '920px',
    [breakpoints.down('sm')]: {
      width: '90%',
      height: '500px',
    },
    position: 'relative',
  },
}));

const getFormattedDateRangeTextWithDuration = (startDate, endDate) => {
  return `${getFormattedDateRangeText(
    parseISODate(startDate || new Date()),
    parseISODate(endDate || new Date())
  )} · ${getCeiledDuration(
    parseISODate(startDate || new Date()),
    parseISODate(endDate || new Date())
  )}`;
};

function TripInvitation({ trip }) {
  const [coverImage, setCoverImage] = useState('');
  const styles = useStyles({ coverImage });
  const { breakpoints } = useTheme();
  const formattedDuratioAndDateRangeText =
    getFormattedDateRangeTextWithDuration(trip?.startDate, trip?.endDate);

  const isMobile = useMediaQuery(breakpoints.down('sm'));

  useEffect(() => {
    (async () => {
      phTrackEvent({
        event: EVENTS.TRIP_INVITE.INVITE_START,
        meta: {
          tripId: trip?.id,
        },
      });
      if (!trip?.firstLocation)
        return setCoverImage('/images/trip-invite-modal-default.svg');
      const { data: imagedata } = await (
        await getCfConnector()
      ).post(process.env.REACT_APP_IMAGES_API, {
        keyword: trip?.firstLocation?.name,
      });
      const result = imagedata.result.response.results;
      const image =
        result[Math.floor(Math.random() * result.length)].urls.regular;
      setCoverImage(image);
    })();
  }, [trip]);
  return (
    <Box className={styles.cardContainer}>
      <Box px="54px" className={styles.modalContainer}>
        <Stack
          height="100%"
          gap="30px"
          py={isMobile ? '32px' : '40px'}
          flexDirection="row"
          justifyContent="center">
          <Stack
            height="100%"
            gap="24px"
            flexDirection="row"
            justifyContent="center">
            {/* Left Side */}
            <Stack width="100%" alignItems="center" flexDirection="column">
              <Header
                dateAndDuration={formattedDuratioAndDateRangeText}
                tripTitle={
                  trip?.title ||
                  `A Trip with ${trip?.owner?.firstName} ${trip?.owner?.lastName}`
                }
              />
              <Tripmates
                sharedUsers={[trip?.owner, ...(trip?.sharedUsers || [])]}
              />
              <FooterActions tripId={trip?.id} />
            </Stack>
          </Stack>
          {/* Poloroid */}
          {!isMobile && (
            <Polaroid
              dateAndDuration={
                trip?.startDate && trip?.endDate
                  ? formattedDuratioAndDateRangeText
                  : 'TBD!'
              }
              coverImage={trip?.coverImage}
            />
          )}
        </Stack>
      </Box>
    </Box>
  );
}

export default TripInvitation;
