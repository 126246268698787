import { httpsCallable } from 'firebase/functions';
import { functions } from '../FirebaseIndex';
import functionNames from './functionNames';

const sendVerificationEmail = async ({ email, actionCodeSettings }) => {
  try {
    const result = await httpsCallable(
      functions,
      functionNames.sendVerificationLinkEmail
    )({
      email,
      actionCodeSettings,
    });
    return {
      data: result.data,
    };
  } catch (error) {
    return {};
  }
};
const sendLoginLinkEmail = async ({ email, actionCodeSettings }) => {
  try {
    const result = await httpsCallable(
      functions,
      functionNames.sendLoginLinkEmail
    )({
      email,
      actionCodeSettings,
    });
    return {
      data: result.data,
    };
  } catch (error) {
    return {};
  }
};
export default {
  sendVerificationEmail,
  sendLoginLinkEmail,
};
