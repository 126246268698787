import React from 'react';
import { useDispatch } from 'react-redux';
import {
  Typography,
  Button,
  Grid,
  Box,
  Stack,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/styles';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import CreateATripDateRangePicker from './DatePicker';
import { removeTimezoneOffset } from '../../../utils';
import { updateTrip } from '../../../redux/slices/Trips';
import { EVENTS, phTrackEvent } from '../../../analytics';

function Step3Page({ setStep, tripId, locationText, dateRange, setDateRange }) {
  const dispatch = useDispatch();
  const updateTripDate = () => {
    const newStartDate = dateRange.from;
    const newEndDate = dateRange.to;
    const tripDuration =
      (new Date(dateRange.to) - new Date(dateRange.from)) / (1000 * 3600 * 24);
    const daysUntilTripStarts =
      (new Date(dateRange.from) - new Date()) / (1000 * 3600 * 24);

    phTrackEvent({
      event: EVENTS.TRIP_CREATE.DATES_ADD,
      meta: {
        trip_dates: [newStartDate, newEndDate],
        trip_length: tripDuration,
        days_until_trip_start: daysUntilTripStarts,
      },
    });

    dispatch(
      updateTrip({
        variables: {
          id: tripId,
          startDate: removeTimezoneOffset(newStartDate)?.toISOString(),
          endDate: removeTimezoneOffset(newEndDate)?.toISOString(),
        },
      })
    );
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        height: { xs: 'auto', sm: '80vh' },
        display: 'flex',
        alignItems: { xs: 'start', sm: 'center' },
      }}>
      <Grid
        container
        sx={{
          paddingX: { xs: '20px', sm: '100px' },
          height: { xs: 'auto', sm: '450px' },
        }}>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          <Stack maxWidth="400px" spacing={3} mt="30px">
            <Box
              onClick={() => {
                if (isMobile) {
                  setStep('2.5');
                } else {
                  setStep((prev) => prev - 1);
                }
              }}
              sx={{
                width: 'fit-content',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                color: '#8A8A8A',
              }}>
              <ChevronLeftRoundedIcon sx={{ marginTop: '-2px' }} />
              Back
            </Box>

            <Typography sx={{ fontSize: '37px', fontWeight: 500 }}>
              When are you going to {locationText}?
            </Typography>

            {!isMobile && (
              <Button
                sx={{
                  backgroundColor: 'white',
                  border: '#d9d9d9 1px solid',
                  color: 'black',
                  '&:hover': {
                    backgroundColor: 'primary.main',
                  },
                  width: 'fit-content',
                }}
                onClick={() => {
                  setStep((prev) => prev + 1);
                  setDateRange({ from: null, to: null });
                }}>
                Not sure yet!
              </Button>
            )}
          </Stack>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          <Box
            sx={{
              maxWidth: '400px',
            }}>
            <Box mb={5}>
              <CreateATripDateRangePicker
                range={dateRange}
                setRange={setDateRange}
              />
            </Box>

            {isMobile && (
              <Box mb={2}>
                <Button
                  sx={{
                    backgroundColor: '#F4F4F4',
                    border: '#D9D9D9 2px solid',
                    color: 'black',
                    '&:hover': {
                      backgroundColor: 'primary.main',
                    },
                    width: 'fit-content',
                  }}
                  onClick={() => {
                    setStep((prev) => prev + 1);
                    setDateRange({ from: null, to: null });
                  }}>
                  Not sure yet!
                </Button>
              </Box>
            )}

            <Button
              fullWidth
              disabled={!dateRange?.from}
              onClick={() => {
                updateTripDate();
                setStep((prev) => prev + 1);
              }}>
              Next
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Step3Page;
