import React from 'react';
import { Snackbar, SnackbarContent } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { SecondaryOutlinedButton } from '../../atoms/Button/index';

const useStyles = makeStyles(({ palette }) => ({
  snackbarContent: {
    backgroundColor: palette?.primary?.main,
    color: '#FFF',
    borderRadius: '4px',
    height: '30px',
    zIndex: 2100,
    padding: '4px 12px',
    minWidth: '1px !important',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    '& > .MuiSnackbarContent-message': {
      padding: 0,
      fontSize: 12,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '& > .MuiSnackbarContent-action': {
      paddingLeft: 0,
      marginTop: '-2px',
    },
  },
  snackbarActionBtn: {
    padding: '0px',
    color: '#FFF',
    fontSize: 12,
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    textDecoration: 'underline',
    '&:hover': {
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      textDecoration: 'underline',
    },
  },
}));

export default function NotificationPopper({
  open,
  anchorEl,
  onCancel,
  onClose,
  destinationName = '',
  isNewDestination = false,
  cancelButtonLabel = 'Change',
  paperStyle,
}) {
  const handleCancel = (e) => {
    e.stopPropagation();
    if (onCancel) {
      onCancel();
    }
  };

  const classes = useStyles();
  const cardWidth = anchorEl?.offsetWidth;

  return (
    <div style={{ width: '100%', position: 'relative' }}>
      {open && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={5000}
          open={open}
          disableWindowBlurListener
          sx={{
            position: 'absolute',
            top: '8px !important',
          }}
          onClose={onClose}>
          <SnackbarContent
            message={
              isNewDestination
                ? `Destination added: ${destinationName}`
                : `Added to: ${destinationName}`
            }
            className={classes.snackbarContent}
            sx={{
              width: `${cardWidth * 0.95}px`,
              ...paperStyle,
            }}
            action={
              <SecondaryOutlinedButton
                onClick={handleCancel}
                className={classes.snackbarActionBtn}>
                {cancelButtonLabel}
              </SecondaryOutlinedButton>
            }
          />
        </Snackbar>
      )}
    </div>
  );
}
