import { Typography } from '@mui/material';

function AmenityCategory({ name, items, isNew }) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: 12,
        marginTop: 24,
      }}>
      {isNew && (
        <Typography variant="h3" fontWeight={600}>
          {name}
        </Typography>
      )}
      {items?.map((feature, fidx) => (
        <Typography key={fidx} variant="h5">
          {feature}
        </Typography>
      ))}
    </div>
  );
}

export default AmenityCategory;
