import { createSlice } from '@reduxjs/toolkit';
import QUERY from '../../graphql/queries';
import getAsyncThunk, { reorderList } from '../helpers';
import { createAccommodation, deleteAccommodation } from './Accommodation';
import { deleteSection } from './Section';
import {
  clearMapPinsFromLocation,
  createSection,
  handleDndHotels,
  handleDndSections,
} from './sharedThunks';
import { createNewActivityInLocation } from './Activity';

const initialState = {
  locations: {},
};

export const createLocation = getAsyncThunk(
  'LOCATION/createLocation',
  QUERY.CREATE_LOCATION
);

export const updateLocation = getAsyncThunk(
  'LOCATION/updateLocation',
  QUERY.UPDATE_LOCATION
);

export const deleteLocation = getAsyncThunk(
  'LOCATION/deleteLocation',
  QUERY.DELETE_LOCATION,
  clearMapPinsFromLocation,
  ({ variables }) => ({ locationId: variables.id, tripId: variables.tripId })
);

const LocationSlice = createSlice({
  name: 'LOCATION',
  initialState,
  reducers: {
    initializeLocations: (state, action) => {
      state.locations = {
        ...state.locations,
        ...action.payload,
      };
    },
    removeLocationSectionTemp: (state, action) => {
      const { locationId } = action.payload;
      if (locationId && state.locations[locationId]) {
        state.locations[locationId].tempRemove = true;
      }
    },
    addLocationSectionTemp: (state, action) => {
      const { locationId } = action.payload;
      if (
        locationId &&
        state.locations[locationId] &&
        state.locations[locationId].tempRemove
      ) {
        delete state.locations[locationId].tempRemove;
      }
    },
    addLocalSectionToLocation: (
      state,
      { payload: { locationId, sectionId, index } }
    ) => {
      const newSections = [
        ...(state.locations[locationId]?.thingsToDo?.slice(0, index + 1) || []),
        sectionId,
        ...(state.locations[locationId]?.thingsToDo?.slice(index + 1) || []),
      ];
      state.locations = {
        ...state.locations,
        [locationId]: {
          ...(state.locations[locationId] || {}),
          thingsToDo: newSections,
        },
      };
    },
  },
  extraReducers: {
    [updateLocation.fulfilled]: (
      state,
      {
        meta: {
          arg: { variables: location },
        },
        payload: {
          updateLocation: { version },
        },
      }
    ) => {
      state.locations[location.id] = {
        ...state.locations[location.id],
        ...location,
        ...(version && { version }),
      };
    },
    [deleteLocation.fulfilled]: (state, { meta }) => {
      const { id: locationId } = meta.arg.variables;
      delete state.locations[locationId];
    },
    [createLocation.fulfilled]: (state, { payload, meta }) => {
      const locationId = payload.createLocation.id;
      const { variables: location } = meta.arg;
      state.locations = {
        ...state.locations,
        [locationId]: { ...location, id: locationId },
      };
    },
    [createAccommodation.fulfilled]: (state, { payload, meta }) => {
      const { id: accommodationId } = payload.createAccommodation;
      const { locationId } = meta.arg.extra;
      if (!state.locations[locationId].hotels) {
        state.locations[locationId].hotels = [];
      }
      state.locations[locationId].hotels.push(accommodationId);
    },
    [deleteAccommodation.pending]: (
      state,
      {
        meta: {
          arg: { variables: accommodation },
        },
      }
    ) => {
      state.locations = {
        ...state.locations,
        [accommodation?.locationId]: {
          ...state.locations[accommodation?.locationId],
          hotels: state.locations[accommodation?.locationId].hotels?.filter(
            (id) => id !== accommodation?.id
          ),
        },
      };
    },
    [createSection.fulfilled]: (
      state,
      {
        payload: { createThingsToDo: section },
        meta: {
          arg: { index, locationId },
        },
      }
    ) => {
      const newSections = [
        ...(state?.locations[locationId]?.thingsToDo?.slice(0, index + 1) ||
          []),
        section?.id,
        ...(state?.locations[locationId]?.thingsToDo?.slice(index + 1) || []),
      ]?.filter((sectionId) => !sectionId.includes('local-section'));
      state.locations = {
        ...state.locations,
        [locationId]: {
          ...state.locations[locationId],
          thingsToDo: newSections,
        },
      };
    },
    [deleteSection.pending]: (
      state,
      {
        meta: {
          arg: { variables: section },
        },
      }
    ) => {
      state.locations = {
        ...state.locations,
        [section?.locationId]: {
          ...state.locations[section?.locationId],
          thingsToDo: state.locations[section?.locationId].thingsToDo?.filter(
            (id) => id !== section?.id
          ),
        },
      };
    },

    // to create an activity in a location without sections
    [createNewActivityInLocation.fulfilled]: (
      state,
      {
        payload: { section },
        meta: {
          arg: { locationId },
        },
      }
    ) => {
      state.locations = {
        ...state.locations,
        [locationId]: {
          ...(state.locations[locationId] || {}),
          thingsToDo: [
            ...(state.locations[locationId]?.thingsToDo || []),
            section?.id,
          ],
        },
      };
    },

    [handleDndSections.pending]: (
      state,
      {
        meta: {
          arg: { source, destination },
        },
      }
    ) => {
      const sourceSections = state.locations[source.droppableId]?.thingsToDo;
      const destinationSections =
        state.locations[destination.droppableId]?.thingsToDo;
      const { sourceList, destinationList, isSameList } = reorderList(
        sourceSections,
        destinationSections,
        source,
        destination
      );
      state.locations[source.droppableId].thingsToDo = sourceList;
      if (!isSameList)
        state.locations[destination.droppableId].thingsToDo = destinationList;
    },

    [handleDndHotels.pending]: (
      state,
      {
        meta: {
          arg: { source, destination },
        },
      }
    ) => {
      const sourceSections = state.locations[source.droppableId]?.hotels || [];
      const destinationSections =
        state.locations[destination.droppableId]?.hotels || [];
      const { sourceList, destinationList, isSameList } = reorderList(
        sourceSections,
        destinationSections,
        source,
        destination
      );
      state.locations[source.droppableId].hotels = sourceList;
      if (!isSameList)
        state.locations[destination.droppableId].hotels = destinationList;
    },
  },
});

export const LocationActions = LocationSlice.actions;
export const LocationReducer = LocationSlice.reducer;
