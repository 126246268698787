import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Box, Typography, InputAdornment, IconButton } from '@mui/material';
import { FullPageButton } from '../../atoms/Button/index';
import { InputAuthForm } from '../../atoms/Input/index';
import AuthLayout from '../../templates/AuthLayout';
import { LinkHyper, LinkRoute } from '../../atoms/Link';
import { Visibility, VisibilityOff } from '../../atoms/Icon';
import { firebaseAuth } from '../../../provider/AuthProvider';
import actions from '../../../redux/actions';
import Logo from '../../molecules/Logo';
// import Logo from '../../molecules/Logo';

function PasswordReset() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { confirmPasswordReset } = useContext(firebaseAuth);

  const [oobCode] = useState(window.localStorage.getItem('userOobCode') || '');
  const [emailValue] = useState(
    localStorage.getItem('emailForPasswordReset') || ''
  );
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);

  const handlePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handleConfirmPasswordVisibility = () => {
    setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
  };

  // Passowrd reset logic
  const handleSubmitWithFormik = async (values) => {
    await confirmPasswordReset(oobCode, values.password);

    // Removing the saved varaibles in localStorage
    window.localStorage.removeItem('userOobCode');
    window.localStorage.removeItem('emailForPasswordReset');
    dispatch(
      actions.Auth.setSuccess('Your password has been changed successfully!')
    );
    navigate('/continue');
  };

  const resetPasswordValidationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .required('Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password is required'),
  });

  return (
    <Formik
      initialValues={{ email: emailValue }}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(false);
        await handleSubmitWithFormik(values);
      }}
      // Define Validations
      validationSchema={resetPasswordValidationSchema}>
      {(props) => {
        const {
          values,
          isSubmitting,
          handleBlur,
          handleChange,
          handleSubmit,
          isValid,
          touched,
        } = props;

        return (
          <AuthLayout>
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Typography
                  mb="0.5rem"
                  variant="h2"
                  sx={{
                    fontFamily: 'Work Sans',
                    fontSize: '2rem',
                    fontWeight: '600',
                    lineHeight: '20px',
                  }}>
                  Reset your password
                </Typography>

                <Typography
                  pt={3}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    color: '#4E4E4E',
                    fontSize: '1rem',
                    fontWeight: 500,
                    textAlign: 'center',
                  }}>
                  Fill out the following to continue to Pilot
                </Typography>
              </Box>

              <form autoComplete="on" onSubmit={handleSubmit} noValidate>
                <Box mt="12%" mb="5%">
                  <InputAuthForm
                    label="E-mail"
                    placeholder="Email Address..."
                    name="email"
                    autoComplete="email"
                    // required
                    fullWidth
                    variant="outlined"
                    disabled
                    InputLabelProps={{
                      shrink: true,
                      sx: {
                        color: '#8A8A8A',
                        '&.Mui-disabled': {
                          backgroundColor: '#FFFFFF',
                        },
                      },
                      required: false,
                    }}
                    InputProps={{
                      sx: {
                        '&::placeholder': {
                          color: '#8A8A8A',
                        },
                        '&.Mui-disabled': {
                          backgroundColor: '#F4F4F4',
                          '&:hover fieldset': {
                            borderColor: '#D9D9D9',
                          },
                        },
                      },
                    }}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={emailValue}
                  />
                </Box>

                <Box mb="5%">
                  <InputAuthForm
                    label="New Password"
                    placeholder="Enter Password"
                    name="password"
                    type={isPasswordVisible ? 'text' : 'password'}
                    autoComplete="off"
                    // required
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                      style: { color: '#8A8A8A', margin: '0px' },
                      required: false,
                    }}
                    InputProps={{
                      sx: {
                        '&::placeholder': {
                          color: '#8A8A8A',
                        },
                        '&::label': {
                          color: 'red',
                        },
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handlePasswordVisibility}>
                            {isPasswordVisible ? (
                              <Visibility sx={{ color: '#141414' }} />
                            ) : (
                              <VisibilityOff sx={{ color: '#141414' }} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                  />
                </Box>

                <Box>
                  <InputAuthForm
                    label="Confirm Password"
                    placeholder="Re-enter password"
                    name="confirmPassword"
                    type={isConfirmPasswordVisible ? 'text' : 'password'}
                    autoComplete="off"
                    // required
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                      style: { color: '#8A8A8A', margin: '0px' },
                      required: false,
                    }}
                    InputProps={{
                      sx: {
                        '&::placeholder': {
                          color: '#8A8A8A',
                        },
                        '&::label': {
                          color: 'red',
                        },
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleConfirmPasswordVisibility}>
                            {isConfirmPasswordVisible ? (
                              <Visibility sx={{ color: '#141414' }} />
                            ) : (
                              <VisibilityOff sx={{ color: '#141414' }} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.confirmPassword}
                  />
                </Box>

                <Box sx={{ padding: '1rem 0' }}>
                  <Typography
                    color="text.light"
                    sx={{
                      fontSize: '0.75rem',
                      fontWeight: 500,
                      paddingRight: '120px',
                    }}>
                    Creating an account means you&apos;re okay with Pilot&apos;s{' '}
                    <LinkHyper
                      href="https://pilotplans.com/legal/terms-of-service"
                      target="_blank"
                      underline="hover"
                      sx={{ fontSize: '12px !important' }}>
                      Terms of Service
                    </LinkHyper>{' '}
                    and{' '}
                    <LinkHyper
                      href="https://pilotplans.com/legal/privacy-policy"
                      target="_blank"
                      underline="hover"
                      sx={{ fontSize: '12px !important' }}>
                      Privacy Policy
                    </LinkHyper>
                  </Typography>
                </Box>

                <FullPageButton
                  type="submit"
                  disabled={
                    !isValid ||
                    isSubmitting ||
                    (!touched.password && !touched.confirmPassword)
                  }>
                  Save new password
                </FullPageButton>
              </form>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center">
                <Typography
                  component="span"
                  variant="h5"
                  // onClick={handleResetCLick}
                  sx={{
                    padding: '15px 0',
                    color: 'text.light', // Apply link-like color
                    textDecoration: 'underline', // Apply link-like underline
                    cursor: 'pointer', // Show pointer cursor on hover
                  }}>
                  <LinkRoute to="/continue">Not you? Go back. </LinkRoute>
                </Typography>
              </Box>
              {/* Logo */}
              <Box pt={5} pr={1.5}>
                <Logo fullLogo imageWidth="30%" />
              </Box>
            </Box>
          </AuthLayout>
        );
      }}
    </Formik>
  );
}

export default PasswordReset;
