import { WishlistActions } from './slices/Wishlist';
import { AuthActions } from './slices/Auth';
import { TripsActions } from './slices/Trips';
import { LocationActions } from './slices/Location';
import { TransportationActions } from './slices/Transportation';
import { AccommodationActions } from './slices/Accommodation';
import { SectionActions } from './slices/Section';
import { ActivityActions } from './slices/Activity';
import { MapActions } from './slices/Map';
import { FilesActions } from './slices/Files';
import { ErrorActions } from './slices/Error';
import { ViewActions } from './slices/View';
import { RecommendationsActions } from './slices/Recommendations';
import { DiscoverActions } from './slices/Discover';
import { BookingsActions } from './slices/Bookings';

const actions = {
  Wishlist: WishlistActions,
  Auth: AuthActions,
  Trips: TripsActions,
  Location: LocationActions,
  Transportation: TransportationActions,
  Accommodation: AccommodationActions,
  Section: SectionActions,
  Activity: ActivityActions,
  Map: MapActions,
  Files: FilesActions,
  Error: ErrorActions,
  View: ViewActions,
  Recommendations: RecommendationsActions,
  Discover: DiscoverActions,
  Bookings: BookingsActions,
};

export default actions;
