import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(({ palette }) => ({
  listIcon: {
    color: palette.text.main,
  },
  listItem: {
    color: palette.text.main,
    '&:hover': {
      cursor: 'pointer',
      '& $text': {
        color: palette.primary.main,
      },
      '& $listIcon': {
        color: palette.primary.main,
      },
    },
  },
}));

function SettingsPopoverOption({ Icon, content, action, id }) {
  const classes = useStyles();
  return (
    <ListItem className={classes.listItem} onClick={action} id={id}>
      <ListItemIcon className={classes.listIcon}>{Icon}</ListItemIcon>
      <ListItemText primary={content} />
    </ListItem>
  );
}

export default SettingsPopoverOption;
