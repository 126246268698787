import { useState } from 'react';
import { Button, Typography, CircularProgress } from '@mui/material';
import { ChevronRightRounded } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';
import SubscriptionsPriceSelector from '../PwywModal/SubscriptionsPriceSelector';
import { sendCustomMessage } from '../../../../intercom';

import CONFIG from '../../../config';
import actions from '../../../../redux/actions';
import { getUserSubscriptions } from '../../../../redux/slices/Auth';
import getCfConnector from '../../../cfConnector';

const useStyles = makeStyles((theme) => ({
  ctaButton: {
    minWidth: 130,
    backgroundColor: theme.palette.system.white,
    border: '1px solid',
    borderColor: theme.palette.text.main,
    color: theme.palette.text.main,
    marginTop: 3,
    '&:hover': {
      backgroundColor: theme.palette.text.main,
    },
  },
}));

function UpdateContribution() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const subscriptionId = useSelector(
    (state) => state.View.updateSubscription?.subscriptionId
  );
  const userEmail = useSelector((state) => state.Auth.userData.user.email);
  const subscriptionPrice = useSelector(
    (state) => state.Auth.userData.subscription?.amount
  );

  const [loader, setLoader] = useState(false);
  const [price, setPrice] = useState(18.0);

  const openIntercomMessenger = () => {
    sendCustomMessage('');
  };

  const handleContributeClick = async () => {
    if (!subscriptionId || subscriptionPrice === price) return;

    setLoader(true);
    try {
      await (
        await getCfConnector()
      ).post(`${CONFIG.paymentsAPI}/update-subscription`, {
        subscriptionId,
        price,
      });

      await dispatch(getUserSubscriptions({ userEmail }));
      dispatch(
        actions.View.setUpdateSubscription({ subscriptionId, action: 1 })
      );
    } catch (error) {
      // handle error
      dispatch(
        actions.View.setUpdateSubscription({ subscriptionId, action: -1 })
      );
    }
    setLoader(false);
  };

  return (
    <div>
      <Typography variant="h2" fontSize="20px">
        Change your contribution amount
      </Typography>
      <SubscriptionsPriceSelector
        price={price}
        setPrice={setPrice}
        isSubscription
        disableToggle
      />
      <Typography
        color="warning.main"
        variant="caption"
        component="p"
        sx={{ mt: '2px' }}>
        Need help?{' '}
        <span
          style={{ textDecoration: 'underline', cursor: 'pointer' }}
          onClick={openIntercomMessenger}>
          {' '}
          Contact our support{' '}
        </span>
      </Typography>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: 8,
        }}>
        <Button
          onClick={handleContributeClick}
          disabled={!price || loader || price === subscriptionPrice}
          className={classes.ctaButton}
          sx={{ marginLeft: 'auto' }}>
          {loader ? (
            <CircularProgress size={16} sx={{ color: 'text.main' }} />
          ) : (
            <>
              Contribute
              <ChevronRightRounded />
            </>
          )}
        </Button>
      </div>
    </div>
  );
}

export default UpdateContribution;
