import { Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import pilotTheme from '../../theme';
import { ButtonDefault } from './Button/index';

const useStyles = makeStyles(() => ({
  errorContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    maxWidth: ({ containerMaxWidth }) => containerMaxWidth || '540px',
    minHeight: '100vh',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
  },
  errorText: {
    color: '#4E4E4E',
  },
  supportButton: {
    width: 'max-content',
    padding: '12px 16px',
  },
}));

function Error({ title, error, showSupportButton, containerMaxWidth }) {
  const classes = useStyles({ containerMaxWidth });
  return (
    <ThemeProvider theme={pilotTheme}>
      <div className={classes.errorContainer}>
        <Typography variant="h2">{title}</Typography>
        <Typography
          className={classes.errorText}
          fontSize="20px"
          fontWeight="500"
          marginTop="8px"
          marginBottom="16px">
          {error}
        </Typography>
        {showSupportButton && (
          <ButtonDefault
            className={classes.supportButton}
            size="large"
            href="https://docs.google.com/forms/d/e/1FAIpQLSev_ej9SSSJtS1Jgy_gT7tB8hvdPin1AkW7QB9tp_y38t6Q3w/viewform">
            Contact Support
          </ButtonDefault>
        )}
      </div>
    </ThemeProvider>
  );
}

export default Error;
