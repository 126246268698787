import {
  Box,
  FormHelperText,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { Formik } from 'formik';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { firebaseAuth } from '../../provider/AuthProvider';
import { ButtonDefault } from '../atoms/Button/index';
import { InputAuthForm } from '../atoms/Input';

const useStyles = makeStyles((theme) => ({
  error: {
    minHeight: '1rem',
  },
  copyContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 48px',
    },
  },
}));

function EmailInputForm() {
  const { handleAddEmail, handleSignout } = useContext(firebaseAuth);
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const errors = useSelector((state) => state.Auth.error);
  const firebaseUser = useSelector((state) => state.Auth.firebaseUser);
  const loading = useSelector((state) => state.Auth.loading);

  const handleSubmitWithFormik = async (values) => {
    await handleAddEmail(values.email);
  };

  const classes = useStyles();

  return (
    <Formik
      initialValues={{ email: '' }}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        handleSubmitWithFormik(values);
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email().required('Email is required'),
      })}>
      {(props) => {
        const {
          values,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          isValid,
        } = props;

        return (
          <Grid
            container
            height="100%"
            justifyContent="center"
            alignItems="center"
            direction="column">
            <div className={classes.copyContainer}>
              <Typography
                variant={isMobile ? 'h4Sub' : 'h2Sub'}
                textAlign="center">
                Please enter your email {firebaseUser.email}
              </Typography>
              <Box textAlign="center" mt="12px">
                <Typography variant="h5" color="text.light">
                  It appears that there&apos;s no email linked to your account.
                  Enter your email to continue.
                </Typography>
              </Box>
            </div>
            <form
              style={{ width: 305 }}
              autoComplete="on"
              onSubmit={handleSubmit}
              noValidate>
              <Box mt="1.5rem" width="100%">
                <InputAuthForm
                  placeholder="Email Address"
                  name="email"
                  autoComplete="email"
                  required
                  fullWidth
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
              </Box>
              <FormHelperText error className={classes.error}>
                {errors}
              </FormHelperText>
              <ButtonDefault
                type="submit"
                fullWidth
                disabled={!isValid || isSubmitting || loading}>
                {loading ? 'Loading...' : 'Next'}
              </ButtonDefault>
            </form>
            <Typography
              sx={{
                mt: '12px',
                textDecoration: 'underline',
                '&:hover': { cursor: 'pointer' },
              }}
              onClick={handleSignout}
              variant="ctaSmall"
              color="text.light">
              Go Back
            </Typography>
          </Grid>
        );
      }}
    </Formik>
  );
}

export default EmailInputForm;
