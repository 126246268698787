import React, { useState } from 'react';
import { Modal, Button, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Header from './Header';

const useStyles = makeStyles({
  root: {
    backgroundColor: '#F2F3F4',
    display: 'flex',
    paddingBottom: '50px',
    flexDirection: 'column',
  },
  initialContainer: {},
  title: {
    color: 'var(--product-text-main-text, #222)',
    textAlign: 'center',
    alignSelf: 'center',
    marginTop: '70px',
    font: '700 30px/70% Inter, sans-serif ',
    whiteSpace: 'nowrap',
    '@media (max-width: 991px)': {
      marginTop: '40px',
      whiteSpace: 'initial',
    },
  },
  subHeading: {
    color: 'var(--product-text-main-text, #222)',
    textAlign: 'center',
    alignSelf: 'center',
    marginTop: '11px',
    whiteSpace: 'nowrap',
    '@media (max-width: 991px)': {
      whiteSpace: 'initial',
    },
    font: '400 14px/171% Inter, sans-serif ',
  },
  container: {
    alignSelf: 'center',
    marginTop: '46px',
    width: '525px',
    maxWidth: '100%',
    '@media (max-width: 991px)': {
      marginTop: '20px',
    },
  },
  cardHolder: {
    gap: '10px',
    display: 'flex',
    '@media (max-width: 991px)': {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    lineHeight: 'normal',
    width: '50%',
    marginLeft: '0px',
    cursor: 'pointer',
    '@media (max-width: 991px)': {
      width: '80%',
      marginLeft: 0,
    },
  },
  cardBody: {
    borderRadius: '8px',
    border: '2px solid var(--product-system-grey-outline, #D9D9D9)',
    backgroundColor: '#FFF',
    display: 'flex',
    flexGrow: '1',
    flexDirection: 'column',
    width: '100%',
    padding: '21px 26px',
    '@media (max-width: 991px)': {
      marginTop: '10px',
      height: '15vh',
      padding: '5px 10px',
      flexDirection: 'row',
      alignItems: 'center',
    },

    '@media (max-width:400px)': {
      marginTop: '10px',
      height: '15vh',
      padding: '5px 10px',
      flexDirection: 'row',
    },
  },
  cardImage: {
    aspectRatio: '1.27',
    objectFit: 'contain',
    objectPosition: 'center',
    width: '167px',
    overflow: 'hidden',
    alignSelf: 'center',
    maxWidth: '100%',
    '@media (max-width: 991px)': {
      marginTop: 2,
      paddingLeft: '10px',
    },
  },
  cardHeading: {
    color: 'var(--product-text-main-text, #222)',
    textAlign: 'center',
    alignSelf: 'center',
    marginTop: '16px',
    whiteSpace: 'nowrap',
    font: '700 16px/131% Inter, sans-serif ',
    '@media (max-width: 991px)': {
      whiteSpace: 'initial',
      textAlign: 'left',
      justifyContent: 'flex-start',
      alignSelf: 'flex-start',
      paddingLeft: '10px',
      marginTop: '5px',
    },
  },
  cardText: {
    color: 'var(--product-text-main-text, #222)',
    textAlign: 'center',
    alignSelf: 'stretch',
    marginTop: '14px',
    font: '400 14px/18px Inter, sans-serif ',
    '@media (max-width: 991px)': {
      whiteSpace: 'initial',
      textAlign: 'left', // Ensured text alignment is left on mobile devices
      justifyContent: 'flex-start',
      alignSelf: 'flex-start',
      paddingLeft: '10px',
      marginTop: '5px',
    },
  },
  selectionWrapper: {
    strokeWidth: '1px',
    borderRadius: '50%',
    alignSelf: 'center',
    display: 'flex',
    marginTop: '20px',
    width: '18px',
    height: '18px',
    flexDirection: 'column',
    '@media (max-width: 991px)': {
      display: 'none',
    },
  },
  button: {
    color: '#FFF',
    textAlign: 'center',
    whiteSpace: 'nowrap',

    borderRadius: '8px',
    backgroundColor: 'var(--product-primary-primary, #ED702E)',
    alignSelf: 'center',
    width: '231px',
    maxWidth: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '44px 0 60px',
    padding: '17px 60px',
    font: '600 16px/131% Inter, sans-serif ',
    '@media (max-width: 991px)': {
      position: 'fixed',
      bottom: '0',
      left: '50%',
      transform: 'translateX(-50%)',
      width: '80%',

      padding: '17px 60px',

      marginBottom: '20px',
    },
  },
  indicatorDesktop: {
    display: 'none',
    '@media (min-width: 992px)': {
      display: 'block',
      margin: 'auto',
    },
  },
  indicatorMobile: {
    display: 'block',
    margin: 'auto',
    '@media (min-width: 992px)': {
      display: 'none',
    },
  },
});

function InitialModal({
  handleClose,
  handleCreateTrip,
  handleAddExampleTrip,
  ...props
}) {
  const [newFlow, setNewFlow] = useState(true);
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Modal {...props} sx={{ overflowY: 'auto', top: '50px', height: '100%' }}>
      <div
        style={{
          backgroundColor: '#F2F3F4',
          minHeight: '100vh',
        }}>
        <Header step={0} />
        <div className={classes.root}>
          <div className={classes.title}>How do you want to learn?</div>
          <div className={classes.subHeading}>
            You will get to create a trip either way, don’t worry :)
          </div>
          <div className={classes.container}>
            <div className={classes.cardHolder}>
              <div className={classes.card} onClick={() => setNewFlow(false)}>
                <span className={classes.cardBody}>
                  <div
                    className={classes.indicatorMobile}
                    style={{ width: '18px', height: '18px' }}>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{
                        margin: 'auto',
                      }}>
                      <circle
                        cx="8"
                        cy="8"
                        r="7.5"
                        fill={newFlow ? '#F4F4F4' : theme.palette.primary.main}
                        stroke="#D9D9D9"
                      />
                    </svg>
                  </div>
                  <img
                    loading="lazy"
                    src="images/exampleTrip.png"
                    className={classes.cardImage}
                    alt=""
                  />
                  <div className={classes.cardContent}>
                    <div className={classes.cardHeading}>View sample trip</div>
                    <div className={classes.cardText}>
                      I want to learn about Pilot on a sample trip.
                    </div>
                    <div className={classes.selectionWrapper} />

                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className={classes.indicatorDesktop}>
                      <circle
                        cx="9"
                        cy="9"
                        r="8.5"
                        fill={newFlow ? '#F4F4F4' : theme.palette.primary.main}
                        stroke="#D9D9D9"
                      />
                    </svg>
                  </div>
                </span>
              </div>
              <div className={classes.card} onClick={() => setNewFlow(true)}>
                <span className={classes.cardBody}>
                  <div
                    className={classes.indicatorMobile}
                    style={{ width: '18px', height: '18px' }}>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{
                        margin: 'auto',
                      }}>
                      <circle
                        cx="8"
                        cy="8"
                        r="7.5"
                        fill={newFlow ? theme.palette.primary.main : '#F4F4F4'}
                        stroke="#D9D9D9"
                      />
                    </svg>
                  </div>
                  <img
                    loading="lazy"
                    src="images/blankTrip.png"
                    className={classes.cardImage}
                    alt=""
                  />
                  <div className={classes.cardContent}>
                    <div className={classes.cardHeading}>Create a trip</div>
                    <div className={classes.cardText}>
                      I want to create my trip and learn how to use Pilot as I
                      go.
                    </div>
                    <div className={classes.selectionWrapper} />

                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className={classes.indicatorDesktop}>
                      <circle
                        cx="9"
                        cy="9"
                        r="8.5"
                        fill={newFlow ? theme.palette.primary.main : '#F4F4F4'}
                        stroke="#D9D9D9"
                      />
                    </svg>
                  </div>
                </span>
              </div>
            </div>
          </div>

          <Button
            fullWidth
            className={classes.button}
            // disabled={!location}
            onClick={() => {
              if (newFlow) return handleCreateTrip(false, true);
              handleAddExampleTrip();
              handleClose();
            }}>
            Let’s go!
          </Button>
          {/* <div className={classes.button}></div> */}
        </div>
      </div>
    </Modal>
  );
}

export default InitialModal;
