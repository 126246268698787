import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from '@stripe/react-stripe-js';
import { CircularProgress, Typography, IconButton } from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import CONFIG from '../../../config';
import actions from '../../../../redux/actions';
import getCfConnector from '../../../cfConnector';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#FFF',
    flexDirection: 'column',
    display: 'flex',
    outline: 'none',
    padding: '12px 20px',
    borderRadius: 4,
    height: 578,
    overflow: 'auto',
    width: 437,
    [theme.breakpoints.down('sm')]: {
      width: 380,
    },
    alignItems: 'center',
    position: 'relative',
  },
  iconBtn: {
    position: 'absolute',
    top: 12,
    right: 12,
    zIndex: 2,
    backgroundColor: theme.palette.system.white,
    height: 24,
    width: 24,
    padding: 4,
    '&:hover': {
      backgroundColor: theme.palette.system.white,
      color: theme.palette.primary.main,
    },
  },
}));

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

function StripePayment() {
  const classes = useStyles();
  const [clientSecret, setClientSecret] = useState('');
  const dispatch = useDispatch();
  const userEmail = useSelector((state) => state?.Auth?.firebaseUser?.email);
  const { price = 5, paymentMode = 'subscription' } = useSelector(
    (state) => state.View.contribute || {}
  );

  useEffect(async () => {
    (await getCfConnector())
      .post(`${CONFIG.paymentsAPI}/new-checkout-session`, {
        userEmail,
        price,
        paymentMode,
      })
      .then(({ data }) => setClientSecret(data.clientSecret));
  }, []);

  const handleBackClick = () =>
    dispatch(actions.View.setContribute({ action: 0, cancelled: true }));

  const handleClose = () =>
    dispatch(actions.View.setContribute({ action: -1 }));

  return (
    <div className={classes.root}>
      {clientSecret ? (
        <div style={{ width: '100%' }}>
          <Typography
            variant="caption"
            color="text.light"
            sx={{
              textDecoration: 'underline',
              alignSelf: 'flex-start',
              cursor: 'pointer',
              marginBottom: 1,
              marginTop: 1,
            }}
            onClick={handleBackClick}>
            Change Amount?
          </Typography>
          <IconButton className={classes.iconBtn} onClick={handleClose}>
            <CloseRounded sx={{ height: 16, width: 16 }} />
          </IconButton>
          <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={{
              clientSecret,
            }}>
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        </div>
      ) : (
        <div
          style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <CircularProgress />
        </div>
      )}
    </div>
  );
}

export default StripePayment;
