import { useContext, useEffect, useState, forwardRef } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
  Box,
  FormHelperText,
  IconButton,
  InputAdornment,
  Link,
  Typography,
  Snackbar,
  Alert as MuiAlert,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import CheckIcon from '@mui/icons-material/Check';
import { firebaseAuth } from '../../provider/AuthProvider';
import { FullPageButton } from '../atoms/Button/index';
import { InputAuthForm } from '../atoms/Input';
import SocialMediaAuth from '../molecules/SocialMediaAuth';
import Logo from '../molecules/Logo';
import { LinkRoute } from '../atoms/Link';
import {
  FacebookLogo,
  GoogleLogo,
  Visibility,
  VisibilityOff,
} from '../atoms/Icon';
import actions from '../../redux/actions';
import { ButtonWithIcon } from '../atoms/Button';
import classList from '../classList';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  error: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '1rem',
    // padding: '1rem 0',
    '&.Mui-error': {
      color: '#ED702E',
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '1rem',
    },
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  horizontalLine: {
    width: '100%',
    textAlign: 'center',
    borderBottom: '1px solid #4E4E4E',
    lineHeight: '0.1em',
    margin: '10px 0 20px',
  },
  horizontalLineText: {
    color: '#4E4E4E',
    background: '#fff',
    padding: '0 10px',
  },
  input: {
    padding: '2% 4%',
  },
  customSnackbar: {
    position: 'absolute',
  },
  grayColor: {
    color: '#757575',
  },
}));

function SigninForm() {
  const classes = useStyles();
  const {
    handleSignin,
    fetchSignInMethodsForEmail,
    sendSignInLinkToEmail,
    handleGoogleSignin,
    handleFacebookSignin,
    handleTokenSignIn,
  } = useContext(firebaseAuth);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const errors = useSelector((state) => state.Auth.error);
  const successMessage = useSelector((state) => state.Auth.success);

  const [isEmailExists, setIsEmailExists] = useState(null);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isPasswordFieldVisible, setIsPasswordFieldVisible] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [providersList, setProvidersList] = useState([]);
  const [isSocialMediaSignIn, setIsSocialMediaSignIn] = useState(false);

  useEffect(() => {
    errors?.length ? setIsError(true) : setIsError(false);
  }, [errors]);

  useEffect(() => {
    if (searchParams.get('token')) {
      const isNewUser = searchParams.get('newUser');
      handleTokenSignIn(searchParams.get('token'), isNewUser);
    }
  }, []);

  useEffect(() => {
    successMessage?.length ? setIsSuccess(true) : setIsSuccess(false);
  }, [successMessage]);

  useEffect(() => {
    if (successMessage) {
      setTimeout(() => {
        dispatch(actions.Auth.setSuccess(null));
      }, 5000); // Hide Snackbar after 5 seconds
    }
  }, [successMessage]);

  /* Formik takes care of onChange events */
  const handleSubmitWithFormik = async (values) => {
    if (isEmailExists && isPasswordFieldVisible) {
      await handleSignin(values);
    } else {
      sendSignInLinkToEmail(values.email);
      navigate('/email-verification', {
        state: { email: values.email, isPasswordReset: false },
      });
    }
  };

  const checkEmailExists = async (value) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const isValidEmail = emailRegex.test(value);

    if (!isValidEmail) {
      setIsEmailValid(false);
      return;
    }
    setIsEmailValid(true);

    try {
      // Use Firebase's authentication service to check if email exists
      const providers = await fetchSignInMethodsForEmail(value);

      setProvidersList(providers);

      // If there are sign-in methods associated with the email, it exists
      setIsEmailExists((providers && providers.length > 0) || false);
    } catch (error) {
      // Error occurred while checking email existence
      // Set to false if there's an error (e.g., Firebase API error)
      setIsEmailExists(false);
      setIsPasswordFieldVisible(false);
      dispatch(actions.Auth.setError(null));
    }
  };

  useEffect(() => {
    if (!(providersList.length && !providersList.includes('password'))) {
      dispatch(actions.Auth.setError(null));
      setIsSocialMediaSignIn(false);
    }
  }, [providersList]);

  const handlePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handlePasswordToggle = () => {
    setIsPasswordFieldVisible(true);
  };

  const handleResetCLick = () => {
    setIsPasswordFieldVisible(false);
    setIsSocialMediaSignIn(false);
    dispatch(actions.Auth.setError(null));
    setIsError(false);
  };

  const handleSocialMediaLogin = () => {
    if (providersList.length && !providersList.includes('password')) {
      const errorText = `Wow! 😍 We love a keener, but it looks like there's already a ${
        providersList.includes('google.com') ? 'Google' : 'Facebook'
      } Account signed in with this email. Try logging in through Google instead.`;

      dispatch(actions.Auth.setError(errorText));
      setIsSocialMediaSignIn(true);
    } else {
      dispatch(actions.Auth.setError(null));
      setIsSocialMediaSignIn(false);
    }
  };

  const signInValidationSchema = Yup.object().shape({
    email: Yup.string().email().required('Email is Required'),
    password: Yup.string().when('email', {
      // Conditionally validate password based on email existence
      is: (email) => email && email.trim().length > 0,
      then: Yup.string()
        .required('Password is Required')
        .min(6, 'Password should be at least 6 characters long'),
    }),
  });

  const signUpValidationSchema = Yup.object().shape({
    email: Yup.string().email().required('Email is Required'),
  });

  const handleResetPasswordCLick = (email) => {
    navigate('/passwordrecovery', {
      state: { email: email || '' },
    });
  };

  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const CustomSnackbar = forwardRef((props) => {
    return (
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: `cneter` }}
        open={Boolean(isSuccess)}
        autoHideDuration={3000} // Snackbar will be closed manually
        onClose={() => {
          dispatch(actions.Auth.setSuccess(null));
        }}
        sx={{ marginTop: '25%' }}
        {...props}>
        <Alert icon={<CheckIcon />} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>
    );
  });

  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(false);
        await handleSubmitWithFormik(values);
      }}
      // Define Validations
      validationSchema={
        isEmailExists ? signInValidationSchema : signUpValidationSchema
      }>
      {(props) => {
        const {
          values,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          isValid,
          setValues,
          touched,
        } = props;

        const handleEmailChange = (event) => {
          const { value } = event.target;
          handleChange(event); // Let Formik handle the change event
          setIsEmailExists(null); // Reset email existence state
          setProvidersList([]);
          setIsPasswordFieldVisible(false);
          if (value && value.trim().length > 0) {
            // Only check email existence if the value is not empty
            checkEmailExists(value);
          }
        };

        const handleEmailBlur = (emailValue) => {
          const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
          const isValidEmail = emailRegex.test(emailValue);

          if (isValidEmail) {
            setIsEmailValid(true);
          } else {
            setIsEmailValid(false);
          }

          // Calling the custom onBlur of formik
          handleBlur('email');
        };

        // To Empty the password field each time
        useEffect(() => {
          if (isPasswordFieldVisible) {
            setValues((prevValues) => ({
              ...prevValues,
              password: '',
            }));
          }
        }, [isPasswordFieldVisible]);

        return (
          <Box>
            <Box flexDirection="column" display="flex">
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}>
                <CustomSnackbar className={classes.customSnackbar} />
                <Typography
                  variant="h2"
                  sx={{
                    fontFamily: 'Work Sans',
                    fontSize: '2rem',
                    fontWeight: '600',
                    lineHeight: '20px',
                  }}>
                  {!(isPasswordFieldVisible || isSocialMediaSignIn)
                    ? 'Welcome to Pilot'
                    : 'Sign In'}
                </Typography>
              </Box>

              {!(isPasswordFieldVisible || isSocialMediaSignIn) && (
                <Box sx={{ marginTop: '32px' }}>
                  <SocialMediaAuth showByLiner={false} />
                </Box>
              )}
            </Box>
            {!(isPasswordFieldVisible || isSocialMediaSignIn) && (
              <Box display="flex" justifyContent="center" my="1rem">
                <div className={classes.horizontalLine}>
                  <span className={classes.horizontalLineText}>or</span>
                </div>
              </Box>
            )}

            {!(isPasswordFieldVisible || isSocialMediaSignIn) && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '1.5rem',
                }}>
                <Typography
                  sx={{
                    fontFamily: 'UI/H4, Inter',
                    fontWeight: '500',
                    fontSize: '1rem',
                    color: '#4E4E4E',
                  }}>
                  Please enter your email.
                </Typography>
              </Box>
            )}

            {isPasswordFieldVisible && !isError && (
              <Box>
                <Typography
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    paddingTop: '0.625rem',
                    marginTop: '2.5rem',
                    marginBottom: '2.1875rem',
                    fontFamily: 'UI/H4, Inter',
                    fontWeight: '500',
                    fontSize: '1rem',
                    color: '#4E4E4E',
                  }}>
                  Please enter your password
                </Typography>
              </Box>
            )}

            {isError && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '1.5rem 0',
                }}>
                <FormHelperText error className={classes.error}>
                  {errors}
                  {errors?.includes('entered is incorrect') && (
                    <Box sx={{ display: 'flex' }}>
                      <Typography
                        sx={{
                          color: '#ED702E',
                          fontSize: '14px',
                          fontWeight: '500',
                          lineHeight: '1rem',
                        }}>
                        You can{' '}
                        <Link
                          sx={{ cursor: 'pointer' }}
                          onClick={() =>
                            handleResetPasswordCLick(values.email)
                          }>
                          reset your password here
                        </Link>
                        .
                      </Typography>
                    </Box>
                  )}
                </FormHelperText>
              </Box>
            )}

            <form
              autoComplete="on"
              onSubmit={handleSubmit}
              className={`${classes.form} ${classList.signinForm}`}
              noValidate>
              <Box mb="0.5rem">
                <InputAuthForm
                  label="E-mail"
                  placeholder="Enter your email..."
                  name="email"
                  autoComplete="email"
                  disabled={isSocialMediaSignIn}
                  // required
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                    style: {
                      color: '#8A8A8A',
                    },
                    required: false,
                  }}
                  InputProps={{
                    sx: {
                      '&::placeholder': {
                        color: '#8A8A8A',
                      },
                      '&::label': {
                        color: 'red',
                      },
                    },
                    endAdornment: isEmailExists ? (
                      <InputAdornment position="end">
                        <CheckIcon sx={{ color: '#3AA031' }} />
                      </InputAdornment>
                    ) : null,
                  }}
                  value={values.email}
                  onChange={handleEmailChange}
                  onBlur={() => handleEmailBlur(values.email)}
                />
              </Box>
              {isPasswordFieldVisible && ( // Conditionally render password field if email exists providerList has passowrd in it
                <InputAuthForm
                  label="Password"
                  placeholder="Enter Password..."
                  name="password"
                  type={isPasswordVisible ? 'text' : 'password'}
                  autoComplete="password"
                  // required
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                    style: { color: '#8A8A8A', margin: '0px' },
                    required: false,
                  }}
                  InputProps={{
                    sx: {
                      marginBottom: '0.5rem',
                      '&::placeholder': {
                        color: '#8A8A8A',
                      },
                      '&::label': {
                        color: 'red',
                      },
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handlePasswordVisibility}>
                          {isPasswordVisible ? (
                            <Visibility sx={{ color: '#141414' }} />
                          ) : (
                            <VisibilityOff sx={{ color: '#141414' }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                />
              )}

              {!isSocialMediaSignIn ? (
                <FullPageButton
                  id="LetsBeginButton"
                  type="submit"
                  sx={{
                    height: '3rem',
                    '&.Mui-disabled': {
                      background: '#D9D9D9',
                      color: '#8A8A8A',
                    },
                  }}
                  disabled={
                    !isEmailValid ||
                    isEmailExists === null ||
                    (isEmailExists &&
                      !isPasswordFieldVisible &&
                      !touched.email &&
                      (!isValid || isSubmitting))
                  }
                  onClick={
                    isEmailExists
                      ? providersList.includes('password')
                        ? isPasswordFieldVisible
                          ? handleSignin
                          : handlePasswordToggle
                        : handleSocialMediaLogin
                      : () => {}
                  }>
                  Let&apos;s Begin
                </FullPageButton>
              ) : providersList.includes('google.com') ? (
                <ButtonWithIcon
                  onClick={handleGoogleSignin}
                  style={{
                    backgroundColor: '#FFFFFF',
                    borderColor: '#D9D9D9',
                    width: '100%',
                    height: '3rem',
                  }}
                  variant="contained">
                  <GoogleLogo />
                  <Box ml="1.3rem" className={classes.grayColor}>
                    Continue with Google
                  </Box>
                </ButtonWithIcon>
              ) : (
                <ButtonWithIcon
                  onClick={handleFacebookSignin}
                  style={{
                    backgroundColor: '#1877F2',
                    width: '100%',
                    height: '3rem',
                  }}
                  variant="contained">
                  <FacebookLogo />
                  <Box ml="1rem">Continue with Facebook</Box>
                </ButtonWithIcon>
              )}
            </form>
            {(isPasswordFieldVisible || isSocialMediaSignIn) && (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center">
                <Typography
                  component="span"
                  variant="h5"
                  onClick={handleResetCLick}
                  sx={{
                    padding: '15px 0',
                    color: 'text.light', // Apply link-like color
                    textDecoration: 'underline', // Apply link-like underline
                    cursor: 'pointer', // Show pointer cursor on hover
                  }}>
                  <LinkRoute to="/continue">Not you? Go back. </LinkRoute>
                </Typography>
              </Box>
            )}
            {!isSocialMediaSignIn && (
              <Box sx={{ padding: '8px 0' }}>
                <Link
                  sx={{
                    cursor: 'pointer',
                    textDecoration: 'none',
                    fontSize: '14px',
                  }}
                  className={classList.forgotPasswordBtn}
                  onClick={() => handleResetPasswordCLick(values.email)}>
                  Forgot Password?
                </Link>
              </Box>
            )}
            {/* Logo */}
            <Box pt={5} pr={1.5}>
              <Logo fullLogo imageWidth="30%" />
            </Box>
          </Box>
        );
      }}
    </Formik>
  );
}

export default SigninForm;
