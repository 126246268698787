import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Badge, IconButton, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import styled from '@emotion/styled';
import { tooltipClasses } from '@mui/material/Tooltip';
import { StaysBookmarkIcon } from '../../atoms/Icon';
import actions from '../../../redux/actions';

const useStyles = makeStyles(() => ({
  container: {
    flex: 1,
    '&:hover': {
      backgroundColor: '#F4F4F4',
      cursor: 'pointer',
    },
    '&.Mui-disabled': {
      backgroundColor: '#D9D9D9',
    },
  },
}));

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #D9D9D9',
    color: '#000000',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '19px',
  },
}));

export default function SavedIconButton({ selectedItem }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { slug: tripId } = useParams();

  const savedItems =
    selectedItem === 0
      ? useSelector((state) => Object.values(state.Bookings.saved[tripId]))
      : useSelector((state) =>
          Object.values(state.Recommendations.saved[tripId])
        );

  const handleSavedIconClick = () => {
    dispatch(
      actions.Bookings.setIsSaveListVisible({
        tripId,
        isSaveListVisible: true,
      })
    );
  };

  return (
    <CustomTooltip
      title="Please add stays to the saved list!"
      disableHoverListener={savedItems?.length !== 0}>
      <IconButton
        disabled={savedItems?.length === 0}
        py={3}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: 55,
          height: 40,
          border: '1px solid #D9D9D9',
          borderRadius: '24px',
        }}
        className={classes.container}
        onClick={handleSavedIconClick}>
        <Badge
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          badgeContent={Number(savedItems?.length)}
          color="primary"
          sx={{
            '.MuiBadge-badge': {
              width: 18,
              height: 18,
              fontSize: 12,
            },
          }}>
          <StaysBookmarkIcon style={{ width: '12px', height: '12px' }} />
        </Badge>
      </IconButton>
    </CustomTooltip>
  );
}
