import { makeStyles } from '@mui/styles';
import IconDatePicker from '../../molecules/IconDatePicker';

const useStyles = makeStyles((theme) => ({
  iconDateContainer: {
    margin: 4,
    padding: '0 12px',
    fontWeight: 400,
    height: '40px',
    backgroundColor: '#FFFFFF',
    color: '#4E4E4E',
    borderRadius: 24,
    border: `1px solid #D9D9D9`,
    // zIndex: theme.zIndex.drawer + 1,
    '& .MuiButton-startIcon': {
      '& svg': {
        transition: '0.25s',
      },
    },
    '&:hover': {
      color: '#4E4E4E',
      backgroundColor: '#F4F4F4',
      '& .MuiButton-startIcon': {
        '& svg': {
          color: theme.palette.primary.light,
        },
      },
    },
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'clip',
    flexShrink: 1,
  },
  icon: {
    fontSize: '14px',
    color: '#4E4E4E',
  },
}));

function CheckInCheckOutButton({
  date = {
    from: new Date(Date.now() + 2 * 24 * 60 * 60 * 1000),
    to: new Date(Date.now() + 4 * 24 * 60 * 60 * 1000),
  },
  updateDate = () => {},
  overlayOpen = false,
  setOverlayOpen = () => {},
  setfetchFlip = () => {},
  dateStringFormat = 'eee, MM/dd/yy',
  disableScroll = false,
}) {
  const classes = useStyles();

  return (
    <IconDatePicker
      useRange
      defaultDate={date}
      onDateUpdate={(d) => {
        updateDate(d);
        // setOverlayOpen(false);
      }}
      displayDate
      customIcon
      dateStringFormat={dateStringFormat}
      iconButtonProps={{
        size: 'small',
        className: classes.iconDateContainer,
        style: { zIndex: overlayOpen ? 1121 : null },
      }}
      iconProps={{
        fontSize: 'small',
        className: classes.icon,
      }}
      numberOfMonths={2}
      isBookings
      onButtonClick={() => {
        setOverlayOpen(true);
      }}
      onCalanderClose={() => {
        setOverlayOpen(false);
        setfetchFlip((flip) => !flip);
      }}
      disableScroll={disableScroll}
    />
  );
}

export default CheckInCheckOutButton;
