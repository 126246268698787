import { useState } from 'react';
import { CloseRounded } from '@mui/icons-material';
import { Popper, IconButton, Typography, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import copy from 'copy-to-clipboard';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { deleteFile } from '../../../../redux/slices/Files';
import ImportFileCard from '../../Card/ImportFileCard';
import actions from '../../../../redux/actions';
import { FilePreviewModal } from '../index';

const useStyles = makeStyles(({ breakpoints, palette }) => ({
  // Categories
  popper: {
    height: 'calc(100% - 90px)',
    marginTop: 90,
    zIndex: 801,
  },
  importsContainer: {
    height: '100%',
    overflow: 'hidden',
    width: 540,
    [breakpoints.down('sm')]: {
      minWidth: '100%',
      width: '100%',
    },
    [breakpoints.down('md')]: {
      width: 400,
    },
    backgroundColor: '#FFF',
    boxShadow: '0px 0px 4px rgba(0,0,0,0.1)',
    borderRadius: '0px 4px 4px 0px',
    display: 'flex',
    flexDirection: 'column',
    padding: 24,
    paddingRight: 16,
  },
  bottomContainer: {
    flex: 1,
    display: 'flex',
    padding: '14px 0px',
    flexDirection: 'column',
    minHeight: 0,
  },
  topContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    paddingBottom: 14,
    borderBottom: '1px solid #DEDDDD',
  },
  linkContainer: {
    backgroundColor: '#F4F4F4',
    width: '100%',
    display: 'flex',
    padding: '8px 12px',
    borderRadius: 4,
    alignItems: 'center',
    marginTop: 10,
    flexWrap: 'wrap',
  },
  linkCopyButton: {
    backgroundColor: '#FFF',
    color: '#4E4E4E',
    padding: '6px',
    lineHeight: '140%',
    fontSize: 12,
    '&:hover': {
      backgroundColor: '#FFF',
      color: palette.primary.light,
    },
  },
  activeButton: {
    pointerEvents: 'none',
    backgroundColor: palette.success.main,
    color: '#FFF',
  },
}));

function ImportFilesModal({
  modalAnchor = null,
  onClose = () => {},
  openAttachModal = () => {},
}) {
  const classes = useStyles();
  const [copied, setCopied] = useState();
  const { slug: tripId } = useParams();
  const linkText = `${
    process.env.REACT_APP_ENVIRONMENT === 'local' ? 'ranjan' : 'import'
  }+${tripId}@pilotplans.com`;
  const dispatch = useDispatch();

  const handleLinkCopy = () => {
    if (copied) return;
    setCopied(true);
    copy(linkText);
    setTimeout(() => setCopied(false), 3000);
  };

  const importedFiles = useSelector(
    (state) => state.Trips.trips[tripId].importedFiles
  );

  const handleFileDelete = (fileId) => {
    dispatch(
      deleteFile({
        variables: {
          id: fileId,
          fileRelation: [],
          isImported: true,
        },
      })
    );
    dispatch(actions.Trips.removeImportedFile({ tripId, fileId }));
  };

  return (
    <Popper
      open={Boolean(modalAnchor)}
      id="import-booking-popper"
      anchorEl={modalAnchor}
      placement="right-start"
      className={classes.popper}>
      <div className={classes.importsContainer}>
        <div className={classes.topContainer}>
          <div style={{ display: 'flex' }}>
            <div style={{ flex: 1 }}>
              <Typography variant="h4Sub">Import files</Typography>
              <Typography
                variant="h5"
                fontSize={14}
                color="text.light"
                marginTop="6px">
                Import any attached files from your email address!
              </Typography>
              <div className={classes.linkContainer} onClick={handleLinkCopy}>
                <div
                  style={{
                    minWidth: 0,
                    flexBasis: '50%',
                    display: 'flex',
                    flex: 1,
                  }}>
                  <Typography
                    style={{
                      pointerEvents: 'none',
                    }}
                    textOverflow="ellipsis"
                    noWrap
                    fontSize={16}
                    color="text.secondary">
                    {linkText}
                  </Typography>
                </div>
                <Button
                  className={`${classes.linkCopyButton} ${
                    copied ? classes.activeButton : ''
                  }`}
                  id="import-bookings-copy-email-button"
                  style={{
                    display: 'flex',
                    whiteSpace: 'nowrap',
                  }}
                  onClick={handleLinkCopy}
                  disableRipple>
                  {copied ? 'Copied!  ' : 'Copy Email'}
                </Button>
              </div>
            </div>
            <div>
              <IconButton
                sx={{ padding: '4px', color: '#1D1D1D' }}
                size="small"
                onClick={onClose}>
                <CloseRounded />
              </IconButton>
            </div>
          </div>
        </div>
        <div className={classes.bottomContainer}>
          <Typography textTransform="uppercase" fontSize={12} fontWeight={600}>
            Recently imported files
          </Typography>
          <div
            style={{
              width: '100%',
              marginTop: 10,
              overflowY: 'scroll',
              flex: 1,
            }}>
            {importedFiles?.map((file) => (
              <div
                key={file?.id}
                style={{
                  marginTop: 8,
                }}>
                <ImportFileCard
                  openAttachModal={openAttachModal}
                  handleFileDelete={handleFileDelete}
                  {...file}
                />
              </div>
            ))}
          </div>
        </div>

        <FilePreviewModal isImported />
      </div>
    </Popper>
  );
}

export default ImportFilesModal;
