const CitiesList = [
  'London',
  'Paris',
  'Madrid',
  'Berlin',
  'Rome',
  'Milan',
  'Istanbul',
  'Athens',
  'Barcelona',
  'Dublin',
  'Edinburgh',
  'Budapest',
  'Amsterdam',
  'Brussels',
  'Prague',
  'Munich',
  'St. Petersburg',
  'Moscow',
  'Stockholm',
  'Oslo',
  'Copenhagen',
  'Reykjavík',
  'Tokyo',
  'Kyoto',
  'Dubai',
  'Shanghai',
  'Beijing',
  'Goa',
  'Hong Kong',
  'Bangkok',
  'Hanoi',
  'Singapore',
  'Kuala Lumpur',
  'Phuket',
  'Phenom Penh',
  'New Delhi',
  'Kathmandu',
  'Tel Aviv',
  'Petra',
  'Banff',
  'Montreal',
  'Toronto',
  'New York',
  'Miami',
  'Los Angeles',
  'San Francisco',
  'Austin',
  'Maui',
  'Mexico City',
  'Boston',
  'New Orleans',
  'Portland',
  'Seattle',
  'Chicago',
  'Rio de Janeiro',
  'Buenos Aires',
  'Sydney',
  'Auckland',
  'Cairo',
  'Marrakech',
  'Cape Town',
];

export default CitiesList;
