import React from 'react';
import { IconButton } from '@mui/material';
import { useDispatch } from 'react-redux';
import { CloseRounded } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import SocialComponent from './SocialComponent';
import actions from '../../../../redux/actions';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#FFF',
    flexDirection: 'column',
    display: 'flex',
    outline: 'none',
    borderRadius: 8,
    height: 310,
    overflow: 'auto',
    width: 355,
    padding: '28px',
    textAlign: 'center',
    position: 'absolute',
  },
}));

function AlternateSupport() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleClose = () =>
    dispatch(actions.View.setContribute({ action: -1 }));

  return (
    <div className={classes.root}>
      <IconButton
        sx={{ position: 'absolute', top: 4, right: 0 }}
        onClick={handleClose}>
        <CloseRounded sx={{ height: 16, width: 16 }} />
      </IconButton>
      <SocialComponent />
    </div>
  );
}

export default AlternateSupport;
