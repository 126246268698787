import { createSlice } from '@reduxjs/toolkit';
import getAsyncThunk from '../helpers';
import QUERY from '../../graphql/queries';

const initialState = {
  hotels: {},
};

export const createAccommodation = getAsyncThunk(
  'ACCOMMODATION/createAccommodation',
  QUERY.CREATE_ACCOMMODATION
);

export const updateAccommodation = getAsyncThunk(
  'ACCOMMODATION/updateAccommodation',
  QUERY.UPDATE_ACCOMMODATION
);

export const deleteAccommodation = getAsyncThunk(
  'ACCOMMODATION/deleteAccommodation',
  QUERY.DELETE_ACCOMMODATION
);

const AccommodationSlice = createSlice({
  name: 'ACCOMMODATION',
  initialState,
  reducers: {
    initializeAccommodations: (state, action) => {
      return {
        ...state,
        hotels: { ...state.hotels, ...action.payload },
      };
    },
    deleteAccommodationFile: (
      state,
      { payload: { accommodationId, fileId } }
    ) => {
      state.hotels[accommodationId] = {
        ...state.hotels[accommodationId],
        files: state.hotels[accommodationId].files.filter(
          (file) => file !== fileId
        ),
      };
    },
    handleAccommodationFiles: (
      state,
      { payload: { fileId, itemsToAttach, itemsToDetach } }
    ) => {
      if (itemsToAttach?.length > 0) {
        itemsToAttach?.forEach((item) => {
          state.hotels[item.id].files = [
            ...state.hotels[item.id].files,
            fileId,
          ];
        });
      }
      if (itemsToDetach?.length > 0) {
        itemsToDetach?.forEach((item) => {
          state.hotels[item.id].files = state.hotels[item.id].files.filter(
            (file) => file !== fileId
          );
        });
      }
    },
  },
  extraReducers: {
    [createAccommodation.fulfilled]: (state, { payload, meta }) => {
      const { id: accommodationId } = payload.createAccommodation;
      const { variables } = meta.arg;
      state.hotels[accommodationId] = { id: accommodationId, ...variables };
    },
    [updateAccommodation.fulfilled]: (state, { payload }) => {
      const updated = payload.updateAccommodation;
      state.hotels[updated.id] = {
        ...updated,
        mapPin: updated?.mapPin?.id,
        files: updated?.files?.map((file) => file.id) || [],
      };
    },
    [deleteAccommodation.fulfilled]: (state, { meta }) => {
      const { id: accommodationId } = meta.arg.variables;
      delete state.hotels[accommodationId];
    },
  },
});

export const AccommodationActions = AccommodationSlice.actions;
export const AccommodationReducer = AccommodationSlice.reducer;
