const { Stack } = require('@mui/material');

function Container({ children }) {
  return (
    <Stack
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center">
      <div
        style={{
          backgroundColor: '#FFF',
          height: 450,
          width: 390,
          borderRadius: 4,
          display: 'flex',
          padding: 24,
          flexDirection: 'column',
        }}>
        {children}
      </div>
    </Stack>
  );
}

export default Container;
