import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useRef, useEffect } from 'react';
import { LocationOn } from '@mui/icons-material';
import { IconButton, InputBase } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';

import { BasicButton } from '../../../atoms/Button/index';

import { PlacesSearchBar } from '../../SearchBar';
import { useMapUtils } from '../../../organisms/MapUtils';
import { LocationMapPinIcon } from '../../../atoms/Icon';
import actions from '../../../../redux/actions';
import classList from '../../../classList';
import { useSounds, SOUNDS } from '../../../../sounds';

const useStyles = makeStyles(({ palette, typography }) => ({
  input: {
    ...typography.h4,
    color: palette.text.secondary,
    '&::placeholder': {
      color: palette.text.light,
    },
  },
  exploreSearchbar: {
    backgroundColor: '#F4F4F4',
    borderRadius: 6,
    padding: '12px 8px',
    border: '1px solid transparent',
    '&:hover': {
      boxShadow: `0px 0px 0px 2px ${palette.primary.extraLight}`,
    },
  },
  exploreSearchbarFocused: {
    boxShadow: `0px 0px 0px 2px ${palette.primary.extraLight}`,
    border: '1px solid',
    borderColor: palette.primary.main,
  },
  searchButton: {
    backgroundColor: palette.primary.main,
    color: '#fff',
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: palette.primary.light,
    },
    '&:disabled': {
      backgroundColor: palette.system.lightgrey,
    },
  },
  searchPlaces: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '16px',
    marginTop: '15px',
  },
  searchPlaceTag: {
    padding: '10px 11px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: 6,
    color: '#707070',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    transition: '0.2s',
    '&:hover': {
      color: '#222222',
      '& $tagIcon': {
        color: '#E5493D',
      },
    },
  },
  tagIcon: {
    transition: '0.2s',
    color: '#F58F81',
    marginRight: 8,
  },

  // Search option list
  popper: {},
  list: {
    width: '100%',
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.15)',
    backgroundColor: '#FFF',
    borderRadius: 16,
    marginTop: 8,
    padding: '10px',
  },
  listItem: {
    ...typography.h5,
    width: '100%',
    borderBottom: '1px solid #DEDDDD',
    cursor: 'pointer',
    color: palette.text.secondary,
  },
}));

function RecommendationCitySearchBar({ onSelect, searchTags = [], city }) {
  const dispatch = useDispatch();
  const { playSound } = useSounds();
  const { slug: tripId } = useParams();
  // Search settings
  const searchValue = useSelector((state) => state.View.exploreCitySearch);
  const setSearchValue = (value) =>
    dispatch(actions.View.setExploreCitySearch(value));

  const classes = useStyles();

  const inputRef = useRef();
  const searchbarRef = useRef();

  const handleTagClick = (e, searchTag) => {
    if (!searchTag) return;
    setSearchValue(searchTag?.title);
    onSelect({
      id: searchTag?.id,
      placeId: searchTag?.placeId,
      coordinates: {
        latitude: searchTag?.latitude,
        longitude: searchTag?.longitude,
      },
      title: searchTag?.title,
    });
    searchbarRef.current?.click();
  };

  useEffect(() => {
    if (city?.title !== searchValue && city?.title?.length > 0) {
      setSearchValue(city?.title);

      if (!city?.coordinates) {
        // workaround to render focus after page is rendered to prevent popper scroll
        setTimeout(() => inputRef?.current?.focus(), 400);
      }
    }
  }, [city?.title]);

  const { getPlaceDetails } = useMapUtils();
  const handleLocationSelect = async (locationResult) => {
    const placeId = locationResult?.place_id;
    playSound(SOUNDS.softPop);
    const details = await getPlaceDetails(placeId, 'RECOMMENDATION-CITY', true);

    onSelect({
      id: placeId,
      coordinates: {
        latitude: details?.lat,
        longitude: details?.long,
      },
      placeId,
      title: details?.title,
    });
  };
  const handleCloseButtonClick = () => {
    setSearchValue('');
    dispatch(actions.Recommendations.resetCity({ tripId }));
  };

  const dynamicEndIcons =
    searchValue.length > 0 ? (
      <IconButton onClick={handleCloseButtonClick}>
        <CloseIcon />
      </IconButton>
    ) : null;

  return (
    <>
      <div
        className={classList.recommendationsSearchbarContainer}
        style={{ width: '100%' }}>
        <PlacesSearchBar
          fullWidth
          autoSearch
          openOnFocus
          variant="standard"
          inputRef={inputRef}
          id="recommendations-search"
          onChange={(search) => setSearchValue(search)}
          tripLocation={false}
          handleSelect={(option) => {
            handleLocationSelect(option);
            inputRef?.current?.blur();
          }}
          popperPlacement="bottom"
          CustomSearchBar={InputBase}
          value={searchValue}
          controlledValue={searchValue}
          searchBarProps={{
            variant: 'standard',
            fullWidth: true,
            inputProps: {
              style: {
                padding: 0,
              },
            },
            placeholder: 'Search for a location to get started',
            classes: {
              root: classes.exploreSearchbar,
              focused: classes.exploreSearchbarFocused,
            },
            startIcon: (
              <LocationMapPinIcon
                style={{
                  marginRight: 8,
                  marginLeft: 4,
                }}
              />
            ),
            endIcon: dynamicEndIcons,
            onKeyDown: (e) => {
              if (e.key === 'Enter') {
                e.target.blur();
              }
            },
          }}
        />
      </div>

      {!searchValue.length ? (
        <div className={classes.searchPlaces}>
          {searchTags?.map((tag, i) => (
            <BasicButton
              sx={{
                color: 'text.secondary',
                borderRadius: 24,
                padding: '6px 12px',
                '&:hover': { color: 'text.secondary' },
                '& > .MuiButton-startIcon': {
                  marginRight: '4px',
                },
              }}
              key={i}
              startIcon={<LocationOn sx={{ height: 16, width: 16 }} />}
              onClick={(e) => handleTagClick(e, tag)}>
              {tag?.title}
            </BasicButton>
          ))}
        </div>
      ) : null}
    </>
  );
}

export default RecommendationCitySearchBar;
