import { Navigate, Route } from 'react-router-dom';
import SentryRoutes from './SentryRoutes';
import Trips from '../components/pages/Trips';
import Itinerary from '../components/pages/Itinerary';
import Friends from '../components/pages/Friends';
import Activity from '../components/pages/Activity';
import Settings from '../components/pages/Settings';
import Wishlist from '../components/pages/Wishlist';
import Discover from '../components/pages/Discover';
import Offers from '../components/pages/Offers';
import ProfilePage from '../components/pages/ProfilePage';
import Destination from '../components/pages/Destination';
import DestinationArticle from '../components/pages/DestinationArticle';
import HotelDetails from '../components/pages/HotelDetails';
import CreateATripFlow from '../components/pages/CreateATripFlow';
import JoinTrip from '../components/pages/JoinTrip';

function AppRoutes() {
  return (
    <SentryRoutes>
      <Route path="/trips" element={<Trips />} />
      <Route path="/trips/:slug/:tab" element={<Itinerary />} />
      <Route path="/join/:slug" element={<JoinTrip />} />
      <Route path="/wishlist" element={<Wishlist />} />
      <Route path="/discover" element={<Discover />} />
      <Route path="/discover/:slug" element={<Destination />} />
      <Route
        path="/discover/:slug/article/:id"
        element={<DestinationArticle />}
      />
      <Route path="/offers" element={<Offers />} />
      <Route path="/friends" element={<Friends />} />
      <Route path="/activities" element={<Activity />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/profile/:id" element={<ProfilePage />} />
      <Route path="/hotel/:slug" element={<HotelDetails />} />
      <Route path="/create-a-trip/:slug" element={<CreateATripFlow />} />
      <Route path="*" element={<Navigate replace to="/trips" />} />
    </SentryRoutes>
  );
}

export default AppRoutes;
