import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { CircularProgress, Typography } from '@mui/material';
import AppLayout from '../templates/AppLayout';
import DestinationArticlePage from '../organisms/Discover/DestinationArticlePage';
import { fetchCityInfo, fetchCities } from '../../redux/slices/Discover';

function DestinationArticle() {
  const { id: blogId, slug: cityId } = useParams();
  const [shouldRender, setShouldRender] = useState(false);
  const dispatch = useDispatch();

  const citiesFetchStatus = useSelector(
    (state) => state.Discover.cities?.status || 'IDLE'
  );
  const cityFetchStatus = useSelector(
    (state) => state.Discover.cities?.data[cityId]?.status || 'IDLE'
  );

  useEffect(() => {
    if (citiesFetchStatus === 'SUCCESS' && cityFetchStatus === 'SUCCESS')
      setShouldRender(true);
    else {
      if (citiesFetchStatus === 'IDLE') dispatch(fetchCities());
      if (cityFetchStatus === 'IDLE') dispatch(fetchCityInfo({ cityId }));
    }
  }, [citiesFetchStatus, cityFetchStatus]);

  const destinationArticle = useSelector(
    (state) =>
      state.Discover.cities?.data[cityId]?.blogs?.find(
        (blog) => blog.slug === blogId
      ) || null
  );
  return (
    <AppLayout>
      {shouldRender ? (
        destinationArticle ? (
          <DestinationArticlePage
            article={destinationArticle}
            destination={cityId}
          />
        ) : (
          <div
            style={{
              height: '80vh',
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Typography variant="h3Sub" align="center">
              Hm.. That does not look right, try again with a different link!
            </Typography>
          </div>
        )
      ) : (
        <CircularProgress />
      )}
    </AppLayout>
  );
}

export default DestinationArticle;
