import * as prismic from '@prismicio/client';

// Fill in your repository name
export const repositoryName = 'pilot-web';

export const client = prismic.createClient(repositoryName, {
  accessToken: process.env.REACT_APP_PRISMIC_ACCESS_TOKEN,

  routes: [
    {
      type: 'affiliate_deals_card',
      path: '/offers',
    },
  ],
});
