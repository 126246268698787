import { useState, useEffect, useRef } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AddRounded, AddCircleRounded } from '@mui/icons-material';
import {
  addDoc,
  collection,
  updateDoc,
  query,
  where,
  getDocs,
  deleteDoc,
  doc,
} from 'firebase/firestore';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import NoteIndex from '../molecules/NoteIndex';
import NoteContainer from '../organisms/NoteContainer';
import LoadingLayout from '../templates/LoadingLayout';
import { firestore } from '../../firebase/FirebaseIndex';
import { updateTrip } from '../../redux/slices/Trips';
import { trackEvents, Events } from '../../intercom';
import { EVENTS, phTrackEvent } from '../../analytics';

const useStyles = makeStyles(({ palette }) => ({
  container: {
    display: 'flex',
    height: 'calc(-90px + 100vh)',
    padding: '32px 0',
    backgroundColor: 'white',
    position: 'relative',
    marginTop: '10px',
  },
  notesList: {
    display: 'flex',
    flexDirection: 'column',
    width: '20vw',
    alignItems: 'center',
    minWidth: 150,
    padding: '0 16px',
  },
  notesBox: {
    display: 'flex',
    width: '80vw',
    justifyContent: 'center',
    overflow: 'auto',
    padding: '0 16px',
  },
  newNote: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    marginTop: '8px',
    backgroundColor: 'transparent',
    padding: '8px 8px 8px 12px',
    color: palette.primary.main,
    border: `1px dashed ${palette.primary.main}`,
    borderRadius: 4,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  loadingBox: {
    display: 'block',
    position: 'absolute',
    height: 'calc(-90px + 100vh)',
    width: '100%',
    top: 0,
    left: 0,
    background: '#FFF',
  },
}));

const newNoteMaker = () => {
  const creationDate = new Date();
  return {
    name: '',
    content: [
      {
        type: 'paragraph',
        children: [{ text: '' }],
      },
    ],
    createdDate: creationDate,
    updatedDate: creationDate,
  };
};

function Notes({ tripID, tripNotes }) {
  const classes = useStyles();
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newHovered, setNewHovered] = useState(false);
  const [chosenNote, setChosenNote] = useState(null);
  const dispatch = useDispatch();

  const tripTitle = useSelector((state) => state.Trips.trips[tripID]?.title);

  const ref = useRef({
    notesIDs: [],
  });

  const updateTripNotes = () => {
    dispatch(
      updateTrip({
        variables: {
          id: tripID,
          notes: ref.current.notesIDs,
        },
      })
    );
  };

  useEffect(() => {
    (async () => {
      setLoading(true);

      let notesData = [];

      if (!tripNotes?.length) {
        const newNote = newNoteMaker();

        const packingListNote = {
          ...newNote,
          name: 'Packing List',
          content: [
            {
              type: 'paragraph',
              children: [
                {
                  text: 'Use this space to make lists or notes of anything for your trip! We’ve added some must-haves to get you started 🙂',
                },
              ],
            },
            {
              type: 'check-list-item',
              checked: false,
              children: [{ text: 'Passport' }],
            },
            {
              type: 'check-list-item',
              checked: false,
              children: [{ text: 'Towel' }],
            },
            {
              type: 'check-list-item',
              checked: false,
              children: [{ text: 'Sunscreen' }],
            },
            {
              type: 'check-list-item',
              checked: false,
              children: [{ text: 'Try it yourself!' }],
            },
          ],
        };
        const anotherNote = {
          ...newNote,
          name: 'New Note',
          content: [
            {
              type: 'paragraph',
              children: [
                {
                  text: 'Trip notes, reminders, love letters, use this to write whatever you wish!',
                },
              ],
            },
          ],
        };
        const packingDocRef = await addDoc(
          collection(firestore, 'notes'),
          packingListNote
        );
        ref.current.notesIDs.push(packingDocRef.id);
        const anotherDocRef = await addDoc(
          collection(firestore, 'notes'),
          anotherNote
        );
        ref.current.notesIDs.push(anotherDocRef.id);
        notesData = [
          {
            noteId: packingDocRef.id,
            noteData: packingListNote,
          },
          {
            noteId: anotherDocRef.id,
            noteData: anotherNote,
          },
        ];
        updateTripNotes();
      } else {
        ref.current.notesIDs = tripNotes;

        // firestore limitation, query 10 at a time.
        const splittedNotes = [];
        for (let i = 0; i < tripNotes.length; i += 10) {
          splittedNotes.push(tripNotes.slice(i, i + 10));
        }
        const notesPromises = splittedNotes.map((notesIDs) =>
          getDocs(
            query(
              collection(firestore, 'notes'),
              where('__name__', 'in', notesIDs)
            )
          )
        );
        const retrivedNotes = await Promise.all(notesPromises);
        retrivedNotes?.map((noteDocIterable) =>
          noteDocIterable.forEach((notesDoc) => {
            notesData.push({
              noteId: notesDoc.id,
              noteData: notesDoc.data(),
            });
          })
        );
        // Sorting notes by creation date (Ascending order)
        notesData.sort((note1, note2) => {
          return note1.noteData.createdDate < note2.noteData.createdDate
            ? -1
            : 1;
        });
      }

      const lastEditedNote = localStorage.getItem(`lastEditedNote_${tripID}`);
      if (lastEditedNote) {
        const noteIndex = notesData.findIndex(
          (note) => note.noteId === lastEditedNote
        );
        setChosenNote(noteIndex !== -1 ? noteIndex : 0);
      } else {
        setChosenNote(0);
      }

      setNotes(notesData);
      setLoading(false);
    })();
  }, [tripID]);

  const newNoteHandler = async () => {
    const newIdx = notes.length;
    const newNote = newNoteMaker();
    const docRef = await addDoc(collection(firestore, 'notes'), newNote);
    setNotes([
      ...notes,
      {
        noteId: docRef.id,
        noteData: newNote,
      },
    ]);
    setChosenNote(newIdx);
    ref.current.notesIDs = [...ref.current.notesIDs, docRef.id];
    trackEvents(Events.NoteCreated);
    phTrackEvent({
      event: EVENTS.TRIP_NOTES.NOTE_CREATE,
    });
    updateTripNotes();
  };

  function NewNoteButton() {
    return (
      <Box
        className={classes.newNote}
        onMouseEnter={() => setNewHovered(true)}
        onMouseLeave={() => setNewHovered(false)}
        onClick={() => {
          setNewHovered(false);
          newNoteHandler();
        }}>
        {newHovered ? <AddCircleRounded /> : <AddRounded />}
        <Typography style={{ marginLeft: '10px' }}> New Note </Typography>
      </Box>
    );
  }

  const updateNoteHandler = (newNote) => {
    const updatedNote = { ...newNote, updatedDate: new Date() };
    const updatedNotes = [...notes];
    updatedNotes[chosenNote].noteData = updatedNote;
    setNotes(updatedNotes);
    const docRef = doc(firestore, 'notes', notes[chosenNote].noteId);
    updateDoc(docRef, updatedNote);

    localStorage.setItem(`lastEditedNote_${tripID}`, notes[chosenNote].noteId);
  };

  const deleteNoteHandler = async () => {
    let updatedNotes;
    const chosenNoteId = notes[chosenNote].noteId;

    if (notes.length > 1) {
      updatedNotes = [...notes];
      updatedNotes.splice(chosenNote, 1);
      setChosenNote(Math.max(chosenNote - 1, 0));

      ref.current.notesIDs = ref.current.notesIDs.filter(
        (noteID) => noteID !== chosenNoteId
      );

      updateTripNotes();
      deleteDoc(doc(firestore, 'notes', chosenNoteId));
    } else {
      const newNote = newNoteMaker();
      updatedNotes = [
        {
          noteId: chosenNoteId,
          noteData: newNote,
        },
      ];
      updateDoc(doc(firestore, 'notes', chosenNoteId), newNote);
    }
    setNotes(updatedNotes);
  };
  return (
    <Box className={classes.container}>
      <Helmet>
        <title>
          {tripTitle ? `${tripTitle} - Notes | Pilot` : 'Notes | Pilot'}
        </title>
      </Helmet>
      <Box className={classes.notesList}>
        {!loading && notes.length > 0 && (
          <>
            {notes.map((note, idx) => (
              <NoteIndex
                key={idx.toString()}
                title={note.noteData.name}
                activated={chosenNote === idx}
                activateNote={() => setChosenNote(idx)}
              />
            ))}
            <NewNoteButton />
          </>
        )}
      </Box>
      <Divider orientation="vertical" flexItem style={{ color: '#D2D2D2' }} />
      <Box className={classes.notesBox} id="notes">
        {!loading && notes.length > 0 ? (
          <NoteContainer
            key={chosenNote}
            noteObj={notes[chosenNote].noteData}
            noteUpdateCallback={updateNoteHandler}
            noteDeleteCallback={deleteNoteHandler}
          />
        ) : (
          <Box className={classes.loadingBox}>
            <LoadingLayout />
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default Notes;
