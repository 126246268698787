import { Button } from '@mui/material';
import { withStyles } from '@mui/styles';

/*
    https://material-ui.com/api/button/

    DO NOT USE THIS, THIS IS LEGACY. Still here because it's used in some places.
    USE Button/index.js instead. If you have to use a component here, export it to there and use it from there
*/
export const ButtonWithIcon = withStyles(() => ({
  root: {
    fontWeight: '600',
    fontSize: '14px',
    textTransform: 'none',
    lineHeight: '17px',
    padding: '.6em',
    color: '#FFFFFF',
    '&:hover': {
      padding: '.6em',
    },
  },
  disabled: {},
  colorInherit: {},
}))(Button);

export const FriendButton = withStyles(() => ({
  root: {
    fontWeight: '400',
    fontSize: '14px',
    textTransform: 'none',
    lineHeight: '17px',
    padding: '.6em',
    color: '#FFFFFF',
    margin: 'none',
    border: '2px solid transparent',
    borderRadius: 4,
  },
  disabled: {},
  colorInherit: {},
}))(Button);

export const MessageButton = withStyles(() => ({
  root: {
    fontWeight: '400',
    fontSize: '14px',
    textTransform: 'none',
    lineHeight: '19.6px',
    padding: '.6em',
    color: '#FFFFFF',
    paddingLeft: 16,
    paddingRight: 16,
  },
  disabled: {},
  colorInherit: {},
}))(Button);

export const LightButton = withStyles(() => ({
  root: {
    fontWeight: '400',
    fontSize: '14px',
    textTransform: 'none',
    lineHeight: '17px',
    padding: '.6em',
    color: '#ED702E',
    margin: 'none',
    backgroundColor: '#FFFFFF',
    border: '2px solid #ED702E',
    borderRadius: 4,
  },
  disabled: {},
  colorInherit: {},
}))(Button);

export const MapLinkButton = withStyles(() => ({
  root: {
    border: '1px solid #DEDDDD',
    borderRadius: 100,
    backgroundColor: '#FFF',
    textTransform: 'capitalize',
    color: '#000',
    padding: '3px 12px',
    marginRight: 4,
    '&:hover': {
      opacity: 1,
      color: '#000',
      backgroundColor: '#F4F4F4',
    },
    '& .MuiButton-startIcon': {
      margin: '0px 4px 0px -4px',
    },
  },
}))(Button);

export const AddToWishlist = withStyles(() => ({
  root: {
    height: '36px',
    width: '100%',
    padding: '9px 0',
    borderRadius: '6px',
    fontSize: '14px',
  },
  disabled: {
    backgroundColor: '#F4F4F4',
  },
}))(Button);

export const FlightSearchButton = withStyles(() => ({
  root: {
    backgroundColor: '#E5493D',
    color: '#FFF',
    borderRadius: 4,
    fontSize: '12px',
    padding: '1px 4px',
    display: 'flex',
    '&:disabled': {
      color: '#FFF',
      backgroundColor: '#DEDDDD',
    },
  },
}))(Button);

export const AddConnectingTransportButton = withStyles(() => ({
  root: {
    backgroundColor: '#F4F4F4',
    borderRadius: 8,
    color: '#8A8A8A',
    border: '1px solid #DEDDDD',
    display: 'flex',
    padding: '4px 8px',
    '& .MuiButton-startIcon': {
      margin: '0px 4px 0px 0px',
    },
    '&:hover': {
      opacity: 1,
      textDecoration: 'underline',
      textUnderlineOffset: '2px',
      color: '#8A8A8A',
      backgroundColor: '#F4F4F4',
    },
  },
}))(Button);
