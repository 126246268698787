import classList from '../../../classList';
import TodoItem from './Todo';

function TodoList({
  todos,
  column,
  tripId,
  locationId,
  isDragging,
  isSectionHighlight,
  locationBias,
}) {
  return (
    <div className={classList.todoList}>
      {todos?.map((todoId, index) => (
        <TodoItem
          key={todoId}
          todoId={todoId}
          column={column}
          index={index}
          tripId={tripId}
          locationId={locationId}
          isDragging={isDragging}
          isSectionHighlight={isSectionHighlight}
          locationBias={locationBias}
        />
      ))}
    </div>
  );
}

export default TodoList;
