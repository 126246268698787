import { useState } from 'react';
import { Box, Typography, Popper, Button } from '@mui/material';
import { ArrowOutwardRounded } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { useFeatureFlagVariantKey } from 'posthog-js/react';
import { ArrowOutwardThick } from '../../atoms/Icon';
import AB_TESTS from '../../../abTests';
import classList from '../../classList';
import TransitionLayout from '../../templates/TransitionLayout';

const useStyles = makeStyles(() => ({
  list: {
    border: '1px solid #D9D9D9',
    backgroundColor: '#FFF',
    borderRadius: 4,
    padding: '6px 12px',
  },
  twoColList: {
    padding: 0,
  },
  listItem: {
    display: 'flex',
    width: '100%',
    padding: '14px 0px',
    alignItems: 'center',
  },
  twoColListItem: {
    padding: '14px 16px',
    '&:hover': {
      backgroundColor: '#D9D9D9',
    },
  },
}));

function ThreeColumnPriceListItem({ logo, price, handleClick, index }) {
  const classes = useStyles();
  return (
    <div
      className={classes.listItem}
      style={{
        borderTop: `1px solid ${index === 0 ? 'transparent' : '#D9D9D9'}`,
      }}>
      <Box
        component="img"
        alt="hotel-provider-logo"
        src={logo}
        sx={{ width: '75px' }}
      />

      <Typography variant="h4" ml="16px">
        {price}
      </Typography>

      <Button
        variant="a"
        className={classList.staysQuickbookBtn}
        sx={{ padding: '2px 4px', ml: '20px' }}
        onClick={handleClick}>
        Details
        <ArrowOutwardRounded sx={{ color: '#FFF', height: 16, width: 16 }} />
      </Button>
    </div>
  );
}

function TwoColumnPriceListItem({ logo, price, handleClick, index }) {
  const classes = useStyles(true);
  return (
    <a
      rel="noreferrer"
      onClick={handleClick}
      style={{
        textDecoration: 'inherit',
        color: 'inherit',
      }}>
      <div
        className={`${classes.listItem} ${classes.twoColListItem}`}
        style={{
          borderTop: `1px solid ${index === 0 ? 'transparent' : '#D9D9D9'}`,
        }}>
        <Typography variant="h4">{price}</Typography>

        <Box
          component="img"
          alt="hotel-provider-logo"
          src={logo}
          sx={{ width: '75px', ml: '16px' }}
        />

        <ArrowOutwardThick
          style={{
            marginLeft: '4px',
          }}
        />
      </div>
    </a>
  );
}

function HotelPricesPopper({
  rates,
  providers,
  anchorEl,
  currencyCode,
  handleAffiliateClick,
  numberOfNights = 1,
}) {
  const classes = useStyles();
  const pricesPopupVariant =
    useFeatureFlagVariantKey(AB_TESTS.STAYS_DEALS_POPUP_VARIANT) || '0';

  const isTwoColList = pricesPopupVariant === '1';
  const PriceListItem = isTwoColList
    ? TwoColumnPriceListItem
    : ThreeColumnPriceListItem;

  const currencyFormatter = new Intl.NumberFormat(undefined, {
    currency: currencyCode || 'USD',
    style: 'currency',
  });

  const [showTransition, setShowTransition] = useState(false);
  const [bookingUri, setBookingUri] = useState('');
  const [providerDetails, setProviderDetails] = useState('');

  const handleItemClick = (bookUri, providerDetail) => {
    setBookingUri(bookUri);
    setProviderDetails(providerDetail);
    setShowTransition(true);
  };

  if (showTransition) {
    return (
      <TransitionLayout
        providerDetails={providerDetails}
        targetUri={bookingUri}
        setShowTransition={setShowTransition}
      />
    );
  }

  return (
    <Popper
      id="hotel-prices-popper"
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      sx={{
        zIndex: 4,
      }}>
      <Box
        className={`${classes.list} ${isTwoColList ? classes.twoColList : ''}`}>
        {(rates || [])?.map((providerDeal, idx) => (
          <PriceListItem
            logo={providers[providerDeal?.providerIndex]?.logo}
            price={currencyFormatter
              .format((providerDeal?.totalRate || 0) / numberOfNights)
              ?.replace(/\.\d+/, '')}
            handleClick={(e) => {
              e.stopPropagation();
              handleAffiliateClick({
                providerCode: providers[providerDeal?.providerIndex]?.code,
                providerName: providers[providerDeal?.providerIndex]?.name,
                name: providerDeal?.name,
                rate: providerDeal?.totalRate,
                id: providerDeal?.id,
              });
              handleItemClick(
                providerDeal?.bookUri,
                providers[providerDeal?.providerIndex]
              );
            }}
            index={idx}
          />
        ))}
      </Box>
    </Popper>
  );
}

export default HotelPricesPopper;
