import getCfConnector from '../components/cfConnector';

const getUserHash = async (userId = null) => {
  if (!process.env.REACT_APP_USER_HASH_API || !userId) {
    return null;
  }
  return (await getCfConnector())
    .post(process.env.REACT_APP_USER_HASH_API, {
      userId,
    })
    .then((res) => res?.data?.key);
};

export default getUserHash;
