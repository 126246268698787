import { useRef, useState } from 'react';
import { Typography, Button, IconButton, TextField } from '@mui/material';

import { CloseRounded, FilterListRounded } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

import CustomLottiePlayer from '../../CustomLottiePlayer';
import CategorySelector from '../../../organisms/Recommendations/CategorySelector';
import { BasicButton } from '../../../atoms/Button/index';
// import { LoadingText } from '../../../templates/LoadingLayout';

const useStyles = makeStyles(({ breakpoints }) => ({
  modal: {
    backgroundColor: '#FFF',
    width: 650,
    minHeight: 300,
    borderRadius: 4,
    display: 'flex',
    padding: 35,
    flexDirection: 'column',
    [breakpoints.down('sm')]: {
      maxWidth: '95%',
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  quickActions: {
    flexDirection: 'row',
    display: 'flex',

    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      paddingX: 20,
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    },
  },
  quickActionButton: {
    borderRadius: 30,
    marginRight: 10,
    marginTop: 10,
    [breakpoints.down('sm')]: {
      width: 250,
    },
  },
}));

function InitialScreen({
  onClose,
  isLoading,
  handleGenerateTripFromPrompt = () => {},
}) {
  const [description, setDescription] = useState('');
  const [filterLabels, setFilterLabels] = useState([]);
  const [filterOpen, setFilterOpen] = useState(false);

  const handleGenerateButtonClick = () =>
    handleGenerateTripFromPrompt({
      description,
      interests: filterLabels,
    });

  const catButtonRef = useRef(null);
  const classes = useStyles();
  if (isLoading) {
    return (
      <div
        className={classes.modal}
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          maxWidth: '600',
        }}>
        <CustomLottiePlayer
          filePath="/lottiefiles/loader_map.lottie"
          style={{
            width: '90%',
          }}
        />
        <Typography
          style={{
            fontWeight: 600,
            fontSize: '20px',
          }}>
          Count down to your next adventure!
        </Typography>
        {/* <LoadingText /> */}
      </div>
    );
  }
  return (
    <div className={classes.modal}>
      <div style={{ display: 'flex' }} ref={catButtonRef}>
        <div style={{ flex: 1, flexDirection: 'row' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}>
            <Typography variant="h2">Quick Start</Typography>
            <Typography
              fontSize={12}
              noWrap
              marginLeft={1}
              border="1px solid"
              borderColor="primary.main"
              borderRadius={8}
              paddingX="8px"
              color="primary.main">
              Labs
            </Typography>
          </div>
        </div>
        <div>
          <IconButton
            id="quickstart-close-button-initial-screen"
            sx={{ padding: '4px', color: '#1D1D1D' }}
            size="small"
            onClick={onClose}>
            <CloseRounded />
          </IconButton>
        </div>
      </div>
      <Typography style={{ marginLeft: 6, fontSize: 14 }}>
        Try out our beta itinerary generator! Note: Quick Start can only handle
        trips under 7 days for now.
      </Typography>
      <div className={classes.quickActions}>
        <BasicButton
          onClick={() =>
            setDescription(
              'Plan a cycling day in Paris, emphasizing the sensations of the breeze and iconic sights like the Eiffel Tower and Louvre. Describe navigating traffic, discovering hidden eateries, and listening to city sounds. Highlight the contrast between historic and modern Paris, capturing the romance of biking in the city.'
            )
          }
          className={classes.quickActionButton}>
          🚴 Cycling in Paris
        </BasicButton>
        <BasicButton
          onClick={() =>
            setDescription(
              'Plan a weekend getaway to the iconic beaches of Malibu, California. Dive into the experiences as visitors bask in the golden sun, surf the Pacific waves, and indulge in fresh seafood delicacies. Highlight activities such as exploring tide pools, paddleboarding, and beach volleyball. Paint a picture of evenings spent around bonfires with gourmet smores and local wine. Create a narrative that captures Malibus essence, a haven where nature beauty meets culinary delights and exhilarating activities.'
            )
          }
          className={classes.quickActionButton}>
          🏖️ Beaches in Malibu
        </BasicButton>
        <BasicButton
          onClick={() =>
            setDescription(
              `Plan a family week in Tokyo, Japan. Emphasize the city's energy, the sound of trains, the aroma of cherry blossoms, and the neon lights' guidance. Highlight visits to Tokyo Skytree, Meiji Shrine, and viewing the Shibuya crossing. Describe family experiences like tasting sushi from Tsukiji Market, kids' excitement at robot shows, and the serenity of a tea ceremony. Explore Tokyo's balance between ancient traditions and modern innovations, making the trip memorable.`
            )
          }
          className={classes.quickActionButton}>
          🗻 Family trip to Tokyo
        </BasicButton>
      </div>
      <div
        style={{
          padding: 10,
          marginTop: 20,
          marginBottom: 20,
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          overflow: 'hidden',
          borderRadius: 8,
          border: '1px solid #D9D9D9',
        }}>
        <TextField
          variant="standard"
          id="auto pilot"
          inputProps={{
            style: { padding: 0 },
            outline: 'none',
          }}
          placeholder="Plan me a backpacking trip in Thailand..."
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
          }}
          name="Auto pilot"
          autoComplete="off"
          multiline
          rows={3}
          maxRows={5}
          InputProps={{
            disableUnderline: true,
          }}
        />
      </div>
      <div
        style={{
          maxWidth: '180px',
          marginBottom: 20,
        }}>
        <BasicButton
          id="add-interests-button"
          marginRight={1}
          onClick={() => setFilterOpen(!filterOpen)}
          sx={{
            ...((filterOpen && {
              color: 'primary.main',
              borderColor: 'primary.main',
              backgroundColor: 'primary.extraLight',
            }) ||
              {}),
          }}
          disableRipple
          startIcon={<FilterListRounded />}>
          {`Add Interests ${
            filterLabels?.length > 0 ? `(${filterLabels.length})` : ''
          }`}
        </BasicButton>
      </div>
      <CategorySelector
        filterLabels={filterLabels}
        setFilterLabels={setFilterLabels}
        filterOpen={filterOpen ? catButtonRef.current : null}
        setFilterOpen={setFilterOpen}
        isQuickFilter
      />

      <Button
        id="generate-suggestions-button"
        disabled={isLoading}
        onClick={handleGenerateButtonClick}
        width="100%"
        startIcon={<AutoAwesomeIcon />}>
        Generate Suggestions
      </Button>
    </div>
  );
}

export default InitialScreen;
