import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Typography,
  Button,
  TextField,
  Grid,
  Box,
  Fade,
  Stack,
  CircularProgress,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/styles';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import { ImagesList } from '../../molecules/Modal/ImageSelectorModal';
import { UploadIcon } from '../../atoms/Icon';
import { SecondaryOutlinedButton } from '../../atoms/Button/index';
import {
  debounce,
  getImagesFromAPI,
  handleTripCoverImage,
} from '../../../utils';
import { updateTrip } from '../../../redux/slices/Trips';

function Step2Page({ setStep, tripName, setTripImageURL, tripId }) {
  const dispatch = useDispatch();
  const uploadRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [searchTerm, setSearchTerm] = useState(tripName);
  const [searchResults, setSearchResults] = useState([]);
  const [coverImageUploading, setCoverImageUploading] = useState(false);

  const [loadingSearchResults, setloadingSearchResults] = useState(false);

  const moveNext = (url) => {
    setTripImageURL(url);
    dispatch(
      updateTrip({
        variables: { id: tripId, coverImage: url },
      })
    );
    setStep(3);
  };

  const handleSearch = debounce(async (searchKeyword) => {
    setloadingSearchResults(true);
    const Images = await getImagesFromAPI(searchKeyword);
    setSearchResults(Images);
    setloadingSearchResults(false);
  }, 500);

  useEffect(() => {
    if (tripName) {
      handleSearch(tripName);
    }
  });

  return (
    <Box
      sx={{
        height: '90vh',
        display: 'flex',
        alignItems: { xs: 'start', sm: 'center' },
      }}>
      <Grid
        container
        height="450px"
        sx={{ paddingX: { xs: '20px', sm: '100px' } }}>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          <Stack maxWidth="400px" spacing={3} mt="30px">
            <Box
              onClick={() => {
                setStep(2);
              }}
              sx={{
                width: 'fit-content',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                color: '#8A8A8A',
              }}>
              <ChevronLeftRoundedIcon sx={{ marginTop: '-2px' }} />
              Back
            </Box>

            <Typography sx={{ fontSize: '37px', fontWeight: 500 }}>
              Let&lsquo;s set a cover Image
            </Typography>

            <TextField
              label="Search Image"
              variant="standard"
              sx={{ display: 'block', mb: 2 }}
              fullWidth
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                handleSearch(e.target.value);
              }}
            />

            <ImagesList
              searchResults={searchResults}
              loadingSearchResults={loadingSearchResults}
              handleImageClick={(selectedImage) =>
                moveNext(selectedImage.urls.regular)
              }
            />

            <input
              ref={uploadRef}
              accept="image/*"
              style={{ display: 'none' }}
              type="file"
              onChange={async (e) => {
                setCoverImageUploading(true);
                const image = await handleTripCoverImage(e, tripId);
                setCoverImageUploading(false);
                moveNext(image.url);
              }}
            />
            <span style={{ marginRight: 4 }}>
              <SecondaryOutlinedButton
                sx={{
                  padding: '8px 16px',
                  minWidth: '100%',
                }}
                onClick={() => uploadRef?.current?.click()}
                startIcon={
                  coverImageUploading ? (
                    <Fade in={coverImageUploading} unmountOnExit>
                      <CircularProgress size={14} style={{ marginRight: 4 }} />
                    </Fade>
                  ) : (
                    <UploadIcon
                      style={{
                        height: 20,
                        width: 20,
                      }}
                    />
                  )
                }>
                {coverImageUploading ? 'Uploading' : 'Upload'}
              </SecondaryOutlinedButton>
            </span>

            {isMobile && (
              <Button
                fullWidth
                onClick={() => {
                  setStep(3);
                }}>
                Next
              </Button>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Step2Page;
