import { Button } from '@mui/material';
import React from 'react';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(({ palette }) => {
  return {
    exportPDFButton: {
      backgroundColor: ({ isActive }) =>
        isActive ? palette.primary?.extraLight : '#fff',
      color: ({ isActive }) =>
        isActive ? palette.primary?.main : palette.text?.light,
      borderColor: palette?.system?.grey,
      borderStyle: 'solid',
      borderWidth: '1px',
      '&:hover': {
        borderColor: palette?.primary?.light,
        backgroundColor: '#fff',
        color: palette.primary?.main,
      },
    },
  };
});

function ExportButton({ children, isActive, onClick }) {
  const classes = useStyles({ isActive });
  return (
    <Button onClick={onClick} disableRipple className={classes.exportPDFButton}>
      {children}
    </Button>
  );
}

export default ExportButton;
