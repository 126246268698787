import { Box, Button, IconButton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { FacebookIcon, InstagramIcon, LinkedinIcon } from '../atoms/Icon';
import classList from '../classList';

const useStyles = makeStyles(({ breakpoints }) => ({
  header: {
    textAlign: 'center',
    fontSize: '20px',
    fontWeight: '500',
    [breakpoints.down('md')]: {
      marginLeft: '5%',
      textAlign: 'left',
    },
  },
  row: {
    height: '100%',
    margin: '30px 0 10px 0',
    [breakpoints.up('md')]: {
      margin: '30% 0',
    },
  },
  row1: {
    margin: '50px 0 0 0',
  },
  clearButton: {
    backgroundColor: 'white',
    color: '#ED702E',
    borderColor: '#ED702E',
    textTransform: 'none',
    borderRadius: '4px',
    width: '90%',
    [breakpoints.up('md')]: {
      width: '70%',
    },
    marginBottom: '5%',
    '&:hover': {
      backgroundColor: '#FFF1E0',
      borderColor: '#ED702E',
      color: '#ED702E',
    },
  },
  filledButton: {
    backgroundColor: '#ED702E',
    color: '#FFFFFF',
    textTransform: 'none',
    width: '90%',
    [breakpoints.up('md')]: {
      width: '70%',
    },
    '&:hover': {
      backgroundColor: '#ED702E',
      opacity: 0.65,
    },
  },
}));

function handleSubmitFeedback() {
  window.open('https://www.pilotplans.com/roadmap', '_blank');
}

function handleSubmitReview() {
  window.open('https://g.page/pilotplans/review', '_blank');
}

function handleFacebookIcon() {
  window.open('https://www.facebook.com/pilotplans/', '_blank');
}

function handleInstagramIcon() {
  window.open('https://www.instagram.com/pilotplans/', '_blank');
}

function handleLinkedinIcon() {
  window.open('https://ca.linkedin.com/company/pilotplans', '_blank');
}

function Feedback() {
  const classes = useStyles();

  return (
    <Box textAlign="center" className={classes.row}>
      <Typography className={classes.header}>Give us Feedback!</Typography>
      <Button
        className={`${classes.clearButton} ${classList.settingsProductRequests}`}
        variant="outlined"
        disableElevation
        disableRipple
        onClick={handleSubmitFeedback}>
        Product Requests
      </Button>
      <Button
        className={`${classes.filledButton} ${classList.settingsLeaveReview}`}
        variant="contained"
        disableElevation
        disableRipple
        onClick={handleSubmitReview}>
        Leave us a Review
      </Button>
      <div className={classes.row1}>
        <Typography className={classes.header}>
          Follow us on Social Media
        </Typography>
        <IconButton onClick={handleFacebookIcon} disableRipple size="large">
          <FacebookIcon />
        </IconButton>
        <IconButton onClick={handleInstagramIcon} disableRipple size="large">
          <InstagramIcon />
        </IconButton>
        <IconButton onClick={handleLinkedinIcon} disableRipple size="large">
          <LinkedinIcon />
        </IconButton>
        <Button
          className={classes.clearButton}
          variant="outlined"
          disableElevation
          disableRipple
          onClick={handleInstagramIcon}>
          Connect on Instagram!
        </Button>
      </div>
    </Box>
  );
}

export default Feedback;
