import { useState } from 'react';

import { Typography, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { RefreshRounded } from '@mui/icons-material';
import classList from '../../../classList';

const useStyles = makeStyles((theme) => ({
  refetchAnimation: {
    animation: '$spin 1s linear',
  },
  '@keyframes spin': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
  refetchButton: {
    alignSelf: 'center',
    justifyContent: 'flex-start',
    alignItems: 'left',
    minWidth: 0,
    backgroundColor: 'transparent',
    color: theme.palette.text.light,
    '&:hover': {
      backgroundColor: theme.palette.system.lightgrey,
      color: theme.palette.text.light,
    },
  },
}));

function RefetchButton({ handleClick }) {
  const classes = useStyles();
  const [hovered, setHovered] = useState(false);
  const [spin, setSpin] = useState(false);
  return (
    <Button
      onClick={async () => {
        if (!spin) {
          setHovered(false);
          setSpin(true);
          await handleClick();
        }
      }}
      onMouseOver={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      style={{ marginLeft: '1rem' }}
      className={`${classes.refetchButton} ${classList.refetchButton}`}
      disableRipple
      disableFocusRipple>
      <RefreshRounded
        style={{
          height: 16,
          width: 16,
          color: '#222',
        }}
        fontSize="small"
        className={spin ? classes.refetchAnimation : ' '}
        onAnimationEnd={() => setSpin(false)}
      />
      <Typography variant="h5" color="text.light" ml="8px" fontStyle="italic">
        {spin ? ' ' : hovered ? 'Refresh' : 'Saved'}
      </Typography>
    </Button>
  );
}

export default RefetchButton;
