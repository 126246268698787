import { Card, Typography } from '@mui/material';

import { AddCircleOutlineOutlined } from '@mui/icons-material';
import classList from '../../../classList';

/* 
  CREATE TRIP CARD COMPONENT
*/

// The main create trip card component.
function CreateTripCard({ onClick = () => {} }) {
  return (
    <Card
      onClick={onClick}
      sx={{
        width: '100%',
        height: { xs: 'calc(50vw + 78px)', sm: '385px' },
        maxHeight: '385px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        boxShadow: 'none',
        outline: 'none',
        border: '2px dashed',
        borderColor: 'primary.light',
        '&:hover': {
          backgroundColor: 'primary.extraLight',
        },
      }}
      className={classList.createTripCard}>
      <AddCircleOutlineOutlined
        sx={{
          width: '60px',
          height: '60px',
          color: 'primary.main',
        }}
      />
      <Typography
        variant="h4Sub"
        sx={{
          color: 'primary.main',
          paddingTop: '13px',
        }}>
        Create new trip
      </Typography>
    </Card>
  );
}

export default CreateTripCard;
