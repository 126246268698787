import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import AuthLayout from '../../templates/AuthLayout';
import { EmailSniper, Gmail, MailIcon, Outlook, Yahoo } from '../../atoms/Icon';
import { ButtonWithIcon } from '../../atoms/Button';
import Logo from '../../molecules/Logo';
import { LinkRoute } from '../../atoms/Link';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  form__success: {
    fontSize: '14px',
    marginBottom: '2vh',
    color: theme.palette.success.main,
  },
  grayColor: {
    color: '#757575',
  },
}));

function MailSniper() {
  const classes = useStyles();

  const location = useLocation();
  const { email, isPasswordReset } = location.state || {
    email: '',
    isPasswordReset: false,
  };

  let pName = '';
  const [providerName] = useState(email.split('@')[1].split('.')[0]);

  if (
    providerName === 'gmail' ||
    providerName === 'google' ||
    providerName === 'googleemail'
  ) {
    pName = 'gmail';
  } else {
    pName = providerName;
  }

  const subject =
    process.env.REACT_APP_ENVIRONMENT === 'production'
      ? isPasswordReset
        ? 'Reset your password for Pilot'
        : 'Sign in to Pilot'
      : isPasswordReset
      ? 'Reset your password for Pilot Test'
      : 'Sign in to Pilot Test';

  const yahooSniperLink =
    process.env.REACT_APP_ENVIRONMENT === 'production'
      ? isPasswordReset
        ? 'https://mail.yahoo.com/d/search/keyword=subject%253A%2522Reset%2520your%2520password%2520for%2520Pilot%2522'
        : 'https://mail.yahoo.com/d/search/keyword=subject%253A%2522Sign%2520in%2520to%2520Pilot%2522'
      : isPasswordReset
      ? 'https://mail.yahoo.com/d/search/keyword=subject%253A%2522Reset%2520your%2520password%2520for%2520Pilot%2520Test%2522'
      : 'https://mail.yahoo.com/d/search/keyword=subject%253A%2522Sign%2520in%2520to%2520Pilot%2520Test%2522';

  const sniperLinkDetails = {
    gmail: {
      icon: <Gmail />,
      label: ' Gmail',
      link: `https://mail.google.com/mail/u/${email}/#search/subject%3A%22${encodeURIComponent(
        subject
      )}%22`,
    },
    yahoo: {
      icon: <Yahoo />,
      label: ' Yahoo!',
      link: yahooSniperLink,
    },
    outlook: {
      icon: <Outlook />,
      label: ' Outlook',
      link: `https://outlook.live.com/mail/?login_hint=${email}`,
    },
    hotmail: {
      icon: <Outlook />,
      label: ' Outlook',
      link: `https://outlook.live.com/mail/?login_hint=${email}`,
    },
    other: {
      icon: <MailIcon />,
      label: ' Email',
      link: ``,
    },
  };

  return (
    <AuthLayout>
      <Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Typography
            mb="2.5rem"
            variant="h2"
            sx={{
              fontFamily: 'Work Sans',
              fontSize: '2rem',
              fontWeight: '600',
              lineHeight: '20px',
            }}>
            {isPasswordReset ? <>Reset your password</> : <>Sign Up</>}
          </Typography>

          <EmailSniper />

          <Typography mt={5}>
            {isPasswordReset ? (
              <>Password reset email sent to</>
            ) : (
              <>Verification email sent to</>
            )}
          </Typography>

          <Typography sx={{ fontWeight: '700' }}>{email}</Typography>

          <Typography
            component="span"
            variant="h5"
            // onClick={handleResetCLick}
            sx={{
              padding: '15px 0',
              color: '#ED702E', // Apply link-like color
              textDecoration: 'underline', // Apply link-like underline
              cursor: 'pointer', // Show pointer cursor on hover
            }}>
            <LinkRoute to="/continue">Not you? Go back. </LinkRoute>
          </Typography>

          <Typography
            pt={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ color: '#8A8A8A', textAlign: 'center' }}>
            We’ve sent you an email with a confirmation link. Open the link and
            get to trip planning!
          </Typography>
          {pName in sniperLinkDetails ? (
            <ButtonWithIcon
              component="a"
              target="_blank"
              fullWidth
              // onClick={handleGoogleSignin}
              style={{
                height: '3rem',
                backgroundColor: '#FFFFFF',
                borderColor: '#D9D9D9',
                marginTop: '25px',
              }}
              variant="contained"
              href={
                sniperLinkDetails[pName]?.link || sniperLinkDetails.other.link
              }>
              {sniperLinkDetails[pName]?.icon || sniperLinkDetails.other.icon}
              <Box ml="0.5rem" className={classes.grayColor}>
                Open
                {sniperLinkDetails[pName]?.label ||
                  sniperLinkDetails.other.label}
              </Box>
            </ButtonWithIcon>
          ) : null}
        </Box>
        {/* Logo */}
        <Box pt={5} pr={1.5}>
          <Logo fullLogo imageWidth="30%" />
        </Box>
      </Box>
    </AuthLayout>
  );
}

export default MailSniper;
