const MARKER_ID = '371896';
const TRS = '180539';
const SUB_ID = 'app';

const PROGRAMS = {
  'booking.com': {
    programId: 2076,
    campaignId: 84,
  },
  'viator.com': {
    programId: 1922,
    campaignId: 47,
  },
  'getyourguide.com': {
    programId: 3965,
    campaignId: 108,
  },
  'hostelworld.com': {
    programId: 3518,
    campaignId: 93,
  },
  'tripadvisor.com': {
    programId: 4456,
    campaignId: 149,
  },
  'gocity.com': {
    programId: 1942,
    campaignId: 62,
  },
  'omio.com': {
    programId: 2078,
    campaignId: 91,
  },
  'tiqets.com': {
    programId: 2074,
    campaignId: 89,
  },
  'busbud.com': {
    programId: 4109,
    campaignId: 138,
  },
  'global.flixbus.com': {
    programId: 4083,
    campaignId: 136,
  },
  'wayaway.io': {
    programId: 5976,
    campaignId: 200,
  },
  'eatwith.com': {
    programId: 4696,
    campaignId: 164,
  },
  'vrbo.com': {
    programId: 4398,
    campaignId: 145,
  },
  'klook.com': {
    programId: 4110,
    campaignId: 137,
  },
  'aviasales.com': {
    programId: 4114,
    campaignId: 100,
  },
};

const getTpUrl = ({ campaignId, programId, encodedURL }) => {
  return `https://tp.media/r?campaign_id=${campaignId}&marker=${MARKER_ID}.${SUB_ID}&p=${programId}&trs=${TRS}&u=${encodedURL}`;
};

export const switchLink = (url) => {
  try {
    const encodedURL = encodeURIComponent(url);
    const domain = new URL(url).hostname.replace('www.', '');
    if (PROGRAMS[domain] === undefined || !PROGRAMS[domain]) {
      return null;
    }
    const { programId, campaignId } = PROGRAMS[domain];
    const tpUrl = getTpUrl({ campaignId, programId, encodedURL });
    return tpUrl;
  } catch (err) {
    return null;
  }
};

export const trackTpLink = (ogUrl) => {
  const tpUrl = switchLink(ogUrl);
  if (!tpUrl) return;
  window?.heap?.track('tp-outbound-link-clicked', {
    tpUrl,
    ogUrl,
    domain: new URL(ogUrl)?.hostname?.replace('www.', ''),
  });
};

export const getLinkSwitcherMap = (urls) => {
  const linkSwitcherMap = {};
  urls.forEach((url) => {
    const switchedlink = switchLink(url);
    if (switchedlink) linkSwitcherMap[url] = switchLink(url);
  });
  return linkSwitcherMap;
};
