import React from 'react';
import { Typography, useTheme, useMediaQuery, Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { EVENTS, phTrackEvent } from '../../../../../analytics';

function PriceNav({ navHeight = 0 }) {
  const { slug: hotelKey } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
  const hotelDetails = useSelector(
    (state) => state.Bookings?.hotelDetailsPage?.data[hotelKey]
  );
  const hotel = {
    ...hotelDetails,
    price: hotelDetails?.lowestRate,
  };

  const currencyFormatter = new Intl.NumberFormat(undefined, {
    currency: hotel?.currencyCode || 'CAD',
    style: 'currency',
  });
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginLeft: isMobile ? 0 : isTablet ? 'auto' : '32px',
        }}>
        <Typography
          noWrap
          variant="h5"
          color="text.primary"
          fontSize={isMobile ? 16 : 14}>
          <b>
            {currencyFormatter
              .format(hotel.price / hotel.numberOfNights || 0)
              ?.replace(/\.\d+/, '')}
          </b>
          &nbsp;/night
        </Typography>
        <Typography
          noWrap
          variant="h5"
          color="text.secondary"
          fontSize={isMobile ? '14px' : '12px'}>
          {`Total: ${currencyFormatter
            .format(hotel.price)
            ?.replace(/\.\d+/, '')}`}
        </Typography>
      </div>
      <Button
        sx={{ marginLeft: isMobile ? 'auto' : '32px', whiteSpace: 'nowrap' }}
        onClick={() => {
          phTrackEvent({
            event: EVENTS.EXPLORE_STAY_DETAIL.STAY_VIEW_DEALS_CLICK,
          });
          const elementPosition = document
            .getElementById('deals-container')
            ?.getBoundingClientRect().top;
          const scrollContainer = document.getElementById('hotel-details-page');
          scrollContainer?.scrollTo({
            top:
              elementPosition +
              (scrollContainer?.scrollTop || 0) -
              navHeight -
              (isMobile ? 112 : 32),
            behaviour: 'smooth',
          });
        }}>
        View Deals
      </Button>
    </>
  );
}

export default PriceNav;
