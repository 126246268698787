import React, { useState, useEffect } from 'react';
import { useAllPrismicDocumentsByType } from '@prismicio/react';
import { Typography, Stack, Grid, Box } from '@mui/material';
import { Helmet } from 'react-helmet';
import OfferCard from '../molecules/Card/OfferCard';
import { sendCustomMessage, trackEvents, Events } from '../../intercom';

function GetOfferCard({ document, offerName, customEvents }) {
  const targetOffer = document.find(
    (offer) => offer?.data?.a_b_test_name?.[0]?.text === offerName
  );

  return (
    <OfferCard
      cardImage={targetOffer?.data?.card_image}
      logo={targetOffer?.data?.deal_logo}
      name={targetOffer?.data?.name?.[0]?.text}
      content={targetOffer?.data?.description?.[0]?.text}
      coupons={targetOffer?.data?.coupons}
      linkText={targetOffer?.data?.deal_link_text?.[0]?.text}
      link={targetOffer?.data?.deal_link?.url}
      customClickHandler={
        Object.keys(customEvents)?.includes(targetOffer?.deal_slug)
          ? customEvents[targetOffer?.deal_slug]
          : null
      }
    />
  );
}

function Offers() {
  const [document] = useAllPrismicDocumentsByType('affiliate_deals_card');
  const [offerNamesList, setOfferNamesList] = useState();
  const allOfferNamesObj = {
    card1: 'flightdeckcard1',
    card2: 'surfsharkcard1',
    card3: 'airalocard1',
  };
  const offersPageText = {
    title: 'Find Offers for Better Travel',
    description:
      'The latest offers that will make your travels even better. Researched, tested and collected by travelers just like you.',
  };

  useEffect(() => {
    if (allOfferNamesObj) {
      setOfferNamesList(Object.values(allOfferNamesObj));
    }
  }, [allOfferNamesObj]);

  useEffect(() => {
    trackEvents(Events.OffersPageView);
  }, []);

  const customEvents = {
    'surfshark-vpn': () => sendCustomMessage({ message: 'SurfShark' }),
  };

  return (
    <Stack
      sx={{
        alignItems: 'center',
        px: '7vw',
      }}>
      <Helmet>
        <title>Offers | Pilot Trip Planner</title>
      </Helmet>
      <Typography
        sx={{
          fontSize: { xs: '30px', sm: '40px' },
          fontWeight: '600',
          my: '30px',
          textAlign: 'center',
        }}>
        {offersPageText?.title || 'Find Offers for Better Travel'}
      </Typography>

      <Typography sx={{ maxWidth: '700px', textAlign: 'center', mb: '30px' }}>
        {offersPageText?.description ||
          'The latest offers that will make your travels even better. Researched,tested and collected by travelers just like you.'}
      </Typography>

      <Grid
        container
        spacing="50"
        sx={{ mb: '30px', justifyContent: 'center' }}>
        {document &&
          offerNamesList?.map((offerName) => {
            return (
              <Grid item xs={12} md={4} key={offerName}>
                <GetOfferCard
                  document={document}
                  offerName={offerName}
                  customEvents={customEvents}
                />
              </Grid>
            );
          })}

        <Grid item xs={12}>
          <Box
            sx={{
              width: {
                xs: 'calc(min(100%, 450px))',
                md:
                  document?.length === 1
                    ? 'calc(33.33% - 25px)'
                    : document?.length === 2
                    ? 'calc(66.66% - 10px)'
                    : '100%',
              },

              border: '2px dashed #D9D9D9',
              borderRadius: '4px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '250px',
              margin: '0 auto',
              mb: '70px',
            }}>
            <Typography sx={{ fontSize: '20px', fontWeight: 700, mb: '10px' }}>
              That&apos;s all for now!
            </Typography>

            <Typography
              sx={{ fontSize: '14px', fontWeight: 400, color: '#4E4E4E' }}>
              Check back for more Pilot exclusive deals
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
}

export default Offers;
