import getCfConnector from '../../../cfConnector';

const generateItineraryFromPrompt = async ({ userPrompt, tripId }) => {
  try {
    const res = await (
      await getCfConnector()
    ).post(process.env.REACT_APP_AI_QUICKSTART_API, {
      userPrompt,
      tripId,
    });
    if (res?.data?.success) return true;
  } catch (err) {
    // handle error
  }
  return false;
};

export default generateItineraryFromPrompt;
