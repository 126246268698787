import { useState, useEffect } from 'react';
import { Modal, Box } from '@mui/material';
import { useTheme } from '@mui/styles';
import { airports } from '@pilotplans/aviation-data';
import { TravelRestrictionsButton } from '../atoms/Button/index';
import { trackEvents, Events } from '../../intercom';
import { FlightOutlined } from '../atoms/Icon';
import { EVENTS, phTrackEvent } from '../../analytics';

function SherpaTripElement({ segments }) {
  useEffect(() => {
    const elementConfig = {
      segments,
      placement: 'itinerary',
    };
    window.$sherpa.V2.createElement('trip', elementConfig).mount(
      '#sherpa-trip-element'
    );
  }, []);

  return <div id="sherpa-trip-element" />;
}

function TravelRestrictions({ flightList, CustomButton }) {
  const [isOpen, setOpen] = useState(false);
  const theme = useTheme();

  const getIATA = (airport) => {
    if (airport?.length === 3) return airport;
    const iata = airports.findWhere({ icao: airport })?.get('iata');
    if (iata === '\\N') return '';
    return iata;
  };

  const formatSegments = (flights) => {
    return flights?.map((flight) => ({
      segmentType: 'OUTBOUND',
      origin: {
        airportCode: getIATA(flight.fromAirport) || '',
      },
      destination: {
        airportCode: getIATA(flight.toAirport) || '',
      },
    }));
  };

  return (
    <Box sx={{ width: { xs: '100%', md: 'auto' } }}>
      <Modal
        open={isOpen}
        onClose={(e) => {
          e.stopPropagation();
          setOpen(false);
        }}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Box
          sx={{
            padding: '20px',
            backgroundColor: '#FFF',
            width: '55%',
            maxHeight: '500px',
            borderWidth: 0,
            borderRadius: '16px',
            overflow: 'auto',
            [theme.breakpoints.down('sm')]: {
              minWidth: '340px',
              minHeight: '420px',
            },
            [theme.breakpoints.up('md')]: {
              maxWidth: '800px',
              maxHeight: '600px',
            },
          }}>
          <SherpaTripElement segments={formatSegments(flightList)} />
        </Box>
      </Modal>
      {CustomButton ? (
        <CustomButton
          onClick={(e) => {
            e.stopPropagation();
            setOpen(true);
            trackEvents(Events.TravelRestrictionOpened);
            phTrackEvent({ event: EVENTS.PLAN_HEADER.VISAS_REQUIREMENTS_VIEW });
          }}
        />
      ) : (
        <TravelRestrictionsButton
          startIcon={
            <FlightOutlined style={{ width: '14px', height: '14px' }} />
          }
          onClick={(e) => {
            e.stopPropagation();
            setOpen(true);
            trackEvents(Events.TravelRestrictionOpened);
          }}>
          Visas & Requirements
        </TravelRestrictionsButton>
      )}
    </Box>
  );
}

export default TravelRestrictions;
