import getUserHash from './getUserHash';

export const intercomInit = async (userProps = {}) => {
  if (
    process.env.REACT_APP_DISABLE_INTERCOM_BOOT === 'true' ||
    !userProps.user_id
  ) {
    return;
  }
  const userHash = await getUserHash(userProps.user_id);
  window?.Intercom('boot', {
    api_base: 'https://api-iam.intercom.io',
    app_id: process.env.REACT_APP_INTERCOM_APPID,
    user_hash: userHash,
    hide_default_launcher: true,
    ...userProps,
  });
  window?.Intercom('update', {
    hide_default_launcher: true,
  });
};

export const trackEvents = (name, meta = null) =>
  window.Intercom('trackEvent', name, meta);

export const sendCustomMessage = async ({ message }) =>
  window.Intercom('showNewMessage', message);

export const openIntercom = async () => window.Intercom('showSpace', 'home');

export const Events = {
  TripCreated: 'trip-created',
  CityAdded: 'city-added',
  LinkAdded: 'link-added',
  FlightAdded: 'flight-added',
  BusTrainAdded: 'bus-train-added',
  OtherTransportAdded: 'other-transport-added',
  HeadingAdded: 'heading-added',
  ActivityAdded: 'activity-added',
  TravelRestrictionOpened: 'travel-restriction-opened',
  InviteSent: 'invite-sent',
  InviteLinkCopied: 'invite-link-copied',
  ChatMessageSent: 'chat-message-sent',
  ChatStarted: 'chat-started',
  FileAddedAttached: 'file-added-attached',
  NoteCreated: 'note-created',
  ChecklistCreated: 'checklist-created',
  CostAdded: 'cost-added',
  DateAdded: 'date-added',
  ItineraryItemReordered: 'itinerary-item-reordered',
  DescriptionAdded: 'description-added',
  WishListAdded: 'whishlist-added',
  TripExported: 'trip-exported',
  LocationAddedFromMap: 'location-added-from-map',
  ProfileVisited: 'profile-visited',
  RanIntoBug: 'ran-into-bug',
  ImportedBookings: 'imported-bookings',
  ImportBookingEmailCopied: 'import-booking-email-copied',
  RecommendationsItemAdded: 'recommendations-item-added',
  OffersPageView: 'offers-page-view',
};

export default { intercomInit, trackEvents };
