import React from 'react';
import { Typography, IconButton, Avatar } from '@mui/material';
import { useDispatch } from 'react-redux';
import {
  CloseRounded,
  PhoneIphoneRounded,
  HomeRounded,
  OutlinedFlagRounded,
} from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import COPIES from './copies.json';
import SocialComponent from './SocialComponent';
import actions from '../../../../redux/actions';

const useStyles = makeStyles(({ breakpoints }) => ({
  root: {
    backgroundColor: '#FFF',
    flexDirection: 'column',
    display: 'flex',
    outline: 'none',
    borderRadius: 8,
    height: 578,
    overflow: 'auto',
    width: 437,
    [breakpoints.down('sm')]: {
      width: 380,
    },
    padding: '28px 20px',
    textAlign: 'center',
    position: 'absolute',
    alignItems: 'center',
  },
  teamContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 8,
  },
  projectContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: 6,
    padding: '6px 8px',
  },
  projectDescriptionContainer: {
    display: 'column',
    flex: 1,
    textAlign: 'left',
    marginLeft: 12,
  },
}));

function Appreciation() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleClose = () =>
    dispatch(actions.View.setContribute({ action: -1 }));

  // ordered list - image should have same name
  const team = [
    'avinash.png',
    'yuwei.png',
    'connor.webp',
    'shawny.webp',
    'james.webp',
    'ranjan.png',
    'harsiddak.webp',
    'kevin.png',
    'janine.png',
    'jess.png',
  ];

  const projects = [
    {
      Icon: PhoneIphoneRounded,
      title: 'Mobile App',
      description: 'Wherever you go, make the most of every trip.',
    },
    {
      Icon: HomeRounded,
      title: 'Stays Booking',
      description: 'Find a stay that works for everybody!',
    },
    {
      Icon: OutlinedFlagRounded,
      title: 'Quick Start',
      description: 'A personalized itinerary, made just for you!',
    },
  ];

  return (
    <div className={classes.root}>
      <IconButton
        sx={{ position: 'absolute', top: 4, right: 0 }}
        onClick={handleClose}>
        <CloseRounded />
      </IconButton>
      <img
        src="/logo192.png"
        alt="pilot-logo"
        style={{
          height: 40,
          width: 40,
        }}
      />
      <Typography variant="h3" sx={{ marginTop: 2 }}>
        {COPIES.APPRECIATION.title}
      </Typography>
      <Typography variant="caption" sx={{ marginTop: 1 }}>
        {COPIES.APPRECIATION.subtitle}
      </Typography>
      <Typography variant="caption" sx={{ marginTop: 1 }}>
        {COPIES.APPRECIATION.subtitleFooter}
      </Typography>
      <div className={classes.teamContainer}>
        {team.map((name) => (
          <Avatar
            sx={{
              m: 1,
              mx: 1.5,
              height: 70,
              width: 70,
              backgroundColor: 'primary.extraLight',
            }}
            alt={name}
            src={`/images/team/${name}`}
          />
        ))}
      </div>
      <div style={{ marginTop: 16, padding: '0px 16px' }}>
        <Typography variant="h5Sub">
          {COPIES.APPRECIATION.projectsTitle}
        </Typography>
        <div className={classes.projectContainer}>
          {projects.map((project) => (
            <div style={{ display: 'flex', marginTop: 16 }}>
              <project.Icon
                sx={{ height: 40, width: 40, color: 'primary.main' }}
              />
              <div className={classes.projectDescriptionContainer}>
                <Typography variant="h4">{project.title}</Typography>
                <Typography
                  variant="caption"
                  component="p"
                  color="text.secondary">
                  {project.description}
                </Typography>
              </div>
            </div>
          ))}
        </div>
        <Typography variant="h5" color="text.secondary" marginTop="16px">
          {COPIES.APPRECIATION.next}
        </Typography>
        <SocialComponent
          titleProps={{
            variant: 'h5',
            color: 'text.light',
            marginTop: '32px',
          }}
        />
      </div>
    </div>
  );
}

export default Appreciation;
