import { useState, useEffect, useRef } from 'react';
import { Collapse } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import ItineraryHeader from './ItineraryHeader';
import ItineraryBody from './ItineraryBody';
import { useItineraryDnd } from '../ItineraryDndProvider';
import { deleteLocation } from '../../../redux/slices/Location';
import { DateProvider } from '../../molecules/DatePickers/DateUtils';

const useStyles = makeStyles(({ palette }) => ({
  container: {
    marginTop: '24px',
  },
  isDragging: {
    backgroundColor: '#F4F4F4',
    padding: '1% 1% 2% 0%',
    borderRadius: 8,
    border: `2px solid ${palette.primary.light}`,
    marginLeft: 'calc(3.6rem + 15px)',
  },
  highlight: {
    padding: '1% 1% 2% 0%',
    borderRadius: 8,
    border: `2px solid ${palette.primary.light}`,
    marginLeft: 'calc(3.6rem + 15px)',
  },
}));

function ItinerarySection({
  tripId,
  locationId,
  activeLocation,
  setActiveLocation,
  handleAddLocation,
  dragHandleProps,
  isDragging,
  citySuggestion,
  index,
}) {
  const dispatch = useDispatch();
  const location = useSelector((state) => state.Location.locations[locationId]);
  const highlightedSection = useSelector(
    (state) => state.View.highlightedSection
  );

  const [isCollapsed, setCollapsed] = useState(true);
  const classes = useStyles();
  const locationRef = useRef(null);
  const { isNewLocation, setNewLocation } = useItineraryDnd();
  const isSectionHighlight = highlightedSection === index;
  const handleDelete = async (id) => {
    try {
      dispatch(
        deleteLocation({
          variables: {
            id,
            tripId,
          },
          mapPin: location?.mapPin,
        })
      );
      return true;
    } catch (err) {
      return err;
    }
  };

  useEffect(() => {
    if (isNewLocation === locationId) {
      locationRef?.current?.focus();
      setNewLocation(null);
    }
  }, [isNewLocation]);
  return (
    <div
      className={`${classes.container} ${
        isSectionHighlight ? classes.highlight : null
      } ${isDragging ? classes.isDragging : null}`}
      id={`scrollable-container-${index}`}
      // {...dragHandleProps}
    >
      <DateProvider location={location}>
        <ItineraryHeader
          isCollapsed={isCollapsed}
          setCollapsed={setCollapsed}
          location={location}
          handleDelete={handleDelete}
          handleAdd={handleAddLocation}
          dragHandleProps={dragHandleProps}
          locationRef={locationRef}
          isDragging={isDragging}
          isSectionHighlight={isSectionHighlight}
          citySuggestion={citySuggestion}
        />
        <Collapse in={isCollapsed}>
          {location?.name && location?.name !== '' ? (
            <ItineraryBody
              tripId={tripId}
              location={location}
              activeLocation={activeLocation}
              setActiveLocation={setActiveLocation}
              isDragging={isDragging}
              isSectionHighlight={isSectionHighlight}
            />
          ) : null}
        </Collapse>
      </DateProvider>
    </div>
  );
}

export default ItinerarySection;
