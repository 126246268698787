/**
 * Slice to form a single point of error for the app
 */

import { createSlice } from '@reduxjs/toolkit';
import { getCompleteTrip, getTrips } from './Trips';
import { getUser } from './Auth';

const initialState = {
  error: null,
  code: '', // for use when logging is setup
  type: null, // FULLSCREEN, TOAST, IGNORE
};

const ErrorSlice = createSlice({
  name: 'ERRORS',
  initialState,
  reducers: {
    setError: (state, action) => {
      if (!action?.payload) return;
      const {
        payload: { error, type },
      } = action;
      state.error = error;
      state.type = type;

      // TODO: log this error on the backend.
    },
  },

  extraReducers: {
    // SET FULL SCREEN ERRORS
    ...([getCompleteTrip.rejected, getTrips.rejected, getUser.rejected].reduce(
      (ErrorReducers, action) => ({
        ...ErrorReducers,
        [action]: (state, { payload }) => {
          state.error = payload.message;
          state.type = 'FULLSCREEN';
        },
      }),
      {}
    ) || {}),
  },
});

export const ErrorActions = ErrorSlice.actions;
export const ErrorReducer = ErrorSlice.reducer;
