import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import AppLayout from '../templates/AppLayout';
import DestinationPage from '../organisms/Discover/DestinationPage';
import { fetchCities } from '../../redux/slices/Discover';
import { MapProvider } from '../organisms/MapUtils';

function Destination() {
  const { slug: cityId } = useParams();

  // Load content if not already loaded
  const cityFetchStatus = useSelector(
    (state) => state.Discover.cities && state.Discover.cities?.status
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (cityFetchStatus === 'IDLE' || !cityFetchStatus) {
      dispatch(fetchCities());
    }
  }, []);

  return (
    <AppLayout>
      <MapProvider>
        {cityFetchStatus === 'LOADING' ||
        cityFetchStatus === 'IDLE' ||
        !cityFetchStatus ? (
          <div
            style={{
              width: '100%',
              height: '80vh',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <CircularProgress />
          </div>
        ) : (
          <DestinationPage cityId={cityId} />
        )}
      </MapProvider>
    </AppLayout>
  );
}

export default Destination;
