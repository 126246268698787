import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Grid,
  Typography,
  FormHelperText,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { firebaseAuth } from '../../provider/AuthProvider';
import { FullPageButton } from '../atoms/Button/index';
import { LinkHyper, LinkRoute } from '../atoms/Link';
import { InputAuthForm } from '../atoms/Input';
import Logo from '../molecules/Logo';
import { Visibility, VisibilityOff } from '../atoms/Icon';
import classList from '../classList';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  horizontalLine: {
    width: '100%',
    textAlign: 'center',
    borderBottom: '2px solid #000',
    lineHeight: '0.1em',
    margin: '10px 0 20px',
  },
  horizontalLineText: {
    fontWeight: 'bold',
    background: '#fff',
    padding: '0 10px',
  },
  error: {
    color: theme.palette.error.main,
  },
}));

function SignupForm() {
  const classes = useStyles();

  const location = useLocation();
  const navigate = useNavigate();

  const { emailValue, isValidSignUp, signInSession } = location?.state || {
    emailValue: null,
    isValidSignUp: false,
    signInSession: null,
  };

  const { handleSignInWithEmailLink } = useContext(firebaseAuth);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [email, setEmail] = useState(emailValue);
  const errors = useSelector((state) => state.Auth.error);

  /* Formik takes care of onChange events */
  const handleSubmitWithFormik = async (values) => {
    await handleSignInWithEmailLink(values, signInSession);
  };

  const handlePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  useEffect(() => {
    if (!isValidSignUp) {
      navigate('/continue');
    }
  }, [isValidSignUp]);

  // Define Validations
  const signUpValidationSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, 'First Name must be at least 2 characters')
      .max(50, 'First Name must be at most 50 characters')
      .required('First Name is required'),

    lastName: Yup.string()
      .min(2, 'Last Name must be at least 2 characters')
      .max(50, 'Last Name must be at most 50 characters')
      .required('Last Name is required'),

    email: Yup.string().email('Email is invalid').required('Email is required'),

    password: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .required('Password is required'),
  });

  function MuiErrorMessage(props) {
    return (
      <ErrorMessage
        padding=".5em"
        {...props}
        component={(componentProps) => (
          <Typography
            variant="caption"
            className={classes.error}
            {...componentProps}
          />
        )}
      />
    );
  }

  return email ? (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        email: email || '',
        password: '',
      }}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(false);
        await handleSubmitWithFormik(values);
      }}
      validationSchema={signUpValidationSchema}>
      {(props) => {
        const {
          values,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          isValid,
          setValues,
        } = props;

        useEffect(() => {
          if (emailValue) {
            setValues((prevValues) => ({
              ...prevValues,
              email: emailValue,
            }));
          }
        }, [emailValue, setValues]);

        return isValidSignUp ? (
          <Box>
            <Box
              flexDirection="column"
              display="flex"
              alignItems="center"
              pt="2.3rem">
              <Typography
                mb="2.5rem"
                variant="h2"
                sx={{
                  fontFamily: 'Work Sans',
                  fontSize: '2.875rem',
                  fontWeight: '600',
                  lineHeight: '20px',
                }}>
                Sign Up
              </Typography>

              <Typography>
                Fill out the following to continue to Pilot
              </Typography>
            </Box>

            <form
              onSubmit={handleSubmit}
              className={`${classes.form} ${classList.signupForm}`}
              noValidate>
              <Box my={4}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <InputAuthForm
                      label="E-mail"
                      name="email"
                      autoComplete="email"
                      // required
                      fullWidth
                      disabled
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                        sx: {
                          color: '#8A8A8A',
                          '&.Mui-disabled': {
                            backgroundColor: '#FFFFFF',
                          },
                        },
                        required: false,
                      }}
                      InputProps={{
                        sx: {
                          '&::placeholder': {
                            color: '#8A8A8A',
                          },
                          '&.Mui-disabled': {
                            backgroundColor: '#F4F4F4',
                            '&:hover fieldset': {
                              borderColor: '#D9D9D9',
                            },
                          },
                        },
                      }}
                      value={emailValue}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <MuiErrorMessage name="email" />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <InputAuthForm
                      label="First Name"
                      name="firstName"
                      autoComplete="fname"
                      // required
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                        style: { color: '#8A8A8A' },
                        required: false,
                      }}
                      InputProps={{
                        sx: {
                          '&::placeholder': {
                            color: '#8A8A8A',
                          },
                          '&::label': {
                            color: 'red',
                          },
                        },
                      }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.firstName}
                    />
                    <MuiErrorMessage name="firstName" />
                  </Grid>

                  <Grid item xs={6} sm={6}>
                    <InputAuthForm
                      label="Last Name"
                      name="lastName"
                      autoComplete="lname"
                      // required
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                        style: { color: '#8A8A8A' },
                        required: false,
                      }}
                      InputProps={{
                        sx: {
                          '&::placeholder': {
                            color: '#8A8A8A',
                          },
                          '&::label': {
                            color: 'red',
                          },
                        },
                      }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.lastName}
                    />
                    <MuiErrorMessage name="lastName" />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <InputAuthForm
                      id="password"
                      placeholder="Password *"
                      name="password"
                      type={isPasswordVisible ? 'text' : 'password'}
                      autoComplete="current-password"
                      // required
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                        style: { color: '#8A8A8A' },
                        required: false,
                      }}
                      InputProps={{
                        sx: {
                          '&::placeholder': {
                            color: '#8A8A8A',
                          },
                          '&::label': {
                            color: 'red',
                          },
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handlePasswordVisibility}>
                              {isPasswordVisible ? (
                                <Visibility sx={{ color: '#141414' }} />
                              ) : (
                                <VisibilityOff sx={{ color: '#141414' }} />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                    />
                    <MuiErrorMessage name="password" />
                  </Grid>

                  <Grid item xs={10} sm={10}>
                    <Box>
                      <Typography variant="h5" color="text.light">
                        Creating an account means you&apos;re okay with
                        Pilot&apos;s{' '}
                        <LinkHyper
                          href="https://pilotplans.com/legal/terms-of-service"
                          target="_blank"
                          underline="hover">
                          Terms of Service
                        </LinkHyper>{' '}
                        and{' '}
                        <LinkHyper
                          href="https://pilotplans.com/legal/privacy-policy"
                          target="_blank"
                          underline="hover">
                          Privacy Policy
                        </LinkHyper>
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box my={4}>
                <FormHelperText className={classes.error}>
                  {errors}
                </FormHelperText>
              </Box>
              <Box my={4}>
                <FullPageButton
                  type="submit"
                  sx={{ height: '3rem' }}
                  disabled={!isValid || isSubmitting}>
                  Continue with Email
                </FullPageButton>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center">
                <Typography variant="h5" color="text.light">
                  <LinkRoute to="/continue">Not you? Go back. </LinkRoute>
                </Typography>
              </Box>
            </form>
            {/* Logo */}
            <Box pt={5} pr={1.5}>
              <Logo fullLogo imageWidth="35%" />
            </Box>
          </Box>
        ) : (
          <Typography>Not Valid SignUp</Typography>
        );
      }}
    </Formik>
  ) : (
    <Formik
      initialValues={{
        email: '',
      }}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(false);
        setEmail(values.email);
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email('Email is invalid')
          .required('Email is required'),
      })}>
      {(props) => {
        const {
          values,
          // isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          isValid,
          setValues,
        } = props;

        useEffect(() => {
          if (emailValue) {
            setValues((prevValues) => ({
              ...prevValues,
              email: emailValue,
            }));
          }
        }, [emailValue, setValues]);

        return isValidSignUp ? (
          <Box>
            <Box
              flexDirection="column"
              display="flex"
              alignItems="center"
              pt="2.3rem">
              <Typography
                mb="2.5rem"
                variant="h2"
                sx={{
                  fontFamily: 'Work Sans',
                  fontSize: '2.875rem',
                  fontWeight: '600',
                  lineHeight: '20px',
                }}>
                Sign Up
              </Typography>

              <Typography>Please enter your email for confirmation</Typography>
            </Box>

            <form onSubmit={handleSubmit} className={classes.form} noValidate>
              <Box mt={4}>
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <InputAuthForm
                      label="E-mail"
                      name="email"
                      autoComplete="email"
                      // required
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                        sx: {
                          color: '#8A8A8A',
                          '&.Mui-disabled': {
                            backgroundColor: '#FFFFFF',
                          },
                        },
                        required: false,
                      }}
                      InputProps={{
                        sx: {
                          '&::placeholder': {
                            color: '#8A8A8A',
                          },
                          '&.Mui-disabled': {
                            backgroundColor: '#F4F4F4',
                            '&:hover fieldset': {
                              borderColor: '#D9D9D9',
                            },
                          },
                        },
                      }}
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <MuiErrorMessage name="email" />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <MuiErrorMessage name="password" />
                  </Grid>
                </Grid>
              </Box>
              <Box my={1}>
                <FormHelperText className={classes.error}>
                  {errors}
                </FormHelperText>
              </Box>
              <Box mb={4}>
                <FullPageButton
                  type="submit"
                  sx={{ height: '3rem' }}
                  disabled={!isValid}>
                  Continue with Email
                </FullPageButton>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                mb={2}>
                <Typography variant="h5" color="text.light">
                  <LinkRoute to="/continue">Not you? Go back. </LinkRoute>
                </Typography>
              </Box>
            </form>
            {/* Logo */}
            <Box pt={3} pr={1.5}>
              <Logo fullLogo imageWidth="35%" />
            </Box>
          </Box>
        ) : (
          <Typography>Not Valid SignUp</Typography>
        );
      }}
    </Formik>
  );
}

export default SignupForm;
