import { Grid, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    backgroundColor: theme.palette.system.white,
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    paddingTop: '12%',
    position: 'relative',
    // paddingLeft: '8%',
    // paddingRight: '8%',
  },
  image: {
    backgroundImage: `url(/images/CoverImage0.jpg)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'bottom center',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

function AuthLayout({ children }) {
  const classes = useStyles();
  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
        <Container maxWidth="xs" className={classes.container}>
          {children}
        </Container>
      </Grid>
      <Grid item md={7} lg={7} xl={7} className={classes.image} />
    </Grid>
  );
}

export default AuthLayout;
