import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Typography, Button, IconButton } from '@mui/material';
import { ChevronRightRounded, CloseRounded } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import COPIES from './copies.json';
import actions from '../../../../redux/actions';
import SubscriptionsPriceSelector from './SubscriptionsPriceSelector';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#FFF',
    flexDirection: 'column',
    display: 'flex',
    outline: 'none',
    borderRadius: 8,
    height: 578,
    overflow: 'auto',
    width: 437,
    [theme.breakpoints.down('sm')]: {
      width: 380,
    },
    position: 'relative',
  },
  image: {
    width: '100%',
    height: 200,
    objectFit: 'cover',
    transform: 'translate3d(0, 0, 1px)',
  },
  body: {
    padding: '16px 48px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  ctaButton: {
    backgroundColor: theme.palette.system.white,
    border: '1px solid',
    borderColor: theme.palette.text.main,
    color: theme.palette.text.main,
    marginTop: 3,
    '&:hover': {
      backgroundColor: theme.palette.text.main,
    },
  },
  iconBtn: {
    position: 'absolute',
    top: 8,
    right: 8,
    zIndex: 2,
    backgroundColor: theme.palette.system.white,
    height: 24,
    width: 24,
    padding: 4,
    '&:hover': {
      backgroundColor: theme.palette.system.white,
      color: theme.palette.primary.main,
    },
  },
}));

function SellScreen() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [price, setPrice] = useState(18.0);
  const [isSubscription, setIsSubscription] = useState(true);

  const handleContributeClick = () => {
    dispatch(
      actions.View.setContribute({
        action: 1,
        price,
        paymentMode: isSubscription ? 'subscription' : 'payment',
      })
    );
  };

  const handleAlternateContributionClick = () => {
    dispatch(
      actions.View.setContribute({
        action: 3,
      })
    );
  };

  const handleClose = () =>
    dispatch(actions.View.setContribute({ action: -1 }));

  return (
    <div className={classes.root}>
      <img
        className={classes.image}
        alt="team-pic"
        src="/images/teamImage.jpg"
      />
      <IconButton className={classes.iconBtn} onClick={handleClose}>
        <CloseRounded sx={{ height: 16, width: 16 }} />
      </IconButton>
      <div className={classes.body}>
        <Typography variant="h2">{COPIES.SELL_SCREEN.title}</Typography>
        <Typography
          variant="caption"
          component="p"
          color="text.secondary"
          mt="12px">
          {COPIES.SELL_SCREEN.subtitle}
        </Typography>

        <SubscriptionsPriceSelector
          price={price}
          setPrice={setPrice}
          isSubscription={isSubscription}
          setIsSubscription={setIsSubscription}
        />
        <Button
          fullWidth
          disabled={!price}
          className={classes.ctaButton}
          onClick={handleContributeClick}>
          {COPIES.SELL_SCREEN.ctaCopy} <ChevronRightRounded />
        </Button>
        <Typography
          variant="caption"
          component="p"
          color="text.secondary"
          mt="12px"
          onClick={handleAlternateContributionClick}
          sx={{
            cursor: 'pointer',
            textDecoration: 'underline',
          }}>
          {COPIES.SELL_SCREEN.secondaryCtaCopy}
        </Typography>
      </div>
    </div>
  );
}

export default SellScreen;
