import { TRAVEL_MODES } from '../../../utils';
import config from '../../config';

async function fetchDirections(coordinates, travelMode = 'CAR') {
  const waypoints = coordinates
    .map((point) => `${point.lat},${point.long}`)
    .join(':');

  const TOMTOM_API_KEY = config.tomtomApiKey;

  const TOMTOM_URL = `https://api.tomtom.com/routing/1/calculateRoute/${waypoints}/json?instructionsType=text&language=en-US&travelMode=${
    TRAVEL_MODES[travelMode].apiName || 'car'
  }&routeType=shortest&key=${TOMTOM_API_KEY}`;

  let result = {};
  try {
    const data = await (await fetch(TOMTOM_URL)).json();
    result = {
      tripLegs: data?.routes && data?.routes[0]?.legs,
      error: data?.detailedError?.code || null,
    };
  } catch (err) {
    // handle error
  }
  return result;
}

export default fetchDirections;
