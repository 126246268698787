export default {
  primary: {
    main: '#ED702E',
    dark: '#ED702E',
    light: '#FFA766',
    extraLight: '#FFF1E0',
  },
  text: {
    main: '#222222',
    secondary: '#4E4E4E',
    light: '#8A8A8A',
  },
  system: {
    lightgrey: '#F4F4F4',
    grey: '#D9D9D9',
    white: '#FFFFFF',
  },
  warning: {
    main: '#E68A63',
  },
  error: {
    main: '#E5493D',
  },
  success: {
    main: '#43A047',
  },
  info: {
    main: '#1877F2',
  },
  loading: {
    bg: '#DCDCDC',
  },
};
