import { InputAdornment, Autocomplete } from '@mui/material';
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';

import { InputFriendSearchBar } from '../atoms/Input';

const useStyles = makeStyles(() => ({
  searchIcon: {
    color: '#A7A7A7',
  },
  searchBar: {
    padding: '1%',
  },
  input: {
    marginLeft: 8,
  },
  container: {
    width: 'inherit',
  },
}));

function FriendsSearchBar({ data }) {
  const classes = useStyles();
  return (
    <Autocomplete
      freeSolo
      disableClearable
      id="friend-in-list"
      onChange={() => {}}
      className={classes.container}
      options={
        data.getUser.friends
          ? data.getUser.friends.map((friend) => friend.user)
          : []
      }
      getOptionLabel={(option) => option.firstName}
      renderInput={(params) => (
        <InputFriendSearchBar
          {...params}
          fullWidth
          placeholder="Search your friends list"
          className={classes.searchBar}
          InputProps={{
            ...params.InputProps,
            className: classes.input,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon className={classes.searchIcon} />
              </InputAdornment>
            ),
            disableUnderline: true,
          }}
        />
      )}
    />
  );
}

export default FriendsSearchBar;
