import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { isIOS } from '../../../utils';

function LottiePlayer({
  filePath,
  style,
  fallbackImg,
  autoplay = true,
  loop = true,
  playOnIOS = false,
}) {
  const [error, setError] = useState(false);

  useEffect(() => {
    const player = document.getElementById('lottie-player-p');
    if (player)
      player.addEventListener('error', () => {
        setError(true);
      });
    return () => {
      if (player) player.removeEventListener('error', () => {});
    };
  }, []);

  if (error && fallbackImg) {
    return <img src={fallbackImg} alt="fallback" style={style} />;
  }

  if (error || (!playOnIOS && isIOS())) {
    return (
      <div
        style={{
          ...style,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <CircularProgress color="primary" size={25} />
      </div>
    );
  }

  return (
    <dotlottie-player
      autoplay={autoplay}
      loop={loop}
      mode="normal"
      id="lottie-player-p"
      src={filePath}
      style={style}
    />
  );
}

export default LottiePlayer;
