import { makeStyles } from '@mui/styles';
import { Checkbox, Grid, Typography } from '@mui/material';
import { FlightOutlined } from '@mui/icons-material';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  flightIcon: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(0.5),
    transform: 'rotate(90deg)',
  },
  flightDetails: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    paddingLeft: theme.spacing(1),
    border: '1px solid #DEDDDD',
    maxHeight: '28px',
    backgroundColor: '#FFFFFF',
    borderRadius: '4px',
    '&:hover': {
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  flightCheckbox: {
    marginLeft: 'auto',
    borderRadius: '4px',
  },
  customCheckbox: {
    color: '#D9D9D9',
    paddingRight: '4px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  orText: {
    textAlign: 'center',
    marginTop: theme.spacing(1),
  },
}));

function MultipleFlightSelect({
  flightNumber = '',
  flightIata = '',
  departure = [],
  arrival = [],
  setSelectedFlight = () => {},
}) {
  const classes = useStyles();

  const [selectedFlight, setSelectedFlightState] = useState('');

  const handleFlightSelection = (flightId) => {
    // Update the selectedFlight state
    setSelectedFlightState(flightId);

    // Call the parent setSelectedFlight prop to update the parent state
    setSelectedFlight(+flightId.split('-').pop());
  };

  return (
    <>
      {departure.map((departureDetail, index) => {
        const flightId = `flight-${index}`;
        return (
          <div key={flightId}>
            <Grid
              container
              alignItems="center"
              sx={{
                maxHeight: '24px',
                borderRadius: '4px',
              }}>
              <Grid item>
                <FlightOutlined
                  fontSize="small"
                  className={classes.flightIcon}
                />
              </Grid>
              <Grid item className={classes.flightDetails}>
                <Typography variant="subtitle1">
                  {flightIata} {flightNumber} - {departureDetail.airport} →{' '}
                  {arrival[index].airport}
                </Typography>
                <div className={classes.flightCheckbox}>
                  <Checkbox
                    checked={selectedFlight === flightId}
                    onChange={() => handleFlightSelection(flightId)}
                    className={classes.customCheckbox}
                  />
                </div>
              </Grid>
            </Grid>
            {index < departure.length - 1 && (
              <Typography variant="body2" className={classes.orText}>
                OR
              </Typography>
            )}
          </div>
        );
      })}
    </>
  );
}

export default MultipleFlightSelect;
