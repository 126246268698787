import React, { useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Typography,
  Button,
  TextField,
  Grid,
  Box,
  Stack,
  IconButton,
  InputAdornment,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/styles';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import SendIcon from '@mui/icons-material/Send';
import copy from 'copy-to-clipboard';
import InviteFriends from './InviteFriends';
import { createTripInvitation } from '../../../redux/slices/Trips';
import { firebaseAuth } from '../../../provider/AuthProvider';
import config from '../../config';
import UserAvatar from '../../molecules/UserAvatar';
import { HappyEmoji, LinkIcon } from '../../atoms/Icon';
import { EVENTS, phTrackEvent } from '../../../analytics';
import getCfConnector from '../../cfConnector';

function UserListItem({
  itemText = '',
  profilePhoto = null,
  index = 0,
  customEmojiProps = {},
  customAvatarStyles = {},
  isOwner = false,
}) {
  return (
    <Grid container style={{ alignItems: 'center', marginTop: 12 }}>
      <UserAvatar
        user={{}}
        index={index}
        avatarProps={{
          style: {
            height: 28,
            width: 28,
            ...customAvatarStyles,
          },
          src: profilePhoto,
        }}
        showBorder={false}
        CustomContent={(props) => (
          <HappyEmoji {...props} {...customEmojiProps} />
        )}
      />
      <Typography
        style={{
          display: 'flex',
          flex: 1,
          marginLeft: 16,
        }}>
        {itemText}
      </Typography>
      {isOwner ? (
        <Typography
          style={{
            color: '#8A8A8A',
            fontSize: '0.875rem',
            marginRight: 8,
          }}>
          Owner
        </Typography>
      ) : (
        <Typography
          style={{
            color: '#8A8A8A',
            fontSize: '0.875rem',
            marginRight: 8,
          }}>
          Editor
        </Typography>
      )}
    </Grid>
  );
}

function Step4Page({
  setStep,
  tripId,
  showFinalTrip,
  tripUsers,
  showPilotLabsPrompt,
}) {
  const dispatch = useDispatch();

  const trip = useSelector((state) => state.Trips.trips[tripId]);
  const currUser = useSelector((state) => state.Auth.userData.user);

  const [shareTripEmail, setShareTripEmail] = useState('');

  const { user = {} } = useContext(firebaseAuth);
  const { origin } = window.location;

  const inviteLink = `${origin}/join/${tripId}`;

  const checkRepeatedEmail = () =>
    shareTripEmail?.length > 0 &&
    tripUsers?.some((tripUser) => tripUser.email === shareTripEmail);

  const handleSendEmail = () => {
    if (checkRepeatedEmail(shareTripEmail)) {
      setShareTripEmail('');
      return;
    }
    dispatch(
      createTripInvitation({
        variables: {
          userEmail: shareTripEmail,
          tripId,
        },
      })
    )
      .then(async () => {
        phTrackEvent({
          event: EVENTS.TRIP_CREATE.INVITE_SEND,
          meta: {
            invitedEmail: shareTripEmail,
          },
        });
        /*
          TODO: Manage send email as part of the createInvitation call on the backend.
        */
        try {
          await (
            await getCfConnector()
          ).post(config.emailAPI, {
            toEmail: shareTripEmail,
            tripName: trip?.title,
            inviterName: user?.displayName,
            tripLink: inviteLink,
          });
        } catch (err) {
          // handle error
        }
      })
      .finally(() => {
        setShareTripEmail('');
      });
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleNext = () => {
    if (showPilotLabsPrompt) return setStep((prev) => prev + 1);
    if (!currUser?.labDisabled) return setStep((prev) => prev + 2);
    return showFinalTrip();
  };

  return (
    <Box
      sx={{
        height: { xs: 'auto', sm: '80vh' },
        display: 'flex',
        alignItems: { xs: 'start', sm: 'center' },
      }}>
      <Grid
        container
        height="500px"
        sx={{ paddingX: { xs: '20px', sm: '20px' } }}>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          <Stack maxWidth="400px" spacing={3} mt="30px">
            <Box
              onClick={() => {
                setStep((prev) => prev - 1);
              }}
              sx={{
                width: 'fit-content',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                color: '#8A8A8A',
              }}>
              <ChevronLeftRoundedIcon sx={{ marginTop: '-2px' }} />
              Back
            </Box>

            <Box>
              <Typography sx={{ fontSize: '37px', fontWeight: 500 }}>
                {!isMobile
                  ? 'Invite your friends to collab!'
                  : 'Invite your friends'}
              </Typography>

              {isMobile && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    ml: { xs: '20px', sm: '30px', md: '40px' },
                    mb: '20px !important',
                  }}>
                  <InviteFriends friends={tripUsers} />
                </Box>
              )}

              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSendEmail();
                }}>
                <TextField
                  label="Enter an email address"
                  variant="standard"
                  sx={{ display: 'block', mb: '20px !important' }}
                  fullWidth
                  value={shareTripEmail}
                  onChange={(e) => {
                    setShareTripEmail(e.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton type="submit">
                          <SendIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </form>

              <Box
                sx={{
                  height: '120px',
                  mb: '20px !important',
                  overflowY: 'scroll',
                }}>
                {tripUsers.map((tripUser, index) => (
                  <Grid item xs={12}>
                    <UserListItem
                      key={index}
                      profilePhoto={tripUser?.profilePicture}
                      itemText={`${tripUser?.firstName || ''} ${
                        tripUser?.lastName || ''
                      }`}
                      index={index}
                      isOwner={tripUser?.id === trip?.owner?.id && index === 0}
                    />
                  </Grid>
                ))}
              </Box>
            </Box>

            {!isMobile && (
              <Button
                sx={{
                  backgroundColor: 'white',
                  border: '#d9d9d9 1px solid',
                  color: 'black',
                  '&:hover': {
                    borderColor: 'primary.main',
                    backgroundColor: 'white',
                    color: 'black',
                  },
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '2px',
                }}
                onClick={() => copy(inviteLink)}>
                {LinkIcon()}
                Copy Link
              </Button>
            )}
          </Stack>
          {isMobile && (
            <Button
              sx={{ width: '100%', display: 'block', margin: '0 auto' }}
              onClick={handleNext}>
              {tripUsers.length === 1 ? 'Continue Without Inviting' : 'Next'}
            </Button>
          )}
        </Grid>

        {!isMobile && (
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            <Box
              sx={{
                maxWidth: '520px',
                height: '390px',
              }}>
              <Box
                mb="24px !important"
                height="100%"
                flexDirection="column"
                display="flex">
                <div style={{ flex: 1, display: 'flex', width: '100%' }} />
                <InviteFriends friends={tripUsers} />
              </Box>

              <Button
                sx={{
                  width: '80%',
                  display: 'block',
                  margin: '0 auto',
                  border: '2px solid transparent',
                }}
                onClick={handleNext}>
                {tripUsers.length === 1 ? 'Continue Without Inviting' : 'Next'}
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default Step4Page;
