import { makeStyles } from '@mui/styles';
import { Link } from '@mui/material';

const useSocialIconStyles = (hoverColor) =>
  makeStyles(() => ({
    iconLink: {
      color: '#A7A7A7',
      padding: 6,
      paddingBottom: 0,
      '& .MuiIcon-root': {
        height: 16,
        width: 16,
      },
      '&:hover': {
        cursor: 'pointer',
        color: hoverColor,
      },
    },
  }))();

function SocialIcon({ link, hoverColor, Icon }) {
  const classes = useSocialIconStyles(hoverColor);
  return (
    <Link className={classes.iconLink} href={link} target="_blank">
      {Icon}
    </Link>
  );
}

export default SocialIcon;
