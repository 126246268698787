import { useEffect, useState } from 'react';
import {
  CircularProgress,
  Grid,
  Typography,
  Box,
  useMediaQuery,
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import loadingScreenData from '../loadingTexts.json';
import LottiePlayer from '../molecules/CustomLottiePlayer';

/**
 * The Layout will serve the UX state when fetching the data from the API
 *
 * @param {bool} fullPage - When set to true, will display loading gif on a full page
 * 							When set to false, will display the loading gif in any container
 * @returns Loading Component
 */

const useStyles = makeStyles(() => ({
  loadingContainer: {
    minHeight: '100vh',
    padding: '0 16px',
  },
  loadingWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '516px',
    textAlign: 'center',
  },
  flightLoaderBox: {
    height: '50px',
    width: '50px',
    boxShadow: '0 0 0 2px #DDDDDD',
    borderRadius: '50%',
    position: 'relative',
    animation: '$spin 2s cubic-bezier(0.45, 0.05, 0.55, 0.95) infinite',
    marginBottom: '25px',
  },
  animatedFlightIcon: {
    position: 'absolute',
    top: '-10px',
    left: '15px',
    transform: 'rotate(45deg)',
  },
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
}));

function isIOS() {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
}

export function LoadingText() {
  const [randomIndex, setRandomIndex] = useState(null);

  useEffect(() => {
    const changeText = () => {
      const index = Math.floor(
        Math.random() * loadingScreenData.loadingTexts.length
      );
      setRandomIndex(index);
    };
    changeText(); // Initial text setup
    const intervalId = setInterval(changeText, 2500); // Changes text every 2.5 seconds

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, []);

  if (randomIndex === null) {
    return null;
  }
  return (
    <Typography variant="h3Sub" fontWeight={600}>
      {loadingScreenData?.loadingTexts[randomIndex]}
    </Typography>
  );
}

function LoadingLayout({ fullPage = true }) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  if (!fullPage) {
    return <CircularProgress />;
  }
  return (
    <Grid
      container
      spacing={0}
      justifyContent="center"
      className={classes.loadingContainer}>
      <Grid item className={classes.loadingWrapper}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            flex: 1,
            marginTop: '100px',
          }}>
          {isIOS() ? (
            <img
              src="/images/plane-wind-clouds_loader_ios.gif"
              alt="pilot_loading"
              style={{
                width: isMobile ? 150 : 200,
                height: isMobile ? 150 : 200,
              }}
            />
          ) : (
            <LottiePlayer
              filePath="/lottiefiles/flight-loader.lottie"
              style={{
                alignSelf: 'center',
                width: isMobile ? 150 : 200,
                height: isMobile ? 150 : 200,
              }}
            />
          )}

          <LoadingText />
        </Box>
        <img
          src="/images/FullLogo.png"
          alt="pilot"
          height="30"
          style={{
            marginBottom: '100px',
          }}
        />
      </Grid>
    </Grid>
  );
}

export default LoadingLayout;
