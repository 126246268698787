import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import graphqlClient from '../../graphql/index';
import QUERY from '../../graphql/queries';

const initialState = {
  wishlists: [],
  count: 0,
  status: 'IDLE', // IDLE, LOADING, SUCCESS
  error: false,
};

export const getWishlists = createAsyncThunk(
  'WISHLIST/getWishlists',
  async ({ userId }, { getState, rejectWithValue }) => {
    try {
      const wStatus = getState().Wishlist.status;
      if (!wStatus === 'IDLE') return null;
      const { data } = await graphqlClient.query({
        query: QUERY.GET_WISHLISTS,
        variables: { userId },
      });
      return data.getWishlists;
    } catch (err) {
      rejectWithValue(err);
      return null;
    }
  }
);

export const updateWishlist = createAsyncThunk(
  'WISHLIST/updateWishlist',
  async (wishlist, { getState, rejectWithValue }) => {
    const prevState = getState().Wishlist.wishlists;
    try {
      const { error } = await graphqlClient.mutate({
        mutation: QUERY.UPDATE_WISHLIST,
        variables: wishlist,
      });
      if (error)
        rejectWithValue({
          error,
          reset: prevState,
        });
      return true;
    } catch (err) {
      rejectWithValue({
        error: err,
        reset: prevState,
      });
      return null;
    }
  }
);

export const saveDiscoverItemToWishlist = createAsyncThunk(
  'WISHLIST/saveDiscoverItemToWishlist',
  async ({ discoverPoi }, { getState }) => {
    try {
      const state = getState();
      const userId = state.Auth.firebaseUser.uid;

      const { data } = await graphqlClient.mutate({
        mutation: QUERY.CREATE_WISHLIST,
        variables: {
          owner: userId,
          title: discoverPoi?.title,
          coverImage: discoverPoi?.coverImage,
          type: 'DISCOVER',
          relationId: discoverPoi?.relationId,
          savedData: JSON.stringify(discoverPoi),
        },
      });
      return data;
    } catch (err) {
      return null;
    }
  }
);

const WishlistSlice = createSlice({
  name: 'WISHLIST',
  initialState,
  reducers: {
    createLocalWishlist: (state, action) => {
      state.wishlists = [
        ...(state.wishlists || []),
        {
          ...action.payload,
        },
      ];
      state.count += 1;
    },
    deleteLocalWishlist: (state, { payload }) => {
      state.wishlists = state.wishlists.filter(
        (wishlist) => wishlist.id !== payload.id
      );
    },
    updateLocalWishlist: (state, { payload }) => {
      state.wishlists = state.wishlists.map((wishlist) => {
        if (payload.localId === wishlist.id || payload.id === wishlist.id) {
          return {
            ...wishlist,
            ...payload,
          };
        }
        return wishlist;
      });
    },
    resetWishlist: (state, { payload }) => {
      state.error = payload.error;
    },
  },
  extraReducers: {
    [getWishlists.pending]: (state) => {
      state.status = 'LOADING';
    },
    [getWishlists.fulfilled]: (state, { payload }) => {
      state.status = 'SUCCESS';
      state.wishlists = payload;
      state.count = payload?.length || 0;
    },
    [getWishlists.rejected]: (state, { payload }) => {
      state.status = 'IDLE';
      state.error = payload;
    },
    [updateWishlist.pending]: (state, { meta: { arg: payload } }) => {
      state.wishlists = state.wishlists.map((wishlist) => {
        if (payload.localId === wishlist.id || payload.id === wishlist.id) {
          return {
            ...wishlist,
            ...payload,
          };
        }
        return wishlist;
      });
    },
    [updateWishlist.rejected]: (state, { error: { error, reset } }) => {
      state.error = error;
      state.wishlists = reset;
    },
    [saveDiscoverItemToWishlist.fulfilled]: (
      state,
      {
        meta: {
          arg: { discoverPoi: wishlist },
        },
        payload,
      }
    ) => {
      state.wishlists = [
        ...(state.wishlists || []),
        {
          ...(wishlist || {}),
          type: 'DISCOVER',
          ...payload.createWishlist,
        },
      ];
      state.count += 1;
    },
  },
});

export const createWishlist = createAsyncThunk(
  'WISHLIST/createWishlist',
  async (wishlist, { dispatch, getState }) => {
    try {
      const state = getState();
      const localId = `local-wishlist-card-${state.Wishlist.count}`;
      dispatch(
        WishlistSlice.actions.createLocalWishlist({ id: localId, ...wishlist })
      );

      const { data } = await graphqlClient.mutate({
        mutation: QUERY.CREATE_WISHLIST,
        variables: wishlist,
      });
      dispatch(
        WishlistSlice.actions.updateLocalWishlist({
          localId,
          ...data?.createWishlist,
        })
      );
      return true;
    } catch (err) {
      const state = getState();
      const prevState = state.Wishlist.wishlists;
      dispatch(
        WishlistSlice.actions.resetWishlist({
          wishlists: prevState,
          error: err,
        })
      );
      return null;
    }
  }
);

export const deleteWishlist = createAsyncThunk(
  'WISHLIST/deleteWishlist',
  async (wishlist, { dispatch, getState, rejectWithValue }) => {
    const prevState = getState().Wishlist.wishlists;
    dispatch(WishlistSlice.actions.deleteLocalWishlist(wishlist));
    try {
      const { error } = await graphqlClient.mutate({
        mutation: QUERY.DELETE_WISHLIST,
        variables: { id: wishlist.id },
      });

      if (error) {
        throw error;
      }
    } catch (err) {
      rejectWithValue(err);
      dispatch(
        WishlistSlice.actions.resetWishlist({
          wishlists: prevState,
          error: err,
        })
      );
    }
  }
);

export const WishlistActions = WishlistSlice.actions;
export const WishlistReducer = WishlistSlice.reducer;
