import { Grid, Avatar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classList from '../../classList';
import UserAvatar from '../UserAvatar';

const avatarBackgroundColors = ['#FFD9D9', '#D9FAFF', '#E2FFD9'];
const avatarBorderColors = ['#F28B87', '#67DAEC', '#8DC67F'];
const avatarTextColors = ['#ED702E', '#1592C2', '#3AA031'];

const useAvatarClasses = (props) =>
  makeStyles(() => ({
    avatar: {
      backgroundColor: avatarBackgroundColors[props.index % 3],
      marginLeft: props.onCard
        ? props.index === 0
          ? 0
          : props.renderSmall
          ? -7.5
          : -12
        : props.renderSmall
        ? -7.5
        : -5,
      zIndex: 1,
      border: `2px solid ${
        props.onCard
          ? 'white'
          : props.borderColor || avatarBorderColors[props.index % 3]
      }`,
      position: 'relative',
      height: props.iconSize
        ? props.iconSize
        : props.renderSmall
        ? 30
        : props.onCard
        ? 40
        : 36,
      width: props.iconSize
        ? props.iconSize
        : props.renderSmall
        ? 30
        : props.onCard
        ? 40
        : 36,
      fontSize: props.fontSize || props.renderSmall ? 12 : 16,
      fontWeight: 600,
      color: avatarTextColors[props.index % 3],
    },
    avatar2: {
      backgroundColor: '#FFF',
      marginLeft: props.onCard
        ? props.index === 0
          ? 0
          : props.renderSmall
          ? -7.5
          : -12
        : props.renderSmall
        ? -7.5
        : -5,
      zIndex: 1,
      border: `2px solid ${props.borderColor || '#E3E3E3'}`,
      position: 'relative',
      height: props.renderSmall ? 30 : props.onCard ? 40 : 36,
      width: props.renderSmall ? 30 : props.onCard ? 40 : 36,
      fontSize: props.fontSize || props.renderSmall ? 12 : 16,
      color: '#222',
      fontWeight: 800,
    },
  }))();

function OverlappingAvatar({
  user,
  type,
  renderSmall,
  index,
  onCard,
  borderColor,
  fontSize,
  iconSize,
}) {
  // TODO: use user profile pictures.
  const classes = useAvatarClasses({
    renderSmall,
    fontSize,
    iconSize,
    index,
    onCard,
    borderColor,
  });
  if (type === 1) {
    return (
      <UserAvatar
        iconSize={iconSize}
        fontSize={fontSize}
        avatarProps={{ className: classes.avatar }}
        user={user}
      />
    );
  }
  return <Avatar className={classes.avatar2}>{user}</Avatar>;
}

function AvatarStack({
  people = [],
  renderSmall = true,
  LTR = false,
  onCard = false,
  borderColor,
  limit = 10,
  CustomLimitComponent = null,
  fontSize,
  iconSize,
}) {
  return (
    <Grid
      container
      direction={LTR ? 'row' : 'row-reverse'}
      className={classList.avtarStack}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      {!LTR && people.length - limit > 0 ? (
        CustomLimitComponent ? (
          <CustomLimitComponent count={people.length - limit} />
        ) : (
          <OverlappingAvatar
            iconSize={iconSize}
            borderColor={borderColor}
            user={people.length - limit}
            type={2}
            renderSmall={renderSmall}
            onCard={onCard}
          />
        )
      ) : null}
      {people.slice(0, limit).map((person, index) => (
        <OverlappingAvatar
          iconSize={iconSize}
          borderColor={borderColor}
          key={index.toString()}
          user={person}
          type={1}
          renderSmall={renderSmall}
          index={index}
          onCard={onCard}
        />
      ))}
      {LTR && people.length - limit > 0 ? (
        CustomLimitComponent ? (
          <CustomLimitComponent count={people.length - limit} />
        ) : (
          <OverlappingAvatar
            iconSize={iconSize}
            fontSize={fontSize}
            borderColor={borderColor}
            user={people.length - limit}
            type={2}
            renderSmall={renderSmall}
            onCard={onCard}
          />
        )
      ) : null}
    </Grid>
  );
}

export default AvatarStack;
