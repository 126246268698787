import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import {
  BrowserRouter,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';
import { PostHogProvider } from 'posthog-js/react';
import App from './App';
import AuthProvider from './provider/AuthProvider';
import SoundsProvider from './sounds';
import graphqlClient from './graphql';
import store from './redux/store';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DNS,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV6Instrumentation(
            React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes
          ),
        }),
      ],
      tracePropagationTargets: [
        'localhost',
        /^https:\/\/us-central1-pilotplans-prod\.cloudfunctions\.net/,
        /^https:\/\/us-central1-pilotplans-staging\.cloudfunctions\.net/,
        /^https:\/\/us-central1-happyhour-firebase-mvp-v1\.cloudfunctions\.net/,
      ],
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
});

ReactDOM.render(
  <BrowserRouter>
    <PostHogProvider
      apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
      options={{
        person_profiles: 'identified_only',
        api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
        capture_pageview: false,
        session_recording: {
          maskAllInputs: false,
          maskInputOptions: {
            password: true,
          },
        },
        autocapture: process.env.REACT_APP_ENVIRONMENT === 'production',
      }}>
      <Provider store={store}>
        <SoundsProvider>
          <ApolloProvider client={graphqlClient}>
            <AuthProvider>
              <App />
            </AuthProvider>
          </ApolloProvider>
        </SoundsProvider>
      </Provider>
    </PostHogProvider>
  </BrowserRouter>,
  document.getElementById('root')
);
