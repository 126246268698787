import React from 'react';
import { Typography, Stack, Card, CardContent, Box } from '@mui/material';
import AvatarStack from '../AvatarStack';
import ImgWithFallback from '../../atoms/ImgWithFallback';

function TripCard({ tripImage, tripName, dateRange, tripUsers }) {
  function formatDateRange(tripDateRange) {
    if (!tripDateRange.from || !tripDateRange.to) {
      return 'Date range not available';
    }

    const options = { month: 'short', day: 'numeric' };
    try {
      const fromDate = new Date(tripDateRange.from).toLocaleDateString(
        undefined,
        options
      );
      const toDate = new Date(tripDateRange.to).toLocaleDateString(
        undefined,
        options
      );

      const durationInDays =
        Math.floor(
          (new Date(tripDateRange.to) - new Date(tripDateRange.from)) /
            (24 * 60 * 60 * 1000)
        ) + 1;
      if (durationInDays === 1) {
        return `${fromDate}-${toDate}・${durationInDays} day`;
      }
      return `${fromDate}-${toDate}・${durationInDays} days`;
    } catch (error) {
      return 'Invalid date range';
    }
  }

  return (
    <Card
      sx={{
        width: '100%',
        maxWidth: '350px',
        padding: '0',
      }}>
      <CardContent
        sx={{
          margin: '0',
          padding: '0 !important',
          width: '100%',
          dropShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        }}>
        <Stack>
          {tripImage !== '' ? (
            <Box
              sx={{
                backgroundColor: '#f4f4f4',
                width: '100%',
                position: 'relative',
              }}>
              <ImgWithFallback
                src={tripImage}
                alt="Trip cover"
                style={{ width: '100%' }}
              />
              <Box
                style={{
                  position: 'absolute',
                  left: '8px',
                  bottom: '10px',
                }}>
                <AvatarStack people={tripUsers || []} LTR onCard />
              </Box>
            </Box>
          ) : (
            <Box sx={{ padding: '40px 20px' }}>
              <img
                src="images/worldMapWithPoints.svg"
                alt="Trip cover"
                style={{ width: '100%', opacity: 0.5 }}
              />
            </Box>
          )}

          <Stack p={1}>
            {tripName && (
              <Typography fontSize="20px" fontWeight={500} mb="2px">
                {tripName}
              </Typography>
            )}
            {!tripName && (
              <Box
                sx={{
                  width: '45%',
                  height: '25px',
                  borderRadius: '10px',
                  backgroundColor: '#F4F4F4',
                  marginBottom: '7px',
                }}
              />
            )}
            {dateRange && (
              <Typography color="#8A8A8A">
                {formatDateRange(dateRange)}
              </Typography>
            )}
            {!dateRange && (
              <Box
                sx={{
                  width: '25%',
                  height: '25px',
                  borderRadius: '10px',
                  backgroundColor: '#F4F4F4',
                  marginBottom: '7px',
                }}
              />
            )}
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}

export default TripCard;
