import React, { createContext, useContext, useState, useEffect } from 'react';
import { Howl, Howler } from 'howler';
import { useSelector } from 'react-redux';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import FLAGS from '../featureFlags';

const SOUND_PATH = 'sounds/';

// All sounds in the app, the value should correspond to the file name of the corresponding sound.
export const SOUNDS = {
  detailClose: 'DetailClose',
  heartChime: 'HeartChime',
  inOutClick: 'InOutClick',
  mapZoom: 'MapZoom',
  photoUpload: 'PhotoUpload',
  pongPop: 'PongPop',
  softPop: 'SoftPop',
  removeBaritone: 'RemoveBaritone',
  tripWelcomeTone: 'TripWelcomeTone',
};

const SoundsContext = createContext();

export function SoundsProvider({ children }) {
  const [sounds, setSounds] = useState({});
  const [isFirstLoad, setFirstLoad] = useState(true);

  const isSoundsEnabled = useFeatureFlagEnabled(FLAGS?.APP_SOUNDS);
  const shouldDisableSounds = useSelector(
    (state) => state.Auth.userData?.user?.disableSounds
  );

  // Init config
  Howler.volume(1.0);

  // load all sounds into an object, on first load
  useEffect(() => {
    const HOWLS = Object.values(SOUNDS).reduce((howls, sound) => {
      howls[sound] = new Howl({
        src: [`${window.origin}/${SOUND_PATH}${sound}.mp3`],
      });
      return howls;
    }, {});

    setSounds(HOWLS);
  }, []);

  const playSound = async (soundKey) => {
    // if sounds toggle is not set use feature flag value, or if set to false, do not play sound
    if (shouldDisableSounds === null ? !isSoundsEnabled : shouldDisableSounds)
      return;
    // auto suspends after 30s of inactivity, resume if suspended
    if (Howler?.ctx?.state === 'suspended' && !isFirstLoad) {
      await Howler.ctx.resume();
    }

    // Before first user interaction, the audio is locked, only play sound if context is unlocked and ready
    if (Howler?.ctx?.state === 'running' && sounds[soundKey]) {
      if (isFirstLoad) setFirstLoad(false);
      sounds[soundKey].play();
    }
  };

  const setVolume = (volume) => {
    if (volume && volume >= 0 && volume <= 1) {
      Howler.volume(volume);
    }
  };

  return (
    <SoundsContext.Provider
      value={{
        playSound,
        setVolume,
      }}>
      {children}
    </SoundsContext.Provider>
  );
}

export const useSounds = () => useContext(SoundsContext);

export default SoundsProvider;
