/* eslint-disable no-unused-vars */
import { useState, useContext } from 'react';
import {
  Card,
  CardActionArea,
  CardActions,
  CardMedia,
  Grid,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { MoreVert } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { updateWishlist, deleteWishlist } from '../../redux/slices/Wishlist';
import { firebaseAuth } from '../../provider/AuthProvider';
import OptionsMenu from './Menu/OptionsMenu';
import { ImageSelectorModal } from './Modal';
import { handleWishlistImageUpload, handleOldPhotoDelete } from '../../utils';
import AddWishItem from './AddWishItem';

const useWishlistCardStyles = makeStyles(() => ({
  root: {
    borderRadius: '8px',
    display: 'flex',
    flexGrow: 1,
    width: '98%',
    flexDirection: 'column',
  },
  media: {
    height: 195,
    borderRadius: '8px',
  },
  text: {
    color: '#4E4E4E',
    whiteSpace: 'nowrap',
    maxWidth: 280,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  icon: {
    fontSize: '16px',
    '&:hover': {
      color: '#ED702E',
      cursor: 'pointer',
    },
  },
  title: {
    fontWeight: 700,
    fontSize: 24,
    color: '#4e4e4e',
    maxWidth: 280,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  titleContainer: {
    paddingLeft: 48,
    paddingRight: 48,
    paddingTop: 80,
    paddingBottom: 80,
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
  },
  bottomContainer: {
    height: '57px',
  },
}));

const customMenuItemStyle = {
  sx: {
    color: '#222222',
    fontSize: '14px',
    '&:hover ': {
      color: '#222222',
    },
  },
};

function WishlistCard({ wishlist }) {
  const dispatch = useDispatch();
  const { user } = useContext(firebaseAuth);
  const userId = user.uid;

  const classes = useWishlistCardStyles();
  const [openMenu, setOpenMenu] = useState(false);
  const [pickerOpen, setPickerOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);

  // const handleLike = async () => {
  //   let likesObj = await getWishlist(wishlist.id);
  //   likesObj = likesObj.likes;
  //   let likes = (likesObj && likesObj.map((obj) => obj.id)) || [];
  //   if (liked) {
  //     setLiked(false);
  //     likes.splice(likes.indexOf(user.uid), 1);
  //   } else {
  //     setLiked(true);
  //     likes.push(user.uid);
  //   }
  //   updateWishlist({
  //     variables: {
  //       id: wishlist.id,
  //       likes,
  //     },
  //     refetchQueries: [
  //       {
  //         query: QUERY.GET_WISHLISTS,
  //         variables: { userId: userId },
  //       },
  //     ],
  //   });
  // };

  const handleWishlistUpdate = async (newImageUrl) => {
    const originalUrl = wishlist.coverImage;
    dispatch(
      updateWishlist({
        id: wishlist.id,
        coverImage: newImageUrl,
      })
    );
    // Delete old picture
    if (originalUrl && !originalUrl.includes('unsplash'))
      handleOldPhotoDelete(originalUrl);
    setOpenMenu(false);
    return true;
  };

  const handleDelete = () => {
    const originalUrl = wishlist.coverImage;
    if (originalUrl && !originalUrl.includes('unsplash')) {
      // Delete old picture
      handleOldPhotoDelete(originalUrl);
    }
    dispatch(
      deleteWishlist({
        id: wishlist.id,
      })
    );
  };

  const handleEdit = () => {
    setEditMode(true);
  };

  // const switchPrivacy = (value) => {
  //   setPrivacy(value);
  //   setTooltipActive(true);
  //   setTimeout(() => {
  //     setTooltipActive(false);
  //   }, 2500);
  //   dispatch(
  //     updateWishlist({
  //       id: wishlist.id,
  //       privacy: value ? "private" : "public",
  //     })
  //   );
  // };

  return editMode ? (
    <AddWishItem
      wishlistDetails={wishlist}
      editMode={editMode}
      setEditMode={setEditMode}
    />
  ) : (
    <Card className={classes.root}>
      <CardActionArea disabled>
        {wishlist.coverImage ? (
          <CardMedia
            className={classes.media}
            image={wishlist.coverImage}
            component="img"
          />
        ) : (
          <div className={classes.titleContainer}>
            <Typography className={classes.title}>{wishlist.title}</Typography>
          </div>
        )}
      </CardActionArea>
      <CardActions className={classes.bottomContainer}>
        <Grid container spacing={1}>
          {wishlist.coverImage ? (
            <Grid xs={8} item>
              <Typography className={classes.text}>{wishlist.title}</Typography>
            </Grid>
          ) : (
            <Grid xs={8} item>
              {null}
            </Grid>
          )}
          <Grid xs={4} item style={{ flexGrow: 1, display: 'flex' }}>
            <Grid
              container
              spacing={1}
              justifyContent="flex-end"
              style={{ alignItems: 'center' }}>
              {/* <Grid item style={{ lineHeight: "1" }}>
              <Tooltip
                open={tooltipActive}
                className={classes.tooltip}
                title={privacy ? "private" : "public"}
                arrow>
                {privacy ? (
                  <Lock
                    className={classes.icon}
                    onClick={() => switchPrivacy(false)}
                  />
                ) : (
                  <Globe
                    className={classes.icon}
                    onClick={() => switchPrivacy(true)}
                  />
                )}
              </Tooltip>
            </Grid> */}
              <Grid item style={{ lineHeight: '0' }}>
                <ImageSelectorModal
                  handleUpload={(e) =>
                    handleWishlistImageUpload(e.target.files[0], userId)
                  }
                  changeCoverModalOpen={pickerOpen}
                  setChangeCoverModalOpen={setPickerOpen}
                  handleUpdateCoverImage={handleWishlistUpdate}
                />
                <MoreVert
                  className={classes.icon}
                  aria-controls={`wishlist-${wishlist.id}`}
                  aria-haspopup="true"
                  onClick={(e) => setOpenMenu(e.currentTarget)}
                />
                <OptionsMenu
                  menuItemProps={customMenuItemStyle}
                  id={`wishlist-${wishlist.id}`}
                  state={openMenu}
                  setState={setOpenMenu}
                  callbacks={[handleEdit, handleDelete]}
                  options={['Edit', 'Delete']}
                  isDividerVisisble={false}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
}

export default WishlistCard;
