import { Modal, Box, Avatar, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ButtonDefault, LightGreyButton } from '../../../atoms/Button/index';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    maxWidth: '328px',
    [theme.breakpoints.down('sm')]: {
      width: '75%',
    },
    left: '50%',
    minHeight: '250px',
    top: '50%',
    transform: 'translate(-50%, -50%) !important',
    borderRadius: '8px',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 0,
    padding: '16px',
  },
  icon: {
    width: '54px',
    height: '54px',
    backgroundColor: theme.palette.primary.extraLight,
    margin: '16px 0',
  },
  text1: {
    fontSize: '24px',
    fontWeight: '600',
    color: '#222222',
    margin: '0 0 8px 0',
    lineHeight: '34px',
  },
  text2: {
    color: '#222222',
    lineHeight: '20px',
    textAlign: 'center',
  },
  buttonsBar: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '24px 16px 16px 16px',
  },
  button: {
    fontSize: '14px',
    lineHeight: '18px',
    padding: '12px 16px',
    width: '50%',
    fontWeight: 500,
  },
}));

function DeleteModal({
  open,
  onClose,
  executeFunc,
  emoji,
  alertText,
  descriptionText,
  confirmText,
  cancelText,
  modalProps = {},
}) {
  const classes = useStyles();

  return (
    <Modal open={open} onClose={onClose}>
      <Box className={classes.root} {...modalProps}>
        <Avatar className={classes.icon}>{emoji}</Avatar>
        <Typography className={classes.text1}>{alertText}</Typography>
        <Typography className={classes.text2}>{descriptionText}</Typography>
        <Box className={classes.buttonsBar}>
          {cancelText && (
            <LightGreyButton
              id="delete-modal-cancel-button"
              className={classes.button}
              sx={{ mr: '6px' }}
              onClick={onClose}>
              {cancelText}
            </LightGreyButton>
          )}
          <ButtonDefault
            id="delete-modal-confirm-button"
            className={classes.button}
            sx={{ ml: '6px' }}
            onClick={executeFunc}>
            {confirmText}
          </ButtonDefault>
        </Box>
      </Box>
    </Modal>
  );
}

export default DeleteModal;
