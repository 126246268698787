import Header from '../molecules/Header';
import AppLayout from '../templates/AppLayout';

function Friends() {
  return (
    <AppLayout>
      <Header title="Friends" />
    </AppLayout>
  );
}

export default Friends;
