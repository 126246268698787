import { createSlice } from '@reduxjs/toolkit';
import getAsyncThunk from '../helpers';
import QUERY from '../../graphql/queries';
import {
  ONE_DAY_MS,
  getNumberOfNights,
  parseISODate,
} from '../../components/organisms/Stays/bookingsUtils';

const initialSearchDataState = (startDate, endDate) => {
  let arrivalDate = startDate && parseISODate(startDate);
  let departureDate = endDate && parseISODate(endDate);

  if (!arrivalDate || arrivalDate < new Date(Date.now())) {
    arrivalDate = new Date(Date.now() + 2 * ONE_DAY_MS);
    departureDate = new Date(Date.now() + 4 * ONE_DAY_MS);
  }
  if (arrivalDate && !departureDate) {
    departureDate = new Date(new Date(arrivalDate).getTime() + 2 * ONE_DAY_MS);
  }
  return {
    date: {
      from: arrivalDate,
      to: departureDate,
    },
    guestCount: {
      adults: 2,
      children: 0,
      rooms: 1,
      total: 2,
    },
  };
};

const initialTripState = (startDate, endDate) => ({
  recommendations: {},
  filterLabels: [],
  cityLocationDetails: null,
  search: initialSearchDataState(startDate, endDate),
  hotelList: [],
  hoveredCardIndex: null,
  clickedCardIndex: null,
  activeHotelMarker: null,
  numberOfNights:
    getNumberOfNights(initialSearchDataState(startDate, endDate)?.date) || 1,
  isSaveListVisible: false,
  otherDetails: {
    currencyCode: 'USD',
    lowestTotalRate: 0,
    highestTotalRate: 100,
    totalResults: 0,
    totalAvailableResults: 0,
    totalFilteredResults: 0,
  },
  searchTrigger: false,
});

const initialState = {
  hotelDetailsPage: {
    data: {},
    searchProps: {
      checkInDate: null,
      checkOutDate: null,
      rooms: null,
    },
  },
  saved: {},
};

export const addSavedItem = getAsyncThunk(
  'Bookings/addSavedItem',
  QUERY.ADD_SAVED_ITEM
);

export const deleteSavedItem = getAsyncThunk(
  'Bookings/deleteSavedItem',
  QUERY.DELETE_SAVED_ITEM
);

const BookingsSlice = createSlice({
  name: 'Bookings',
  initialState,
  reducers: {
    initializeTripState: (state, action) => {
      const { tripId, startDate = null, endDate = null } = action.payload;
      if (!state[tripId]) {
        state[tripId] = initialTripState(startDate, endDate);
      }
    },
    deleteTrip: (state, action) => {
      const { tripId } = action.payload;
      if (state[tripId]) {
        delete state[tripId];
      }
    },
    initializeSaves: (state, action) => {
      state.saved[action.payload.tripId] = {
        ...state.saved[action.payload.tripId],
        ...Object.fromEntries(
          action.payload.saved
            ?.filter((savedItem) => savedItem?.type === 'HOTEL')
            ?.map((savedItem) => [savedItem.id, savedItem]) || []
        ),
      };
    },
    setHotelDetails: (state, { payload }) => {
      state.hotelDetailsPage.data[payload.key] = {
        ...(state.hotelDetailsPage.data[payload.key] || {}),
        ...payload,
      };
    },
    setHotelDetailsSearchProps: (state, { payload }) => {
      state.hotelDetailsPage.searchProps = {
        ...state.hotelDetailsPage.searchProps,
        ...payload,
      };
    },
    setCity: (state, action) => {
      const { tripId, cityName } = action.payload;
      state[tripId].recommendations.city = cityName;
    },
    setPois: (state, action) => {
      const { tripId, pois } = action.payload;
      state[tripId].recommendations.pois = pois;
    },
    resetCity: (state, action) => {
      const { tripId } = action.payload;
      state[tripId].recommendations.pois = null;
      state[tripId].recommendations.city = null;
    },
    setFilter: (state, action) => {
      const { tripId, filterItems } = action.payload;
      state[tripId].filterLabels = filterItems;
    },
    setHotelList: (state, action) => {
      const { tripId, hotelList } = action.payload;
      state[tripId].hotelList = hotelList;
    },
    addHotelList: (state, action) => {
      const { tripId, hotelList } = action.payload;
      state[tripId].hotelList = [...hotelList];
    },
    setCityLocationDetails: (state, action) => {
      const { tripId, cityLocationDetails } = action.payload;
      state[tripId].cityLocationDetails = cityLocationDetails;
    },
    setOtherDetails: (state, action) => {
      const { tripId, parsedRegionData } = action.payload;
      state[tripId].otherDetails = parsedRegionData;
    },
    setHoveredCardIndex: (state, action) => {
      const { tripId, cardIndex } = action.payload;
      state[tripId].hoveredCardIndex = cardIndex;
    },
    setClickedCardIndex: (state, action) => {
      const { tripId, cardIndex } = action.payload;
      state[tripId].clickedCardIndex = cardIndex;
    },
    setActiveHotelMarker: (state, action) => {
      const { tripId, activeHotelMarker = null } = action.payload;
      state[tripId].activeHotelMarker = activeHotelMarker;
    },
    setNumberOfNights: (state, action) => {
      const { tripId, numberOfNights } = action.payload;
      state[tripId].numberOfNights = numberOfNights;
    },
    setSearchDate: (state, action) => {
      const { tripId, date } = action.payload;
      state[tripId].search.date = date;
      state[tripId].numberOfNights = getNumberOfNights(date);
    },
    setGuestCount: (state, action) => {
      const { tripId, guestCount } = action.payload;
      state[tripId].search.guestCount = guestCount;
    },
    triggerSearch: (state, action) => {
      const {
        tripId,
        cityLocationDetails = null,
        date = null,
      } = action.payload;
      if (cityLocationDetails && date) {
        state[tripId].cityLocationDetails = cityLocationDetails;
        state[tripId].search.date = date;
        state[tripId].numberOfNights = getNumberOfNights(date);
        state[tripId].searchTrigger = true;
      } else {
        state[tripId].searchTrigger = false;
      }
    },
    setIsSaveListVisible: (state, action) => {
      const { tripId, isSaveListVisible } = action.payload;
      state[tripId].isSaveListVisible = isSaveListVisible;
    },
  },
  extraReducers: {
    [addSavedItem.fulfilled]: (
      state,
      {
        payload,
        meta: {
          arg: { variables },
        },
      }
    ) => {
      state.saved[variables.tripId] = {
        ...state.saved[variables.tripId],
        [payload.addSavedItem.id]: {
          ...variables,
          ...(payload.addSavedItem || {}),
        },
      };
    },
    [deleteSavedItem.fulfilled]: (
      state,
      {
        payload,
        meta: {
          arg: { tripId },
        },
      }
    ) => {
      if (payload.deleteSavedItem) {
        delete state.saved[tripId][payload.deleteSavedItem];
      }
    },
  },
});

export const BookingsActions = BookingsSlice.actions;
export const BookingsReducer = BookingsSlice.reducer;
