import React, { useState } from 'react';
import { Typography, Collapse } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ArrowDownwardRounded, ArrowUpwardRounded } from '@mui/icons-material';
import DealsListItem from './DealsListItem';

const useStyles = makeStyles(() => ({
  dealsList: {
    padding: '24px 0px',
    border: '1px solid #D9D9D9',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 4,
  },
  expandMoreContainer: {
    display: 'flex',
    borderTop: '1px solid #D9D9D9',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '24px',
    color: '#ED702E',
  },
}));

/*
  Component that renders all deals for a specific room type for a hotel
*/
function DealsList({ deals, roomName, numberOfNights }) {
  const classes = useStyles();
  const [collapsed, setCollapsed] = useState(false);

  if (deals?.length < 1) return null;
  return (
    <div
      className={classes.dealsList}
      style={{ paddingBottom: deals?.length === 1 ? '8px' : '24px' }}>
      <Typography variant="h3" style={{ marginLeft: '24px' }}>
        {roomName}
      </Typography>

      <div>
        <DealsListItem key={-1} {...deals[0]} numberOfNights={numberOfNights} />
        <Collapse in={collapsed}>
          {deals?.slice(1, deals?.length)?.map((deal, idx) => (
            <div style={{ borderTop: '1px solid #D9D9D9' }}>
              <DealsListItem
                key={idx}
                {...deal}
                numberOfNights={numberOfNights}
              />
            </div>
          ))}
        </Collapse>
        {deals?.length > 1 && (
          <div className={classes.expandMoreContainer}>
            <Typography
              variant="h5"
              color="primary.main"
              sx={{ cursor: 'pointer' }}
              mr="4px"
              onClick={() => setCollapsed(!collapsed)}>
              {collapsed
                ? 'Show less'
                : `Show ${(deals?.length || 0) - 1} more deals`}
            </Typography>
            {collapsed ? (
              <ArrowUpwardRounded sx={{ height: 16, width: 16 }} />
            ) : (
              <ArrowDownwardRounded sx={{ height: 16, width: 16 }} />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default DealsList;
