import SigninForm from '../../organisms/SigninForm';
import AuthLayout from '../../templates/AuthLayout';

function Signin() {
  return (
    <AuthLayout>
      <SigninForm />
    </AuthLayout>
  );
}

export default Signin;
