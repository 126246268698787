import React, { useState } from 'react';
import {
  Typography,
  Grid,
  useMediaQuery,
  Snackbar,
  SnackbarContent,
} from '@mui/material';
import {
  ArrowCircleDownRounded,
  ArrowCircleUpRounded,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  saveDiscoverItemToWishlist,
  deleteWishlist,
} from '../../../redux/slices/Wishlist';
import CityCard from './CityCard';
import PoiCard from './PoiCard';
import PoiDetailsModal from './PoiDetailsModal';
import classList from '../../classList';
import { SecondaryOutlinedButton } from '../../atoms/Button/index';
import { EVENTS, phTrackEvent } from '../../../analytics';

function PopularCities() {
  const navigate = useNavigate();
  const handleCitySelect = (city) => {
    phTrackEvent({
      event: EVENTS.DISCOVER.CITY_CLICK,
      meta: {
        location_name: city?.name,
        location_country: city?.country,
      },
    });
    navigate(`/discover/${city.slug}`);
  };
  const cities = useSelector((state) =>
    Object.values(state.Discover.cities.data || {})
  );
  return (
    <Grid container spacing={2} style={{ marginTop: 20 }}>
      {cities.map((city) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={city.slug}>
          <CityCard
            className={classList.discoverCityCard}
            handleClick={() => handleCitySelect(city)}
            image={city.image}
            title={city.name}
            description={city.description}
            cardProps={{
              'data-city-slug': city.slug,
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
}

function PopularPlaces({ pois, singleRow = false, city }) {
  const [activePoi, setActivePoi] = useState(null);
  const navigate = useNavigate();

  const [showSavedSnackbar, setShowSavedSnackbar] = useState(false);
  const [prevModifiedPoi, setPrevModifiedPoi] = useState(null);
  const currentCity = useSelector(
    (state) => state.Discover.cities.data[city] || {}
  );

  // TODO: find a better way of doing this.
  const isSmScreen = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const isMdScreen = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const isLgScreen = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const isXlScreen = useMediaQuery((theme) => theme.breakpoints.up('xl'));

  const getCardsPerRow = () => {
    if (isXlScreen) {
      return 4;
    }
    if (isLgScreen) {
      return 4;
    }
    if (isMdScreen) {
      return 3;
    }
    if (isSmScreen) {
      return 2;
    }
    return 1;
  };

  const filteredWishlists = useSelector((state) =>
    state.Wishlist.wishlists?.filter((w) => w?.type === 'DISCOVER')
  );

  const dispatch = useDispatch();

  const isPoiSavedToWishlist = (id) => {
    const savedWishlists = filteredWishlists?.filter(
      (w) => w?.relationId === id
    );
    return savedWishlists?.length > 0 ? savedWishlists[0]?.id : null;
  };

  const handleSaveToWishlist = async ({ id, title, image, poi }) => {
    const savedWishlistId = isPoiSavedToWishlist(id);
    if (savedWishlistId) {
      setPrevModifiedPoi({
        id,
        title,
        image,
        poi,
      });
      await dispatch(deleteWishlist({ id: savedWishlistId }));
    } else {
      phTrackEvent({
        event: EVENTS.DISCOVER_CITY.WISHLIST_ADD,
        meta: {
          activity_name: title,
          activity_tags: poi?.categories,
          activity_address: poi?.address,
          location_name: currentCity?.name,
          location_country: currentCity?.country,
        },
      });
      await dispatch(
        saveDiscoverItemToWishlist({
          discoverPoi: {
            relationId: id,
            title,
            coverImage: image,
            ...poi,
          },
        })
      );
    }
    setShowSavedSnackbar(savedWishlistId ? 'DELETE' : 'ADD');
  };

  const poisList = pois?.slice(0, singleRow ? getCardsPerRow() : pois?.length);

  return (
    <Grid container spacing={2} style={{ marginTop: 8 }}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ top: '84' }}
        autoHideDuration={6000}
        open={Boolean(showSavedSnackbar)}
        onClose={() => {
          setShowSavedSnackbar(null);
          setPrevModifiedPoi(null);
        }}
        key="discover-wishlist-added-success-popup">
        <SnackbarContent
          message={
            showSavedSnackbar === 'ADD'
              ? 'Added to your Wishlist'
              : showSavedSnackbar === 'DELETE'
              ? 'Remove from your Wishlist'
              : ''
          }
          sx={{
            backgroundColor: '#FFF',
            color: '#222',
            '& > .MuiSnackbarContent-message': {
              padding: 0,
            },
          }}
          action={
            <SecondaryOutlinedButton
              onClick={() => {
                if (showSavedSnackbar === 'ADD') navigate('/wishlist');
                else {
                  setShowSavedSnackbar(null);
                  handleSaveToWishlist(prevModifiedPoi);
                }
              }}
              className={classList.discoverWishlistSnackbarButton}
              data-action-id={showSavedSnackbar === 'ADD' ? 'ADD' : 'REMOVE'}
              sx={{
                padding: '4px 8px',
                color: 'primary.main',
                borderColor: 'transparent',
                textDecoration: 'underline',
                '&:hover': {
                  borderColor: 'transparent',
                  textDecoration: 'underline',
                },
              }}>
              {showSavedSnackbar === 'ADD'
                ? 'View'
                : showSavedSnackbar === 'DELETE'
                ? 'Undo'
                : ''}
            </SecondaryOutlinedButton>
          }
        />
      </Snackbar>
      <PoiDetailsModal
        poi={activePoi}
        handleClose={() => setActivePoi(null)}
        showSaveCta
        isSaved={isPoiSavedToWishlist(activePoi?.referenceId)}
        handleSave={() =>
          handleSaveToWishlist({
            id: activePoi?.referenceId,
            title: activePoi?.title,
            image: activePoi?.images && activePoi?.images[0],
            poi: activePoi,
          })
        }
      />
      {poisList.map((place) => (
        <Grid item xs={12} sm={6} md={4} lg={3} xl={12 / 5} key={place.id}>
          <PoiCard
            handleClick={() => {
              phTrackEvent({
                event: EVENTS.DISCOVER_CITY.ACTIVITY_VIEW,
                meta: {
                  activity_name: place?.title,
                  activity_tags: place?.categories,
                  activity_address: place?.address,
                  location_name: currentCity?.name,
                  location_country: currentCity?.country,
                },
              });
              setActivePoi(place);
            }}
            image={place?.images && place?.images[0]}
            title={place?.title}
            tag={place?.categories && place.categories[0]}
            id={place?.referenceId}
            poi={place}
            isSaved={isPoiSavedToWishlist(place?.referenceId)}
            handleSaveButtonClick={() =>
              handleSaveToWishlist({
                id: place?.referenceId,
                title: place?.title,
                image: place?.images && place?.images[0],
                poi: place,
              })
            }
            cardProps={{
              'data-discover-poi-slug': `${place?.referenceId}-${place?.title}`,
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
}

function BlogSection({ city, cityTitle, blogs }) {
  const navigate = useNavigate();

  const handleBlogSelect = (blogSlug) => {
    phTrackEvent({
      event: EVENTS.DISCOVER_CITY.ARTICLE_CLICK,
    });
    navigate(`/discover/${city}/article/${blogSlug}`);
  };

  return (
    <Grid container spacing={2} style={{ marginTop: 8 }}>
      {blogs?.map((blog) => (
        <Grid item xs={12} sm={6} md={4} lg={3} xl={12 / 5} key={blog.slug}>
          <CityCard
            className={classList.discoverBlogCard}
            handleClick={() => handleBlogSelect(blog?.slug)}
            image={blog.thumbnail}
            title={blog.title}
            metaDescription={`${cityTitle} • ${blog.readingTime} minutes read`}
            styleProps={{ height: 331 }}
            cardProps={{
              'data-blog-slug': blog.slug,
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
}

function PopularSection({
  heading,
  description,
  type = 'CITY',
  showViewAll = false,
  toggle = false,
  onViewTogglelick = () => {},
  ...props
}) {
  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
      <div
        style={{
          display: 'flex',
          flex: 1,
        }}>
        <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
          <Typography variant="h2Sub" fontSize={28}>
            {heading}
          </Typography>
          <Typography variant="h4" color="#4E4E4E">
            {description}
          </Typography>
        </div>
        {showViewAll && (
          <div
            style={{
              display: 'flex',
              flex: 0,
              whiteSpace: 'nowrap',
            }}>
            <Typography
              variant="h4"
              color="#4E4E4E"
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                cursor: 'pointer',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
              onClick={(e) => {
                if (!toggle) {
                  window.scrollTo({
                    top: e.target.offsetTop - 100,
                    behavior: 'smooth',
                  });
                }
                onViewTogglelick(!toggle);
              }}>
              {toggle ? 'View less' : 'View all attractions'}
              {toggle ? (
                <ArrowCircleUpRounded
                  sx={{
                    color: '#4E4E4E',
                    marginLeft: 1,
                  }}
                />
              ) : (
                <ArrowCircleDownRounded
                  sx={{
                    color: '#4E4E4E',
                    marginLeft: 1,
                  }}
                />
              )}
            </Typography>
          </div>
        )}
      </div>

      {type === 'CITY' ? (
        <PopularCities {...props} />
      ) : type === 'BLOGS' ? (
        <BlogSection {...props} />
      ) : (
        <PopularPlaces {...props} />
      )}

      <div style={{ marginTop: 40 }} />
    </div>
  );
}

export default PopularSection;
