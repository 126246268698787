import { Avatar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FriendButton } from '../atoms/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'left',
    padding: '1em 2em',
    minWidth: '100%',
    justifyContent: 'left',
    border: 'none',
    color: '#000000',
    backgroundColor: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#F8F8F8',
    },
  },
  avatar: {
    marginRight: '1.5em',
    backgroundColor: theme.palette.primary.light,
  },
}));

function MessagesTab(props) {
  const classes = useStyles();
  const { userName } = props;
  const initials = userName.split(' ');

  return (
    <FriendButton
      onClick={() => {
        props.setActiveChat({
          id: props.chatId,
          chatName: props.userName,
          isTrip: props.isTrip,
          members: props.members,
        });
        props.setChat(true);
      }}
      className={classes.root}
      startIcon={
        initials.length > 1 ? (
          <Avatar className={classes.avatar}>
            {initials[0][0] + initials[1][0]}
          </Avatar>
        ) : (
          <Avatar className={classes.avatar}>{userName[0]}</Avatar>
        )
      }>
      {userName}
    </FriendButton>
  );
}

export default MessagesTab;
