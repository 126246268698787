import axios from 'axios';
import { auth } from '../firebase/FirebaseIndex';

// Connector to call the cloud functions
const getCfConnector = async () => {
  let bearerToken;
  try {
    bearerToken = await auth?.currentUser?.getIdToken();
  } catch (err) {
    // handle error
  }
  return axios.create({
    headers: {
      'Content-type': 'application/json',
      authorization: bearerToken ? `Bearer ${bearerToken}` : '',
    },
  });
};

export default getCfConnector;
