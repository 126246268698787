import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import QUERY from '../../graphql/queries';
import getAsyncThunk from '../helpers';
import { getCompleteTrip } from './Trips';
import { updateAccommodation, deleteAccommodation } from './Accommodation';
import { deleteActivity } from './Activity';
import { deleteLocation, updateLocation } from './Location';
import {
  createTripMapPin,
  clearMapPinsFromSection,
  clearMapPinsFromLocation,
} from './sharedThunks';

export const createMapPin = getAsyncThunk(
  'MAP/createMapPin',
  QUERY.CREATE_MAP_PIN
);

export const updateMapPin = getAsyncThunk(
  'MAP/updateMapPin',
  QUERY.UPDATE_MAP_PIN
);

export const deleteMapPin = getAsyncThunk(
  'MAP/deleteMapPin',
  QUERY.DELETE_MAP_PIN
);

export const deleteTripMapPin = getAsyncThunk(
  'MAP/deleteTripMapPin',
  QUERY.DELETE_TRIP_MAP_PIN
);

export const updateMapPinTitle = createAsyncThunk(
  'MAP/updateMapPinTitle',
  async ({ mapPinId, title, tripId }, { getState, dispatch }) => {
    const mapPin = getState()?.Map?.mapPins[mapPinId];
    if (!mapPin) return;
    const mapPinData = JSON.parse(mapPin.pinData);
    const updatedMapPin = {
      ...mapPin,
      pinData: JSON.stringify({ ...mapPinData, title }),
    };
    await dispatch(
      updateMapPin({ context: { tripId }, variables: { ...updatedMapPin } })
    );
  }
);

const initialState = {
  mapPins: {},
  directionsView: false,
  directionsPins: [{ id: 'stop_1' }, { id: 'stop_2' }],
  directionsRoutes: {
    routeGeoJson: {},
    travelMode: 'CAR',
    error: null,
  },
  hoveredPin: null,
};

const MapSlice = createSlice({
  name: 'MAP',
  initialState,
  reducers: {
    resetDirections: (state) => {
      state.directionsView = false;
      state.directionsPins = [{ id: 'stop_1' }, { id: 'stop_2' }];
      state.directionsRoutes = {
        routeGeoJson: {},
        travelMode: 'CAR',
      };
    },
    setDirectionsView: (state, { payload: { activateDirections = false } }) => {
      state.directionsView = activateDirections;
    },
    setDirectionsPins: (state, { payload = [] }) => {
      state.directionsPins = payload;
    },
    changeTravelMode: (state, { payload: travelMode = 'CAR' }) => {
      state.directionsRoutes.travelMode = travelMode;
    },
    setRoutes: (state, { payload: { tripLegs = null } }) => {
      if (!tripLegs) {
        state.directionsRoutes.tripLegs = [];
        state.directionsRoutes.routeGeoJson = {};
        return;
      }
      // GeoJSON format
      state.directionsRoutes.tripLegs = tripLegs;
      state.directionsRoutes.error = false;
      state.directionsRoutes.routeGeoJson = {
        type: 'FeatureCollection',
        features: tripLegs?.map((leg) => ({
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: leg.points.map((point) => [
              point.longitude,
              point.latitude,
            ]),
          },
        })),
      };
    },
    setRoutesError: (state) => {
      state.directionsRoutes.error = true;
      state.directionsRoutes.tripLegs = [];
      state.directionsRoutes.routeGeoJson = {};
    },
    setHoveredPin: (state, { payload: { pinId = null } }) => {
      state.hoveredPin = pinId;
    },
  },
  extraReducers: {
    [getCompleteTrip.fulfilled]: (
      state,
      {
        payload: {
          mapPinList,
          meta: { tripId },
        },
      }
    ) => {
      state.mapPins[tripId] =
        mapPinList?.reduce(
          (mapPins, mapPin) => ({ ...mapPins, [mapPin?.id]: mapPin }),
          {}
        ) || {};
    },

    ...([
      clearMapPinsFromLocation.fulfilled,
      clearMapPinsFromSection.fulfilled,
    ].reduce(
      (clearMapPinsReducers, action) => ({
        ...clearMapPinsReducers,
        [action]: (
          state,
          {
            payload: mapPinIds = [],
            meta: {
              arg: { tripId },
            },
          }
        ) => {
          if (!tripId) return;
          mapPinIds?.forEach((mapPinId) => {
            delete state.mapPins[tripId][mapPinId];
          });
        },
      }),
      {}
    ) || {}),

    [createMapPin.fulfilled]: (
      state,
      {
        payload: { createMapPin: mapPin },
        meta: {
          arg: {
            context: { tripId },
          },
        },
      }
    ) => {
      if (!tripId) return;
      state.mapPins = {
        ...state.mapPins,
        [tripId]: {
          ...(state.mapPins[tripId] || {}),
          [mapPin.id]: mapPin,
        },
      };
    },
    [createTripMapPin.fulfilled]: (
      state,
      {
        payload: { createTripMapPin: mapPin },
        meta: {
          arg: {
            context: { tripId },
          },
        },
      }
    ) => {
      state.mapPins = {
        ...state.mapPins,
        [tripId]: {
          ...(state.mapPins[tripId] || {}),
          [mapPin.id]: mapPin,
        },
      };
    },
    [updateMapPin.fulfilled]: (
      state,
      {
        meta: {
          arg: {
            variables: mapPin,
            context: { tripId },
          },
        },
      }
    ) => {
      state.mapPins = {
        ...state.mapPins,
        [tripId]: {
          ...(state.mapPins[tripId] || {}),
          [mapPin.id]: {
            ...(state.mapPins[tripId][mapPin.id] || {}),
            ...mapPin,
          },
        },
      };
    },
    [deleteMapPin.fulfilled]: (
      state,
      {
        meta: {
          arg: {
            variables: { id },
            context: { tripId },
          },
        },
      }
    ) => {
      delete state.mapPins[tripId][id];
    },
    [deleteTripMapPin.fulfilled]: (
      state,
      {
        meta: {
          arg: {
            variables: { id },
            context: { tripId },
          },
        },
      }
    ) => {
      delete state.mapPins[tripId][id];
    },

    // creating and deleting mapPins in real time, this setup to reduce repetitive code.
    ...([
      deleteActivity.fulfilled,
      deleteAccommodation.fulfilled,
      deleteLocation.fulfilled,
    ].reduce(
      (deletePinReducers, action) => ({
        ...deletePinReducers,
        [action]: (
          state,
          {
            meta: {
              arg: { mapPin },
            },
          }
        ) => {
          if (mapPin) {
            Object.keys(state.mapPins).forEach((tripId) => {
              if (
                Object.prototype.hasOwnProperty.call(
                  state.mapPins[tripId] || {},
                  mapPin
                )
              ) {
                delete state.mapPins[tripId][mapPin];
                return null;
              }
              return null;
            });
          }
        },
      }),
      {}
    ) || {}),

    ...([updateLocation.fulfilled, updateAccommodation.fulfilled].reduce(
      (updatePinReducers, action) => ({
        ...updatePinReducers,
        [action]: (
          state,
          {
            meta: {
              arg: {
                variables: { oldMapPin },
              },
            },
          }
        ) => {
          if (oldMapPin) {
            Object.keys(state.mapPins || {}).forEach((tripId) => {
              if (
                Object.prototype.hasOwnProperty.call(
                  state.mapPins[tripId] || {},
                  oldMapPin
                )
              ) {
                delete state.mapPins[tripId][oldMapPin];
                return null;
              }
              return null;
            });
          }
        },
      }),
      {}
    ) || {}),
  },
});

export const MapActions = MapSlice.actions;
export const MapReducer = MapSlice.reducer;
