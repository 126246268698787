import { makeStyles } from '@mui/styles';
import Error from '../../atoms/Error';

const useStyles = makeStyles(() => ({
  reloadText: {
    color: '#ED702E',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}));

function GlobalErrorComponent({ error }) {
  const errorCopy =
    error === 'INVALID_ACCESS'
      ? `Looks like you don't have access to this trip. If that's not the case, w`
      : 'W';
  const classes = useStyles();
  return (
    <Error
      title="Oops, something went wrong 🤔"
      showSupportButton
      error={
        <>
          We’re so sorry! {errorCopy}e hope a{' '}
          <span
            className={classes.reloadText}
            onClick={() => window.location.reload()}>
            page reload
          </span>{' '}
          sorts it out, but if it doesn’t, please contact our support team by
          clicking the button below.
        </>
      }
    />
  );
}

export default GlobalErrorComponent;
