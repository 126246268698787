import posthog from 'posthog-js';
import EVENTS from './events';

const getRawEventList = (events = {}) => {
  const eventList = [];
  Object.keys(events).forEach((key) => {
    if (typeof events[key] === 'object') {
      eventList.push(...getRawEventList(events[key]));
    } else {
      eventList.push(events[key]);
    }
  });
  return eventList;
};

const rawEventList = getRawEventList(EVENTS);

const phTrackEvent = ({ event, meta, trackOnIntercom = false }) => {
  try {
    if (
      rawEventList?.includes(event) ||
      event === '$pageview' ||
      event === 'session_start'
    ) {
      posthog.capture(event, meta);
      if (trackOnIntercom) {
        window.Intercom('trackEvent', event, meta);
      }
    }
  } catch (err) {
    // handle error
  }
};

export default phTrackEvent;
