import { Typography } from '@mui/material';
import { CheckCircleRounded } from '@mui/icons-material';

function CancellationSuccess() {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Typography
        variant="caption"
        sx={{
          color: 'success.main',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: 2,
        }}>
        <CheckCircleRounded color="success.main" sx={{ marginRight: 1 }} />
        Your cancellation is successful.
      </Typography>
      <Typography variant="h2" fontSize="20px" color="text.secondary">
        Hope to see you when you plan your next destination!
      </Typography>
      <Typography variant="caption" mt="8px">
        Your support so far has helped us immensely. Thank you from the whole
        Pilot crew!
      </Typography>
    </div>
  );
}

export default CancellationSuccess;
