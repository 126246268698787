import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Avatar,
  Divider,
  Grid,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { InsertPhotoRounded } from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { getUser, updateUser } from '../../redux/slices/Auth';
import { deleteTrip } from '../../redux/slices/Trips';
import { LocationPinIcon } from '../atoms/Icon';
import EditModal from '../organisms/EditModal';
import WishlistContainer from '../organisms/WishlistContainer';
import FriendsTab from '../organisms/FriendsTab';
import TripsContainer from '../molecules/TripsContainer';
import AppLayout from '../templates/AppLayout';
import LoadingLayout from '../templates/LoadingLayout';
import { EditProfileCoverImageButton } from '../atoms/Button/index';
import { ImageSelectorModal } from '../molecules/Modal';
import { handleProfileCoverImageUpload } from '../../utils';
import { Events, trackEvents } from '../../intercom';
import classList from '../classList';
import { BookingsActions } from '../../redux/slices/Bookings';
import ImgWithFallback from '../atoms/ImgWithFallback';
import TravelStatsContainer from '../molecules/TravelStats/TravelStatsContainer';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '36px 16px',
    [theme.breakpoints.only('xs')]: {
      maxWidth: 328,
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: 680,
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 1032,
    },
  },
  avatar: {
    [theme.breakpoints.only('xs')]: {
      width: 91,
      height: 91,
      top: -45,
      position: 'absolute',
    },
    [theme.breakpoints.up('sm')]: {
      width: 160,
      height: 160,
      top: -36,
    },
    background: '#C4C4C4',
    border: '5px solid white',
    objectFit: 'cover',
  },
  greyText: {
    color: theme.palette.text.light,
    [theme.breakpoints.only('xs')]: {
      fontSize: 12,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 14,
    },
  },
  descriptionContainer: {
    [theme.breakpoints.only('xs')]: {
      maxWidth: 311,
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: 328,
    },
  },
  avatarContainer: {
    marginLeft: 24,
    [theme.breakpoints.only('xs')]: {
      position: 'relative',
      display: 'flex',
      flex: 1,
      marginLeft: 16,
    },
  },
  grid: {
    padding: 32,
  },
  card: {
    width: '100%',
  },
  profileCoverImage: {
    [theme.breakpoints.up('xs')]: {
      height: 160,
      borderRadius: 8,
    },
    [theme.breakpoints.up('sm')]: {
      height: 260,
      borderRadius: 12,
    },
    objectFit: 'cover',
    objectPosition: 'center',
    width: '100%',
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Grid container spacing={2} className={classes.grid}>
          {children}
        </Grid>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function ProfilePage() {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.Auth.firebaseUser.uid);
  const userObj = useSelector((state) => state.Auth.userData);
  const { user } = userObj;
  const userhandleId = user?.handle;
  const location = useLocation();

  useEffect(() => {
    if (userObj.status === 'IDLE') {
      dispatch(getUser({ id: userId }));
    }
    trackEvents(Events.ProfileVisited);
  }, []);

  if (userhandleId) {
    window.history.replaceState(null, ``, `/profile/${userhandleId}`);
  }

  const [value, setValue] = useState(0);
  const [url, setURL] = useState(null);

  useEffect(() => {
    setURL(user?.profilePicture || '');
  }, [user]);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (query.get('focus') === 'wishlist') setValue(1);
    else setValue(0);
  }, [location]);

  const [openCoverImageModal, setOpenCoverImageModal] = useState(false);

  function ProfileInfoContainer() {
    return (
      <Grid
        item
        container
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          marginLeft: 16,
          marginTop: 8,
        }}>
        <Grid item>
          <Typography variant="h3Sub" textTransform="capitalize">
            {user?.firstName} {user?.lastName}
          </Typography>
        </Grid>
        <Grid item style={{ marginTop: 4 }}>
          <Typography
            variant="caption"
            color="text.light"
            style={{ display: 'flex', alignItems: 'center' }}>
            {user?.location && (
              <>
                <LocationPinIcon
                  fill="#8A8A8A"
                  height="18px"
                  width="18px"
                  style={{ marginRight: 4, marginLeft: -4 }}
                />
                {user.location}
              </>
            )}
            <Typography variant="caption" ml="16px">
              {user?.handle && `@${user.handle}`}
            </Typography>
          </Typography>
        </Grid>
        <Grid
          item
          className={classes.descriptionContainer}
          style={{ marginTop: 4 }}>
          <Typography variant="h5" color="text.light">
            {user?.aboutMe}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <AppLayout>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
        <Helmet>
          <title>{`${user?.firstName} ${user?.lastName}'s Profile | Pilot`}</title>
        </Helmet>
        <Grid container direction="column" className={classes.root}>
          <Grid item style={{ width: '100%' }}>
            <div style={{ position: 'relative' }}>
              <ImgWithFallback
                className={classes.profileCoverImage}
                alt="profile cover"
                src={
                  user?.coverImage && user?.coverImage !== ''
                    ? user?.coverImage
                    : '/images/Profile.png'
                }
              />
              <EditProfileCoverImageButton
                style={{
                  position: 'absolute',
                  right: '12px',
                  bottom: '12px',
                }}
                onClick={() => setOpenCoverImageModal(true)}
                startIcon={<InsertPhotoRounded />}>
                Edit cover photo
              </EditProfileCoverImageButton>
            </div>
            <ImageSelectorModal
              handleUpload={(e) => handleProfileCoverImageUpload(e, userId)}
              changeCoverModalOpen={openCoverImageModal}
              setChangeCoverModalOpen={setOpenCoverImageModal}
              handleUpdateCoverImage={(image) => {
                dispatch(
                  updateUser({
                    variables: {
                      id: userId,
                      coverImage: image,
                    },
                  })
                );
              }}
            />
          </Grid>
          <Grid item container direction="row" style={{ width: '100%' }}>
            <Grid item className={classes.avatarContainer}>
              <Avatar className={classes.avatar} src={url} />
            </Grid>
            {isMobile ? null : <ProfileInfoContainer />}
            <Grid
              item
              style={{
                marginTop: 8,
                justifyContent: 'flex-end',
                flexShrink: 1,
              }}>
              <EditModal userData={user} />
            </Grid>
          </Grid>
          {isMobile ? <ProfileInfoContainer /> : null}
          <TravelStatsContainer />
          <div style={{ width: '100%' }}>
            <Tabs
              value={value}
              onChange={handleTabChange}
              indicatorColor="primary">
              <Tab
                label="Past Trips"
                className={classList.profileTripsTab}
                {...a11yProps(0)}
              />
              <Tab
                label="Wishlist"
                className={classList.profileWishlistTab}
                {...a11yProps(1)}
              />
              <Tab
                style={{ display: 'none' }}
                label="Friends"
                {...a11yProps(2)}
                onChange={handleTabChange}
              />
            </Tabs>
            <Divider />

            <TabPanel value={value} index={0}>
              <FeaturedTrips />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <WishlistContainer />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <FriendsTab />
            </TabPanel>
          </div>
        </Grid>
      </div>
    </AppLayout>
  );
}

function FeaturedTrips() {
  const dispatch = useDispatch();

  const tripsObj = useSelector((state) => state.Trips);

  const handleDeleteTrip = async (tripId) => {
    await dispatch(deleteTrip(tripId));
    dispatch(BookingsActions.deleteTrip({ tripId }));
  };

  let pastTrips = (tripsObj && Object.values(tripsObj.trips)) || [];
  pastTrips = pastTrips.filter(
    (trip) =>
      trip.endDate &&
      Date.parse(trip.endDate) < Date.parse(new Date().toString())
  );

  return tripsObj.status === 'LOADING' ? (
    <LoadingLayout />
  ) : tripsObj.error ? null : (
    <TripsContainer
      trips={pastTrips}
      deleteTripCallback={handleDeleteTrip}
      alignFirstCard
      defaultMessage="Oh no! You have no past trips 😮"
      tripType="past"
      isProfile
    />
  );
}

export default ProfilePage;
