import { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import {
  IconButton,
  Tab,
  Tabs,
  useMediaQuery,
  Menu,
  MenuItem,
  Divider,
  AppBar,
  Toolbar,
  Box,
  // Typography,
} from '@mui/material';
import { withStyles, makeStyles, useTheme } from '@mui/styles';

import { ChevronLeft, MoreVert } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';

import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';

import AvatarStack from '../../../molecules/AvatarStack';
import ShareTripModal from '../../../molecules/Modal/ShareTripModal';
import { ChatBubble, SupportIcon } from '../../../atoms/Icon';
import DeleteModal from '../../../molecules/Modal/DeleteModal';
import { deleteTrip, updateTripName } from '../../../../redux/slices/Trips';
import { InviteButton, RefetchButton } from '../../../atoms/Button/index';
import classList from '../../../classList';
import { TripTitleInput } from '../../../atoms/Input';
import actions from '../../../../redux/actions';
import { getDaysDiff } from '../../../../utils';
import { openIntercom } from '../../../../intercom';
import { BookingsActions } from '../../../../redux/slices/Bookings';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appbar: {
    backgroundColor: '#FFF',
  },
  toolbar: {
    minHeight: 64,
    [theme.breakpoints.down('md')]: {
      minHeight: 60,
    },
    paddingRight: '1.5rem',
    paddingLeft: '1.5rem',
    borderBottom: '1px solid #DEDDDD',
  },
  toolbarSecondary: {
    minHeight: 54,
  },
  divider: {
    width: 1,
    height: '1.5rem',
    borderColor: '#DEDDDD',
    alignSelf: 'center',
    marginLeft: '1.25rem',
    marginRight: '0.75rem',
  },
  tripMenu: {},
  tripMenuItem: {
    minWidth: 200,
    borderTop: '1px solid #F4F4F4',
    minHeight: 0,
    fontSize: 14,
  },
  invitedTripContainer: {
    borderTop: '1px solid #F4F4F4',
    fontSize: 14,
    display: 'flex',
    padding: '6px 16px',
    flexDirection: 'column',
  },
  tabContainer: {
    flexGrow: 1,
    display: 'flex',
  },
  iconButton: {
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  greyText: {
    color: '#8A8A8A',
    fontSize: 14,
    marginLeft: '0.25rem',
    alignSelf: 'center',
  },
  inviteButton: {
    minWidth: '2.75rem',
    minHeight: '2rem',
    padding: '6px 12px',
  },
  inviteMenuButton: {
    color: theme.palette.info.main,
    cursor: 'pointer',
    textDecoration: 'underline',
    textUnderlineOffset: '2px !important',
  },
  tripTitleDateWrapper: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      marginTop: '9px',
      marginBottom: '2px',
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      alignItems: 'stretch',
      marginRight: 5,
    },
  },
  backButtonWrapper: {
    alignSelf: 'stretch',
    display: 'flex',
    alignItems: 'start',
  },
  backIconButton: {
    marginRight: '0.75rem',
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },
  tabsFlexContainer: {
    height: '100%',
  },
}));

function InviteButtonWrapper({ tripData }) {
  // TODO: Make modals open state a global entity and redux state and remove wrappers like this
  const [openShareModal, setOpenShareModal] = useState(false);

  const handleClose = () => {
    setOpenShareModal(false);
  };

  const handleOpen = () => {
    setOpenShareModal(true);
  };

  const getTripEventMeta = () => {
    try {
      const today = new Date().setHours(0, 0, 0, 0);
      const startDate = tripData?.startDate && new Date(tripData.startDate);
      const endDate = tripData?.endDate && new Date(tripData.endDate);
      const duration = getDaysDiff(startDate, endDate);
      const tripStatus = endDate && endDate < today ? 'past' : 'upcoming';
      return {
        tripStatus,
        trip_length: duration || null,
        days_until_trip_start: getDaysDiff(new Date(), startDate),
        days_until_trip_end: getDaysDiff(new Date(), endDate),
        trip_dates: [
          (startDate && format(startDate, 'yyyy-MM-dd')) || null,
          (endDate && format(endDate, 'yyyy-MM-dd')) || null,
        ],
      };
    } catch (err) {
      return {};
    }
  };

  return (
    <>
      <InviteButton handleClick={handleOpen} />
      <ShareTripModal
        open={openShareModal}
        handleClose={handleClose}
        tripTitle={tripData.title}
        slug={tripData.id}
        tripUsers={[tripData?.owner, ...(tripData?.sharedUsers || [])]}
        invitedUsers={tripData?.invitedUsers}
        ownerId={tripData?.owner?.id}
        tripCoverImage={tripData?.coverImage}
        trackerMeta={getTripEventMeta()}
        context="trip"
      />
    </>
  );
}

// function InviteMenuButton({ tripData }) {
//   const classes = useStyles();
//   const [openShareModal, setOpenShareModal] = useState(false);

//   const handleClose = () => {
//     setOpenShareModal(false);
//   };

//   const handleOpen = () => {
//     setOpenShareModal(true);
//   };
//   return (
//     <>
//       <Typography
//         id="invite-friends-button-mobile"
//         variant="ctaSmall"
//         className={classes.inviteMenuButton}
//         onClick={handleOpen}>
//         + Invite friends
//       </Typography>
//       <ShareTripModal
//         open={openShareModal}
//         handleClose={handleClose}
//         tripTitle={tripData.title}
//         slug={tripData.id}
//         tripUsers={[tripData?.owner, ...(tripData?.sharedUsers || [])]}
//         invitedUsers={tripData?.invitedUsers}
//         ownerId={tripData?.owner?.id}
//         tripCoverImage={tripData?.coverImage}
//       />
//     </>
//   );
// }

function TripNavbar({
  tripData,
  people,
  setOpenMessenger,
  refetch,
  TripDateSelector,
  activePath,
}) {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [plannerScrolled, setPlannerScrolled] = useState(false);
  const [tab, setTab] = useState(null);
  const isSectionHighlight = useSelector(
    (state) => state.View.highlightedSection
  );
  const isHeadingHighlight = useSelector(
    (state) => state.View.highlightedHeading
  );

  useEffect(() => {
    const plannerElement = document.getElementById(activePath);

    let oldScroll = 0;

    const handleScroll = () => {
      if (
        !isSectionHighlight &&
        isSectionHighlight !== 0 &&
        !isHeadingHighlight
      ) {
        if (plannerElement.scrollTop > 70) {
          if (plannerElement.scrollTop > oldScroll) {
            setPlannerScrolled(true);
          } else {
            setPlannerScrolled(false);
          }
          oldScroll = plannerElement.scrollTop;
        } else {
          setPlannerScrolled(false);
        }
      }
    };

    if (location.pathname.includes('/planner')) {
      plannerElement?.addEventListener('scroll', handleScroll);

      if (!plannerElement) setPlannerScrolled(false);

      return () => {
        plannerElement?.removeEventListener('scroll', handleScroll);
      };
    }

    return () => {
      setPlannerScrolled(true);
    };
  }, [activePath, isSectionHighlight, isHeadingHighlight]);

  const [openMenu, setOpenMenu] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const { slug } = useParams();
  const trip = useSelector((state) => state.Trips.trips[slug]);
  const currUserId = useSelector((state) => state.Auth.firebaseUser.uid);
  const isOwner = trip.owner.id === currUserId;

  const ref = useRef(null);

  const handleBlur = (e) => {
    const newTripName = e.target.value;
    if (newTripName !== '' && newTripName !== trip?.title) {
      dispatch(
        updateTripName({
          variables: {
            id: slug,
            title: newTripName,
          },
        })
      );
    }
  };

  useEffect(() => {
    dispatch(
      actions.View.setExploreView(location.pathname.includes('/explore'))
    );
    if (location.pathname.includes('/explore')) {
      setPlannerScrolled(false);
      setTab(0);
    } else if (location.pathname.includes('/notes')) {
      setPlannerScrolled(true);
      setTab(2);
    } else if (location.pathname.includes('/files')) {
      setPlannerScrolled(true);
      setTab(3);
    } else {
      setPlannerScrolled(false);
      setTab(1);
    }
  }, [location]);

  useEffect(() => {
    if (trip?.status === 'SUCCESS') {
      // autofocus trip input if new trip
      if (location?.state?.newTrip) {
        if (ref) {
          ref.current?.focus();
        }
      }
    }
  }, [trip?.status]);

  const handleDeleteTrip = async () => {
    // Delete Trip
    await dispatch(deleteTrip(tripData?.id))
      .then(() => {
        navigate('/trips');
        return null;
      })
      .catch((error) => {
        return error;
      });
    dispatch(BookingsActions.deleteTrip({ tripId: tripData?.id }));
    setOpenMenu(false);
  };

  const handleSwitch = (item) => {
    switch (item) {
      case 0:
        navigate(`/trips/${tripData.id}/explore`);
        break;
      case 1:
        navigate(`/trips/${tripData.id}/planner`);
        break;
      case 2:
        navigate(`/trips/${tripData.id}/notes`);
        break;
      case 3:
        navigate(`/trips/${tripData.id}/files`);
        break;
      default:
        break;
    }
    setTab(item);
  };

  const CustomTab = withStyles({
    root: {
      textTransform: 'none',
      minWidth: '1.25rem',
      fontWeight: 500,
      height: '100%',
      marginBottom: 0,
      minHeight: 'auto',
    },
    '&:hover': {
      color: '#ED702E',
      opacity: 1,
    },
    '&$selected': {
      color: '#ED702E',
    },
    '&:focus': {
      color: '#ED702E',
    },
    selected: {},
  })((props) => <Tab disableRipple {...props} />);

  function TabNav() {
    return (
      <Tabs
        value={tab}
        onChange={(e, index) => handleSwitch(index)}
        style={{
          minHeight: '100%',
          display: 'flex',
        }}
        classes={{
          flexContainer: classes.tabsFlexContainer,
        }}
        TabIndicatorProps={{
          sx: {
            bottom: '-2px',
            height: '4px',
            borderRadius: 30,
          },
        }}
        className={classList.plannerNavButtons}
        textColor="primary"
        indicatorColor="primary">
        <CustomTab
          label="Explore"
          className={classList.tripPlanNavButton}
          disableRipple
        />
        <CustomTab
          label="Plan"
          className={classList.tripPlanNavButton}
          disableRipple
        />

        <CustomTab
          id="notes-tab"
          label="Notes"
          className={classList.notesNavButton}
          disableRipple
        />
        <CustomTab
          id="files-tab"
          label="Files"
          className={classList.filesNavButton}
          disableRipple
        />
      </Tabs>
    );
  }

  const primaryToolbarHeight = isMobile ? 60 : plannerScrolled ? 64 : 90;

  return (
    <>
      <AppBar
        position="fixed"
        className={`${classes.appbar} ${classList.plannerNavBar}`}
        elevation={0}
        sx={{
          ...(isMobile && plannerScrolled && { visibility: 'hidden' }),
        }}
        id="trips-navbar">
        <Toolbar
          className={classes.toolbar}
          style={{
            minHeight: primaryToolbarHeight,
            transition: 'min-height 0.2s linear',
            padding:
              isMobile && '0% calc(9.6% - 1.5rem) 0% calc(9.6% - 1.5rem)',
            // display: 'flex',
            flexDirection: 'column',
          }}>
          <Toolbar
            style={{
              minHeight: primaryToolbarHeight,
              // display: 'flex',
              width: '100%',
              padding: '0',
            }}>
            <Box display="flex" flexGrow="1" alignSelf="stretch">
              <Box className={classes.backButtonWrapper}>
                <IconButton
                  onClick={() => navigate(`/trips`)}
                  disableRipple
                  className={`${classes.iconButton} ${classes.backIconButton}`}
                  sx={{
                    alignSelf: plannerScrolled ? 'center' : 'start',
                    marginTop: plannerScrolled ? 0 : '8px',
                  }}>
                  <ChevronLeft />
                </IconButton>
              </Box>
              <Box
                sx={{
                  ...(isMobile && {
                    display: 'flex',
                    alignItems: 'stretch',
                    margin: '8px 0',
                    width: '100%',
                  }),
                }}>
                <div
                  style={{
                    maxHeight: plannerScrolled ? 0 : 46,
                    // visibility: plannerScrolled ? 'hidden' : 'visible',
                    transition: 'max-height 0.2s linear',
                    display: 'flex',
                    overflow: 'hidden',
                  }}>
                  {isMobile ? (
                    <TripDateSelector />
                  ) : (
                    <Box
                      className={
                        plannerScrolled ? '' : classes.tripTitleDateWrapper
                      }
                      id="tripTitleDateWrapper">
                      <TripTitleInput
                        titleInputRef={ref}
                        handleTripNameChange={handleBlur}
                        tripName={trip?.title || ''}
                      />
                      {!isMobile && <TripDateSelector />}
                    </Box>
                  )}
                </div>
                <div
                  className={classes.tabContainer}
                  style={{
                    height: plannerScrolled ? 63 : 40,
                    transition: 'height 0.2s linear',
                    marginTop: !isMobile && !plannerScrolled ? 3 : 0,
                  }}>
                  {isMobile ? null : (
                    <>
                      <TabNav />
                      <RefetchButton handleClick={refetch} />
                    </>
                  )}
                </div>
              </Box>
            </Box>

            {isMobile ? null : (
              <div style={{ marginRight: '1.25rem' }}>
                <AvatarStack
                  people={people}
                  renderSmall={false}
                  limit={4}
                  CustomLimitComponent={({ count }) => (
                    <span className={classes.greyText}>+ {count}</span>
                  )}
                />
              </div>
            )}

            <InviteButtonWrapper tripData={tripData} />

            {!isMobile && (
              <Divider
                orientation="vertical"
                flexItem
                className={classes.divider}
              />
            )}

            <IconButton
              disableRipple
              onClick={openIntercom}
              className={`${classes.iconButton} ${classList.tripSupportButton}`}>
              <SupportIcon />
            </IconButton>

            {!isMobile && (
              <IconButton
                disableRipple
                onClick={() => setOpenMessenger(true)}
                className={`${classes.iconButton} ${classList.tripChatButton}`}>
                <ChatBubble />
              </IconButton>
            )}

            <IconButton
              disableRipple
              onClick={(e) => setOpenMenu(e.currentTarget)}
              className={`${classes.iconButton} ${classList.tripOptionsMenuButton}`}>
              <MoreVert
                aria-controls="trips-options-menu"
                aria-haspopup="true"
              />
            </IconButton>
            <Menu
              id="trips-options-menu"
              anchorEl={openMenu}
              open={Boolean(openMenu)}
              onClose={() => setOpenMenu(null)}
              classes={{
                paper: classes.tripMenu,
              }}>
              {/* {isMobile ? (
                <div className={classes.invitedTripContainer}>
                  Invited Friends
                  <div style={{ marginTop: 8, marginBottom: 8 }}>
                    {people?.length > 1 ? (
                      <AvatarStack
                        people={people}
                        renderSmall={false}
                        limit={4}
                        LTR
                        CustomLimitComponent={({ count }) => (
                          <span className={classes.greyText}>+ {count}</span>
                        )}
                      />
                    ) : (
                      <InviteMenuButton tripData={tripData} />
                    )}
                  </div>
                </div>
              ) : null} */}

              {isMobile && (
                <MenuItem
                  className={classes.tripMenuItem}
                  onClick={() => setOpenMessenger(true)}
                  disableRipple>
                  Chat
                </MenuItem>
              )}
              <MenuItem
                className={`${classes.tripMenuItem} ${
                  isOwner
                    ? classList.tripNavDeleteTrip
                    : classList.tripNavLeaveTrip
                }`}
                onClick={() => {
                  setDeleteModalOpen(true);
                  setOpenMenu(null);
                }}
                disableRipple>
                {isOwner ? 'Delete' : 'Leave'} Trip
              </MenuItem>
              <Divider
                style={{
                  borderColor: '#F4F4F4',
                  height: 1,
                  margin: 0,
                }}
              />
            </Menu>
            <DeleteModal
              executeFunc={handleDeleteTrip}
              open={deleteModalOpen}
              onClose={() => setDeleteModalOpen(false)}
              emoji="😮"
              alertText="Slow down!"
              descriptionText={`You’re about to ${
                isOwner ? 'delete' : 'leave'
              } your trip. Are you sure you’d like to do that?`}
              confirmText={`Yes, ${isOwner ? 'delete' : 'leave'} it!`}
              cancelText="No, keep it."
            />
          </Toolbar>
          {isMobile && (
            <Box
              sx={{ width: '100%', paddingRight: 'calc(9.6% - 1.5rem)' }}
              display="flex"
              alignItems="center"
              // style={{ transition: 'min-height 0.2s linear', }}
            >
              <TripTitleInput
                titleInputRef={ref}
                handleTripNameChange={handleBlur}
                tripName={trip?.title || ''}
              />
              <EditIcon sx={{ color: '#8A8A8A', height: '20px' }} />
            </Box>
          )}
        </Toolbar>
      </AppBar>
      {/* This toolbar helps fixing scrolling errors. */}
      {!isMobile && (
        <Toolbar
          className={classes.toolbar}
          style={{
            minHeight: primaryToolbarHeight,
            overflow: 'hidden',
            display: isMobile && plannerScrolled ? 'none' : 'flex',
            transition: 'min-height 0.2s linear',
          }}
        />
      )}
      {/* Mobile/Tablet specific toolbar. */}
      {isMobile ? (
        <>
          <AppBar
            position="fixed"
            className={`${classes.appbar} ${classList.plannerNavBarTablet}`}
            elevation={0}
            id="trips-navbar-mobile"
            style={{
              // This is to fix the toolbar's placement below the top bar.
              marginTop: plannerScrolled ? 0 : 90,
              transition: 'margin-top 0.2s linear',
              zIndex: 1000,
            }}>
            <Toolbar
              className={`${classes.toolbar} ${classes.toolbarSecondary}`}
              style={{
                padding:
                  isMobile && '0% calc(9.6% - 1.5rem) 0% calc(9.6% - 0.5rem)',
              }}>
              <div className={classes.tabContainer} style={{ height: 54 }}>
                <TabNav />
              </div>
              <RefetchButton handleClick={refetch} />
            </Toolbar>
          </AppBar>
          {/* This toolbar helps fixing scrolling errors. */}
          <Toolbar
            className={`${classes.toolbar} ${classes.toolbarSecondary}`}
            style={{ minHeight: 20 }}
          />
        </>
      ) : null}
    </>
  );
}

export default TripNavbar;
