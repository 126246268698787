import React from 'react';

import {
  StarRounded,
  StarBorderRounded,
  StarHalfRounded,
} from '@mui/icons-material';

import { makeStyles } from '@mui/styles';

const useStarRatingStyles = makeStyles((theme) => ({
  star: {
    color: theme.palette.primary.main,
    height: 18,
    width: 18,
  },
  container: {
    display: 'inline-flex',
    flexDirection: 'row',
  },
  greyStar: {
    color: '#DEDDDD',
  },
}));

function StarRatings({ rating = 0, maxRating = 0 }) {
  const classes = useStarRatingStyles();
  const emptyStars = Math.floor(maxRating - rating);
  const filledStars = Math.floor(rating);
  const halfStars = rating !== filledStars ? 1 : 0;

  return (
    <div className={classes.container}>
      {[...Array(filledStars)].map((e, i) => (
        <StarRounded className={classes.star} key={`filled-star-${i}`} />
      ))}
      {halfStars === 1 ? (
        <StarHalfRounded className={classes.star} key="half-star-1" />
      ) : null}
      {[...Array(emptyStars)].map((e, i) => (
        <StarBorderRounded
          className={`${classes.star} ${rating === 0 ? classes.greyStar : ''}`}
          key={`empty-star-${i}`}
        />
      ))}
    </div>
  );
}

export default StarRatings;
