import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Typography,
  Button,
  Grid,
  Box,
  Stack,
  Switch,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/styles';

import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
// import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import TripCard from '../../molecules/CreateATripFlow/TripCard';
import { updateUser } from '../../../redux/slices/Auth';
import { EVENTS, phTrackEvent } from '../../../analytics';

const LabSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

function Step5Page({
  setStep,
  tripId,
  tripName,
  tripImageURL,
  dateRange,
  tripUsers,
}) {
  const user = useSelector((state) => state.Auth.userData);
  const userId = useSelector((state) => state.Auth.firebaseUser.uid);
  const [labStatus, setLabStatus] = useState(!user.user?.labDisabled);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(
      updateUser({
        variables: {
          id: userId,
          showPilotLabsPrompt: false,
        },
      })
    );
  }, []);

  const handleLabStateChange = (e) => {
    setLabStatus(e.target.checked);
    if (e.target.checked) phTrackEvent(EVENTS.TRIP_CREATE.LABS_TOGGLE_ON_CLICK);
    dispatch(
      updateUser({
        variables: {
          id: userId,
          labDisabled: !e.target.checked,
        },
      })
    );
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        height: { xs: 'auto', sm: '80vh' },
        display: 'flex',
        alignItems: { xs: 'start', sm: 'center' },
      }}>
      <Grid
        container
        sx={{
          paddingX: { xs: '20px', sm: '100px' },
          height: { xs: 'auto', sm: '450px' },
        }}>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          <Stack maxWidth="400px" spacing={3} mt="30px">
            <Box
              onClick={() => {
                setStep((prev) => prev - 1);
              }}
              sx={{
                width: 'fit-content',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                color: '#8A8A8A',
              }}>
              <ChevronLeftRoundedIcon sx={{ marginTop: '-2px' }} />
              Back
            </Box>

            <Typography sx={{ fontSize: '37px', fontWeight: 500 }}>
              Almost there!
            </Typography>

            {isMobile && (
              <Box mb={8}>
                <TripCard
                  tripImage={
                    tripImageURL || '/images/blank-trip-placeholder.png'
                  }
                  tripName={tripName}
                  dateRange={dateRange}
                />
              </Box>
            )}

            <Stack
              sx={{
                flexDirection: { xs: 'column-reverse', sm: 'column' },
                gap: { xs: '10px', sm: 0 },
              }}>
              <Typography sx={{ fontSize: '16px', color: '#4E4E4E' }}>
                Want access to new features? Enable Pilot Labs for access to
                early stage experimental features like our new trip generator,
                Quick Start.
              </Typography>

              <Stack
                direction="row"
                alignItems="center"
                mt="15px"
                spacing="30px"
                sx={{
                  justifyContent: { xs: 'space-between', sm: 'flex-start' },
                }}>
                <Typography sx={{ fontWeight: '500' }}>
                  Enable Pilot Labs
                </Typography>
                <LabSwitch
                  checked={labStatus}
                  onChange={handleLabStateChange}
                />
              </Stack>
            </Stack>

            {isMobile && (
              <Button
                fullWidth
                onClick={() => {
                  if (!labStatus) {
                    navigate(`/trips/${tripId}/planner?o=create-a-trip`, {
                      state: { newTrip: true },
                    });
                  } else {
                    setStep((prev) => prev + 1);
                  }
                }}>
                Next
              </Button>
            )}
          </Stack>
        </Grid>

        {!isMobile && (
          <Grid
            item
            xs={6}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            <Box
              sx={{
                maxWidth: '400px',
              }}>
              <Box mb={8}>
                <TripCard
                  tripImage={tripImageURL}
                  tripName={tripName}
                  dateRange={dateRange}
                  tripUsers={tripUsers}
                />
              </Box>

              <Button
                fullWidth
                onClick={() => {
                  if (!labStatus) {
                    navigate(`/trips/${tripId}/planner`, {
                      state: { newTrip: true },
                    });
                  } else {
                    setStep((prev) => prev + 1);
                  }
                }}>
                Next
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default Step5Page;
