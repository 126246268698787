import React from 'react';
import {
  AppBar,
  Box,
  useMediaQuery,
  Typography,
  IconButton,
} from '@mui/material';
import { useTheme } from '@mui/styles';
import { CloseRounded } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import Logo from '../../molecules/Logo';

function Header({ step, setStep, skipAll, cancel }) {
  const currUser = useSelector((state) => state.Auth.userData.user);
  const StepBar = () => {
    const steps = [];
    for (let i = 1; i <= 6; i += 1) {
      steps.push(
        <div
          style={{
            height: '7px',
            width: '7px',
            borderRadius: '50%',
            marginRight: '5px',
            backgroundColor: i <= step ? '#ED702E' : '#D9D9D9',
          }}
        />
      );
    }

    return steps;
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (isMobile)
    return (
      <Box
        sx={{
          width: `${(step / 6) * 100}%`,
          backgroundColor: '#ED702E',
          height: '3px',
          position: 'fixed',
          top: '0',
        }}
      />
    );

  return (
    <AppBar sx={{ backgroundColor: 'white' }}>
      <Box
        sx={{
          paddingY: 2,
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
        }}>
        <Box sx={{ marginLeft: '10%' }}>
          <Logo fullLogo imageWidth="100px" />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '7px',
          }}>
          {StepBar()}
        </Box>
        {currUser.welcomeScreen && step ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginRight: '5%',
            }}>
            <Typography
              style={{
                cursor: 'pointer',
                marginRight: 10,
              }}
              color="GrayText"
              onClick={() => {
                if (step === 4 && currUser?.labDisabled) return setStep(6);
                if (step > 1 && step < 5) setStep((prevStep) => prevStep + 1);
                else skipAll();
              }}>
              {step > 1 ? 'skip' : 'Skip all'}
            </Typography>
            <Box style={{}}>
              <IconButton
                id="close-create-trip-flow"
                sx={{ padding: '4px', color: '#1D1D1D' }}
                size="small"
                onClick={cancel}>
                <CloseRounded />
              </IconButton>
            </Box>
          </Box>
        ) : (
          <Box sx={{ marginRight: '15%' }} />
        )}
      </Box>
    </AppBar>
  );
}

export default Header;
