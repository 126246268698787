import { Box, Typography } from '@mui/material';

function Logo({ title, fullLogo, imageWidth }) {
  if (fullLogo === true) {
    return (
      <Box justifyContent="center" alignItems="center" display="flex">
        <img
          src="/images/FullLogo.png"
          width={imageWidth}
          alt="Pilot Full Logo"
        />
      </Box>
    );
  }
  return (
    <Box justifyContent="center" alignItems="center" display="flex">
      <Box mr="7px">
        <img src="/logo192.png" width={imageWidth} alt="Pilot Logo" />
      </Box>
      <Typography variant="h3">{title}</Typography>
    </Box>
  );
}

export default Logo;
