import {
  BathtubRounded,
  PoolRounded,
  AirportShuttleRounded,
  SportsTennisRounded,
  GolfCourseRounded,
  BeachAccessRounded,
} from '@mui/icons-material';
import FEATURES from '../../../../assets/staysFeatures.json';

const ONE_DAY_MS = 24 * 60 * 60 * 1000;

export const getNumberOfNights = (fromDate, toDate) => {
  try {
    const timeDiff = toDate.getTime() - fromDate.getTime();
    return Math.ceil(timeDiff / ONE_DAY_MS);
  } catch (err) {
    // error
  }
  return 1;
};

/*
    Function to get rating copies depending on rating.
*/
export const getRatingProps = (rating) => {
  if (rating > 9) return ['Excellent', '#43A047'];
  if (rating > 7) return ['Good', '#43A047'];
  if (rating > 5) return ['Average', 'yellow'];
  return ['Bad', '#E5493D'];
};

export const computeAmenities = (features) => {
  const topFeatures = [
    { ids: [54], text: 'Hot tub', Icon: BathtubRounded },
    { ids: [371], text: 'Pool', Icon: PoolRounded },
    {
      ids: [894],
      text: 'Shuttle service',
      Icon: AirportShuttleRounded,
    },
    {
      ids: [84],
      text: 'Tennis',
      Icon: SportsTennisRounded,
    },
    {
      ids: [50],
      text: 'Golf course',
      Icon: GolfCourseRounded,
    },
    {
      ids: [395, 17],
      text: 'Beachfront',
      Icon: BeachAccessRounded,
    },
  ]?.filter(
    (f) =>
      f.ids?.map((fid) => features?.includes(fid))?.filter(Boolean)?.length > 0
  );
  const perksFeatures = [
    388, 778, 361, 723, 489, 541, 335, 537, 11, 72, 36, 57, 58, 73,
  ]
    ?.filter((f) => features?.includes(f))
    ?.map((f) => ({
      id: f,
      text: FEATURES[f],
    }));

  return {
    topFeatures,
    perksFeatures,
  };
};

/*
  Function to map different inclusions available with a hotel deal, and map them to our custom copies
  returns a list of inclusion copies to display
*/
export const computeInclusions = ({
  inclusions,
  features,
  canPayLater,
  hasFreeCancellation,
}) => {
  const inclusionCopies = {
    hasFreeCancellation: 'Free cancellation',
    canPayLater: 'Pay Later',
    0: 'Breakfast included',
    1: 'Lunch included',
    2: 'Dinner included',
    3: 'Meals included',
    4: 'All inclusive',
    freeInternet: 'Free Internet',
  };

  return [
    hasFreeCancellation && inclusionCopies.hasFreeCancellation,
    (features?.includes(361) || features?.includes(388)) &&
      inclusionCopies.freeInternet,
    ...(inclusions?.map((inclusion) => inclusionCopies[inclusion]) || []),
    canPayLater && inclusionCopies.canPayLater,
  ]?.filter(Boolean);
};
