import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteOutlineRounded } from '@mui/icons-material';
import { Snackbar, SnackbarContent } from '@mui/material';
import { makeStyles } from '@mui/styles';
import InlineBlade from '../molecules/InlineBlade';
import AutomatedFlightBlade from './AutomatedFlightBlade';
import { updateFlight } from '../../redux/slices/Transportation';

const useStyles = makeStyles(() => ({
  bladeContainer: {
    marginBottom: 4,
    display: 'flex',
    flexDirection: 'row',
  },
  deleteIconContainer: {
    display: 'flex',
    marginRight: 8,
    marginTop: 16,
    minWidth: 20,
  },
  deleteIcon: {
    cursor: 'pointer',
    fontSize: 20,
    color: 'rgba(138, 138, 138, 1)',
    '&:hover': {
      color: '#474747',
    },
  },
  deleteIconHide: {
    display: 'none',
  },
}));

function TransportationBlade({
  open,
  setOpen,
  newFlight,
  defaultFlightProps = {},
  id,
  transportId,
  index,
  type = 'flight',
  loading,
  canShowDeleteIcon = false,
  handleUpdateTransportationBlade = () => {},
  handleDeleteConnectingFlight = () => {},
  handleClickOutside = () => {},
  newFlightIds = [],
  setNewFlightIds = () => {},
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [openAutomatedFlightBlade, setOpenAutomatedFlightBlade] = useState(
    type === 'flight' && (newFlight || defaultFlightProps.automated)
  );
  const [bladeHovered, setBladeHovered] = useState(false);

  const [searchSuccess, setSearchSuccess] = useState(false);

  const reduxFlightProps = useSelector((state) =>
    state.Transportation.transports[transportId]?.details.filter(
      (flight) => flight.id === id
    )
  )?.[0];

  const [flightProps, setFlightProps] = useState({
    notes: '',
    link: '',
    cost: null,
    currency: null,
    toAirport: '',
    fromAirport: '',
    toDate: null,
    fromDate: null,
    files: null,
    ...defaultFlightProps,
  });
  const checkNewFlightId = flightProps?.id || (newFlight && 'new-flight');
  const handleUpdateFlight = async (data, additionalFields) => {
    if (
      newFlightIds.includes(checkNewFlightId) &&
      !Object.prototype.hasOwnProperty.call(data, 'automated')
    ) {
      setNewFlightIds(
        newFlightIds.filter((flightId) => flightId !== checkNewFlightId)
      );
    }

    setFlightProps({
      ...flightProps,
      ...data,
    });
    handleUpdateTransportationBlade({ ...flightProps, ...data }, index);
    if (newFlight) {
      return;
    }
    dispatch(
      updateFlight({
        variables: {
          id,
          ...data,
        },
        extra: { transportId },
        ...additionalFields,
      })
    );
  };

  const namePlaceholder = () => {
    let res;
    switch (type) {
      case 'train':
      case 'bus':
        res = 'ticket number, company, link';
        break;
      case 'other':
        res = 'title, link, transportation type';
        break;
      default:
        res = 'flight number, airline, link';
    }
    return res;
  };

  return (
    <div
      className={classes.bladeContainer}
      onMouseEnter={() => setBladeHovered(true)}
      onMouseLeave={() => setBladeHovered(false)}>
      <div className={classes.deleteIconContainer} onClick={handleClickOutside}>
        <DeleteOutlineRounded
          onClick={(e) => {
            e.stopPropagation();
            handleDeleteConnectingFlight();
          }}
          className={`${classes.deleteIcon} ${
            bladeHovered && canShowDeleteIcon ? '' : classes.deleteIconHide
          }`}
        />
      </div>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={searchSuccess}
        autoHideDuration={5000}
        onClose={() => setSearchSuccess(false)}
        key="auto-flight-search-success-popup">
        <SnackbarContent
          sx={{
            backgroundColor: 'success.main',
            justifyContent: 'center',
          }}
          message="Success! We have found your flight."
        />
      </Snackbar>

      {openAutomatedFlightBlade ? (
        <AutomatedFlightBlade
          open={openAutomatedFlightBlade}
          setOpen={setOpenAutomatedFlightBlade}
          setFlightProps={handleUpdateFlight}
          setOpenFlightBlade={setOpen}
          handleSuccess={() => setSearchSuccess(true)}
          showHeader={index === 0}
        />
      ) : (
        <InlineBlade
          key={id}
          id={id}
          isFlight
          flightProps={{
            ...flightProps,
            handleUpdateFlight,
            showHeader: index === 0,
            setOpenAutomatedFlightBlade,
            isNewFlight: newFlightIds.includes(checkNewFlightId),
          }}
          isLoading={loading}
          name={flightProps?.flightNumber}
          notes={flightProps?.notes}
          placeholder={namePlaceholder()}
          costVal={{
            amount: flightProps?.cost,
            currency: flightProps?.currency,
            per: flightProps?.costPer,
          }}
          linkVal={flightProps?.link}
          hasLink
          hasCost
          open={open}
          transportationType={type}
          setOpen={() => {}}
          updateLinkCallback={(link) =>
            handleUpdateFlight({
              link,
            })
          }
          updateNotesCallback={(notes) =>
            handleUpdateFlight({
              notes,
            })
          }
          updateCostCallback={({ amount, currency, per }) =>
            handleUpdateFlight({
              currency,
              cost: parseFloat(amount),
              costPer: per,
            })
          }
          updateNameCallback={(title) =>
            handleUpdateFlight({
              flightNumber: title,
            })
          }
          disableTransition
          fileAttachmentModalProps={{
            attachedToText: flightProps?.flightNumber,
            attachedToID: id,
            attachedToType: 'Transportation',
            transportType: type,
            attachedFiles: reduxFlightProps?.files || flightProps?.files || [],
            attachFunc: (newFiles, tripId) => {
              handleUpdateFlight(
                {
                  files: newFiles,
                },
                {
                  attachedToType: 'Transportation',
                  tripId,
                }
              );
            },
          }}
        />
      )}
    </div>
  );
}

export default TransportationBlade;
