import { createContext, useContext } from 'react';

export const DateContext = createContext();

export function DateProvider({ children, location }) {
  return (
    <DateContext.Provider value={location}>{children}</DateContext.Provider>
  );
}

export const useDateUtils = () => {
  return useContext(DateContext);
};
