import { useContext, useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useSelector } from 'react-redux';
import { firebaseAuth } from '../../../provider/AuthProvider';
import { LinkHyper } from '../../atoms/Link';
import EmailInputForm from '../../organisms/EmailInputForm';
import { Gmail, Yahoo, Outlook } from '../../atoms/Icon';
import AuthLayout from '../../templates/AuthLayout';
import classList from '../../classList';

const useStyles = makeStyles(() => ({
  link: {
    color: '#000',
    textDecoration: 'none',
  },
  linkBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '30px',
    padding: '15px',
    margin: '30px 0',
    borderRadius: '4px',
    boxShadow:
      '0px 0px 4px 0px rgba(0, 0, 0, 0.25), 0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
  },
  openMailButton: {
    color: '#000000',
    fontSize: '14px',
  },
}));

function VerifyEmail() {
  const classes = useStyles();
  const [firstName] = useState(localStorage.getItem('firstName'));
  const { sendVerificationEmail, handleSignout } = useContext(firebaseAuth);
  const firebaseUser = useSelector((state) => state.Auth.firebaseUser);

  if (!firebaseUser.email) {
    return <EmailInputForm />;
  }

  const { email } = firebaseUser;
  const subject = 'Verify your email for Pilot';

  const sniperLinkDetails = {
    GMAIL: {
      icon: <Gmail />,
      label: 'Open Gmail',
      link: `https://mail.google.com/mail/u/${email}/#search/subject%3A%22${encodeURIComponent(
        subject
      )}%22`,
    },
    YAHOO: {
      icon: <Yahoo />,
      label: 'Open Yahoo',
      link: 'https://mail.yahoo.com/d/search/keyword=subject%253A%2522Verify%2520your%2520email%2520for%2520Pilot%2522',
    },
    OUTLOOK: {
      icon: <Outlook />,
      label: 'Open Outlook',
      link: `https://outlook.live.com/mail/?login_hint=${email}`,
    },
  };

  return (
    <AuthLayout>
      <Box>
        <Box flexDirection="column" display="flex" mb="1.5rem" pt="2.3rem">
          <Typography variant="h2">Verify your Email Address</Typography>
        </Box>
        <img src="/images/VerifyEmail.png" alt="Email" />

        <Box pt="1.6rem">
          <Typography variant="h5">
            Hi {firstName}! Please open the verification link we have sent to
            your email to start trip planning!
          </Typography>
          {(email.includes('@gmail') ||
            email.includes('@google') ||
            email.includes('@googlemail')) && (
            <a
              target="_blank"
              rel="noreferrer"
              href={sniperLinkDetails.GMAIL.link}
              className={classes.link}>
              <Box className={classes.linkBox}>
                {sniperLinkDetails.GMAIL.icon}
                <span className={classes.openMailButton}>
                  {sniperLinkDetails.GMAIL.label}
                </span>
              </Box>
            </a>
          )}
          {email.includes('@yahoo') && (
            <a
              target="_blank"
              rel="noreferrer"
              href={sniperLinkDetails.YAHOO.link}
              className={classes.link}>
              <Box className={classes.linkBox}>
                {sniperLinkDetails.YAHOO.icon}
                <span className={classes.openMailButton}>
                  {sniperLinkDetails.YAHOO.label}
                </span>
              </Box>
            </a>
          )}
          {(email.includes('@hotmail') || email.includes('@outlook')) && (
            <a
              target="_blank"
              rel="noreferrer"
              href={sniperLinkDetails.OUTLOOK.link}
              className={classes.link}>
              <Box className={classes.linkBox}>
                {sniperLinkDetails.OUTLOOK.icon}
                <span className={classes.openMailButton}>
                  {sniperLinkDetails.OUTLOOK.label}
                </span>
              </Box>
            </a>
          )}
        </Box>

        <Box flexDirection="row" pt="1.6rem">
          <Box>
            <Typography variant="h5">
              Oops! Have not received our email?
            </Typography>
          </Box>
          <Box>
            <Typography variant="h5">
              Request a new one by
              {'  '}
              <LinkHyper
                onClick={() => sendVerificationEmail(email)}
                underline="hover">
                clicking here
              </LinkHyper>
            </Typography>
          </Box>
        </Box>

        <Box pt="1.6rem">
          <Button
            className={classList.verifyEmailBackBtn}
            onClick={handleSignout}
            startIcon={<ArrowBackIosIcon sx={{ height: 12, width: 12 }} />}>
            Back
          </Button>
        </Box>
      </Box>
    </AuthLayout>
  );
}

export default VerifyEmail;
