import React from 'react';

import { TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  textField: {
    backgroundColor: 'transparent',
  },
  grayBackground: {
    backgroundColor: '#F4F4F4',
  },
  boxText: {
    fontWeight: 'bold',
    fontSize: 28,
  },
  label: {
    paddingTop: '1em',
  },
}));

function LabelInputField(props) {
  const classes = useStyles();

  return (
    <>
      {props.label ? (
        <Typography variant="h6" className={classes.label}>
          {props.label}
        </Typography>
      ) : null}
      <TextField
        {...props?.params}
        className={
          props.isHovered || props.isActive
            ? classes.grayBackground
            : classes.whiteBackground
        }
        required={props.required}
        variant={props.tripLocation ? 'standard' : 'outlined'}
        margin="none"
        size="small"
        fullWidth
        id={props.airport}
        inputProps={{
          ...props?.params?.inputProps,
        }}
        InputProps={{
          disableUnderline: true,
          startAdornment: props.startAdornment,
          ...props?.params?.InputProps,
          classes: {
            input: props.tripLocation && classes.boxText,
          },
        }}
        value={props?.params?.inputProps.value || props.value}
        placeholder={props.placeholder}
        onChange={(e) => props.onChange(e)}
        multiline={!props.tripLocation}
        minRows={props.rows ? props.rows : 1}
        inputRef={props.inputRef}
      />
    </>
  );
}

export default LabelInputField;
