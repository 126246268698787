import { useState } from 'react';
import { ArrowDropDownRounded } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';

import OptionsMenu from '../../Menu/OptionsMenu';
import actions from '../../../../redux/actions';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: 12,
    border: '1px solid #D9D9D9',
    borderRadius: 4,
    display: 'flex',
  },
  pdfTag: {
    textTransform: 'uppercase',
    color: palette.primary.light,
    fontSize: 12,
    backgroundColor: palette.primary.extraLight,
    padding: 4,
    borderRadius: 4,
    margin: 'auto',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'hidden',
    marginLeft: 12,
    marginRight: 12,
  },
}));

function ImportFileCard({
  id,
  name: title,
  description = 'imported file from attchment',
  handleFileDelete = () => {},
  handleAttachClick = () => {},
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [menuOpen, setMenuOpen] = useState(false);

  const handleFileOpen = () =>
    dispatch(actions.Files.setMultipleFilesPreview([id]));
  return (
    <div className={classes.root} key={id}>
      <div onClick={handleFileOpen} className={classes.pdfTag}>
        PDF
      </div>
      <div onClick={handleFileOpen} className={classes.contentContainer}>
        <Typography variant="h3" noWrap textTransform="ellipsis">
          {title}
        </Typography>
        <Typography
          variant="h5"
          noWrap
          color="text.light"
          textTransform="ellipsis">
          {description}
        </Typography>
      </div>
      <div>
        <Button
          endIcon={<ArrowDropDownRounded />}
          onClick={(e) => setMenuOpen(e.currentTarget)}
          style={{
            padding: '8px',
          }}>
          Add
        </Button>
        <OptionsMenu
          id={`imported-file-card-menu-${id}`}
          state={menuOpen}
          setState={setMenuOpen}
          callbacks={[
            handleAttachClick.bind(null, id),
            handleFileDelete.bind(null, id),
          ]}
          options={['Attach file', 'Delete']}
          menuItemProps={{
            style: {
              fontSize: 12,
              padding: '8px 12px',
            },
          }}
          menuProps={{
            MenuListProps: {
              style: {
                padding: '0px',
                border: '1px solid #DEDDDD',
                width: 116,
              },
            },
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
          }}
        />
      </div>
    </div>
  );
}

export default ImportFileCard;
