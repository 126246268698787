import React from 'react';
import { Skeleton, Card } from '@mui/material';

function HotelDetailsCardSkeleton() {
  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        boxShadow: 'none',
        mb: '8px',
      }}>
      <Skeleton variant="rectangular" sx={{ height: '200px', width: '100%' }} />
      <Skeleton variant="text" width="80%" height={24} />
      <Skeleton
        variant="text"
        width="20%"
        height={15}
        style={{ marginTop: '-4px' }}
      />
      <Skeleton
        variant="text"
        width="100%"
        height={32}
        sx={{
          mt: '8px',
        }}
      />
      <Skeleton
        variant="text"
        width="10%"
        height={15}
        style={{ marginTop: '-4px' }}
      />
      <Skeleton
        variant="text"
        width="50%"
        height={28}
        sx={{
          mt: '4px',
        }}
      />
    </Card>
  );
}

export default HotelDetailsCardSkeleton;
