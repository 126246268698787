import { useState, useContext } from 'react';

import { Grid, Select, FormControl, MenuItem, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useQuery } from '@apollo/client';
import QUERY from '../../graphql/queries';
import { firebaseAuth } from '../../provider/AuthProvider';

import { FriendButton, LightButton } from '../atoms/Button';
import FriendList from '../molecules/FriendList';
import FriendsSearchBar from '../molecules/FriendsSearchBar';
import LoadingLayout from '../templates/LoadingLayout';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    maxWidth: 360,
    borderRadius: '4px',
  },
  rootSelect: {
    fontWeight: '400',
    fontSize: '14px',
    border: '2px solid #E5493D',
    width: '8em',
    borderRadius: 4,
    '&:hover': {},
    paddingLeft: 12,
  },
  flexContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    maxWidth: '75%',
  },
  fullWidth: {
    width: '100%',
    marginBottom: '2%',
  },
  redText: {
    color: '#E5493D',
  },
  listContainer: {
    height: 400,
    overflowY: 'scroll',
    width: '100%',
    marginBottom: '2%',
  },
  seperator: {
    marginBottom: 24,
  },
}));

function FriendsTab() {
  const classes = useStyles();
  const { user } = useContext(firebaseAuth);
  const [trip, setTrip] = useState('');
  const [friendsFilter, setFriendsFilter] = useState(1);
  const { loading, error, data } = useQuery(QUERY.GET_FRIENDS_FOR_USER, {
    variables: { id: user.uid },
  });
  const handleFilterSet = () => {
    setFriendsFilter(friendsFilter ? 0 : 1);
  };

  if (loading) return <LoadingLayout fullPage={false} />;
  if (error)
    return <Typography>{`Error Occured! Contact Admin. ${error}`}</Typography>;
  return (
    <div className={classes.flexContainer}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        className={classes.container}>
        <Grid container className={classes.fullWidth}>
          <FriendsSearchBar data={data} />
        </Grid>
        <Grid container direction="row" className={classes.fullWidth}>
          <Grid item container direction="row" spacing={2} xs={10}>
            {friendsFilter === 1 ? (
              <>
                <Grid item>
                  <FriendButton onClick={handleFilterSet}>
                    All Friends
                  </FriendButton>
                </Grid>
                <Grid item>
                  <LightButton onClick={handleFilterSet}>
                    Recently Added
                  </LightButton>
                </Grid>
              </>
            ) : (
              <>
                <Grid item>
                  <LightButton onClick={handleFilterSet}>
                    All Friends
                  </LightButton>
                </Grid>
                <Grid item>
                  <FriendButton onClick={handleFilterSet}>
                    Recently Added
                  </FriendButton>
                </Grid>
              </>
            )}
          </Grid>
          <Grid item container xs={2} justifyContent="flex-end">
            <FormControl className={classes.rootSelect}>
              <Select
                value={trip}
                disableUnderline
                id="trips"
                displayEmpty
                variant="standard"
                inputProps={{ 'aria-label': 'Without label' }}
                onChange={(e) => {
                  setTrip(e.target.value);
                }}>
                <MenuItem value="" className={classes.redText}>
                  Group Trips
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <div className={classes.listContainer}>
          <Grid container direction="column">
            {data.getUser &&
              data.getUser.friends &&
              data.getUser.friends.map((friend) => (
                <Grid item xs={12} className={classes.seperator}>
                  <FriendList friend={friend.user} />
                </Grid>
              ))}
          </Grid>
        </div>
      </Grid>
    </div>
  );
}

export default FriendsTab;
