import { useState, useEffect, useRef, useMemo } from 'react';
import {
  Typography,
  Card,
  CardContent,
  IconButton,
  Box,
  useMediaQuery,
  CircularProgress,
  Button,
} from '@mui/material';
import { StarRounded, AddRounded, CheckRounded } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, useTheme } from '@mui/styles';
import { Bookmark } from '../../../atoms/Icon';
import AddToTripButton from '../../AddToTripButton';
import actions from '../../../../redux/actions';
import {
  deleteSavedItem,
  addSavedItem,
} from '../../../../redux/slices/Recommendations';
import recommendationCategories from '../../../../assets/newRecommendationCategories.json';
import { formatRatingCount } from '../../../../utils';
import CustomCarousel from '../../Carousel';
import { useSounds, SOUNDS } from '../../../../sounds';
import { EVENTS, phTrackEvent } from '../../../../analytics';
import NotificationPopper from '../../../organisms/Stays/NotificationPopper';
import {
  createActivity,
  createNewActivityInLocation,
  deleteActivity,
} from '../../../../redux/slices/Activity';
import { useMapUtils } from '../../../organisms/MapUtils';
import {
  createLocation,
  deleteLocation,
} from '../../../../redux/slices/Location';
import { deleteSection } from '../../../../redux/slices/Section';

const useStyles = makeStyles(({ palette }) => ({
  card: ({ isPopupCard }) => ({
    borderRadius: 4,
    boxShadow: isPopupCard ? 'auto' : 'none',
    overflow: 'hidden',
    position: 'relative',
    '&:hover': {
      boxShadow: isPopupCard ? 'auto' : 'none',
      cursor: 'pointer',
    },
  }),
  cardContent: ({ isPopupCard }) => ({
    height: '100px',
    padding: isPopupCard ? '6px 12px 12px 12px !important' : '2px 0px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '12px !important',
  }),
  buttonContainer: {
    marginLeft: 'auto',
  },
  actionIconBtn: {
    backgroundColor: '#FFF',
    padding: 8,
    height: '30px',
    width: '30px',
    position: 'absolute',
    top: 10,
    zIndex: 3,
    borderRadius: '50%',
    display: 'flex',
    color: palette.text.light,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#FFF',
      '& > svg': {
        color: palette.primary.main,
      },
    },
  },
  saveIconContainer: {
    right: 4,
  },
  addToTripContainer: {
    right: 44,
    '&.Mui-disabled': {
      cursor: 'default',
      pointerEvents: 'all !important',
      backgroundColor: '#FFF',
      color: palette.text.light,
      '&:hover': {
        backgroundColor: '#FFF',
        '& > svg': {
          color: palette.text.light,
        },
      },
    },
  },
  savedIcon: ({ saved: isSaved }) => ({
    color: isSaved ? palette.primary.main : 'currentColor',
    fill: isSaved ? palette.primary.main : 'none',
  }),
}));

function RecommendationCard({
  place = {},
  index,
  isPopupCard = false,
  isSavedList = false,
}) {
  const { slug: tripId } = useParams();

  const savedItem = useSelector(
    (state) =>
      place?.referenceId &&
      Object.values(state.Recommendations.saved[tripId] || {}).filter(
        (obj) => obj?.referenceId === place?.referenceId
      )
  );
  const isSaved = Boolean(savedItem?.length);

  // repeated state for faster local user feedback
  const [saved, setSaved] = useState(isSaved);
  const [cardHovered, setCardHovered] = useState(false);
  const [activeActionProps, setActiveActionProps] = useState(null);
  const [popperNotification, setPopperNotification] = useState(null);
  const [destinationSelectorOpen, setDestinationSelectorOpen] = useState(null);
  const [addToTripLoader, setAddToTripLoader] = useState(false);
  const [loading, setLoading] = useState(false);

  const destinationSelectorRef = useRef(null);
  const isNewDestination = useRef(false);
  const isUpdateSelection = useRef(false);
  const iconContainerRef = useRef();

  const mapPins = useSelector((state) => state.Map.mapPins[tripId] || {});
  const tripItems = useSelector((state) => state.Trips.trips[tripId].items);
  const tripDestinations = useSelector((state) => state.Location.locations);
  const sections = useSelector((state) => state.Section.sections);
  const locationContext = useSelector(
    (state) => state.Recommendations.recommendations[tripId]?.city || {}
  );

  const classes = useStyles({ saved, isPopupCard });
  const { createMapPinForPlace, createMapPinForPlaceId } = useMapUtils();
  const { playSound } = useSounds();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => setSaved(isSaved), [isSaved]);

  const dispatch = useDispatch();

  const navigateToDetails = () => {
    dispatch(
      actions.View.setPoiView({
        isInView: true,
        poi: place,
        cardIndex: index,
      })
    );
    dispatch(
      actions.Recommendations.setActivePoisPlaceMarker({
        cardIndex: index,
        posiPlace: place,
      })
    );
  };

  const handleCardClick = () => {
    phTrackEvent({
      event: EVENTS.EXPLORE_ACTIVITIES.ACTIVITY_CLICK,
    });
    navigateToDetails();
  };

  const handleSaveClick = async (e) => {
    e.stopPropagation();
    if (loading) return;
    setLoading(true);
    if (isSaved) {
      playSound(SOUNDS.removeBaritone);
      setSaved(false);
      await dispatch(
        deleteSavedItem({
          variables: {
            id: savedItem?.length > 0 && savedItem[0].id,
          },
          tripId,
        })
      ).catch(() => setSaved(true));
    } else {
      playSound(SOUNDS.heartChime);
      phTrackEvent({
        event: EVENTS.EXPLORE_ACTIVITIES.ACTIVITY_SAVED_LIST_ADD,
      });
      setSaved(true);
      await dispatch(
        addSavedItem({
          variables: {
            tripId,
            referenceId: place.referenceId,
            savedData: JSON.stringify(place),
          },
        })
      ).catch(() => setSaved(false));
    }
    setLoading(false);
  };

  function FormattedTagsList() {
    const topTags = place?.rankedCategories?.slice(0, 2);
    return (
      topTags?.length > 0 && (
        <div
          style={{
            display: 'flex',

            flexGrow: 1,
          }}>
          {topTags?.map((tag) => (
            <div
              style={{
                backgroundColor: '#F4F4F4',
                padding: '4px 8px',
                borderRadius: 12,
                marginRight: 6,
                marginTop: 'auto',
                marginBottom: 'auto',
              }}>
              <Typography variant="h5" fontSize={12} color="text.light" noWrap>
                {recommendationCategories.ref[tag]?.name || ''}
              </Typography>
            </div>
          ))}
        </div>
      )
    );
  }

  const handleCardMouseEnter = () => {
    setCardHovered(true);
    if (!isMobile && !isPopupCard) {
      dispatch(
        actions.Recommendations.setHoveredCardIndex({
          cardIndex: index,
          tripId,
        })
      );
    }
  };

  const handleCardMouseLeave = () => {
    setCardHovered(false);
    if (!isMobile && !isPopupCard) {
      dispatch(
        actions.Recommendations.setHoveredCardIndex({ cardIndex: null, tripId })
      );
    }
  };

  const isActivityAddedToTrip = useMemo(
    () =>
      (Object.values(mapPins)
        ?.map((mapPin) => mapPin?.placeId === place?.referenceId)
        ?.filter(Boolean)?.length || 0) > 0,
    [mapPins, tripId, place?.referenceId]
  );

  // Process filtered locations to add mapPin, hotelsCount, etc.
  const validLocations = useMemo(
    () =>
      Object.values(tripDestinations || {})
        .filter(
          (loc) => loc.tripID === tripId && loc.name !== '' && loc?.mapPin
        )
        .map((loc) => {
          const mapPin = mapPins[loc.mapPin] || null;
          if (!mapPin) return null;
          const sectionsCount = loc.thingsToDo?.length || 0;
          return {
            id: loc.id,
            title: loc.name,
            mapPin,
            sectionsCount,
            defaultSectionId: sectionsCount > 0 ? loc?.thingsToDo[0] : null,
          };
        })
        .filter((tag) => Boolean(tag)),
    [tripDestinations, mapPins]
  );

  const similarLocations = useMemo(
    () =>
      validLocations?.filter(
        (location) =>
          location?.title?.toLowerCase()?.trim() ===
          locationContext?.title?.toLowerCase()?.trim()
      ),
    [locationContext, validLocations]
  );

  const autoAddToTrip = async ({ sectionId, locationId }) => {
    let destinationId = locationId;
    let locationMapPinId;
    let newSectionId;
    let newActivityId;
    if (!destinationId) {
      try {
        locationMapPinId = await createMapPinForPlaceId(
          locationContext?.placeId,
          'LOCATION'
        );
        const location = await dispatch(
          createLocation({
            variables: {
              name: locationContext.title,
              tripID: tripId,
              mapPin: locationMapPinId,
              index: tripItems.length || 0,
            },
          })
        ).then((data) => data.payload.createLocation);
        destinationId = location?.id;
        isNewDestination.current = true;
      } catch (err) {
        return;
      }
    }
    const mapPin = await createMapPinForPlace(
      {
        title: place?.title,
        placeId: place?.referenceId,
        website: place?.website,
        photo: place?.thumbnail,
        lat: place?.coordinates?.latitude,
        long: place?.coordinates?.longitude,
      },
      'ACTIVITY'
    );
    if (!sectionId) {
      const { section, activity } =
        (
          await dispatch(
            createNewActivityInLocation({
              locationId: destinationId,
              activityTitle: place?.title,
              mapPin: mapPin?.id,
            })
          )
        )?.payload?.createNewActivityInLocation || {};
      newSectionId = section?.id;
      newActivityId = activity?.id;
    } else {
      newActivityId = (
        await dispatch(
          createActivity({
            variables: {
              title: place?.title,
              mapPin: mapPin?.id,
              thingsToDoId: sectionId,
              index: sections[sectionId]?.todos?.length,
            },
            sectionId,
            index: sections[sectionId]?.todos?.length,
          })
        )
      )?.payload?.createTodo?.id;
    }
    setActiveActionProps({
      locationId: destinationId,
      locationMapPinId,
      isNewLocation: Boolean(locationMapPinId),
      isNewSection: Boolean(newSectionId),
      sectionId: sectionId || newSectionId,
      activityId: newActivityId,
      activityMapPinId: mapPin?.id,
    });
    setPopperNotification(
      tripDestinations[locationId]?.name || locationContext.title
    );
  };

  const handleActivityAddToTrip = async () => {
    const location = similarLocations?.length > 0 ? similarLocations[0] : null;
    setAddToTripLoader(true);
    await autoAddToTrip({
      locationId: location?.id,
      sectionId: location?.defaultSectionId,
    });
    setAddToTripLoader(false);
  };

  const handleUndoAction = async ({ newLocationId, newSectionId }) => {
    if (
      newLocationId === activeActionProps?.locationId &&
      newSectionId === activeActionProps?.sectionId
    ) {
      return 'NO-ACTION';
    }

    if (!activeActionProps) return null;

    const {
      locationId = null,
      locationMapPinId = null,
      activityId = null,
      activityMapPinId = null,
      sectionId = null,
      isNewSection = null,
    } = activeActionProps;

    if (activityId === null) return null;

    if (isNewDestination.current) {
      await dispatch(
        deleteLocation({
          variables: {
            id: locationId,
            tripId,
          },
          mapPin: locationMapPinId,
        })
      );
    } else if (isNewSection) {
      await dispatch(
        deleteSection({
          variables: {
            locationId,
            id: sectionId,
          },
          tripId,
        })
      );
    }
    await dispatch(
      deleteActivity({
        variables: {
          id: activityId,
          thingsToDoId: sectionId,
        },
        mapPin: activityMapPinId,
        sectionId,
      })
    );
    setActiveActionProps(null);
    return null;
  };

  return (
    <Card
      className={`${classes.card} recommendation-poi-card`}
      onClick={handleCardClick}
      onMouseEnter={handleCardMouseEnter}
      onMouseLeave={handleCardMouseLeave}
      id={`recommendation-poi-card-${place?.referenceId}`}>
      <div ref={iconContainerRef} />
      <Box position="relative" onClick={(e) => e?.stopPropagation()}>
        <NotificationPopper
          open={Boolean(popperNotification)}
          anchorEl={iconContainerRef?.current}
          onClose={(e, reason) => {
            if (reason === 'timeout') {
              setActiveActionProps(null);
            }
            setPopperNotification(null);
          }}
          onCancel={async () => {
            setPopperNotification(null);
            if (validLocations?.length <= 1) {
              setAddToTripLoader(true);
              await handleUndoAction({
                newLocationId: null,
                newSectionId: null,
              });
              setAddToTripLoader(false);
            } else {
              isUpdateSelection.current = true;
              setDestinationSelectorOpen(true);
            }
          }}
          destinationName={popperNotification}
          isNewDestination={isNewDestination?.current}
          cancelButtonLabel={validLocations?.length <= 1 ? 'Undo' : 'Change'}
        />
        <AddToTripButton
          tripId={tripId}
          setLoader={setAddToTripLoader}
          customAnchor={iconContainerRef?.current}
          initAction={handleUndoAction}
          postAction={({ locationId }) =>
            setPopperNotification(tripDestinations[locationId]?.name)
          }
          handleClose={setDestinationSelectorOpen}
          trigger={destinationSelectorOpen}
          CustomButton={(props) => (
            <div>
              <IconButton
                disabled={isActivityAddedToTrip || addToTripLoader}
                className={`${classes.addToTripContainer} ${classes.actionIconBtn}`}
                id={`poi-card-add-to-trip-button-${index}`}
                onClick={async (e) => {
                  if (
                    similarLocations?.length > 1 ||
                    isUpdateSelection?.current ||
                    isSavedList
                  ) {
                    props?.onClick(e);
                    isUpdateSelection.current = false;
                  } else {
                    await handleActivityAddToTrip();
                  }
                }}
                ref={destinationSelectorRef}>
                {addToTripLoader ? (
                  <CircularProgress size={14} />
                ) : isActivityAddedToTrip ? (
                  <CheckRounded fontSize="small" sx={{ color: '#43A047' }} />
                ) : (
                  <AddRounded fontSize="small" />
                )}
              </IconButton>
            </div>
          )}
          placeDetails={{
            title: place?.title,
            placeId: place?.referenceId,
            website: place?.website,
            photo: place?.thumbnail,
            lat: place?.coordinates?.latitude,
            long: place?.coordinates?.longitude,
          }}
          id={`recommendations-poi-card-add-to-trip-button-${place?.referenceId}`}
          className="recommendations-poi-card-add-to-trip-button"
          source="recommendations"
        />

        <IconButton
          onClick={handleSaveClick}
          className={`${classes.saveIconContainer} ${classes.actionIconBtn} recommendations-poi-card-save-button`}
          id={`recommendations-poi-card-save-button-${place?.referenceId}`}>
          <Bookmark height={16} width={16} className={classes.savedIcon} />
        </IconButton>

        <CustomCarousel
          onImageClick={handleCardClick}
          showArrows={cardHovered}
          images={place?.images}
          isRecomendationCard
        />
      </Box>
      <CardContent className={classes.cardContent}>
        <Typography
          variant="h3"
          fontSize={16}
          sx={{
            display: '-webkit-box',
            WebkitLineClamp: 1,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}>
          {place.title}
        </Typography>
        <FormattedTagsList />
        <div
          style={{
            display: 'flex',
            paddingTop: '6px',
          }}>
          {place?.rating && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <StarRounded
                sx={{ height: 20, width: 20, color: '#ED702E', mr: '4px' }}
              />
              <Typography
                variant="h5"
                fontSize={14}
                color="#4E4E4E"
                justifyItems="center"
                alignContent="center">
                <b>{place.rating ? (place.rating / 2).toFixed(1) : ''}</b>
                {place.ratingCount
                  ? ` (${formatRatingCount(place.ratingCount)})`
                  : ''}
              </Typography>
            </div>
          )}
          {isPopupCard && (
            <div style={{ marginLeft: 'auto' }}>
              <Button sx={{ padding: '2px 8px' }} onClick={navigateToDetails}>
                See details
              </Button>
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
}

export default RecommendationCard;
