import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import graphqlClient from '../../graphql/index';
import QUERY from '../../graphql/queries';
import getAsyncThunk from '../helpers';
import CONFIG from '../../components/config';
import { EVENTS, phTrackEvent } from '../../analytics';
import getCfConnector from '../../components/cfConnector';

const initialState = {
  firebaseUser: null,
  error: null,
  success: null,
  accountDeleted: false,
  loading: false,
  userData: {
    user: null,
    status: 'IDLE',
    error: false,
    errorPayload: null,
    subscription: null,
  },
};

export const getUser = createAsyncThunk(
  'USER/getUser',
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data, error } = await graphqlClient.query({
        query: QUERY.GET_USER,
        variables: { id },
        fetchPolicy: 'no-cache',
      });
      if (error) throw new Error(error.message);
      return data.getUser;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getUserSubscriptions = createAsyncThunk(
  'USER/getUserSubscriptions',
  async ({ userEmail }, { rejectWithValue }) => {
    try {
      const subscription = (
        await (
          await getCfConnector()
        ).get(`${CONFIG.paymentsAPI}/get-subscriptions?email=${userEmail}`)
      ).data;
      return subscription;
    } catch (e) {
      return rejectWithValue('Failed');
    }
  }
);

export const updateUser = getAsyncThunk('USER/updateUser', QUERY.UPDATE_USER);

export const deleteUser = getAsyncThunk('USER/deleteUser', QUERY.DELETE_USER);

const AuthSlice = createSlice({
  name: 'AUTH',
  initialState,
  reducers: {
    setFirebaseUser: (state, action) => {
      state.firebaseUser = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setSuccess: (state, action) => {
      state.success = action.payload;
    },
    setAccountDeleted: (state, action) => {
      state.accountDeleted = action.payload;
    },
    updateUser: (state, { payload }) => {
      state.userData.user = { ...state.userData.user, ...payload };
    },
    setAuthLoading: (state, { payload }) => {
      state.loading = payload;
    },
    reset: () => initialState,
  },
  extraReducers: {
    [getUserSubscriptions.fulfilled]: (state, { payload }) => {
      state.userData.subscription = payload;
    },
    [getUser.pending]: (state) => {
      state.userData.status = 'LOADING';
    },
    [getUser.fulfilled]: (state, { payload }) => {
      state.userData.status = 'SUCCESS';
      state.userData.user = payload;
    },
    [getUser.rejected]: (state, { payload }) => {
      state.userData.status = 'IDLE';
      state.userData.error = true;
      state.userData.errorPayload = payload;
    },
    [updateUser.fulfilled]: (state, { meta }) => {
      const { variables } = meta.arg;
      phTrackEvent({
        event: EVENTS.PROFILE.PROFILE_UPDATE,
      });
      state.userData.user = { ...state.userData.user, ...variables };
    },
  },
});

export const AuthActions = AuthSlice.actions;
export const AuthReducer = AuthSlice.reducer;
