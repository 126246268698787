/* eslint-disable no-unused-vars */
import { useEffect, useState, useContext } from 'react';

import { Grid, Snackbar, Typography, Box } from '@mui/material';
import { Alert } from '@mui/lab';
import { useSelector, useDispatch } from 'react-redux';

import { firebaseAuth } from '../../provider/AuthProvider';

import AddWishItem from '../molecules/AddWishItem';
import WishlistCard from '../molecules/WishlistCard';
import LoadingLayout from '../templates/LoadingLayout';
import { handleWishlistImageUpload } from '../../utils';

import {
  getWishlists,
  createWishlist,
  deleteWishlist,
  saveDiscoverItemToWishlist,
} from '../../redux/slices/Wishlist';
import PoiCard from './Discover/PoiCard';
import PoiDetailsModal from './Discover/PoiDetailsModal';

function WishlistContainer({ main = false }) {
  const { user } = useContext(firebaseAuth);
  const userId = user.uid;
  const { wishlists, status, error } = useSelector((state) => state.Wishlist);

  const defaultWishlists = wishlists?.filter((w) => w?.type !== 'DISCOVER');
  const discoverWishlists = wishlists?.filter((w) => w?.type === 'DISCOVER');
  const dispatch = useDispatch();

  const [discoverPoiModal, setDiscoverPoiModal] = useState(null);

  const [uploading, setUploading] = useState(false);
  const [errorState, setErrorState] = useState(false);

  useEffect(() => {
    if (status === 'IDLE') {
      dispatch(getWishlists({ userId: user.uid }));
    }
  }, []);

  const handleWishlistPicUpload = async (coverImageFile) => {
    let resultURL = null;
    await handleWishlistImageUpload(coverImageFile, userId)
      .then((result) => {
        resultURL = result.url;
        return true;
      })
      .catch((err) => {
        return err;
      });
    return resultURL;
  };

  const handleSubmit = async (title, imageFile) => {
    const { image: imagePath, source: imageSource } = imageFile;
    setUploading(true);
    let coverImageURL = imagePath;
    if (imageSource === 'upload')
      coverImageURL = await handleWishlistPicUpload(imagePath);
    const newWishlist = {
      title,
      coverImage: coverImageURL,
      owner: user.uid,
    };
    await dispatch(
      createWishlist({
        ...newWishlist,
      })
    );
    setUploading(false);
  };

  const handleClose = () => {
    setErrorState(false);
  };

  function AddWishItemContainer() {
    return (
      <Grid xs={12} sm={6} md={6} lg={4} xl={4} item>
        <AddWishItem handleSubmit={handleSubmit} uploading={uploading} />
      </Grid>
    );
  }

  function EmptyWishlist() {
    return (
      <Grid
        container
        item
        md={12}
        style={{
          display: 'flex',
          height: '100%',
          width: '100%',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        {main ? (
          <Box
            style={{
              flexDirection: 'column',
              width: '100%',
              textAlign: 'center',
            }}>
            <Typography variant="h2Sub">
              Add your first wishlist idea!
            </Typography>
            <Typography mt="8px" mb="65px" variant="h2" fontWeight="400">
              Wishlist is all the trips you want to go on but can’t start
              planning just yet.
            </Typography>
            <Grid
              container
              item
              style={{
                flexDirection: 'row',
                justifyContent: 'center',
              }}>
              <AddWishItemContainer />
            </Grid>
          </Box>
        ) : (
          <>
            <Typography variant="h3Sub" fontWeight="500" color="text.light">
              Oh no! Your wishlist is empty 😮
            </Typography>
            <Typography variant="h4" color="text.light">
              Head over to the wishlist tab in order to add wishlist ideas
            </Typography>
          </>
        )}
      </Grid>
    );
  }

  // discover pois
  const filteredWishlists = useSelector((state) =>
    state.Wishlist.wishlists?.filter((w) => w?.type === 'DISCOVER')
  );

  const isPoiSavedToWishlist = (id) => {
    const savedWishlists = filteredWishlists?.filter(
      (w) => w?.relationId === id
    );
    return savedWishlists?.length > 0 ? savedWishlists[0]?.id : null;
  };

  const handleSaveToWishlist = async ({ id, title, image, poi }) => {
    const savedWishlistId = isPoiSavedToWishlist(id);
    if (savedWishlistId) {
      await dispatch(deleteWishlist({ id: savedWishlistId }));
    } else {
      await dispatch(
        saveDiscoverItemToWishlist({
          discoverPoi: {
            relationId: id,
            title,
            coverImage: image,
            ...poi,
          },
        })
      );
    }
  };

  if (status === 'LOADING') {
    return <LoadingLayout />;
  }
  if (error) {
    return <Typography>Error Occured! Contact Admin.</Typography>;
  }
  return (
    <Grid
      container
      spacing={2}
      style={{
        overflow: 'auto',
        padding: '20px 0',
      }}>
      {wishlists?.length > 0 ? (
        <>
          <Snackbar
            open={errorState}
            autoHideDuration={6000}
            onClose={handleClose}>
            <Alert severity="error">Unable to upload the wishlist image!</Alert>
          </Snackbar>
          <PoiDetailsModal
            handleClose={() => setDiscoverPoiModal(null)}
            poi={discoverPoiModal}
            showSaveCta
            isSaved={isPoiSavedToWishlist(discoverPoiModal?.referenceId)}
            handleSave={() =>
              handleSaveToWishlist({
                id: discoverPoiModal?.referenceId,
                title: discoverPoiModal?.title,
                image: discoverPoiModal?.coverImage,
                poi: discoverPoiModal,
              })
            }
          />
          {main && <AddWishItemContainer />}
          {defaultWishlists &&
            defaultWishlists
              .slice()
              .reverse()
              .map((wishlist, idx) => (
                <Grid
                  xs={12}
                  sm={6}
                  md={6}
                  lg={4}
                  xl={4}
                  item
                  key={idx.toString()}>
                  <WishlistCard wishlist={wishlist} />
                </Grid>
              ))}

          <Grid item xs={12} sm={12} md={12} xl={12}>
            {discoverWishlists.length ? (
              <Typography variant="h2Sub" mt="40px">
                Added from Discover
              </Typography>
            ) : null}
          </Grid>

          {discoverWishlists?.map((wishlist, idx) => {
            const wData = JSON.parse(wishlist?.savedData || '{}');
            return (
              <Grid
                xs={12}
                sm={6}
                md={6}
                lg={4}
                xl={4}
                item
                key={idx.toString()}>
                <PoiCard
                  handleClick={() =>
                    setDiscoverPoiModal({ ...wishlist, ...wData })
                  }
                  image={wishlist?.coverImage}
                  title={wishlist?.title}
                  tag={wData?.categories && wData.categories[0]}
                  id={wishlist?.referenceId}
                  poi={{ ...wishlist, ...wData }}
                  showSaveButton={false}
                  isSaved={isPoiSavedToWishlist(wishlist?.referenceId)}
                  handleSaveButtonClick={() =>
                    handleSaveToWishlist({
                      id: wishlist?.referenceId,
                      title: wishlist?.title,
                      image: wishlist?.coverImage,
                      poi: { ...wishlist, ...wData },
                    })
                  }
                  handleDeleteButtonClick={() =>
                    dispatch(deleteWishlist({ id: wishlist?.id }))
                  }
                  showDeleteMenu
                />
              </Grid>
            );
          })}
        </>
      ) : (
        <EmptyWishlist main={main} />
      )}
    </Grid>
  );
}

export default WishlistContainer;
