import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ChatIcon from '@mui/icons-material/Chat';
import { ButtonWithIcon } from '../atoms/Button';

const useHeaderStyles = makeStyles(({ breakpoints }) => ({
  root: {
    backgroundColor: '#F8F8F8',
    padding: '2% 5%',
    [breakpoints.up('md')]: {
      padding: '2% 4%',
    },

    margin: '0 0',
    color: '#000',
    fontSize: '26px',
    fontWeight: 600,
  },
  rootFlex: {
    backgroundColor: '#FFFFFF',
    padding: '2% 2%',
    margin: '0 0',
    display: 'flex',
  },
  button: {
    minWidth: '10.5em',
    height: '2.5em',
    position: 'absolute',
    right: '2em',
    top: '2em',
  },
}));

function Header({ title, Icon }) {
  const classes = useHeaderStyles();
  if (typeof Icon === 'undefined') {
    return (
      <Typography variant="h2" className={classes.root}>
        {title}
      </Typography>
    );
  }
  return (
    <div className={classes.root}>
      <Typography variant="h2">{title}</Typography>
      <ButtonWithIcon className={classes.button} endIcon={<Icon />} onClick="/">
        New chat
      </ButtonWithIcon>
    </div>
  );
}

export default Header;

export function HeaderWithButton(props) {
  const classes = useHeaderStyles();
  return (
    <div className={classes.root}>
      <Typography variant="h2">{props.title}</Typography>
      <ButtonWithIcon
        className={classes.button}
        endIcon={<ChatIcon />}
        onClick={props.handleClick}>
        New chat
      </ButtonWithIcon>
    </div>
  );
}
