import { useRef, useContext, useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import {
  CircularProgress,
  Grid,
  Typography,
  Divider,
  Box,
  Snackbar,
  SnackbarContent,
} from '@mui/material';
import {
  AirplanemodeActive,
  CameraAlt as CameraAltIcon,
} from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  DragDropContext,
  Droppable,
  Draggable,
  useMouseSensor,
  useTouchSensor,
} from 'react-beautiful-dnd';
import { firebaseAuth } from '../../provider/AuthProvider';
import Notes from './Notes';
import {
  LightGreyButton,
  SecondaryOutlinedButton,
} from '../atoms/Button/index';
import AddModuleButton from '../molecules/AddModuleButton/index';
import TransportBlock from '../molecules/TransportBlock';
import TripNavbar from '../organisms/Navbar/TripNavbar';
import ItinerarySection from '../organisms/createTrip/ItinerarySection';
import MessengerBlade from '../organisms/MessengerBlade';
import Map from '../organisms/Map';
import { MapProvider } from '../organisms/MapUtils';
import {
  ItineraryDndProvider,
  useItineraryDnd,
} from '../organisms/ItineraryDndProvider';
import Transportation from '../organisms/Transportation';
import { ImageSelectorModal } from '../molecules/Modal';
import FilesTab from '../organisms/FilesTab';
import AppLayout from '../templates/AppLayout';
import LoadingLayout from '../templates/LoadingLayout';
import CityPage from '../organisms/Recommendations/CityPage';
import { handleTripCoverImage } from '../../utils';
import { getCompleteTrip, updateTrip } from '../../redux/slices/Trips';
import { createLocation } from '../../redux/slices/Location';
import {
  handleDndItems,
  handleDndSections,
  handleDndActivities,
  handleDndHotels,
} from '../../redux/slices/sharedThunks';

import CitiesList from '../../assets/citiesList';
// import Recommendations from '../organisms/Recommendations/Recommendations';
import classList from '../classList';
import ExportPDFButton from '../atoms/Button/ExportPDFButton';
import TravelRestrictions from '../organisms/TravelRestrictions';
import ImportFiles from '../organisms/ImportFiles';
import AutoPilot from '../organisms/AutoPilot';
import { Events, trackEvents } from '../../intercom';
import BookingsModal from '../molecules/Modal/BookingsModal';
import ExploreV1 from '../organisms/Recommendations/ExploreV1';
import TimezoneFixPopup from '../molecules/GlobalMessagePopup/TimezoneFixPopup';
import TripDateSelector from '../molecules/TripDateSelectorExternal';
import { useSounds, SOUNDS } from '../../sounds';
import { EVENTS, phTrackEvent } from '../../analytics';
import { BookingsActions } from '../../redux/slices/Bookings';
import ExportPDFModal from '../molecules/Modal/ExportPDFModal';
import getCfConnector from '../cfConnector';
import config from '../config';

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: 'calc(-56px + 100vh)',
  },
  plannerContainer: {
    overflow: 'auto',
    maxHeight: 'calc(-56px + 100vh)',
    paddingBottom: '2em',
    backgroundColor: '#FFF',
    flexDirection: 'column',

    // hard coded from design for responsiveness, don't change
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
    },
    [theme.breakpoints.down('md')]: {
      width: '46.8%',
      maxWidth: 470,
    },
    [theme.breakpoints.up('md')]: {
      width: '46.8%',
      maxWidth: 767,
    },
  },
  exploreContainer: {
    // hard coded from design for responsiveness, don't change
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
    },
    [theme.breakpoints.down('md')]: {
      width: '46.8%',
      maxWidth: 473,
    },
    [theme.breakpoints.up('md')]: {
      width: '66.1%',
      maxWidth: 677,
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 767,
    },
  },
  mapContainer: {
    display: 'flex',
    flex: 1,
    // hard coded from design for responsiveness, don't change
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '0%',
    },
  },
  coverImage: {
    height: 200,
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '8px',
    margin: '24px 0px 8px 0',
    [theme.breakpoints.down('sm')]: {
      margin: '24px 0px 24px 0',
    },
  },
  emptyCoverImage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 200,
    width: '100%',
    backgroundColor: '#F4F4F4',
    borderRadius: '8px',
    margin: '24px 0 0 0',
    '&:hover': {
      cursor: 'pointer',
      '& .MuiTypography-root': {
        color: theme.palette.text.secondary,
      },
    },
    [theme.breakpoints.down('sm')]: {
      margin: '24px 0px 24px 0',
    },
  },
  spacing: {
    padding: '130px calc(9.6% - 1rem) 0% calc(9.6% - 1rem)',
    [theme.breakpoints.up('sm')]: {
      padding: '0% calc(9.6% - 1rem) 0% calc(9.6% + 1.2rem + 6px)',
    },
  },
  secondarySpacing: {
    marginLeft: 'calc(9.6% - 2.4rem - 12px)',
    marginRight: 'calc(9.6% - 1rem)',
  },
  instructionsBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '0% 9.6% 0% calc(9.6% + 1.2rem + 6px)',
    [theme.breakpoints.down('sm')]: {
      padding: '0% 9.6% 0% 9.6%',
    },
  },
  instruction: {
    marginTop: '4vw',
    color: '#8A8A8A',
  },
  locationDroppable: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'transparent',
  },
  item: {
    backgroundColor: 'transparent',
  },
  itemDragging: {
    backgroundColor: '#FFF',
    border: '2px solid #DEDDDD',
    borderRadius: 4,
  },
  spacingTripTitle: {
    padding: '0% calc(9.6% - 1rem) 0% calc(9.6% - 1rem)',
    [theme.breakpoints.up('sm')]: {
      padding: '0% calc(9.6% - 1rem) 0% calc(9.6% + 1.2rem + 6px)',
    },
    marginTop: '8px',
    marginBottom: '8px',
  },
  iconButtonPropsRoot: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: '9px 8px',
  },
  dateStringPropsRoot: {
    marginLeft: '8px',
    marginRight: '0em',
  },
  divider: {
    padding: '0% 0 0% calc(9.6% + 1.2rem + 6px)',
    '& .MuiDivider-middle': {
      marginLeft: '0em',
      marginRight: '0em',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0% 0 0% 9.6%',
    },
  },
  droppableSpacing: {
    marginBottom: '5%',
  },
  innerTabsContainer: {
    padding: '0% calc(9.6% - 1rem) 0% calc(9.6% - 1rem)',
    marginTop: 140,
    [theme.breakpoints.up('sm')]: {
      padding: '0% calc(9.6% - 1rem) 0% calc(9.6% + 1.2rem + 6px)',
      marginTop: 18,
    },
    width: '100%',
  },
  buttonWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  buttonWrapper1: {
    display: 'flex',
    width: '100%',
    [theme.breakpoints.down(1600)]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },

  buttonLeft: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    [theme.breakpoints.down(1650)]: {
      justifyContent: 'space-between',
      marginTop: 8,
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
      marginTop: 8,
    },
  },

  buttonsRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    [theme.breakpoints.down(1600)]: {
      // justifyContent: 'space-between',
      marginTop: 8,
    },
    [theme.breakpoints.down('sm')]: {
      // justifyContent: 'space-between',
      marginTop: 8,
    },
  },
}));

function Itinerary() {
  const [openMessenger, setOpenMessenger] = useState(false);
  const [shouldDateUpdate, setShouldDateUpdate] = useState(true);
  const dispatch = useDispatch();
  const [pdfLoading, setPdfLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  /* FEATURE FLAGS START */
  const showImportBookings = false;
  /* FEATURE FLAGS END */

  const { playSound } = useSounds();

  const showPoiDetailsPage = useSelector(
    (state) => state.View.poiDetails.isInView
  );

  const { slug, tab } = useParams();

  // redux state
  const trip = useSelector((state) => state.Trips.trips[slug]);
  const bookingDataObj = useSelector((state) => state.Bookings[slug]);
  const tmpAudioDivRef = useRef();
  useEffect(() => {
    // TODO: REMOVE THIS IN BY JUNE 20 2024

    if (new URLSearchParams(location?.search).get('addToTrip') === '1') {
      return navigate(`/join/${slug}`);
    }
    if (!trip || trip?.status === 'IDLE')
      dispatch(getCompleteTrip({ tripId: slug })).then(() => {
        playSound(SOUNDS.tripWelcomeTone);
      });
  }, []);

  useEffect(() => {
    // under explore tab, date should be updated for the 1st time only.
    if (trip && shouldDateUpdate) {
      setShouldDateUpdate(false);
      if (!bookingDataObj) {
        dispatch(
          BookingsActions.initializeTripState({
            tripId: slug,
            startDate: trip?.startDate,
            endDate: trip?.endDate,
          })
        );
      } else if (
        bookingDataObj?.startDate !== trip?.startDate ||
        bookingDataObj?.endDate !== trip?.endDate
      ) {
        dispatch(
          BookingsActions.setSearchDate({
            tripId: slug,
            date: {
              from: trip?.startDate && new Date(trip?.startDate),
              to: trip?.endDate && new Date(trip?.endDate),
            },
          })
        );
      }
    }
  }, [trip]);

  // trigger to update data in the components.
  const { user } = useContext(firebaseAuth);

  // to add an activity to the last column in a location. Used for pins.
  const [triggerAddActivity, setTriggerAddActivity] = useState(null);
  const [updateItineraryState, setUpdateItineraryState] = useState(false);
  // state that opens up a map popup if set with the suggestion from google-places-api.
  const [openMapPopup, setOpenMapPopup] = useState(null);
  const [changeCoverModalOpen, setChangeCoverModalOpen] = useState(false);
  const [errorSnackbarOpened, setErrorSnackbarOpened] = useState(false);
  const [isExportPDFModalOpen, setExportPDFModalOpen] = useState(false);
  const [popupAccessAlert, setPopupAccessAlert] = useState(false);

  const classes = useStyles();

  const [gettingCity, setGettingCity] = useState(-1);
  const citySuggestionRef = useRef({
    suggestion: null,
  });

  const toggleExportPDFModal = () => setExportPDFModalOpen((val) => !val);

  // function to render a hidden iframe and render the pdf on the print window.
  const exportOptions = useSelector((state) => state.Trips.exportPdf.options);
  const handleExportPdf = async () => {
    const type =
      exportOptions.format === 'img' ? 'image/png' : 'application/pdf';
    setPdfLoading(true);
    toggleExportPDFModal();
    try {
      const connector = await getCfConnector();
      const res = await connector.post(
        config.exportTripAPI,
        {
          tripId: slug,
          includeActivityNotes: exportOptions.includeNotes,
          includeImages: exportOptions.includeImages,
          exportMode: exportOptions.format,
        },
        {
          responseType: 'arraybuffer',
          headers: {
            Accept: type,
          },
        }
      );
      const blob = new Blob([res.data], { type });
      const blobUrl = URL.createObjectURL(blob);
      setPdfLoading(false);
      if (!window.open(blobUrl, '_blank')) {
        return setPopupAccessAlert(true);
      }
      trackEvents(Events.TripExported);
    } catch (error) {
      setPdfLoading(false);
      console.error('Error exporting PDF:', error);
    }
  };

  function CoverImage() {
    const [filledCoverImageHovered, setFilledCoverImageHovered] =
      useState(false);
    if (!['', 'empty'].includes(trip?.coverImage))
      return (
        <div
          id="trip-cover-image"
          className={`${classes.coverImage} ${classList.tripCoverImage}`}
          onMouseEnter={() => setFilledCoverImageHovered(true)}
          onMouseLeave={() => setFilledCoverImageHovered(false)}>
          <img
            style={{ objectFit: 'cover', height: 200, width: '100%' }}
            src={trip?.coverImage}
            alt={trip?.title}
          />
          {filledCoverImageHovered && (
            <LightGreyButton
              startIcon={<CameraAltIcon />}
              style={{ position: 'absolute', right: '12px', bottom: '8px' }}
              onClick={() => {
                setChangeCoverModalOpen(true);
                setFilledCoverImageHovered(false);
              }}>
              Change cover photo
            </LightGreyButton>
          )}
        </div>
      );
    return (
      <div
        className={`${classes.emptyCoverImage} ${classList.emptyTripCoverImage}`}
        onClick={() => setChangeCoverModalOpen(true)}>
        <Typography variant="h5" color="text.light">
          + Add a trip photo
        </Typography>
      </div>
    );
  }

  const getRandomCity = () => {
    const randIdx = Math.floor(Math.random() * CitiesList.length);
    citySuggestionRef.current.suggestion = CitiesList[randIdx];
  };

  const handleAddLocation = async (index, setNewLocation) => {
    setGettingCity(index + 1);
    if (trip?.items.some((item) => item.location)) {
      getRandomCity();
    }

    try {
      const res = await dispatch(
        createLocation({
          variables: {
            name: '',
            tripID: slug,
            index: index + 1,
          },
        })
      );
      setNewLocation(res.payload.createLocation.id);
      setUpdateItineraryState(true);
    } catch (e) {
      return e;
    }
    setGettingCity(-1);
    return true;
  };

  if (!trip || trip.status !== 'SUCCESS') {
    return <LoadingLayout />;
  }
  if (trip.error) {
    return (
      <Typography>{`Error Occured! Contact Admin. ${trip.error}`}</Typography>
    );
  }
  const tripOwner = trip?.owner || [];
  const tripSharedUsers = trip?.sharedUsers || [];
  const tripUsers = [tripOwner, ...tripSharedUsers];
  const tripStartDate = trip.startDate;
  const tripEndDate = trip.endDate;

  function TripDateSelectorMain() {
    return (
      <TripDateSelector
        trip={trip}
        tripStartDate={tripStartDate}
        tripEndDate={tripEndDate}
      />
    );
  }

  function CustomTravelRestrictionButton({ onClick = () => {} }) {
    return (
      <SecondaryOutlinedButton
        startIcon={
          <AirplanemodeActive
            sx={{ color: '#1D1D1D', height: 14, width: 14 }}
          />
        }
        onClick={onClick}
        id="travel-restriction-button"
        style={{ width: '100%' }}>
        Visas & Requirements
      </SecondaryOutlinedButton>
    );
  }

  return (
    <AppLayout>
      <Helmet>
        <title>{trip?.title ? `${trip.title} | Pilot` : ' '}</title>
      </Helmet>
      <div ref={tmpAudioDivRef} />
      <ImageSelectorModal
        handleUpload={(e) => handleTripCoverImage(e, slug)}
        changeCoverModalOpen={changeCoverModalOpen}
        setChangeCoverModalOpen={setChangeCoverModalOpen}
        handleUpdateCoverImage={(image) => {
          dispatch(
            updateTrip({
              variables: {
                id: slug,
                coverImage: image,
              },
            })
          ).then(() => {
            phTrackEvent({
              event: EVENTS.PLAN_HEADER.COVER_PHOTO_UPDATE,
            });
            playSound(SOUNDS.photoUpload);
          });
        }}
        showInitialImages
        setErrorSnackbarOpened={setErrorSnackbarOpened}
        trip={trip}
      />

      <BookingsModal />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={popupAccessAlert}
        autoHideDuration={5000}
        onClose={() => setPopupAccessAlert(false)}
        key="auto-flight-search-success-popup">
        <SnackbarContent
          sx={{
            backgroundColor: 'success.main',
            justifyContent: 'center',
          }}
          message="Please enable popups to download your trip."
        />
      </Snackbar>
      <ExportPDFModal
        onExport={handleExportPdf}
        open={isExportPDFModalOpen}
        onClose={toggleExportPDFModal}
      />

      <ItineraryDndProvider
        trip={trip}
        updateItineraryState={updateItineraryState}
        setUpdateItineraryState={setUpdateItineraryState}
        triggerAddActivity={triggerAddActivity}
        setTriggerAddActivity={setTriggerAddActivity}>
        <TripNavbar
          user={user}
          tripData={trip}
          people={tripUsers}
          setOpenMessenger={setOpenMessenger}
          activePath={tab}
          refetch={() =>
            dispatch(getCompleteTrip({ tripId: slug, setLoader: false }))
          }
          TripDateSelector={TripDateSelectorMain}
        />
        {tab === 'planner' ? (
          <Grid container className={classes.container}>
            <MapProvider>
              <Grid item className={classes.plannerContainer} id="planner">
                {showPoiDetailsPage ? <CityPage /> : null}
                <div
                  style={
                    showPoiDetailsPage
                      ? {
                          maxHeight: 0,
                          overflow: 'hidden',
                        }
                      : {}
                  }>
                  <Box>
                    <Grid item xs={12} className={classes.spacing}>
                      <CoverImage />
                    </Grid>
                    <Grid
                      container
                      alignItems="center"
                      flex-direction="column"
                      className={classes.spacingTripTitle}>
                      <Box className={classes.buttonWrapper}>
                        <div className={classes.buttonLeft}>
                          <TravelRestrictions
                            CustomButton={CustomTravelRestrictionButton}
                          />
                        </div>
                        <div className={classes.buttonsRight}>
                          <ExportPDFButton
                            style={{ flex: 1 }}
                            handleExport={toggleExportPDFModal}
                            pdfLoading={pdfLoading}
                          />
                          {showImportBookings && <ImportFiles />}
                        </div>
                      </Box>
                    </Grid>
                    <div className={classes.secondarySpacing}>
                      <Grid className={classes.divider}>
                        <Divider variant="middle" />
                      </Grid>
                      <TripItems
                        slug={slug}
                        tripName={trip?.title || ''}
                        handleAddLocation={handleAddLocation}
                        openMapPopup={openMapPopup}
                        setOpenMapPopup={setOpenMapPopup}
                        citySuggestion={citySuggestionRef}
                        loadingIdx={gettingCity}
                      />
                    </div>
                  </Box>
                </div>
              </Grid>
              <Grid
                item
                className={`${classes.mapContainer} ${classList.mapContainer}`}>
                <Map
                  mapPins={[...(trip?.mapPins || [])]}
                  tripId={slug}
                  triggerAddActivity={triggerAddActivity}
                  setTriggerAddActivity={setTriggerAddActivity}
                  openMapPopup={openMapPopup}
                  setOpenMapPopup={setOpenMapPopup}
                  setUpdateItineraryState={setUpdateItineraryState}
                />
                {trip?.displayTimezoneFixPopup && (
                  <div
                    style={{
                      position: 'fixed',
                      left: '48.5%',
                      bottom: '20px',
                    }}>
                    <TimezoneFixPopup tripId={slug} />
                  </div>
                )}
              </Grid>
            </MapProvider>
          </Grid>
        ) : tab === 'notes' ? (
          <Notes tripID={slug} tripNotes={trip?.notes} />
        ) : tab === 'files' ? (
          <FilesTab trip={trip} />
        ) : (
          <Grid container className={classes.container}>
            <MapProvider>
              <Grid
                item
                className={`${classes.plannerContainer} ${classes.exploreContainer}`}
                id="planner">
                {showPoiDetailsPage ? <CityPage /> : null}
                <div
                  style={
                    showPoiDetailsPage
                      ? {
                          maxHeight: 0,
                          overflow: 'hidden',
                        }
                      : {}
                  }>
                  <div className={classes.spacing}>
                    <ExploreV1 />
                  </div>
                </div>
              </Grid>
              <Grid
                item
                id="map-container"
                className={`${classes.mapContainer} ${classList.mapContainer}`}>
                <Map
                  mapPins={[...(trip?.mapPins || [])]}
                  tripId={slug}
                  triggerAddActivity={triggerAddActivity}
                  setTriggerAddActivity={setTriggerAddActivity}
                  openMapPopup={openMapPopup}
                  setOpenMapPopup={setOpenMapPopup}
                  setUpdateItineraryState={setUpdateItineraryState}
                />
                {trip?.displayTimezoneFixPopup && (
                  <div
                    style={{
                      position: 'fixed',
                      left: '48.5%',
                      bottom: '20px',
                    }}>
                    <TimezoneFixPopup tripId={slug} />
                  </div>
                )}
              </Grid>
            </MapProvider>
          </Grid>
        )}
      </ItineraryDndProvider>
      <MessengerBlade
        setOpenMessenger={setOpenMessenger}
        openMessenger={openMessenger}
        chatId={slug}
        tripName={trip?.title}
        tripUsers={tripUsers}
      />
      <AutoPilot />
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={6000}
        open={errorSnackbarOpened}
        onClose={() => setErrorSnackbarOpened(false)}
        message="Error uploading. Cover photos must be under 10MB."
        ContentProps={{
          sx: {
            backgroundColor: '#F45E62',
            color: '#FFFFFF',
            fontWeight: 500,
            fontSize: 16,
            boxShadow: 'none',
            textAlign: 'center',
            justifyContent: 'center',
          },
        }}
      />
    </AppLayout>
  );
}

function TripItems({
  tripName,
  slug,
  handleAddLocation,
  triggerAddActivity,
  setTriggerAddActivity,
  citySuggestion,
  loadingIdx,
}) {
  const dispatch = useDispatch();
  const reduxItems = useSelector((state) => state.Trips.trips[slug].items);
  const locations = useSelector((state) => state.Location.locations);
  const [openFlightBlade, setOpenFlightBlade] = useState(false);
  const { setNewLocation } = useItineraryDnd();
  const sensor = [useMouseSensor, useTouchSensor];
  const classes = useStyles();
  const ref = useRef({
    transportationType: 'flight',
  });
  const fstLocation = reduxItems.findIndex((item) => item.location !== null);
  const itemsWithLoading = JSON.parse(JSON.stringify(reduxItems));
  if (loadingIdx >= 0) itemsWithLoading.splice(loadingIdx, 0, 'loading');

  const removedLocation = Object.values(locations || {}).find(
    (loc) => loc?.tempRemove === true
  );

  const removedLocationId = removedLocation?.id ?? null;
  let locationToRemoveIndex = null;

  itemsWithLoading.forEach((item, index) => {
    if (item.location === removedLocationId) locationToRemoveIndex = index;
  });

  if (locationToRemoveIndex || locationToRemoveIndex === 0) {
    itemsWithLoading.splice(locationToRemoveIndex, 1);
  }

  const handleDragEnd = ({ source, destination, draggableId, type }) => {
    if (
      !destination ||
      (destination.droppableId === source.droppableId &&
        destination.index === source.index)
    ) {
      return;
    }
    let dndAction;
    switch (type) {
      case 'ITEM':
        dndAction = handleDndItems;
        break;
      case 'SECTION':
        dndAction = handleDndSections;
        break;
      case 'ACCOMMODATION':
        dndAction = handleDndHotels;
        break;
      case 'ACTIVITY':
        dndAction = handleDndActivities;
        break;
      default:
        return;
    }
    dispatch(dndAction({ source, destination, draggableId }));
  };
  return (
    <DragDropContext
      sensors={sensor}
      enableDefaultSensors={false}
      onDragEnd={handleDragEnd}>
      <div className={classes.droppableSpacing}>
        <Droppable
          droppableId={slug}
          type="ITEM"
          direction="vertical"
          ignoreContainerClipping>
          {(provided) => (
            <div
              ref={provided.innerRef}
              className={classes.locationDroppable}
              {...provided.droppableProps}>
              {itemsWithLoading?.length ? (
                itemsWithLoading.map((item, index) => {
                  if (item === 'loading') {
                    return (
                      <div
                        style={{
                          paddingTop: '5px',
                          display: 'flex',
                          justifyContent: 'center',
                        }}>
                        <CircularProgress
                          size={20}
                          style={{ marginRight: 4 }}
                        />
                      </div>
                    );
                  }
                  return (
                    <Draggable
                      type="ITEM"
                      key={item.location || item.transportation}
                      draggableId={item.location || item.transportation}
                      id={index}
                      index={index}
                      disableInteractiveElementBlocking>
                      {(draggableProvided, draggableSnapshot) => {
                        return (
                          <div
                            ref={draggableProvided.innerRef}
                            className={classes.item}
                            {...draggableProvided.draggableProps}>
                            {item.location ? (
                              <ItinerarySection
                                tripId={slug}
                                locationId={item.location}
                                key={item.location}
                                handleAddLocation={() =>
                                  handleAddLocation(index, setNewLocation)
                                }
                                dragHandleProps={
                                  draggableProvided.dragHandleProps
                                }
                                triggerAddActivity={triggerAddActivity}
                                setTriggerAddActivity={setTriggerAddActivity}
                                isDragging={draggableSnapshot.isDragging}
                                citySuggestion={
                                  index === fstLocation ? citySuggestion : null
                                }
                                index={index}
                              />
                            ) : (
                              <TransportBlock
                                tripId={slug}
                                update
                                transportId={item.transportation}
                                locationId={item.transportation}
                                items={reduxItems}
                                dragHandleProps={
                                  draggableProvided.dragHandleProps
                                }
                                handleAddTransportation={(type) => {
                                  ref.current.transportationType = type;
                                  setOpenFlightBlade(index);
                                }}
                                isDragging={draggableSnapshot.isDragging}
                              />
                            )}
                            {openFlightBlade === index ? (
                              <div style={{ margin: '4% 0' }}>
                                <Transportation
                                  open={openFlightBlade === index}
                                  setOpen={setOpenFlightBlade}
                                  newFlight
                                  items={reduxItems || []}
                                  tripId={slug}
                                  index={index}
                                  type={
                                    ref.current.transportationType ||
                                    reduxItems[index]?.type
                                  }
                                />
                              </div>
                            ) : null}

                            {!(
                              itemsWithLoading[index]?.transportation &&
                              itemsWithLoading[index + 1]?.transportation
                            ) &&
                              (draggableSnapshot.isDragging ? null : (
                                <AddModuleButton
                                  handleAddTransportation={(type) => {
                                    ref.current.transportationType = type;
                                    setOpenFlightBlade(index);

                                    // analytics
                                    const {
                                      FLIGHT_ADD,
                                      OTHER_TRANSPORT_ADD,
                                      BUS_TRAIN_ADD,
                                    } = EVENTS.PLAN_ADD;
                                    const transportEvent =
                                      type === 'flight'
                                        ? FLIGHT_ADD
                                        : type === 'bus'
                                        ? BUS_TRAIN_ADD
                                        : OTHER_TRANSPORT_ADD;

                                    phTrackEvent({
                                      event: transportEvent,
                                    });
                                  }}
                                  handleAddLocation={() => {
                                    handleAddLocation(index, setNewLocation);
                                    phTrackEvent({
                                      event: EVENTS.PLAN_ADD.LOCATION_START,
                                    });
                                  }}
                                  type={
                                    itemsWithLoading[index]?.transportation
                                      ? 'transportation'
                                      : 'location'
                                  }
                                />
                              ))}
                            {draggableProvided.placeholder}
                          </div>
                        );
                      }}
                    </Draggable>
                  );
                })
              ) : (
                <>
                  {openFlightBlade === 0 ? (
                    <div style={{ margin: '4% 0' }}>
                      <Transportation
                        open={openFlightBlade === 0}
                        setOpen={setOpenFlightBlade}
                        newFlight
                        items={reduxItems || []}
                        tripId={slug}
                        index={0}
                        type={ref.current.transportationType}
                      />
                    </div>
                  ) : tripName !== '' ? (
                    <div className={classes.instructionsBox}>
                      <Typography className={classes.instruction}>
                        You&apos;re one step closer! Get started by adding a
                        flight or destination with the buttons below.
                      </Typography>
                    </div>
                  ) : (
                    <div className={classes.instructionsBox}>
                      <Typography className={classes.instruction}>
                        Time to Personalize: Enter your trip name and choose a
                        cover photo.
                      </Typography>
                    </div>
                  )}
                  <AddModuleButton
                    needsInteraction={openFlightBlade === 0}
                    handleAddTransportation={(type) => {
                      ref.current.transportationType = type;
                      setOpenFlightBlade(0);
                    }}
                    handleAddLocation={() =>
                      handleAddLocation(0, setNewLocation)
                    }
                  />
                </>
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </DragDropContext>
  );
}

export default Itinerary;
