import { Route, Navigate } from 'react-router-dom';
import SentryRoutes from './SentryRoutes';
import VerifyEmail from '../components/pages/auth/VerifyEmail';

function VerifyRoutes() {
  return (
    <SentryRoutes>
      <Route exact path="/verifyemail" element={<VerifyEmail />} />
      <Route path="*" element={<Navigate to="/verifyemail" replace />} />
    </SentryRoutes>
  );
}

export default VerifyRoutes;
