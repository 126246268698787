import {
  collection,
  onSnapshot,
  query,
  where,
  getDoc,
  doc,
  setDoc,
  orderBy,
  addDoc,
} from 'firebase/firestore';
import { firestore } from './FirebaseIndex';
import { getSharedTrips, getUserName } from '../graphql/api';

const CREATE_CHAT = async (chatId) => {
  const docId = chatId.split('_');
  const id = docId[0];
  const userId = docId[1];
  // Computing the way name would be written
  const user1 = await getUserName(id);
  const user2 = await getUserName(userId);
  let chatName = [user1.firstName, user2.firstName];
  if (docId.indexOf(id) === 1) chatName = chatName.reverse();
  chatName = chatName.join('_');

  const members = [id, userId];
  const newChat = {
    chatName,
    createdOn: new Date(),
    members,
  };
  return new Promise(() => {
    setDoc(doc(firestore, 'chats', chatId), newChat);
  });
};

const GET_CHATS = (user, setPersonalChats, setTripChats) => {
  const chatRef = collection(firestore, 'chats');
  onSnapshot(
    query(chatRef, where('members', 'array-contains', user)),
    (querySnapshot) => {
      const chats = [];
      querySnapshot.forEach((chat) => {
        const data = chat.data();
        data.id = chat.id;
        chats.push(data);
      });
      setPersonalChats(chats);
    }
  );

  getSharedTrips(user).then((resp) => {
    const tripChats = [];
    resp.data.getSharedTrips.map((trip) => {
      return tripChats.push({
        chatName: trip.title,
        id: trip.id,
        coverImage: trip.coverImage,
        members: [...([trip.owner] || []), ...(trip.sharedUsers || [])],
      });
    });
    setTripChats(tripChats);
  });
};

const ADD_MESSAGE = async (message, chatId, isTrip, checked, setChecked) => {
  const chatRef = doc(firestore, 'chats', chatId);
  // Adding a new document to the chats document in case the chat details don't already exists.
  if (!isTrip && !checked) {
    const check = await getDoc(chatRef);
    if (!check.exists) {
      await CREATE_CHAT(chatId);
    }
    setChecked(true);
  }

  return new Promise((resolve, reject) => {
    addDoc(collection(doc(firestore, 'message', chatId), 'messages'), message)
      .then(() => resolve(message))
      .catch((err) => {
        reject(err);
      });
  });
};

const GET_MESSAGES = (chatId, setMessages) => {
  onSnapshot(
    query(
      collection(doc(firestore, 'message', chatId), 'messages'),
      orderBy('sentAt')
    ),
    (querySnapshot) => {
      const allMessages = [];
      querySnapshot.forEach((messageDoc) => {
        if (messageDoc) allMessages.push(messageDoc.data());
      });
      setMessages(allMessages);
    }
  );
};

export default {
  GET_CHATS,
  ADD_MESSAGE,
  GET_MESSAGES,
  CREATE_CHAT,
};
