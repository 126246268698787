import { Grid, Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import TripCard from './Card/TripCard/index';
import CreateTripCard from './Card/CreateTripCard/index';

const useStyles = makeStyles(() => ({
  grid: {
    overflowY: 'auto',
    height: 'inherit',
    paddingBottom: '12px',
    paddingRight: '10px',
  },
  noTripsText: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 16,
  },
}));

/**
 *
 * @param {array} trips list of trips to be displayed.
 * @param {ReactComponent} defaultMessage component to be displayed if no trips are in the list.
 *
 * @returns {ReactComponent} a styled container displaying the list of trips.
 */

function TripsContainer({
  trips,
  alignFirstCard = false,
  defaultMessage,
  horizontalFit = 3,
  tripType,
  deleteTripCallback,
  showCreateTripCard = false,
  handleCreateTrip = () => {},
  isProfile = false,
}) {
  const classes = useStyles();
  const navigate = useNavigate();

  const tripCards = [...(trips || [])];
  if (showCreateTripCard) {
    tripCards.push({ type: 'createTripCard' });
  }
  return (
    <Box
      sx={{
        marginTop: '24px',
        marginBottom: '24px',
        width: 'calc(100% + 10px)',
      }}>
      <Grid
        container
        spacing={3}
        className={classes.grid}
        justifyContent="flex-start"
        alignItems="center">
        {tripCards.length !== 0 ? (
          tripCards?.map((trip) => (
            <Grid
              item
              key={trip.id}
              xs={12}
              sm={6}
              md={horizontalFit}
              xl={3}
              // style={{
              //   ...(alignFirstCard && idx === 0 ? { paddingLeft: '8px' } : {}),
              // }}
            >
              {trip.type === 'createTripCard' ? (
                <CreateTripCard onClick={handleCreateTrip} />
              ) : (
                <TripCard
                  {...trip}
                  onClick={() => navigate(`/trips/${trip?.id}/planner`)}
                  short={tripType === 'past'}
                  tripType={tripType}
                  deleteTripCallback={deleteTripCallback}
                  isProfile={isProfile}
                />
              )}
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography
              style={{
                marginTop: alignFirstCard ? '0px' : '8.6vh',
              }}
              className={classes.noTripsText}
              variant="h3Sub"
              fontWeight="500"
              color="text.light">
              {defaultMessage}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default TripsContainer;
