import { PictureAsPdfOutlined } from '@mui/icons-material';
import { CircularProgress, Fade } from '@mui/material';
import { SecondaryOutlinedButton } from '../index';
import classList from '../../../classList';

function ExportPDFButton({ handleExport, pdfLoading }) {
  return (
    <SecondaryOutlinedButton
      sx={{ minWidth: 146, width: { xs: '100%', md: 'auto' } }} // hot fix for cdl
      onClick={handleExport}
      className={classList.exportPdfButton}
      startIcon={
        pdfLoading ? (
          <Fade in={pdfLoading} unmountOnExit>
            <CircularProgress
              size={16}
              style={{ marginRight: 4, marginLeft: 4 }}
            />
          </Fade>
        ) : (
          <PictureAsPdfOutlined sx={{ color: '#1D1D1D' }} />
        )
      }
      id="export-trip-button">
      Export Trip
    </SecondaryOutlinedButton>
  );
}

export default ExportPDFButton;
