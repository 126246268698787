import { TextField } from '@mui/material';
import { withStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';

export { default as TripTitleInput } from './TripTitleInput';

/*
    https://material-ui.com/api/text-field/
*/
export const InputBasic = withStyles(({ breakpoints }) => ({
  root: {
    backgroundColor: '#F8F8F8',
    border: '0',
    '&:hover': {},
    minWidth: '47.5%',
    [breakpoints.down('md')]: {
      width: '100%',
    },
  },
}))(TextField);

export const InputFriendSearchBar = withStyles(() => ({
  root: {
    backgroundColor: '#F4F4F4',
    borderRadius: 4,
    border: '2px solid transparent',
    '&:focus-within': {
      borderColor: '#ED702E',
    },
  },
}))(TextField);

export const InputAuthForm = styled(TextField)(() => ({
  fontWeight: 'bold',
  '& > .MuiInput-root': {
    backgroundColor: 'red',
  },
  // '.Mui-disabled': {
  //   backgroundColor: '#D9D9D9',
  // },
  '& > .MuiInput-root:hover': {
    boxShadow: '0px 0px 0px 2px #FFEBD9',
    border: '1px solid red',
  },
  '& .MuiOutlinedInput-root.Mui-disabled': {
    backgroundColor: '#F4F4F4',
    border: '1px soldi #D9D9D9',
  },
  // '& .Mui-focused': {
  //   boxShadow: '0px 0px 0px 2px #FFEBD9',
  //   border: '1px solid #FDC798',
  // },
  '& .MuiOutlinedInput-root': {
    '&:hover:not(.Mui-disabled) fieldset': {
      borderColor: '#FFA766',
    },
  },
}));

export const OldInputAuthForm = styled(TextField)(() => ({
  fontWeight: 'bold',
  '& > .MuiInput-root': {
    border: '1px solid #DEDDDD',
    borderRadius: 8,
    padding: '6px 12px',
    disableUnderline: true,
  },
  '& > .MuiInput-root:hover': {
    boxShadow: '0px 0px 0px 2px #FFEBD9',
  },
  '& .Mui-focused': {
    boxShadow: '0px 0px 0px 2px #FFEBD9',
    border: '1px solid #FDC798',
  },
}));

export const InputPeopleSearchBar = withStyles(() => ({
  root: {
    backgroundColor: '#FFF',
    borderRadius: 4,
    border: '1px solid #DEDDDD',
    '&:focus-within': {
      borderColor: '',
    },
  },
}))(TextField);
