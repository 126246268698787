import { Link } from 'react-router-dom';

import { Grid, Avatar, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { MessageButton } from '../atoms/Button';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#F4F4F4',
    borderRadius: '4px',
    padding: '2%',
    marginTop: 24,
  },
  avatar: {
    backgroundColor: '#EA6D64',
    height: 56,
    width: 56,
    fontSize: 20,
  },
  name: {
    fontWeight: 700,
    fontSize: 20,
  },
  location: {
    fontSize: 14,
    color: '#A7A7A7',
  },
}));

function FriendList({ friend }) {
  const classes = useStyles();
  return (
    <Grid container direction="row" xs={12} className={classes.root}>
      <Grid item xs={1}>
        <Avatar className={classes.avatar}>{friend.firstName[0]}</Avatar>
      </Grid>
      <Grid item container xs={9} direction="column" justifyContent="center">
        <Grid item>
          <Typography className={classes.name} variant="h4">
            {`${friend.firstName} ${friend.lastName}`}
          </Typography>
        </Grid>
        {friend.location ? (
          <Grid item>
            <Typography className={classes.location}>
              {friend.location}
            </Typography>
          </Grid>
        ) : null}
      </Grid>
      <Grid
        container
        item
        xs={2}
        direction="column"
        align="center"
        justifyContent="center">
        <MessageButton component={Link} to="/messages">
          Message
        </MessageButton>
      </Grid>
    </Grid>
  );
}

export default FriendList;
