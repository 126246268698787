import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { Typography, Slider, Box, Button } from '@mui/material';
import { styled } from '@mui/styles';
import { useSelector } from 'react-redux';
import { intervalToDuration } from 'date-fns';
import { BasicButton } from '../../atoms/Button/index';

const AirbnbSlider = styled(Slider)(() => ({
  color: '#1877F2',
  height: 3,
  padding: '13px 0',
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#FFFFFF',
    '&:hover': {
      boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
    },
    '& .airbnb-bar': {
      height: 9,
      width: 1,
      backgroundColor: 'red',
      marginLeft: 1,
      marginRight: 1,
    },
  },
  '& .MuiSlider-track': {
    height: 3,
  },
  '& .MuiSlider-rail': {
    color: '#D9D9D9',
    opacity: 1,
    height: 3,
  },
  '& > .MuiSlider-mark': {
    backgroundColor: 'transparent',
  },
}));

const roundOffPrice = (price, roundDown) => {
  const priceRegex = /^(\d+(\.\d{1,2})?|\.\d{1,2})$/;

  if (!price || !priceRegex.test(price)) return null;

  const roundFn = roundDown ? Math.floor : Math.ceil;
  return roundFn(price / 100) * 100;
};

/*
  Function to take a list of price range - frequency distribution list, and splitting it into an array of 30 steps, sensitive to the frequency
*/
function distributeSteps(priceRangesdata = [], maxValue = 10000, minValue = 0) {
  const MAX_STEPS = 30;
  try {
    const data = [
      { ...priceRangesdata[0], key: minValue },
      ...priceRangesdata.slice(1),
    ];
    let totalValue = data.reduce((total, range) => total + range.value, 0);
    data?.sort((a, b) => b.value - a.value);

    const result = [];
    let steps = MAX_STEPS;
    for (let i = 0; i < data.length; i += 1) {
      const p =
        totalValue === 0
          ? 0
          : Math.min(
              20, // Max steps an interval can take is 20
              Math.round(0.7 * steps),
              Math.ceil((data[i].value / totalValue) * steps)
            );
      steps -= p;
      result.push({
        ...data[i],
        steps: p,
      });
      totalValue -= data[i].value;
    }
    result?.sort((a, b) => a.key - b.key);
    result.push({
      key: maxValue,
      value: 0,
      steps: 0,
    });

    // Split an interval equally based on frequency
    const overallSteps = [];
    for (let i = 0; i < result.length - 1; i += 1) {
      const currentRange = result[i];
      const nextRange = result[i + 1];
      const { key: currentKey, steps: currentSteps } = currentRange;
      const { key: nextKey } = nextRange;
      const stepSize = (nextKey - currentKey) / currentSteps;

      for (let j = 0; j < currentSteps; j += 1) {
        overallSteps.push(currentKey + j * stepSize);
      }
    }
    overallSteps.push(result[result.length - 1].key);
    return overallSteps?.map((step) => roundOffPrice(Math.round(step)));
  } catch (err) {
    return Array(MAX_STEPS).fill(0);
  }
}

const getNumberOfNights = (from, to) => {
  if (from && to) {
    return intervalToDuration({
      start: from,
      end: to,
    }).days;
  }
  return 1;
};

function PriceRangeSlider({ handleAction = () => {}, defaultValue = null }) {
  const { slug: tripId } = useParams();
  const { otherDetails, search } = useSelector(
    (state) => state.Bookings[tripId]
  );
  const nightlyStays = getNumberOfNights(search?.date?.from, search?.date?.to);

  const steps = distributeSteps(
    otherDetails?.priceRanges,
    roundOffPrice(otherDetails?.highestTotalRate),
    roundOffPrice(otherDetails?.lowestTotalRate)
  );

  const [value, setValue] = useState(
    defaultValue || [
      roundOffPrice(otherDetails?.lowestTotalRate),
      roundOffPrice(otherDetails?.highestTotalRate),
    ]
  );

  const getStepFromValue = (priceValue) => {
    const range = [0, 0];
    for (let i = 0; i < steps?.length; i += 1) {
      if (steps[i] === priceValue[0]) range[0] = i;
      if (steps[i] === priceValue[1]) range[1] = i;
    }
    return range;
  };

  const handleChange = (event, newValue) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    setValue([steps[newValue[0]], steps[newValue[1]]]);
  };

  const handleMouseUp = () => {
    handleAction({
      filterId: `${value[0]},${value[1]}#Price`,
      action: 'UPDATE',
    });
  };

  const currencyFormatter = new Intl.NumberFormat(undefined, {
    currency: otherDetails?.currencyCode,
    style: 'currency',
    maximumFractionDigits: 0,
  });

  return (
    <Box display="flex" flexDirection="column">
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{ margin: '0 5px' }}>
        <Typography
          pt={1}
          sx={{
            fontFamily: 'Inter',
            fontWeight: '500',
            fontSize: '14px',
            textDecoration: 'underline',
          }}>
          {otherDetails?.currencyCode}
        </Typography>

        <Button
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            backgroundColor: 'transparent',
            textDecoration: 'underline',
            color: '#222222',
            '&:hover': {
              backgroundColor: 'transparent',
              color: '#222222',
              textDecoration: 'underline',
            },
          }}
          onClick={() => {
            const minPrice = roundOffPrice(otherDetails?.lowestTotalRate);
            const maxPrice = roundOffPrice(otherDetails?.highestTotalRate);
            setValue([minPrice, maxPrice]);
            handleAction({
              filterId: `${minPrice},${maxPrice}#Price`,
              action: 'UPDATE',
            });
          }}>
          Reset
        </Button>
      </Box>
      <AirbnbSlider
        getAriaLabel={() => 'Minimum distance shift'}
        value={getStepFromValue(value)}
        onChange={handleChange}
        valueLabelDisplay="auto"
        valueLabelFormat={(stepIndex) =>
          (steps[stepIndex] / nightlyStays).toFixed(0)
        }
        disableSwap
        step={null}
        marks={steps?.map((step, idx) => ({
          label: '',
          value: idx,
        }))}
        min={0} // Set the minimum value
        max={30} // Set the maximum value
        onChangeCommitted={handleMouseUp}
      />
      <Box display="flex" justifyContent="space-between" pt={2}>
        <BasicButton
          disableRipple
          // startIcon="$"
          // id={`recommendations-filter-pill-${subCategoryId}`}
          className="recommendations-filter-pill">
          {currencyFormatter.format(value[0] / nightlyStays)}
        </BasicButton>
        <BasicButton
          disableRipple
          // startIcon="$"
          // id={`recommendations-filter-pill-${subCategoryId}`}
          className="recommendations-filter-pill">
          {currencyFormatter.format(value[1] / nightlyStays)}
        </BasicButton>
      </Box>
    </Box>
  );
}

export default PriceRangeSlider;
