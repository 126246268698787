import React, { useState, useRef, useEffect } from 'react';

import { Grid, InputBase, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  DragIndicatorRounded,
  DeleteOutlineRounded,
  AddRounded,
} from '@mui/icons-material';
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import { PlacesSearchBar } from '../../molecules/SearchBar';
import IconDatePicker from '../../molecules/IconDatePicker';
import { useMapUtils } from '../MapUtils';
import classList from '../../classList';
import { DeleteModal } from '../../molecules/Modal';

const useBlockHeaderStyles = makeStyles(({ palette, breakpoints }) => ({
  root: {
    backgroundColor: 'transparent',
  },
  iconRight: {
    verticalAlign: 'middle',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  titleInput: {
    fontSize: 20,
    fontWeight: 700,
    flex: 1,
    paddingRight: '1em',
    width: '100%',
  },
  highlight: {
    color: `${palette.primary.main}`,
  },
  hovered: {
    backgroundColor: '#F4F4F4',
  },
  actionIcon: {
    fontSize: '1.2rem',
    color: 'rgba(138, 138, 138, 1)',
    marginLeft: 4,
    '&:hover': {
      cursor: 'pointer',
      color: '#474747',
    },
  },
  activeState: {
    backgroundColor: '#F4F4F4',
    border: '1px solid #DEDDDD',
    borderRadius: '8px',
    boxSizing: 'border-box',
  },
  actionContainer: {
    position: 'absolute',
    margin: 'auto',
    top: '50%',
    bottom: '50%',
    left: 'calc(-3.6rem - 15px)',
    minWidth: 'calc(3.6rem + 15px)',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  inputContainer: {
    display: 'flex',
    flex: 1,
    paddingLeft: '1%',
    [breakpoints.down('sm')]: {
      marginLeft: 0,
      paddingLeft: 0,
    },
    border: '2px solid transparent',
    overflow: 'hidden',
  },
  isDragging: {
    backgroundColor: '#F4F4F4',
  },
  datePickerContainer: {
    display: 'flex',
    flexShrink: 1,
  },
  actionIconDragSpan: {
    marginLeft: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  actionIconDrag: {
    fontSize: '1.2rem',
    color: 'rgba(138, 138, 138, 1)',
    '&:hover': {
      color: '#474747',
    },
  },
  customIcon: {
    height: '30px',
  },
  TodoSectionHeadingDiv: {
    position: 'relative',
    marginLeft: 'calc(3.6rem - 18px)',
    [breakpoints.up('sm')]: {
      marginLeft: 'calc(3.6rem + 15px)',
    },
  },
  ItineraryHeadingDiv: {
    position: 'relative',
    marginLeft: 'calc(3.6rem - 18px)',
    [breakpoints.up('sm')]: {
      marginLeft: 'calc(3.6rem + 15px)',
    },
  },
}));

/**
 * Block Header
 *
 * Component to emulate the title styles for each component header
 * in the itinerary page.
 *
 * @param {string} defaultTitle - default value for the input field
 * @param {function} updateTitle - callback when user blurs the input
 * @param {function} updateDate - callback when user sets the date
 * @param {ref} inputRef - reference to the InputBase component to style/focus it programatically.
 * @param {object} inputProps - custom props to the InputBase component
 * @param {object} dragHandleProps - provided.dragHandleProps from react-beautiful-dnd to enable dnd
 * @param {object} iconDatePickerProps - props for the iconDatePicker component
 * @param {bool} isCollapsed to check for collapsed states for location
 * @param {function} setCollapsed - function to set collapsed state
 * @param {bool} isDragging to check for dragging states for section
 * @param {bool} location - Check if the component is a location, to add the minimize/maximise icon.
 * @param {function} handleAdd - function triggered when "+" is clicked
 * @param {function} handleDelete - function triggered when delete icon is clicked
 * @param {object} mapPin - pin data for the location
 * @param {bool} hideActions - bool value to conditionally render the action icons
 */
function BlockHeader({
  defaultTitle,
  updateTitle,
  updateDate,
  inputRef,
  inputProps,
  dragHandleProps,
  iconDatePickerProps,
  isCollapsed,
  setCollapsed,
  isDragging,
  isSectionHighlight,
  location,
  handleAdd,
  handleDelete,
  mapPin,
  hideActions = false,
  classIdentifier = classList.blockHeader,
  isHighlight = false,
}) {
  const classes = useBlockHeaderStyles();

  const [title, setTitle] = useState(defaultTitle);

  // state to keep track of whether the input block has been hovered
  const [isHovered, setHovered] = useState(false);

  // state to keep track of whethter the input block has been selected
  const [isActive, setActive] = useState(false);

  const { defaultDate, ...restIconDatePickerProps } = iconDatePickerProps;

  // state to make date reactive on the component level
  const [date, setDate] = useState({
    from: defaultDate?.from,
    to: defaultDate?.to,
  });

  // ref to keep track of the blur events on the places search bar.
  const ref = useRef();
  const placeRef = useRef({
    id: null,
    title: null,
  });

  const [mobileSingleClick, setMobileSingleClick] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  // for checking single and double clicks on mobile
  let wait = false;
  let timer;

  // to handle pin focus if
  const { focusPin } = useMapUtils();
  useEffect(() => {
    const keyPressHandler = (event) => {
      if (
        ['Backspace', 'Delete'].includes(event.key) &&
        isActive &&
        title === ''
      ) {
        handleDelete(location);
      }
    };

    document.addEventListener('keydown', keyPressHandler);
    return () => document.removeEventListener('keydown', keyPressHandler);
  }, []);

  useEffect(() => {
    if (title === '' && inputProps.name === 'locationName') {
      // Indicates a new location
      inputRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
      inputRef.current?.focus();
    }
  }, []);

  // function to check single or double click on mobile screens
  const todoClickedMobile = () => {
    if (wait === true) {
      wait = false;
      clearTimeout(timer);
      return 'double';
    }
    wait = true;
    timer = setTimeout(() => {
      wait = false;
      return 'single';
    }, 300);

    return 'single';
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className={classes.root}
      onMouseLeave={() => {
        if (isMobile) {
          setHovered(false);
          setMobileSingleClick(false);
        } else if (isHovered && !isActive) {
          setHovered(false);
        }
      }}>
      {isDragging || isSectionHighlight ? null : (
        <div
          className={`${
            inputProps.name === 'locationName'
              ? classes.ItineraryHeadingDiv
              : classes.TodoSectionHeadingDiv
          }`}>
          <Grid
            item
            className={`${classes.actionContainer} ${classList.itemActionContainer}`}>
            {hideActions ? null : (
              <>
                {isHovered && !isMobile && !(title === '' && location) ? (
                  <>
                    <DeleteOutlineRounded
                      className={classes.actionIcon}
                      onClick={() => setDeleteModalOpen(true)}
                    />
                    <AddRounded
                      className={classes.actionIcon}
                      onClick={() => handleAdd()}
                    />
                    {/* DragHandleProps seems to require a html element hence the span element */}
                  </>
                ) : null}
                <span
                  className={classes.actionIconDragSpan}
                  {...dragHandleProps}>
                  {isHovered && !(title === '' && location) ? (
                    <DragIndicatorRounded className={classes.actionIconDrag} />
                  ) : null}
                </span>
              </>
            )}
          </Grid>
        </div>
      )}

      <Grid
        // {...dragHandleProps}
        item
        container
        alignItems="center"
        className={`${isHovered ? classes.hovered : ''} ${
          isActive ? classes.activeState : ''
        } ${classes.inputContainer} ${
          isDragging ? classes.isDragging : ''
        } ${classIdentifier} ${classList.item}`}>
        <Grid item style={{ flex: 1 }}>
          {inputProps.name === 'locationName' ? (
            <div
              ref={ref}
              onBlur={() => {
                setActive(false);
                if (!isMobile) setHovered(false);
                if (title !== '') {
                  updateTitle(
                    placeRef.current.title || title,
                    placeRef.current.id
                  );
                  placeRef.current.id = null;
                } else {
                  handleDelete(location);
                }
              }}
              onMouseEnter={() => setHovered(true)}
              // onFocus={() => {
              //   console.log('focused');
              //   setActive(true);
              //   if (mapPin) focusPin(mapPin);
              // }}
              // onClick={() => {
              //   console.log('clicked');
              //   setActive(true);
              //   if (mapPin) focusPin(mapPin);
              // }}
              onClick={() => {
                if (!isMobile) {
                  setActive(true);
                  if (mapPin) focusPin(mapPin);
                } else {
                  const clickType = todoClickedMobile() || 'single';
                  if (clickType === 'single') {
                    setMobileSingleClick(true);
                  } else if (clickType === 'double') {
                    setMobileSingleClick(false);
                    setActive(true);
                    if (mapPin) focusPin(mapPin);
                  }
                }
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  inputRef.current?.blur();
                }
              }}
              style={{ display: 'flex', alignItems: 'center' }}>
              {isCollapsed ? (
                <ArrowRightRoundedIcon
                  style={{
                    transform: 'rotate(90deg)',
                    fontSize: '32px',
                    marginLeft: '-10px',
                  }}
                  onClick={(e) => {
                    setCollapsed(false);
                    e.stopPropagation();
                  }}
                  className={`${classes.iconRight} ${classes.customIcon}`}
                />
              ) : (
                <ArrowRightRoundedIcon
                  style={{ fontSize: '32px', marginLeft: '-10px' }}
                  onClick={(e) => {
                    setCollapsed(true);
                    e.stopPropagation();
                  }}
                  className={`${classes.iconRight}`}
                />
              )}
              <PlacesSearchBar
                fullWidth
                id="location"
                className={classes.titleInput}
                value={title}
                onChange={(newTitle) => {
                  placeRef.current.title = null;
                  setTitle(newTitle);
                }}
                isHovered={isHovered}
                isActive={isActive}
                tripLocation
                placeholder={inputProps.placeholder}
                handleSelect={(option) => {
                  placeRef.current.title =
                    option?.structured_formatting.main_text;
                  placeRef.current.id = option?.place_id;
                  inputRef?.current?.blur();
                }}
                inputRef={inputRef}
              />
            </div>
          ) : (
            <InputBase
              inputRef={inputRef}
              className={`${classes.titleInput} ${
                isHighlight ? classes.highlight : null
              } `}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              onMouseEnter={() => setHovered(true)}
              onFocus={() => {
                setActive(true);
                if (mapPin) focusPin(mapPin);
              }}
              onBlur={() => {
                setActive(false);
                if (!isMobile) setHovered(false);
                updateTitle(title);
                if (inputProps.name === 'placeholder') setTitle('');
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  inputRef.current?.blur();
                }
              }}
              {...inputProps}
            />
          )}
        </Grid>
        {title === '' && location ? null : (
          <Grid
            item
            className={classes.datePickerContainer}
            onClick={(e) => e.stopPropagation()}>
            {isMobile || date.from || date.to ? (
              <IconDatePicker
                defaultDate={date}
                onDateUpdate={setDate}
                onCalanderClose={() => {
                  updateDate(date);
                }}
                {...restIconDatePickerProps}
              />
            ) : (
              isHovered && (
                <IconDatePicker
                  defaultDate={date}
                  onDateUpdate={setDate}
                  onCalanderClose={() => {
                    updateDate(date);
                  }}
                  {...restIconDatePickerProps}
                />
              )
            )}
          </Grid>
        )}
      </Grid>
      {mobileSingleClick && (
        <DeleteOutlineRounded
          className={classes.actionIcon}
          onClick={() => setDeleteModalOpen(true)}
        />
      )}
      <DeleteModal
        executeFunc={() => handleDelete(location)}
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        emoji="😮"
        alertText="Are you sure?"
        descriptionText="Deleting this item will delete all the information nested under it."
        confirmText="Yes, delete it!"
        cancelText="No, keep it."
      />
    </Grid>
  );
}

export default BlockHeader;
