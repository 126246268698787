import React, { useContext } from 'react';

import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { firebaseAuth } from '../../provider/AuthProvider';
import { ButtonWithIcon } from '../atoms/Button';
import { GoogleLogo, FacebookLogo } from '../atoms/Icon';

const useStyles = makeStyles(() => ({
  byLiner: {
    color: '#AAAAAA',
    fontSize: '12px',
    lineHeight: '200%',
    letterSpacing: '0.005em',
  },
  grayColor: {
    color: '#757575',
  },
}));

function SocialMediaAuth({ showByLiner }) {
  const classes = useStyles();
  const { handleFacebookSignin, handleGoogleSignin } = useContext(firebaseAuth);
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      {/* Social Login */}
      <ButtonWithIcon
        id="GoogleLoginButton"
        onClick={handleGoogleSignin}
        style={{
          backgroundColor: '#FFFFFF',
          borderColor: '#D9D9D9',
          width: '100%',
          height: '3rem',
        }}
        variant="contained">
        <GoogleLogo />
        <Box ml="1.3rem" className={classes.grayColor}>
          Continue with Google
        </Box>
      </ButtonWithIcon>
      {showByLiner === 'true' ? (
        <Typography paragraph className={classes.byLiner}>
          Plan trips, see friends trip itineraries and get recommendation
        </Typography>
      ) : (
        <Typography paragraph className={classes.byLiner} />
      )}
      <ButtonWithIcon
        id="FacebookLoginButton"
        onClick={handleFacebookSignin}
        style={{
          backgroundColor: '#1877F2',
          width: '100%',
          height: '3rem',
        }}
        variant="contained">
        <FacebookLogo />
        <Box ml="1rem">Continue with Facebook</Box>
      </ButtonWithIcon>
    </Box>
  );
}

export default SocialMediaAuth;
