import { useDispatch } from 'react-redux';
import { createSection } from '../../../../redux/slices/sharedThunks';
import actions from '../../../../redux/actions';

import { ItineraryActionButton } from '../../../atoms/Button/index';
import classList from '../../../classList';
import { EVENTS, phTrackEvent } from '../../../../analytics';

function AddTodo({ column, locationId }) {
  const dispatch = useDispatch();

  const handleClick = async () => {
    const newColumnId = column.id;
    if (newColumnId === '') {
      const newActivityId = `local-todo-${Date.now()}`;
      const newActivity = {
        id: newActivityId,
        title: '',
      };
      dispatch(
        createSection({
          variables: {
            locationId,
            name: '',
            startDate: null,
            endDate: null,
            todos: [],
            index: 0,
          },
          index: 0,
          locationId,
          shouldAppendActivity: true,
          localActivity: newActivity,
        })
      );
      phTrackEvent({
        event: EVENTS.PLAN_HEADING.ADD_START,
      });
      dispatch(
        actions.Activity.setNewActivity({
          activityId: newActivityId,
        })
      );
    } else {
      const newTodoId = `local-todo-${Date.now()}`;
      const newActivity = {
        id: newTodoId,
        title: '',
      };

      dispatch(
        actions.Activity.createLocalActivity({
          localId: newTodoId,
          todo: newActivity,
        })
      );
      dispatch(
        actions.Section.addLocalActivityToSection({
          sectionId: column.id,
          activityId: newTodoId,
          index: column.todos.length,
        })
      );

      // sets this to ensure the last added input is highlighted.
      dispatch(
        actions.Activity.setNewActivity({
          activityId: newTodoId,
        })
      );
    }
  };

  return (
    <ItineraryActionButton
      disableRipple
      onClick={handleClick}
      style={{ marginTop: 4 }}
      className={classList.addTodo}>
      + Activity
    </ItineraryActionButton>
  );
}

export default AddTodo;
