import React, { useRef, useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import {
  IconButton,
  Typography,
  TextField,
  InputAdornment,
  Fade,
  CircularProgress,
  Box,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import {
  CloseRounded,
  TripOriginRounded,
  LocationOnRounded,
  AddRounded,
  DeleteOutlineRounded,
  DragIndicatorRounded,
  ErrorRounded,
} from '@mui/icons-material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { PlacesSearchBar } from '../SearchBar';
import actions from '../../../redux/actions';
import { useMapUtils } from '../../organisms/MapUtils';
import fetchDirections from './directionsApi';
import { TRAVEL_MODES } from '../../../utils';

const useStyles = makeStyles(() => ({
  containerModal: {
    backgroundColor: '#FFF',
    maxHeight: 385,
    width: 300,
    padding: 24,
    paddingLeft: 16,
    borderRadius: 4,
    boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.15)',
  },
  containerHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 8,
  },
  containerBody: {
    marginTop: 20,
    overflowY: 'auto',
    maxHeight: 300,
  },
  input: {
    cursor: 'pointer',
  },
  icon: {
    height: 16,
    width: 16,
  },
  addButtonWrapper: {
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#4E4E4E',
    cursor: 'pointer',
    paddingLeft: '8px',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  addButton: {
    backgroundColor: '#D9D9D9',
    borderRadius: '50%',
    height: 14,
    width: 14,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 4,
    marginRight: 4,
  },
  stopRow: {
    marginBottom: 8,
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  travelModeButton: {
    height: 44,
    display: 'flex',
    borderWidth: '1px',
    borderStyle: 'solid',
    backgroundColor: 'rgba(255,255,255,0.3)',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: '#FFF1E0',
    },
  },
}));

const StopTextField = styled(TextField)(() => ({
  fontWeight: 'bold',
  backgroundColor: 'rgba(255,255,255,0.3)',
  '& > .MuiInput-root': {
    border: '1px solid #D9D9D9',
    borderRadius: 4,
    padding: '4px 8px 4px 12px',
    disableUnderline: true,
    cursor: 'pointer',
    '&:hover': {
      border: '1px solid #FFA766',
      '& > .MuiSvgIcon-root': {
        fill: '#FFEBD9',
      },
    },
  },
  '& .Mui-focused': {
    cursor: 'auto',
    border: '1px solid #FFA766',
  },
}));

function StopInput({
  id,
  defaultValue = '',
  handleUpdateStop = () => {},
  handleDeleteStop = () => {},
  placeholder = 'Search destination',
  stopType = null,
  shouldAutoFocus = true,
  keepStop = true,
}) {
  const [value, setValue] = useState(defaultValue);
  const [focused, setFocused] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [isFreeText, setIsFreeText] = useState(false);
  const classes = useStyles();
  const { getPlaceDetails } = useMapUtils();

  useEffect(() => {
    if (defaultValue && defaultValue !== value) setValue(defaultValue);
  }, [defaultValue]);

  const handleLocationSelect = async (locationResult) => {
    const placeId = locationResult?.place_id;
    const details = await getPlaceDetails(placeId, 'DIRECTIONS_PIN', true);

    handleUpdateStop(id, {
      id,
      type: 'DIRECTIONS_SEARCH',
      title: details?.title,
      long: details?.long,
      lat: details?.lat,
    });
  };

  const inputRef = useRef();
  return (
    <PlacesSearchBar
      fullWidth
      inputRef={inputRef}
      id="directions-search"
      onChange={(searchResult) => {
        setValue(searchResult);
        setIsFreeText(true);
      }}
      tripLocation={false}
      handleSelect={(option) => {
        handleLocationSelect(option);
        inputRef?.current?.blur();
        setIsFreeText(false);
      }}
      popperPlacement="bottom"
      CustomSearchBar={StopTextField}
      value={value}
      controlledValue={value}
      searchBarProps={{
        variant: 'standard',
        fullWidth: true,
        inputProps: {
          style: {
            padding: '2px 0px',
            fontSize: '0.8rem',
          },
        },
        InputProps: {
          placeholder,
          autoFocus: shouldAutoFocus,
          disableUnderline: true,
          classes: { input: classes.input },
          startAdornment: (
            <InputAdornment position="start">
              {focused ? (
                <LocationOnRounded
                  className={classes.icon}
                  sx={{
                    fill: '#ED702E',
                  }}
                />
              ) : (
                <TripOriginRounded
                  className={classes.icon}
                  sx={{
                    fill: '#8A8A8A',
                  }}
                />
              )}
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {focused ? (
                <IconButton
                  onClick={() => {
                    setValue('');
                    setIsFreeText(true);
                  }}
                  sx={{ height: 24, width: 24 }}>
                  <CloseRounded
                    className={classes.icon}
                    sx={{
                      fill: '#8A8A8A',
                    }}
                  />
                </IconButton>
              ) : hovered && !keepStop ? (
                <IconButton
                  onClick={handleDeleteStop}
                  sx={{ height: 24, width: 24 }}>
                  <DeleteOutlineRounded
                    className={classes.icon}
                    sx={{
                      fill: '#8A8A8A',
                    }}
                  />
                </IconButton>
              ) : isFreeText && !focused && !stopType ? (
                <ErrorRounded sx={{ height: 20, width: 20, fill: '#E5493D' }} />
              ) : null}
            </InputAdornment>
          ),
        },

        onKeyDown: (e) => {
          if (e.key === 'Enter') {
            e.target.blur();
          }
        },
        onFocus: () => setFocused(true),
        onBlur: () => {
          setFocused(false);
          if (value === '') {
            if (!keepStop) {
              handleDeleteStop();
            } else {
              setValue(defaultValue);
            }
          }
        },
        onMouseOver: () => {
          setHovered(true);
        },
        onMouseLeave: () => {
          setHovered(false);
        },
      }}
    />
  );
}

function DirectionsModal({ recenterMap = () => {} }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [isLoading, setLoading] = useState(false);

  const stops = useSelector((state) => state.Map.directionsPins);
  const directionsError = useSelector(
    (state) => state.Map.directionsRoutes.error
  );
  const travelMode = useSelector(
    (state) => state.Map.directionsRoutes.travelMode
  );
  const setStops = (newStops) => {
    dispatch(actions.Map.setDirectionsPins(newStops));
  };

  // Keep track of current directions stops list
  const [currStops, setCurrStops] = useState(
    (stops || [])?.filter((s) => s.type) || []
  );
  const [currTravelMode, setCurrTravelMode] = useState(travelMode);

  const addDefaultStop = () => {
    const newId = (stops.length || 0) + 1;
    setStops([
      ...(stops || []),
      {
        id: `stop_${newId}_${Date.now()}`,
      },
    ]);
  };

  const getDirections = async (
    routeStops = stops,
    newTravelMode = travelMode
  ) => {
    setLoading(true);
    const allStops = routeStops?.filter((stop) => stop.type) || [];
    if (allStops.length > 1) {
      const routes = await fetchDirections(allStops, newTravelMode);
      if (routes?.error) {
        dispatch(actions.Map.setRoutesError(routes.error));
      } else {
        dispatch(actions.Map.setRoutes(routes));
      }
    } else {
      dispatch(actions.Map.setRoutes({}));
    }
    setLoading(false);
  };

  const isSameArray = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
      return false;
    }
    for (let i = 0; i < arr2.length; i += 1) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }
    return true;
  };

  useEffect(async () => {
    const newStops = stops.filter((stop) => stop.type);
    if (
      (!isSameArray(currStops, newStops) || travelMode !== currTravelMode) &&
      !isLoading
    ) {
      recenterMap((stops || []).filter((stop) => stop.type));
      setCurrStops(newStops);
      setCurrTravelMode(travelMode);
      await getDirections();
    }
  }, [stops, travelMode]);

  // handler to focus routes on map on first load
  useEffect(() => {
    const prevStops = (stops || []).filter((stop) => stop.type);
    if (prevStops?.length > 0) recenterMap(prevStops);
  }, []);

  const updateStop = async (id, stopDetails) => {
    const newId = (stops.length || 0) + 1;
    const tmpStops = [...stops];
    const stopIndex = stops.findIndex((stop) => stop.id === id);

    if (stopIndex === -1) {
      tmpStops.splice(stops.length, 0, {
        id: `stop_${newId}_${Date.now()}`,
        ...stopDetails,
      });
    } else {
      tmpStops.splice(stopIndex, 1, { ...stops[stopIndex], ...stopDetails });
    }

    setStops(tmpStops);
  };

  const handleTravelModeChange = (newTravelMode) => {
    if (newTravelMode && newTravelMode !== travelMode)
      dispatch(actions.Map.changeTravelMode(newTravelMode));
  };

  const removeStop = async (id) => {
    if (stops?.length < 3) return;
    const tmpStops = [...stops].filter((stop) => stop.id !== id);
    setStops(tmpStops);
  };

  const isStopsValid = stops?.filter((s) => !s?.type)?.length === 0;

  const handleDrag = ({ source, destination }) => {
    if (!destination || !source) return;

    const tmpStops = [...stops];
    const [reorderedStop] = tmpStops.splice(source.index, 1);
    tmpStops.splice(destination.index, 0, reorderedStop);

    setStops(tmpStops);
  };

  return (
    <div className={classes.containerModal} id="distances-modal">
      <div className={classes.containerHeader}>
        <Typography
          sx={{
            color: '#222',
            fontWeight: 500,
            fontSize: '1rem',
            marginRight: isLoading ? 0 : 'auto',
          }}>
          Routes
        </Typography>
        <Fade
          in={isLoading}
          style={{
            transitionDelay: isLoading ? '100ms' : '0ms',
          }}
          unmountOnExit>
          <CircularProgress
            size={20}
            style={{ marginLeft: 8, marginRight: 'auto' }}
          />
        </Fade>
        <IconButton
          size="small"
          onClick={() => dispatch(actions.Map.setDirectionsView({}))}
          sx={{ color: '#222', height: 24, width: 24, padding: '2px' }}>
          <CloseRounded height={16} width={16} />
        </IconButton>
      </div>
      <DragDropContext onDragEnd={handleDrag}>
        <div className={classes.containerBody}>
          <Droppable droppableId="Stops">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {stops?.map((stop, idx) => (
                  <div key={stop.id}>
                    <Draggable key={stop.id} draggableId={stop.id} index={idx}>
                      {(draggableProvided, draggableSnapshot) => (
                        <div
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                          className={classes.stopRow}>
                          <span
                            key={stop.id}
                            {...draggableProvided.dragHandleProps}
                            style={{
                              display: 'flex',
                            }}>
                            <DragIndicatorRounded
                              sx={{
                                height: 20,
                                width: 24,
                                color: draggableSnapshot?.isDragging
                                  ? 'transparent'
                                  : '#8A8A8A',
                                '&:hover': {
                                  cursor: 'grab',
                                  color: '#222',
                                },
                              }}
                            />
                          </span>
                          <StopInput
                            id={stop.id}
                            defaultValue={stop?.title}
                            handleUpdateStop={updateStop}
                            keepStop={stops?.length < 3}
                            handleDeleteStop={() => removeStop(stop.id)}
                            placeholder={
                              idx === 0
                                ? 'Search starting point'
                                : 'Search destination'
                            }
                            stopType={stop?.type}
                            shouldAutoFocus={
                              idx === stops?.filter((s) => !s?.type)?.length
                            }
                          />
                        </div>
                      )}
                    </Draggable>
                  </div>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <div
            className={classes.addButtonWrapper}
            style={{
              marginTop: 12,
            }}>
            <span
              style={{
                height: 1,
                width: '100%',
                borderTop: '1px dashed #D9D9D9',
              }}
            />
            <div
              onClick={addDefaultStop}
              style={{
                whiteSpace: 'nowrap',
                display: isStopsValid ? 'flex' : 'none',
                padding: '0px 8px',
                alignItems: 'center',
              }}>
              <span className={classes.addButton}>
                <AddRounded sx={{ fill: '#8A8A8A', height: 14, width: 14 }} />
              </span>{' '}
              Add more destinations
            </div>
            <span
              style={{
                height: 1,
                width: '100%',
                border: '1px dashed #D9D9D9',
              }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              paddingLeft: '24px',
              columnGap: '8px',
              marginTop: 16,
            }}>
            {Object.values(TRAVEL_MODES)?.map((travelOption) => {
              const isActive = travelMode === travelOption?.name;
              return (
                <Box
                  className={classes.travelModeButton}
                  sx={{
                    borderColor: isActive ? '#FFA766' : '#D9D9D9',
                  }}
                  onClick={() => handleTravelModeChange(travelOption?.name)}>
                  <travelOption.icon
                    sx={{
                      height: 24,
                      width: 24,
                      fill: isActive ? '#ED702E' : '#8A8A8A',
                    }}
                  />
                </Box>
              );
            })}
          </div>
        </div>
      </DragDropContext>
      {directionsError ? (
        <div style={{ paddingLeft: 24 }}>
          <div
            style={{
              display: 'flex',
              width: '100%',
              backgroundColor: '#E5493D',
              color: '#FFF',
              fontSize: 12,
              padding: '6px',
              marginTop: '12px',
              borderRadius: 4,
            }}>
            <ErrorRounded
              sx={{
                width: 16,
                height: 16,
                fill: '#FFF',
                marginRight: '8px',
              }}
            />
            Sorry! We couldn&apos;t find a route between your stops for that
            transportation mode.
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default DirectionsModal;
