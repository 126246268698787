import posthog from 'posthog-js';
import { initiateUserSession } from '../graphql/api';
import phTrackEvent from './posthog';

/*
  Function to identify the user on multiple providers
  - fullstory
  - heap
  - posthog
*/

const identifyUser = ({ uid, displayName, email, reloadUserInfo = {} }) => {
  try {
    if (
      !(
        process.env.REACT_APP_ENVIRONMENT === 'production' ||
        process.env.REACT_APP_ENVIRONMENT === 'staging'
      )
    )
      return;
    const { FS = null } = window;
    try {
      const userCreatedAt =
        reloadUserInfo?.createdAt && parseInt(reloadUserInfo.createdAt, 10);

      // eslint-disable-next-line
      if (posthog.__loaded) {
        posthog.identify(uid, {
          name: displayName,
          email,
          ...(userCreatedAt && { created_time: userCreatedAt }),
        });

        const sessionId = posthog.get_session_id();

        initiateUserSession(uid, sessionId)
          .then((result) => {
            if (result?.sessionCount) {
              posthog.setPersonProperties({
                session_count: result.sessionCount,
              });
            }
            if (result?.sessionCount) {
              phTrackEvent({
                event: 'session_start',
                meta: {
                  session_id: sessionId,
                  user_id: uid,
                },
              });
            }
          })
          .catch(() => {
            // handle error
          });

        posthog.register({
          email,
        });
      }
    } catch (err) {
      // handle error
    }

    try {
      if (window.heap && process.env.REACT_APP_ENVIRONMENT === 'production') {
        window.heap.identify(uid);
        window.heap.addUserProperties({
          name: displayName,
          email,
        });
      }
    } catch (err) {
      // handle error
    }

    try {
      if (FS && process.env.REACT_APP_ENVIRONMENT === 'production') {
        FS.identify(uid, {
          name: displayName,
          email,
        });
      }
    } catch (err) {
      // handle error
    }
  } catch (err) {
    // handle global analytics error
  }
};

export default identifyUser;
